import React, { useEffect, useRef, useState } from 'react';
import { Source, NavigationControl, Layer, GeolocateControl } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import {
  activeCommunityLayer,
  activityCircleLayerNamesArray,
  activityCircleLayersArray,
  activityTextLayersArray,
  communityClusterLayer,
  communityLayer,
  activeSlotsLayer,
  slotsLayer,
  communityClusterTextLayer,
  linesLayer,
  activitiesLayersTemplateConfig,
} from './layers';
import {
  createSelectedFeatureWithFriendlyFeatures,
  convertOrganisationsToFeatures,
  zoomCluster,
  focus,
  onLoadHandler,
  setSelectedFeatureOnMap,
  // createSlots,
  createSlotsAround,
  createConnectionLines,
  getActivityFeaturesAndActivityNameFromCoords,
  getSlotFeatureFromCoords,
} from './handlers';

import mapboxgl, { LngLatBounds } from 'mapbox-gl';
import type { Point } from 'mapbox-gl';
import OrgPopup from './components/OrgPopup/OrgPopup';
import styles from './Map.module.scss';
import Legend from './components/Legend/Legend';
import { useFeaturesReducer } from './hooks/useFeatureReducer';
import { map as _map, find, unionBy } from 'lodash';
import { UnionMapType } from './types';
import { useTranslation } from 'react-i18next';
import { CustomMap } from '../../shared/components/common/CustomMap/CustomMap';

const INITIAL_ZOOM = 5;
const MAX_ZOOM = 19;
const INIT_LAT = 50.089566;
const INIT_LONG = 19.939134;

// Component
const UnionMap: React.FC<UnionMapType> = ({ onMapRefresh, onMapClick, organisations }) => {
  const { t } = useTranslation('content');
  const [map, setMap] = useState<mapboxgl.Map>();
  const [zoom, setZoom] = useState(INITIAL_ZOOM);
  const [features, dispatch, featuresActions] = useFeaturesReducer();

  const asyncSetLines = (selectedFeature, friendlyFeatures) => {
    (async () => {
      const lines = await createConnectionLines(selectedFeature, friendlyFeatures, map);
      dispatch(featuresActions.setLines(lines ? lines : []));
    })();
  };

  const asyncSetSlots = (cluster) => {
    (async () => {
      const slots = await createSlotsAround(cluster, 'organisationsSource', 'slotsSource', map);
      dispatch(featuresActions.setSlots(slots as any));
    })();
  };

  //handle all interactions with the map
  const handleMapRefresh = () => {
    const bounds: LngLatBounds = map?.getBounds();
    onMapRefresh(bounds);
  };

  //first draw
  useEffect(() => {
    handleMapRefresh();
  }, [map]);

  useEffect(() => {
    organisations.selected
      ? dispatch(
          featuresActions.setOrganisations(unionBy(features.friendly, convertOrganisationsToFeatures([organisations.selected]), 'id'))
        )
      : dispatch(featuresActions.setOrganisations(unionBy(features.friendly, convertOrganisationsToFeatures(organisations.all), 'id')));
  }, [organisations.all, organisations.friendly, organisations.selected?.id, organisations]);

  useEffect(() => {
    features.selected && focus(unionBy([features.selected], features.friendly, 'id'), map);
  }, [features.selected?.id]);

  useEffect(() => {
    // select feature /////////////////
    if (organisations.friendly?.length && organisations.selected?.id) {
      var { friendlyFeatures } = createSelectedFeatureWithFriendlyFeatures(organisations.selected, organisations.friendly);
    }
    const selectedFeature = organisations.selected ? convertOrganisationsToFeatures([organisations.selected])?.[0] : null;
    setSelectedFeatureOnMap(features.selected, selectedFeature ?? null, map);
    dispatch(featuresActions.setSelected(selectedFeature ?? null));
    dispatch(featuresActions.setFriendly(friendlyFeatures ?? []));
    asyncSetLines(selectedFeature ?? null, friendlyFeatures ?? []);
  }, [organisations.selected?.id]);

  const handleMapClick = (e: mapboxgl.MapLayerMouseEvent) => {
    // clasterisation /////////////////
    const clickedCluster = map?.queryRenderedFeatures(e.point as Point, {
      layers: [communityClusterLayer.id],
    })?.[0];
    zoomCluster(clickedCluster, 'organisationsSource', map);
    // create slots ///////////////////
    if (zoom >= MAX_ZOOM) {
      asyncSetSlots(clickedCluster);
    }
    // click on slot ///////////////////
    const clickedSlotFeature = getSlotFeatureFromCoords(e.point, map);
    // !clickedSlotFeature && dispatch(featuresActions.setSlots([]));
    dispatch(featuresActions.setSlots([]));
    const communityFeatureEqualedToClickedSlot = clickedSlotFeature && find(features.organisations, ['id', clickedSlotFeature.id]);
    // activities click handle and click on custom community point ///////////////////
    const { activityFeatures, activityName } = getActivityFeaturesAndActivityNameFromCoords(e.point, map);
    const newlySelectedCommunityFeature = map?.queryRenderedFeatures(e.point as Point, {
      layers: [communityLayer.id],
    })?.[0];
    onMapClick(
      (communityFeatureEqualedToClickedSlot || newlySelectedCommunityFeature) ?? null,
      activityFeatures?.[0] ?? null,
      activityName
    );
  };

  const hoveredFeature = useRef<any>(null);
  const handleMouseMove = (e: mapboxgl.MapLayerMouseEvent) => {
    // hovered over organisation ////////////
    if (features.hovered) {
      e.target.getCanvas().style.cursor = 'grab';
    }
    features.hovered && dispatch(featuresActions.setHovered(null));

    const hoveredOrganisationFeature = map?.queryRenderedFeatures(e.point as Point, {
      layers: [communityLayer.id],
    })?.[0];

    if (hoveredOrganisationFeature) {
      e.target.getCanvas().style.cursor = 'pointer';
      dispatch(featuresActions.setHovered(find(organisations.all, ['id', hoveredOrganisationFeature.id])));
    }
    // hovered over actions /////////////
    const activityCircleFeatures = map?.queryRenderedFeatures(e.point as Point, {
      layers: [...activityCircleLayerNamesArray],
    });
    if (hoveredFeature.current) {
      map?.setFeatureState(hoveredFeature.current, { activityCircleHover: false });
      hoveredFeature.current = null;
      map.getCanvas().style.cursor = 'grab';
    }
    if (hoveredOrganisationFeature) {
      e.target.getCanvas().style.cursor = 'pointer';
      hoveredFeature.current = hoveredOrganisationFeature;
      map?.setFeatureState(hoveredOrganisationFeature, { activityCircleHover: true });
    }
    if (activityCircleFeatures?.length > 0) {
      e.target.getCanvas().style.cursor = 'pointer';
      hoveredFeature.current = activityCircleFeatures?.[0];
      map?.setFeatureState(activityCircleFeatures?.[0], { activityCircleHover: true });
      dispatch(featuresActions.setHovered(find(organisations.all, ['id', activityCircleFeatures?.[0].id])));
    }
  };

  const handleZoom = ({ target }) => {
    zoom !== target.getZoom() && setZoom(target.getZoom());
  };

  const handleZoomEnd = () => {
    handleMapRefresh();
    asyncSetLines(features.selected, features.friendly);
  };

  return (
    <CustomMap
      initialViewState={{
        latitude: INIT_LAT,
        longitude: INIT_LONG,
        zoom,
      }}
      onZoomEnd={handleZoomEnd}
      onDragEnd={handleMapRefresh}
      onZoom={handleZoom}
      onLoad={(e) => {
        handleMapRefresh();
        onLoadHandler(setMap)(e);
      }}
      onClick={handleMapClick}
      onMouseMove={handleMouseMove}
    >
      <Legend
        options={_map(activitiesLayersTemplateConfig, ({ activityName, countFieldName, color }) => ({
          title: `${t(`match.${activityName.toLocaleLowerCase()}`)} ${organisations.selected?.[countFieldName] || 0}`,
          color,
        }))}
      />
      <NavigationControl position="top-right" />
      <GeolocateControl />
      {(features.hovered || features.selected) && (
        <OrgPopup
          className={styles.fixedPopup}
          id={features.hovered?.id || organisations.selected?.id}
          name={features.hovered?.name || organisations.selected?.name}
          avatar={features.hovered?.avatar || organisations.selected?.avatar}
          description={features.hovered?.description || organisations.selected?.description}
          members_count={features.hovered?.members_count || organisations.selected?.members_count}
          members_new_count={features.hovered?.members_new_count || organisations.selected?.members_new_count}
        />
      )}

      <Source
        key="organisationsSource"
        id="organisationsSource"
        type="geojson"
        cluster={true}
        clusterMaxZoom={21}
        clusterRadius={30}
        data={{
          type: 'FeatureCollection',
          features: features.organisations,
        }}
      >
        <Layer {...communityLayer} />
        <Layer {...activeCommunityLayer} />
        <Layer {...communityClusterLayer} />
        <Layer {...communityClusterTextLayer} />
        {activityCircleLayersArray.map((layer) => (
          <Layer key={layer.id} {...layer} />
        ))}
        {activityTextLayersArray.map((layer) => (
          <Layer key={layer.id} {...layer} />
        ))}
      </Source>
      <Source
        key="slotsSource"
        id="slotsSource"
        type="geojson"
        data={{
          type: 'FeatureCollection',
          features: features.slots,
        }}
      >
        <Layer {...slotsLayer} />
        <Layer {...activeSlotsLayer} />
      </Source>

      <Source
        key="linesSource"
        id="linesSource"
        type="geojson"
        data={{
          type: 'FeatureCollection',
          features: features.lines,
        }}
      >
        <Layer {...linesLayer} />
      </Source>
    </CustomMap>
  );
};

export default UnionMap;
