import { IOrganisationWithPoint } from 'interfaces/organisation.interface';
import { httpService } from 'services';
import { Api } from './http.service';

type PointsArrowRequest = {
  polygon: string;
};

export const getOrgsOnTheArea = async (req: PointsArrowRequest): Promise<IOrganisationWithPoint[]> => {
  const response = await httpService.axios.get(`${Api.ORGANISATIONS}`, { params: { polygon: req.polygon, limit: 0 } });
  const data = response.data.data;
  return data;
};

export const getActsOnTheArea = async (req: PointsArrowRequest): Promise<IOrganisationWithPoint[]> => {
  const response = await httpService.axios.get(`${Api.ORGANISATIONS}`, { params: { polygon: req.polygon, limit: 0 } });
  const data = response.data.data;
  return data;
};

export const getFriendlyOrgs = async (orgId: string): Promise<IOrganisationWithPoint[]> => {
  const response = await httpService.axios.get(`${Api.ORGANISATIONS}/${orgId}/friends`);
  const data = response.data.data;
  return data;
};
