import { useState } from 'react';
import { Helmet } from 'react-helmet';
import styles from './Login.module.scss';
import { Tab } from 'shared/components/common/Tab/Tab';
import GoogleButton from 'common/GoogleButton/GoogleButton';
import { TwoFA } from './TwoFA/TwoFA';
import { WithEmail } from './WithEmail/WithEmail';
import { WithSSI } from './WithSSI/WithSSI';
import { useTranslation } from 'react-i18next';

type LoginProps = {
  setOutsidePanel: (panelNumber: number) => void;
  outsidePanel: number;
  isRedirectToAdmin?: boolean;
};

export type TwoFAProps = {
  login?: string;
  password?: string;
  isNeeded: boolean;
};

const INITIAL_TWOFA = {
  login: '',
  password: '',
  isNeeded: false,
};

export function Login({ setOutsidePanel, outsidePanel, isRedirectToAdmin = false }: LoginProps) {
  const [twoFaStep, setTwoFaStep] = useState<TwoFAProps>(INITIAL_TWOFA);
  const { t } = useTranslation('auth');
  return (
    <>
      <Helmet>
        <title>Вход</title>
      </Helmet>
      {twoFaStep.isNeeded ? (
        <div className={styles.Login}>{<TwoFA twoFaStep={twoFaStep} setTwoFaStep={setTwoFaStep} />}</div>
      ) : (
        <div className={styles.wrapper}>
          <GoogleButton />
          <Tab
            setOutsidePanel={setOutsidePanel}
            activeOutside={outsidePanel}
            className={styles.Login}
            tabPanels={[<WithEmail twoFaStep={twoFaStep} setTwoFaStep={setTwoFaStep} />, <WithSSI isAdminRedirect={isRedirectToAdmin} />]}
            tabList={[t('tabs.email'), t('tabs.noEmail')]}
          />
        </div>
      )}
    </>
  );
}
