import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = {
  visible: number;
  setVisible: (val: number) => Promise<void> | void;
};

export const PublicationVisibility = ({ setVisible, visible }: Props) => {
  const { t } = useTranslation('editor');
  const visibilityText = [
    {
      id: 0,
      label: t('visibility.all'),
    },
    {
      id: 1,
      label: t('visibility.users'),
    },
    {
      id: 2,
      label: t('visibility.organization'),
    },
    /* {
      id: 3,
      label: t('visibility.admin'),
    }, */
  ];
  return (
    <FormControl fullWidth>
      <InputLabel id="viability-selection">{t('vision')}</InputLabel>
      <Select
        labelId="viability-selection"
        value={visible}
        label={t('vision')}
        required
        onChange={(ev) => setVisible(Number(ev.target.value))}
      >
        {visibilityText.map((el) => {
          return (
            <MenuItem value={el.id} key={`org-${el.id}`}>
              {el.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
