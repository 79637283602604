import TwoFactorAuthentication from './TwoFactorAuthentication/TwoFactorAuthentication';
import styles from './Settings.module.scss';
// import Notifications from './Notifications/Notifications';
import EditUserEmail from './EditUserEmail/EditUserEmail';
import EditUserPassword from './EditPassword/EditUserPassword';
import SEO from 'shared/utils/Seo';
import FeedFiltration from './FeedFiltration/FeedFiltration';

function Settings() {
  return (
    <div className={styles.wrapper}>
      <SEO image={'/logo-small.svg'} title={'Настройки'} />
      <FeedFiltration />
      {/* <Notifications /> */}
      <TwoFactorAuthentication />
      <EditUserEmail />
      <EditUserPassword />
    </div>
  );
}

export default Settings;
