import { useContext, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { GlobalContext } from 'contexts/GlobalContext';
import { routes } from 'Routes';
import { toast } from 'react-toastify';
import { GlobalDataContext } from 'contexts/GlobalDataContext';
import { useTranslation } from 'react-i18next';

export function ResetPassword() {
  const {
    services: { authService },
    spinner: { showSpinner, hideSpinner },
  } = useContext(GlobalContext);
  const { t } = useTranslation('auth');
  const { auth } = useContext(GlobalDataContext);
  const { token } = useParams<{ token: string }>();
  const history = useHistory();
  const urlParams = new URLSearchParams(history.location.search);
  const email = urlParams.get('email');

  const resetPassword = async () => {
    if (token && email) {
      try {
        showSpinner();
        await authService.resetPassword(email, token);
        auth.authenticate();
        history.push(routes.settings);
        toast(
          <>
            <p>{t('resetPassword.toasts.reset')}</p>
            <p>
              {t('resetPassword.toasts.edit')}
              <Link to={routes.settings}>{t('resetPassword.toasts.settings')}</Link>
            </p>
          </>
        );
      } catch (error) {
        console.error(error);
        history.push(routes.LOGIN);
      } finally {
        hideSpinner();
      }
    }
  };

  useEffect(() => {
    resetPassword();
  }, []);

  return (
    <>
      {/* <div className={styles.ResetPassword}>
        <h3>пароль успешно сгенерирован</h3>
        <Input
          name="password"
          type="password"
          valueChange={(value) => { }}
          value={values.password}
          label="Пароль"
          description="Введите ваш пароль"
          onTouch={() => setFieldTouched('password')}
          error={errors?.password && touched?.password && errors.password}
          required={true}
          showPassword={true}
        />

        <div className={styles.actions}>
          <p>
            <Icon iconName="exclamation" color="primary" />
            <span>Вы можете изменить пароль из личного профиля</span>
          </p>
          <Button onClick={() => validateForm().then(() => handleSubmit())} type="submit">
            Продолжить
          </Button>
        </div>
      </div> */}
    </>
  );
}
