import styles from './Settings.module.scss';
import { useState } from 'react';
import { useSelector, useDispatch } from '../../../../redux-toolkit';
import { ReactComponent as BackIcon } from '../../../icons/arrow-square-down.svg';
import { Radio } from 'shared/components/common/Radio/Radio';
import OrganizationList from './List';
import * as chatService from 'shared/services/chat.service';
import { toast } from 'react-toastify';
import { Button } from 'shared/components/common/Button/Button';
import { setUserData } from 'shared/slices/chat';
import isEqual from 'lodash/isEqual';
import { useTranslation } from 'react-i18next';

function Settings({ setIsSettingModalOpened }) {
  const { t } = useTranslation('shared');
  const dispatch = useDispatch();
  const user = useSelector((state) => state.chat.user);
  const isAdmin = useSelector((state) => !!state.chat.organizationId);
  const { settings } = user;
  const [value, setValue] = useState<string | number>(settings?.chats?.mode || 1);
  const [values, setValues] = useState(settings?.chats?.list || []);
  const getSettings = () => {
    const settings = [
      {
        id: 1,
        label: t('chat.settings.all'),
      },
      {
        id: 2,
        label: t('chat.settings.member'),
        components: <OrganizationList values={values} setValues={setValues} />,
      },
    ];
    if (!isAdmin) {
      settings.push({
        id: 3,
        label: t('chat.settings.admin'),
        components: <OrganizationList values={values} setValues={setValues} />,
      });
    }
    settings.push({
      id: 4,
      label: t('chat.settings.noOne'),
    });
    return settings;
  };
  const options = getSettings();
  const saveSettings = () => {
    chatService.updateSettings(value, values);
    dispatch(setUserData({ ...user, settings: { ...settings, chats: { mode: value, list: values } } }));
    setIsSettingModalOpened(false);
    toast(t('toast.updateSettings'));
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.ChatSidebarContainer}>
        <div className={styles.ChatSidebarHeader}>
          <h3>{t('chat.settings.title')}</h3>

          <button
            className={styles.BackIcon}
            onClick={() => {
              setIsSettingModalOpened(false);
            }}
          >
            <BackIcon />
          </button>
        </div>
        <Radio
          options={options}
          autoFocus
          className={styles.radio}
          classNameWrapper={styles.radioWrapper}
          name="reason"
          value={value}
          valueChange={(value, name) => {
            setValue(value);
            setValues([]);
          }}
        />
      </div>
      <Button
        onClick={saveSettings}
        className={styles.saveButton}
        disabled={settings?.chats?.mode === value && isEqual(settings?.chats?.list, values)}
      >
        {t('actions.save')}
      </Button>
    </div>
  );
}

export default Settings;
