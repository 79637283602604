import { httpService } from 'services';
import { IContentPreview } from 'shared/interfaces/feed';
import { Api } from './http.service';
import { ActivityType } from 'interfaces/streamContent';

interface IGetFeed {
  beforeTime: Date;
  limit?: number;
  organizations?: 0 | 1; //0 - for content from whole service, 1 - from your orgs
  organizationId?: number;
  username?: string;
}

interface IUploadPost {
  content: string;
  images: string[];
}

export const getFeed = async ({
  beforeTime,
  limit = 10,
  organizations,
  activity,
}: Omit<IGetFeed, 'organizationId' | 'username'> & {
  activity?: ActivityType;
}) => {
  const response = await httpService.axios.get<{
    data: IContentPreview[];
  }>(`${Api.FEED}`, {
    params: { before: beforeTime, limit, organizations, activity },
  });
  return response.data.data;
};

export const getOrgFeed = async ({ beforeTime, limit = 10, organizationId }: IGetFeed): Promise<IContentPreview[]> => {
  const response = await httpService.axios.get(`${Api.ORGANISATIONS}/${organizationId}/feed`, {
    params: { before: beforeTime, limit },
  });
  const data = response.data.data;
  return data;
};

export const getUserFeed = async ({
  beforeTime,
  limit = 10,
  organizations,
  organizationId,
  username,
}: IGetFeed): Promise<IContentPreview[]> => {
  const response = await httpService.axios.get(`${Api.PROFILE}/${username}/feed`, {
    params: { before: beforeTime, limit, organizations, organizationId },
  });
  const data = response.data.data;
  return data;
};

export const getFeedTG = async (): Promise<string> => {
  const response = await httpService.axios.post(`${Api.UPDATE_SETTINGS}/telefeed`);
  const data = response.data.url;
  return data;
};

export const uploadPost = async ({ content, images }: IUploadPost): Promise<void> => {
  await httpService.axios.post(`${Api.POSTS}`, { content, images });
};
export const deletePost = async (postId: string): Promise<void> => {
  await httpService.axios.delete(`${Api.POSTS}/${postId}`);
};
