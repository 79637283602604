import styles from '../NewsDetails/NewsDetails.module.scss';
import { useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import formatServerError from 'shared/utils/formatServerError';
import { useSsrEffect, useSsrState } from '@issr/core';
import { routes } from 'Routes';
import { toast } from 'react-toastify';
import { GlobalContext } from 'contexts/GlobalContext';
import { getFundraisings } from 'services/finance';
import { PaymentCreated, PaymentTypeEnum } from 'shared/interfaces/finance';
import { getPoll } from 'services/polls';
import { PollFront } from 'shared/interfaces/polls';
import { EnterOrganizationStatuses, IOrganizationEnterRequest } from 'interfaces/organisation.interface';
import { RoutingContainer } from 'common/Routing/RoutingContainer/RoutingContainer';
import ContentLayout from 'containers/ContentLayout/ContentLayout';
import { PollContent } from './PollContent';
import SEO from 'shared/utils/Seo';

type ILogo = {
  src: string;
  alt: string;
  href?: string;
};

const Poll = ({ closed }: { closed?: boolean }) => {
  const {
    services: { organisationsService },
  } = useContext(GlobalContext);
  const { organizationId, pollId } = useParams<{ organizationId: string; pollId: string }>();
  const [poll, setPoll] = useSsrState<PollFront>(null);
  const [logo, setLogo] = useSsrState<ILogo>(null);
  const [serverError, setServerError] = useSsrState(null);
  const [payments, setPayments] = useSsrState<PaymentCreated[]>([]);
  const [requestStatus, setRequestStatus] = useSsrState<IOrganizationEnterRequest | null>(undefined);
  const userInOrganisation = requestStatus?.status === EnterOrganizationStatuses.active;

  const getOrganizationEnterRequest = async (id: number) => {
    try {
      const enterRequestStatus = await organisationsService.getOrganizationEnterRequestById(id);
      setRequestStatus(enterRequestStatus);
    } catch (e) {
      if (e.request.status === 404) {
        setRequestStatus(null);
        return;
      }
      toast.error(formatServerError(e));
    }
  };

  useEffect(() => {
    if (!!organizationId) {
      (async () => {
        try {
          await getOrganizationEnterRequest(+organizationId);
          const fundraisings = await getFundraisings(+organizationId);
          setPayments(fundraisings);
        } catch (e) {
          toast.error(formatServerError(e));
          console.log(formatServerError(e));
        }
      })();
    }
  }, [organizationId]);

  async function loadPolls() {
    const response = await getPoll(+organizationId, +pollId);
    setPoll(response);
    setLogo({
      src: response.organization?.avatar,
      href: routes.union.getLink(organizationId),
      alt: response.organization?.name || response.organization?.short_name,
    });
  }

  useSsrEffect(async () => {
    try {
      await loadPolls();
    } catch (e) {
      console.log(formatServerError(e));
      setServerError(formatServerError(e));
    }
  });

  useEffect(() => {
    if (!!userInOrganisation) {
      (async () => {
        try {
          await loadPolls();
        } catch (e) {
          toast.error(formatServerError(e));
        }
      })();
    }
  }, [pollId, userInOrganisation, requestStatus]);

  const findPayment = payments
    ?.filter((payment) => !!payment.auto_payments?.length || !!payment.manual_payments?.length)
    ?.find((payment) => payment.type === PaymentTypeEnum.fundraising);

  if (!poll || !logo) {
    return null;
  }

  return (
    <RoutingContainer showCloseButton title={poll.organization?.short_name || poll?.organization?.name} className={styles.main}>
      {poll?.id && (
        <SEO
          image={poll.images[0]?.url || '/logo-small.svg'}
          title={poll.name}
          description={poll.description}
          creator={poll.user.public_name}
        />
      )}
      {serverError && <p>{serverError}</p>}
      {poll?.id && (
        <>
          <ContentLayout
            title={poll.name}
            contentId={poll.id}
            orgId={poll.organization.id}
            contentType={'quiz'}
            content={poll.description}
            user={{
              id: poll.user.id,
              public_avatar: poll.user.public_avatar,
              public_family: poll.user.public_family,
              public_name: poll.user.public_name,
            }}
            published_at={poll.published_at}
            logo={logo}
            choosePayment={findPayment}
            uniqueContent={<PollContent poll={poll} closed={closed} />}
          />
        </>
      )}
    </RoutingContainer>
  );
};

export default Poll;
