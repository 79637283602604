import { useEffect, useRef } from 'react';
import { routes } from 'Routes';
import { useLocation, useParams } from 'react-router-dom';
import { useSsrEffect, useSsrState } from '@issr/core';
import { toast } from 'react-toastify';
import formatServerError from 'utils/formatServerError';
import { getFundraisings } from 'services/finance';
import SEO from 'shared/utils/Seo';
import ContentLayout from 'containers/ContentLayout/ContentLayout';
import { PaymentCreated, PaymentTypeEnum } from 'shared/interfaces/finance';
import { RoutingContainer } from 'common/Routing/RoutingContainer/RoutingContainer';
import { ILogo } from 'containers/ContentLayout/ContentLayoutTypes';
import { getEvent } from 'services/events';
import { IEvents } from 'interfaces/events.interface';
import { useTranslation } from 'react-i18next';

function EventsDetails() {
  const { t } = useTranslation('content');
  const { orgId, eventId } = useParams<{ orgId: string; eventId: string }>();
  const [event, setEvent] = useSsrState<IEvents>(undefined);
  const [logo, setLogo] = useSsrState<ILogo>(undefined);
  const [payments, setPayments] = useSsrState<PaymentCreated[]>([]);

  const { state } = useLocation<{ from?: string }>();

  useEffect(() => {
    (async () => {
      try {
        if (!orgId) return;
        const fundraisings = await getFundraisings(+orgId);
        setPayments(fundraisings);
      } catch (e) {
        toast.error(formatServerError(e));
        console.log(formatServerError(e));
      }
    })();
  }, [orgId]);

  const returnPage = useRef(null);

  useEffect(() => {
    if (state?.from) {
      returnPage.current = state.from;
    }
  }, []);

  async function loadEvent() {
    const response = await getEvent(+orgId, +eventId);
    setEvent(response);
    setLogo({
      src: response.organization?.avatar,
      href: routes.union.getLink(orgId),
      alt: response.organization.name,
    });
  }

  useSsrEffect(async () => {
    try {
      if (eventId && orgId) {
        await loadEvent();
      }
    } catch (e) {
      console.log(formatServerError(e));
    }
  }, eventId);

  useEffect(() => {
    (async () => {
      try {
        if (eventId && orgId) {
          await loadEvent();
        }
      } catch (e) {
        toast.error(formatServerError(e));
      }
    })();
  }, [eventId, orgId]);

  const findPayment = payments
    ?.filter((payment) => !!payment.auto_payments?.length || !!payment.manual_payments?.length)
    ?.find((payment) => payment.type === PaymentTypeEnum.fundraising);

  return (
    <RoutingContainer withFooter={false} showCloseButton={true} title={t('match.event')}>
      <SEO title={event?.title} description={event?.excerpt || event?.title} image={event?.preview} />
      {event && (
        <ContentLayout
          title={event.title}
          contentId={event.id}
          orgId={event.organization.id}
          contentType={'event'}
          content={event.content}
          user={event.user}
          published_at={event.published_at}
          // tags={event.tags}
          logo={logo}
          choosePayment={findPayment}
          eventExtra={{
            price: event.price,
            place: event.place,
            date_end: event.date_end,
            date_start: event.date_start,
            point: event.point,
            needRoyPass: event.needRoyPass,
            is_active: event.is_active,
          }}
        />
      )}
    </RoutingContainer>
  );
}

export default EventsDetails;
