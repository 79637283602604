import styles from './RulesService.module.scss';
import { Contents, IContent } from '../../common/Contents/Contents';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

export function RulesService() {
  const { t } = useTranslation('rules');
  const tableOfContents: IContent[] = [
    {
      id: 'general-information',
      label: t('general.label'),
    },
    {
      id: 'introduction',
      label: t('introduction.label'),
    },
    {
      id: 'responsibilities',
      label: t('responsibilities.label'),
    },
    {
      id: 'rights-and-restrictions',
      label: t('rights.label'),
    },
    {
      id: 'limitation-of-liability',
      label: t('liability.label'),
    },
    {
      id: 'termination-of-an-agreement',
      label: t('agreement.label'),
    },
    {
      id: 'settlement-of-disputes',
      label: t('settlement.label'),
    },
    {
      id: 'general-provisions',
      label: t('provisions.label'),
    },
    {
      id: 'permissions-and-prohibitions',
      label: t('prohibitions.label'),
    },
    {
      id: 'claims',
      label: t('claims.label'),
    },
    {
      id: 'contacts',
      label: t('contacts.label'),
    },
  ];
  return (
    <div className={styles.RulesService}>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>

      <aside>
        <Contents contents={tableOfContents} />
      </aside>
      <main className="p-left p-right">
        <h2 id="general-information">{t('title')}</h2>
        <br />
        <h3>{t('comeIntoForce')}</h3>
        <p>{t('manifest')}</p>
        <br />
        <br />
        <h2 id="introduction">{t('introduction.title')}</h2>
        <br />
        <h3>{t('introduction.content.contract.title')}</h3>
        <p>
          {t('introduction.content.contract.text.1')}
          <br />
          {t('introduction.content.contract.text.2')}
        </p>
        <br />
        <h3>{t('introduction.content.services.title')}</h3>
        <p>{t('introduction.content.services.text')}</p>
        <br />
        <h3>{t('introduction.content.roy.title')}</h3>
        <p>
          {t('introduction.content.roy.text.1')}
          <br />
          {t('introduction.content.roy.text.2')}
          <br />
          <br />
          {t('introduction.content.roy.text.3')}
          <br />
          <br />
          {t('introduction.content.roy.text.4')}
        </p>
        <br />
        <h3>{t('introduction.content.members.title')}</h3>
        <p>{t('introduction.content.members.text')}</p>
        <br />
        <h3>{t('introduction.content.changes.title')}</h3>
        <p>
          {t('introduction.content.changes.text.1')}
          <br />
          <br />
          {t('introduction.content.changes.text.2')}
        </p>
        <br />
        <br />
        <h2 id="responsibilities">{t('responsibilities.title')}</h2>
        <br />
        <h3>{t('responsibilities.content.conditions.title')}</h3>
        <p>
          {t('responsibilities.content.conditions.text.1')}
          <br />
          {t('responsibilities.content.conditions.text.2')}
          <br />
          {t('responsibilities.content.conditions.text.3')}
          <br />
          {t('responsibilities.content.conditions.text.4')}
          <br />
          {t('responsibilities.content.conditions.text.5')}
        </p>
        <br />
        <h3>{t('responsibilities.content.account.title')}</h3>
        <p>
          {t('responsibilities.content.account.text.1')}
          <br />
          {t('responsibilities.content.account.text.2')}
          <br />
          {t('responsibilities.content.account.text.3')}
          <br />
          {t('responsibilities.content.account.text.4')}
        </p>
        <br />
        <h3>{t('responsibilities.content.payment.title')}</h3>
        <p>{t('responsibilities.content.payment.text')}</p>
        <br />
        <h3>{t('responsibilities.content.messages.title')}</h3>
        <p>
          {t('responsibilities.content.messages.text.1')}
          <br />
          {t('responsibilities.content.messages.text.2')}
          <br />
          {t('responsibilities.content.messages.text.3')}
        </p>
        <br />
        <h3>{t('responsibilities.content.information.title')}</h3>
        <p>
          {t('responsibilities.content.information.text.1')}
          <br />
          {t('responsibilities.content.information.text.2')}
        </p>
        <br />
        <br />
        <h2 id="rights-and-restrictions">{t('rights.title')}</h2>

        <br />
        <h3>{t('rights.content.license.title')}</h3>
        <p>
          {t('rights.content.license.text.1')}
          <br />
          {t('rights.content.license.text.2')}
          <br />
          <br />
          <ul className="p-0">
            <div className={styles.card}>
              <li>{t('rights.content.license.list.1')}</li>
            </div>
            <div className={styles.card}>
              <li>
                {`${t('rights.content.license.list.2.text')} `}
                <a href="https://creativecommons.org/choose/" target="_blank" rel="noreferrer">
                  {t('rights.content.license.list.2.link')}
                </a>
              </li>
            </div>
            <div className={styles.card}>
              <li>{t('rights.content.license.list.3')}</li>
            </div>{' '}
            <div className={styles.card}>
              <li>{t('rights.content.license.list.4')}</li>
            </div>{' '}
            <div className={styles.card}>
              <li>{t('rights.content.license.list.5')}</li>
            </div>
            <div className={styles.card}>
              <li>{t('rights.content.license.list.6')}</li>
            </div>
            <div className={styles.card}>
              <li>{t('rights.content.license.list.7')}</li>
            </div>
          </ul>
        </p>
        <br />
        <h3>{t('rights.content.availability.title')}</h3>
        <p>{t('rights.content.availability.text')}</p>
        <br />
        <h3>{t('rights.content.thirdParty.title')}</h3>
        <p>
          {t('rights.content.thirdParty.text.1')}
          <br />
          {t('rights.content.thirdParty.text.2')}
          <br />
          {t('rights.content.thirdParty.text.3')}
          <br />
          {t('rights.content.thirdParty.text.4')}
          <br />
          {t('rights.content.thirdParty.text.5')}
        </p>
        <br />
        <h3>{t('rights.content.limitations.title')}</h3>
        <p>
          {t('rights.content.limitations.text.1')}
          <br />
          {t('rights.content.limitations.text.2')}
        </p>
        <br />
        <h3>{t('rights.content.property.title')}</h3>
        <p>
          {t('rights.content.property.text.1')}
          <br />
          {t('rights.content.property.text.2')}
        </p>
        <br />
        <h3>{t('rights.content.automatization.title')}</h3>
        <p>
          {t('rights.content.automatization.text.1')}
          <br />
          {t('rights.content.automatization.text.2')}
        </p>
        <br />
        <br />
        <h2 id="limitation-of-liability">{t('liability.label')}</h2>
        <br />
        <h3>{t('liability.content.guarantees.title')}</h3>
        <p>
          {t('liability.content.guarantees.text.1')}
          <br />
          <br />
          {t('liability.content.guarantees.text.2')}
          <br />
          {t('liability.content.guarantees.text.3')}
        </p>

        <br />
        <h3>{t('liability.content.except.title')}</h3>
        <p>
          {t('liability.content.except.text.1')}
          <br />
          {t('liability.content.except.text.2')}
        </p>
        <br />
        <h3>{t('liability.content.deals.title')}</h3>
        <p>
          {t('liability.content.deals.text.1')}
          <br />
          {t('liability.content.deals.text.2')}
        </p>
        <br />
        <br />
        <h2 id="termination-of-an-agreement">{t('agreement.label')}</h2>
        <br />
        <p>{t('agreement.text')}</p>
        <br />
        <br />
        <h2 id="settlement-of-disputes">{t('settlement.title')}</h2>
        <br />
        <p>{t('settlement.text')}</p>
        <br />
        <br />
        <h2 id="general-provisions">{t('provisions.label')}</h2>
        <br />

        <p>
          {t('provisions.text.1')}
          <br />
          {t('provisions.text.2')}
          <br />
          {t('provisions.text.3')}
          <br />
          {t('provisions.text.4')}
          <br />
          {t('provisions.text.5')}
        </p>
        <br />
        <br />
        <h2 id="permissions-and-prohibitions">{t('prohibitions.title')}</h2>
        <br />
        <p>{t('prohibitions.text')}</p>
        <br />
        <h3>{t('prohibitions.content.allowed.title')}</h3>
        <p>{t('prohibitions.content.allowed.text')}</p>
        <ul className={styles.list}>
          <li>{t('prohibitions.content.allowed.list.1')}</li>
          <li>{t('prohibitions.content.allowed.list.2')}</li>
          <li>{t('prohibitions.content.allowed.list.3')}</li>
        </ul>

        <br />
        <h3>{t('prohibitions.content.forbidden.title')}</h3>
        <p>{t('prohibitions.content.forbidden.text')}</p>
        <br />
        <ul className={styles.list}>
          <li>{t('prohibitions.content.forbidden.list.1')}</li>
          <li>{t('prohibitions.content.forbidden.list.2')}</li>
          <li>{t('prohibitions.content.forbidden.list.3')}</li>
          <li>{t('prohibitions.content.forbidden.list.4')}</li>
          <li>{t('prohibitions.content.forbidden.list.5')}</li>
          <li>{t('prohibitions.content.forbidden.list.6')}</li>
          <li>{t('prohibitions.content.forbidden.list.7')}</li>
          <li>{t('prohibitions.content.forbidden.list.8')}</li>
          <li>{t('prohibitions.content.forbidden.list.9')}</li>
          <li>{t('prohibitions.content.forbidden.list.10')}</li>
          <li>{t('prohibitions.content.forbidden.list.11')}</li>
          <li>{t('prohibitions.content.forbidden.list.12')}</li>
          <li>{t('prohibitions.content.forbidden.list.13')}</li>
          <li>{t('prohibitions.content.forbidden.list.14')}</li>
          <li>{t('prohibitions.content.forbidden.list.15')}</li>
          <li>{t('prohibitions.content.forbidden.list.16')}</li>
        </ul>
        <br />
        <br />
        <h2 id="claims">{t('claims.title')}</h2>
        <br />
        <p>
          {t('claims.text.1')}
          <br />
          <br />
          {t('claims.text.2')}
        </p>
        <br />
        <br />
        <h2 id="contacts">{t('contacts.title')}</h2>
        <br />
        <h3>{t('contacts.text')}</h3>
      </main>
    </div>
  );
}
