import { Link } from 'react-router-dom';
import { IOrganisation } from 'interfaces/organisation.interface';
import { Icon } from 'shared/components/common/Icon/Icon';
import { Icons } from 'shared/components/common/Icon/Icon.interface';
import { routes } from 'Routes';
import styles from './AllianceInfoOnMap.module.scss';
import { ReactElement, useContext } from 'react';
import { GlobalDataContext } from 'contexts/GlobalDataContext';
import classNames from 'classnames';
import { BannerSlideShow } from 'common/BannerSlideShow/BannerSlideShow';
import { CustomImage } from 'shared/components/common/CustomImage/CustomImage';
import { useTranslation } from 'react-i18next';

interface AllianceInfoMapProps {
  organisation: IOrganisation;
  id: number;
  index?: number;
  state?: 'active';
  onMapIconClick?: (e: React.MouseEvent<HTMLButtonElement>, organisationId: string | number) => void;
}

const AllianceInfoOnMap = ({ organisation, index, id, state, onMapIconClick }: AllianceInfoMapProps): ReactElement => {
  const { t } = useTranslation('map');
  const { id: organisationId } = organisation;
  const { user } = useContext(GlobalDataContext);

  const handleMapIconClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    onMapIconClick?.(e, organisationId);
  };

  const bannersImages = organisation?.banners?.filter((banner) => !!banner.small || !!banner.large).map((banner) => banner.large);

  return (
    <Link data-id={id} to={routes.union.getLink(organisationId)} className={styles.wrapper}>
      <div className={classNames(styles.wallpaper)}>
        <BannerSlideShow images={bannersImages} />
      </div>
      <div className={classNames(styles.wrapperCard, { [styles.active]: state === 'active' })}>
        <div className={styles.header}>
          <CustomImage isOrganization width={50} height={50} src={organisation.avatar} alt={organisation.short_name} rounded />
          {!!user && (
            <Link
              to={{
                pathname: routes.chat,
                search: `id=${organisationId}&type=organization`,
              }}
            >
              <Icon iconName={Icons.entryChat} className={styles.chatIcon} />
            </Link>
          )}
          <div className={styles.headerText}>
            <h2>{organisation.name.toUpperCase()}</h2>
          </div>
        </div>

        <div className={styles.mainInfo}>
          {organisation.description && (
            <div className={styles.focusWrapper}>
              <p className={styles.mainInfoText}>{organisation.description}</p>
            </div>
          )}
        </div>

        <div className={styles.wrapperOrganizationShortInfo}>
          <div className={styles.organizationShortInfo}>
            <p className={styles.shortInfoTitle}>{t('subscribers')}</p>
            <span className={styles.organizationCount}>{organisation?.members_count || 0}</span>
          </div>
          <div className={styles.organizationShortInfo}>
            <p className={styles.shortInfoTitle}>{t('activity')}</p>
            {/* add after back normal count */}
            <span className={styles.organizationCount}>{organisation?.members_new_count || 0}</span>
          </div>
          {organisation.point && (
            <button className={styles.showOnMapButton} onClick={handleMapIconClick}>
              <p className={styles.showOnMapButtonTitle}>{t('map')}</p>
              <Icon iconName={Icons.location} />
            </button>
          )}
        </div>
      </div>
    </Link>
  );
};

export default AllianceInfoOnMap;
