import React from 'react';
import cn from 'classnames';
import styles from './Spinner.module.scss';

type ISpinnerProps = {
  color?: 'white' | 'grey' | 'red' | 'purple';
  size?: number;
  borderWidth?: string;
  position?: 'default' | 'center'
};

export function Spinner({ color = 'red', size = 18, borderWidth = '3px', position = 'default' }: ISpinnerProps) {
  return (
    <div className={styles.SpinnerContainer} style={{
      width: position === 'center' ? '100%' : 'auto'
    }}>
      <div
        className={cn(styles.Spinner, {
          [styles[color]]: !!color,
        })}
        style={{ width: size, height: size, borderWidth: borderWidth, margin: position === 'center' ? '0 auto' : 0 }}
      />
    </div>
  );
}
