import React from 'react';
import { FunctionComponent } from 'react';
import { RegovWidgetAuthProps } from '../types';
import { Suspensed } from 'components/Suspense/Suspense';

const SelfRegistration: FunctionComponent<RegovWidgetAuthProps> = React.lazy(() => import('./SelfRegistration'));

export const SelfRegistrationWidget = (props: RegovWidgetAuthProps) => {
  return (
    <Suspensed fallback={<div>Loading...</div>}>
      <SelfRegistration {...props} />
    </Suspensed>
  );
};
