import { useContext, useState } from 'react';
import { Icon } from 'shared/components/common/Icon/Icon';
import { Button } from 'shared/components/common/Button/Button';
import { GlobalContext } from 'contexts/GlobalContext';
import { InputWithButton } from 'common/InputWithButton/InputWithButton';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { REGISTRATION_PUBLIC_NAME_SS_KEY } from 'constans/storage';
import styles from './StepConfirmEmail.module.scss';
import { toast } from 'react-toastify';
import { routes } from 'Routes';
import { useTranslation } from 'react-i18next';

export function StepConfirmEmail() {
  const { t } = useTranslation('settings');
  const {
    services: { authService },
    spinner: { showSpinner, hideSpinner },
  } = useContext(GlobalContext);

  // const history = useHistory();
  // useEffect(() => {
  //   const unblock = history.block();
  //   return () => {
  //     unblock();
  //   };
  // }, []);
  const [asyncErrors, setAsyncErrors] = useState<{ [key: string]: string }>({});

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .trim()
      .email(t('errors.invalidEmail'))
      .max(100, `${t('errors.max')} 100 ${t('errors.symbols')}`)
      .not([authService.registrationUserData.email], t('errors.notUniqEmail')),
  });

  const { errors, touched, values, handleSubmit, setFieldValue, isValid, validateForm, setFieldTouched } = useFormik({
    initialValues: { newEmail: '' },
    validationSchema,
    validate: () => {
      return asyncErrors;
    },
    onSubmit: async (values, actions) => {
      showSpinner();
      const result = await authService.resendEmail(authService.registrationUserData.email, values.newEmail).finally(() => hideSpinner());
      if (result.ok) {
        authService.registrationUserData = { email: values.newEmail };
        toast(t('confirmEmail.sending'));
      }
      hideSpinner();
    },
  });

  const resendEmail = async () => {
    showSpinner();
    const { email } = authService.registrationUserData;
    const result = await authService.resendEmail(email);

    if (result.ok) {
      toast(t('confirmEmail.sending'));
    }
    hideSpinner();
  };

  const publicName = sessionStorage.getItem(REGISTRATION_PUBLIC_NAME_SS_KEY);

  return (
    <div className={styles.StepConfirmEmail}>
      <div className={styles.content}>
        <div className={styles.icon}>
          <Icon iconName={'logoSmall'} width={200} height={100} />
        </div>
        <p>
          {publicName ? (
            <>
              <strong className={styles.publicName}>{publicName}</strong>,{`${t('confirmEmail.you').toLowerCase()} `}
            </>
          ) : (
            `${t('confirmEmail.you')} `
          )}
          {`${t('confirmEmail.onEmail')} `}
          <a className="highlight" target="_blank" rel="noreferrer" href={`mailto:${authService.registrationUserData?.email}`}>
            {authService.registrationUserData?.email}{' '}
          </a>
          {` ${t('confirmEmail.letter')} `}
          <Button onClick={resendEmail} className={styles.btnResend}>
            {t('confirmEmail.resend')}
          </Button>
          <Button to={routes.LOGIN} color="light" className={styles.btnResend}>
            {t('confirmEmail.enter')}
          </Button>
        </p>
      </div>

      <div className={styles.actions}>
        <InputWithButton
          inputProps={{
            name: 'newEmail',
            value: values.newEmail,
            valueChange: (value) => {
              delete asyncErrors.newEmail;
              validateForm();
              setFieldValue('newEmail', value, true);
              setAsyncErrors({ ...asyncErrors });
            },
            label: t('confirmEmail.change'),
            description: '',
            type: 'email',
            onTouch: () => {
              setFieldTouched('newEmail');
            },
            error: errors?.newEmail && touched?.newEmail && errors.newEmail,
          }}
          buttonProps={{
            children: t('confirmEmail.send'),
            onClick: () => validateForm().then(() => handleSubmit()),
            type: 'submit',
            disabled: !isValid || !values.newEmail.trim(),
          }}
        />
      </div>
    </div>
  );
}
