import classNames from 'classnames';
import { CustomSlider } from 'components/CustomSlider/CustomSlider';
import { useState } from 'react';
import { Icon } from 'shared/components/common/Icon/Icon';
import { NewsInterface } from 'shared/interfaces/news';
import styles from './NewsSlider.module.scss';
import { routes } from 'Routes';
import { NewsCard } from 'components/Crads/NewsCards';
import { useTranslation } from 'react-i18next';

type CustomMembersProps = {
  slidesOnPage: number;
  isMobile: boolean;
  news: NewsInterface[];
  loadNews: () => void;
  hasMore?: boolean;
};

export const NewsSlider = ({ slidesOnPage = 2, isMobile, news, loadNews, hasMore = false }: CustomMembersProps) => {
  const { t } = useTranslation('roy-global');
  const [activeSlide, setActiveSlide] = useState<number>(0);

  const nextSlide = () => {
    setActiveSlide(activeSlide + 1);
    if (activeSlide + 2 * slidesOnPage === news.length && hasMore) {
      loadNews();
    }
  };
  const prewSlide = () => {
    setActiveSlide(activeSlide - 1);
  };

  const mainSlides = news.map((el, index) => {
    return (
      <NewsCard
        variant="main"
        content={el.excerpt}
        image={el.image}
        title={el.title}
        author={{ id: el.user_id.id, logo: el.user_id.public_avatar, name: el.user_id.public_name }}
        commentsCount={el.comments_count || 0}
        organization={{ id: el.organization.id, logo: el.organization.avatar, name: el.organization.name }}
        publishedAt={el.published_at}
        withFooter={true}
        link={routes.newsDetails.getLink(el.organization.id, el.id)}
        key={`news-main-card-${index}`}
        isMobile={isMobile}
      />
    );
  });
  const smallSlides = news.map((el, index) => {
    return (
      <NewsCard
        variant="row"
        content={el.excerpt}
        image={el.image}
        title={el.title}
        author={{ id: el.user_id.id, logo: el.user_id.public_avatar, name: el.user_id.public_name }}
        commentsCount={el.comments_count || 0}
        organization={{ id: el.organization.id, logo: el.organization.avatar, name: el.organization.name }}
        publishedAt={el.published_at}
        withFooter={true}
        link={routes.newsDetails.getLink(el.organization.id, el.id)}
        key={`news-small-card-${index}`}
        isMobile={isMobile}
      />
    );
  });

  return (
    <div className={classNames(styles.wrapper, { [styles.mobileWrapper]: isMobile })}>
      <div className={classNames(styles.title, { [styles.mobile]: isMobile })}>
        <h2>{t('news.popular')}</h2>
        <div className={styles.controls}>
          <button className={styles.button} disabled={activeSlide === 0} onClick={prewSlide} title={t('buttons.slider.prev')}>
            <Icon width={24} height={24} iconName={'arrowLeft'} color="black" />
          </button>
          <button
            className={styles.button}
            onClick={nextSlide}
            disabled={activeSlide >= mainSlides.length - slidesOnPage - (isMobile ? 0 : 2)}
            title={t('buttons.slider.next')}
          >
            <Icon width={24} height={24} iconName={'arrowRight'} color="black" />
          </button>
        </div>
      </div>

      <CustomSlider activeSlide={activeSlide} slides={mainSlides} slidesOnPage={slidesOnPage} />
      {!isMobile && <CustomSlider activeSlide={activeSlide + 2} slides={smallSlides} slidesOnPage={slidesOnPage} />}
    </div>
  );
};
