import { Button } from 'shared/components/common/Button/Button';
import { Icon } from 'shared/components/common/Icon/Icon';
import styles from './SuggestionButton.module.scss';
import { useTranslation } from 'react-i18next';

interface SuggestionButtonProps {
  setSuggestionBlockStatus: (val: boolean) => void;
}

export const helpOffersBlockId = 'help-offers';

export const SuggestionButton = ({ setSuggestionBlockStatus }: SuggestionButtonProps) => {
  const { t } = useTranslation('profile');
  return (
    <div data-cy="ready-to-do" id={helpOffersBlockId} className={styles.wrapper}>
      <div className={styles.title}>
        <div className={styles.icon}>
          <Icon color="white" iconName="infoCircle" />
        </div>
        <h5 className={styles.titleText}>{t('organization.suggestions.panel.title')}</h5>
      </div>
      <Button
        color="light"
        className={styles.button}
        data-cy="choose-help-btn"
        onClick={() => setSuggestionBlockStatus(true)}
        size={'small'}
      >
        {t('organization.suggestions.panel.suggest')}
      </Button>
    </div>
  );
};
