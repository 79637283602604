import styles from './StreamNewsCard.module.scss';
import { Author, CONTENT_TYPE } from 'interfaces/streamContent';
import { CustomImage } from 'shared/components/common/CustomImage/CustomImage';
import { StreamCardFooter, FooterItem } from '../../StreamCardFooter/StreamCardFooter';
import { useEffect, useRef, useState } from 'react';
import Tags from 'common/Tags/Tags';
import { Link, useLocation } from 'react-router-dom';
import { routes } from 'Routes';
import { toast } from 'react-toastify';
import { Icon } from 'shared/components/common/Icon/Icon';
import { formatDistance } from 'date-fns';
import { ru, be, enGB, lt, uk } from 'date-fns/locale';
import { EnumContentType } from 'containers/ContentLayout/ContentLayoutTypes';
import { OrganizationShort } from 'shared/interfaces/organization';
import { ImagesSlider } from '../../ImagesSlider/ImagesSlider';
import { allowedAttributesSchema, allowedTagsSynitizer, allowedIframeHostnamesSchema } from 'shared/constants/allowedTagsSynitizer';
import sanitizeHtml from 'sanitize-html';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

type StreamNewsCardProps = {
  type: CONTENT_TYPE;
  id: number;
  title: string;
  images?: Array<string>;
  author: Author;
  excerpt: string;
  commentsCount: number;
  tags: Array<string>;
  watches?: number;
  published_at: Date;
  organization: OrganizationShort;
};

export const StreamNewsCard = ({
  author,
  commentsCount,
  excerpt,
  id,
  organization,
  published_at,
  tags,
  title,
  watches,
  images,
  type,
}: StreamNewsCardProps): JSX.Element => {
  const location = useLocation();
  const { t, i18n } = useTranslation('profile');
  const locales = {
    ru,
    be,
    enGB,
    lt,
    uk,
  };
  const [showHashtag, setShowHashtag] = useState(true);
  const [showFullText, setShowFullText] = useState(false);
  const temporaryImages = images?.length ? images : [];
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current.scrollHeight > containerRef.current.offsetHeight) {
    } else {
      setShowFullText(true);
    }
  }, []);
  const icons: FooterItem[] = [
    {
      iconName: 'clock',
      content: formatDistance(new Date(published_at), new Date(Date.now()), {
        locale: locales[i18n.language],
        addSuffix: false,
      }),
    },
    // {iconName: 'like', content: `${likes}`},
    // { iconName: 'eye', content: `${watches}` },
    { iconName: 'comments', content: `${commentsCount}` },
    {
      iconName: 'hashtag',
      onClick: () => setShowHashtag(!showHashtag),
      content: <Icon rotate={showHashtag ? 180 : 0} iconName="arrowBottom" />,
    },
    {
      iconName: 'link',
      onClick: (e) => {
        e.preventDefault();
        e.stopPropagation();
        navigator.clipboard.writeText(`${process.env.REACT_APP_BASE_URL}${getUrl()}`);
        toast(t('toast.copyLink'));
      },
    },
  ];

  const getUrl = (): string => {
    switch (type) {
      case 'material':
        return routes.materialDetails.getLink(organization.id, id);
      case 'news':
        return routes.newsDetails.getLink(organization.id, id);
      case 'quiz':
        return routes.poll.getLink(organization.id, id);
      case 'task':
        return routes.task.getLink(organization.id, id);
      case 'event':
        return routes.eventsDetails.getLink(organization.id, id);
      case 'suggestion':
        return routes.suggestion.getLink(organization.id, id);
    }
  };

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.author}>
          <Link
            to={{
              pathname: routes.union.getLink(organization.id),
              state: {
                from: location.pathname,
              },
            }}
            className={styles.imageLink}
          >
            <CustomImage isOrganization src={organization.avatar} alt={organization.name} width={55} height={55} className={styles.image} />
          </Link>
          <div className={styles.title}>
            <Link
              to={{
                pathname: getUrl(),
                state: {
                  from: location.pathname,
                },
              }}
            >
              <h4>{title}</h4>
            </Link>
            <Link to={routes.profile.getLink(author.id)}>
              <span className={styles.author_name}>@{author.public_name ?? author.public_family ?? t('anonym')}</span>
            </Link>
          </div>
        </div>
        {!!temporaryImages.length && <ImagesSlider key={`${type}-${id}-slider`} type={type} images={temporaryImages} id={String(id)} />}
        <div className={classNames(styles.content, { [styles.visible]: showFullText })} ref={containerRef}>
          <Link
            to={{
              pathname: getUrl(),
              state: {
                from: location.pathname,
              },
            }}
          >
            <div
              className={styles.description}
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(excerpt, {
                  allowedTags: allowedTagsSynitizer,
                  allowedAttributes: allowedAttributesSchema,
                  allowedIframeHostnames: allowedIframeHostnamesSchema,
                }),
              }}
            />
          </Link>
        </div>
        <StreamCardFooter icons={icons} />
        {showHashtag && <Tags tags={[type, ...tags]} contentTypes={EnumContentType[type]} />}
      </div>
    </>
  );
};
