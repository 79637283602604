import { GlobalContext } from 'contexts/GlobalContext';
import { IUser } from 'interfaces/user.interface';
import { Link, useHistory } from 'react-router-dom';
import { routes } from 'Routes';
import { useContext } from 'react';
import { CustomImage } from 'shared/components/common/CustomImage/CustomImage';
import { Button } from 'shared/components/common/Button/Button';
import styles from './UnionsPopup.module.scss';
import { GlobalDataContext } from 'contexts/GlobalDataContext';
import { EnvironmentModal, TabListEnvModal } from 'components/Modals/ProfileModals/modals/EnvironmentModal';
import { useTranslation } from 'react-i18next';

const UnionsPopup = ({ user }: { user: IUser }) => {
  const { t } = useTranslation('roy-global');
  const history = useHistory();
  const { openModal } = useContext(GlobalContext);
  const { selectedOrganisations } = useContext(GlobalDataContext);
  const { membership, administer } = user;

  const sortedMembership = {
    member: membership,
    admin: administer,
  };
  const sortedSubscribes = {
    organizations: [],
    people: [],
  };

  const onClick = () => {
    openModal({
      params: {
        mainContainer: (
          <EnvironmentModal subscribes={sortedSubscribes} membership={sortedMembership} selectDefault={TabListEnvModal.member} />
        ),
      },
    });
  };
  const moveToUnions = () => {
    history.push(routes.UNIONS);
  };

  if (!user?.membership.length) {
    return;
  }
  return (
    <div className={styles.wrapper}>
      {!!selectedOrganisations.length &&
        selectedOrganisations.map((organization, index) => {
          return (
            <li className={styles.OrganizationListItem} key={index}>
              <Link to={routes.union.getLink(organization.id)}>
                <div className={styles.avatar}>
                  <CustomImage isOrganization src={organization?.avatar} alt={organization?.name} width={40} height={40} />
                </div>

                <p className={styles.title}>{organization?.name}</p>
              </Link>
            </li>
          );
        })}
      {!!user.membership.length && (
        <Button className={styles.button} maxWidth size="small" color="light" onClick={onClick}>
          {t('header.buttons.myUnions')}
        </Button>
      )}
      <Button className={styles.button} maxWidth size="small" color="light" onClick={moveToUnions}>
        {t('header.buttons.unions')}
      </Button>
    </div>
  );
};

export default UnionsPopup;
