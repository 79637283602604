import classNames from 'classnames';
import { useState } from 'react';
import { Icon } from '../Icon/Icon';
import { FormError } from '../Validator/FormError/FormError';
import styles from './TextField.module.scss';

type TextFieldProps = {
  variant: 'text' | 'password' | 'email' | 'number';
  value: string;
  setValue: (val: string) => void;
  helperText?: string;
  error?: string;
  className?: string;
  placeholder?: string;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  maxLength?: number;
  isDisabled?: boolean;
};

export const TextField = ({
  variant = 'text',
  className,
  setValue,
  value,
  error,
  helperText,
  placeholder,
  onBlur,
  onKeyDown,
  maxLength,
  isDisabled = false,
}: TextFieldProps) => {
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [inputVariant, setInputVariant] = useState(variant);
  const toggleShowPassword = () => {
    setInputVariant(isShowPassword ? 'password' : 'text');
    setIsShowPassword(!isShowPassword);
  };
  return (
    <div className={classNames(styles.wrapper, className)} onBlur={onBlur}>
      {helperText && <div className={classNames(styles.helper, { [styles.error]: !!error?.length })}>{helperText}</div>}
      <input
        disabled={isDisabled}
        className={classNames(styles.input, { [styles.error]: !!error?.length })}
        onChange={(ev) => setValue(ev.target.value)}
        value={value}
        placeholder={placeholder}
        onKeyDown={onKeyDown}
        type={inputVariant}
        maxLength={maxLength}
      />
      {variant === 'password' && (
        <div className={styles.toggleSowPassword} onClick={toggleShowPassword}>
          <Icon height={34} width={40} iconName={isShowPassword ? 'eyeClose' : 'eye'} />
        </div>
      )}
      <span className={classNames(styles.errorText, { [styles.error]: !!error?.length })}>
        <FormError>{!!error ? error : 'no-error'}</FormError>
      </span>
    </div>
  );
};
