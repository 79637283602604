import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import styles from './Navigation.module.scss';
import { useTranslation } from 'react-i18next';

interface INavigations {
  title: string;
  route: string;
}

const Navigation = ({ navigations }: { navigations: INavigations[] }) => {
  const location = useLocation();
  const { t } = useTranslation('content');
  return (
    <div className={styles.wrapperNavigation}>
      <ul className={styles.navigation}>
        {navigations.map((navigation) => {
          return (
            <Link
              key={`navigation-${navigation.title}`}
              to={navigation.route}
              className={classNames(styles.links, {
                [styles.active]: navigation.route === location.pathname,
              })}
            >
              {t(navigation.title)}
            </Link>
          );
        })}
      </ul>
    </div>
  );
};

export default Navigation;
