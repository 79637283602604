// import Navigation from 'common/Navigation/Navigation';
import BannerWithButton from 'common/BannerWithButton/BannerWithButton';
import KbaseGroupContainer, { KbaseDataTypes, KbaseGroupDirection, KbaseVariety } from './KbaseGroupContainer/KbaseGroupContainer';
// import { navigationKbase } from 'constans/navigationKbase';
import { GlobalContext } from 'contexts/GlobalContext';
import { useContext } from 'react';
import SEO from 'shared/utils/Seo';
import { bannersKbaseMock } from 'common/BannerWithButton/bannersMock';

const KnowledgeBaseMaterials = () => {
  const { screen } = useContext(GlobalContext);
  const allMaterialDirection = screen.innerWidth <= 680 ? KbaseGroupDirection.list : KbaseGroupDirection.grid;
  return (
    <>
      <SEO image={'/logo-small.svg'} title={'Блог: статьи'} description={'Блог: статьи'} />
      <div>
        <BannerWithButton banners={bannersKbaseMock} />
        {/* <Navigation navigations={navigationKbase()} /> */}
        {/* <KbaseGroupContainer
          variant={KbaseVariety.material}
          direction={KbaseGroupDirection.slider}
          type={KbaseDataTypes.all}
          title={t('sections.training')}
          tags={[t('sections.training').toLowerCase()]}
        /> */}
        <KbaseGroupContainer variant={KbaseVariety.material} direction={allMaterialDirection} type={KbaseDataTypes.all} useNewDesign />

        {/* <KbaseGroupContainer
          variant={KbaseVariety.material}
          direction={KbaseGroupDirection.slider}
          type={KbaseDataTypes.all}
          title="Советы, кейсы"
          tags={['советы', 'кейсы']}
        /> */}
      </div>
    </>
  );
};

export default KnowledgeBaseMaterials;
