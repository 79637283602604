import styles from './RoyPass.module.scss';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'shared/components/common/Checkbox/Checkbox';

type Props = {
  values: { needRoyPass?: boolean };
  setValue: (field: 'needRoyPass', val: boolean) => void;
};

export const RoyPass = ({ setValue, values }: Props) => {
  const { t } = useTranslation('editor');
  return (
    <div className={styles.wrapper}>
      <Checkbox
        className={styles.checkBox}
        value={values.needRoyPass}
        valueChange={() => setValue('needRoyPass', !values.needRoyPass)}
        type="small"
        label={t('settings.qr.label')}
      />
      <div className={styles.about}>
        <img src="/images/qr_default.jpg" alt="qr code" />
        <div className={styles.text}>
          <p>
            {t('settings.qr.description')}
            <a href="#" target="_blank">
              {' '}
              Roy.pass -{' '}
            </a>
            {t('settings.qr.about')}
          </p>
        </div>
      </div>
    </div>
  );
};
