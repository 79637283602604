import ReactTooltip from 'react-tooltip';
import styles from './Tooltip.module.scss';

type TooltipProps = {
  title: string;
  id: string;
};

const Tooltip = ({ title, id }: TooltipProps) => {
  return (
    <ReactTooltip className={styles.tooltip} id={id} place="bottom" effect="solid" backgroundColor="#6339fb">
      {title}
    </ReactTooltip>
  );
};

export default Tooltip;
