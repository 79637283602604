import { AllowedAttribute } from 'sanitize-html';

export const allowedTagsSynitizer = [
  'address',
  'article',
  'aside',
  'footer',
  'header',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'hgroup',
  'main',
  'nav',
  'section',
  'blockquote',
  'dd',
  'div',
  'dl',
  'dt',
  'figcaption',
  'figure',
  'hr',
  'li',
  'main',
  'ol',
  'p',
  'pre',
  'ul',
  'a',
  'abbr',
  'b',
  'bdi',
  'bdo',
  'br',
  'cite',
  'code',
  'data',
  'dfn',
  'em',
  'i',
  'kbd',
  'mark',
  'q',
  'rb',
  'rp',
  'rt',
  'rtc',
  'ruby',
  's',
  'samp',
  'small',
  'span',
  'strong',
  'sub',
  'sup',
  'time',
  'u',
  'var',
  'wbr',
  'caption',
  'col',
  'colgroup',
  'table',
  'tbody',
  'td',
  'tfoot',
  'th',
  'thead',
  'tr',
  'img',
  'iframe',
  'figure',
];

const defaultTags: Array<AllowedAttribute> = ['class', 'width', 'height', 'alt', 'target'];

export const allowedAttributesSchema: Record<string, AllowedAttribute[]> = {
  figure: defaultTags,
  div: defaultTags,
  iframe: [...defaultTags, 'src', 'allowfullscreen', 'frameborder'],
  img: [...defaultTags, 'src'],
  address: defaultTags,
  article: defaultTags,
  aside: defaultTags,
  footer: defaultTags,
  header: defaultTags,
  h1: defaultTags,
  h2: defaultTags,
  h3: defaultTags,
  h4: defaultTags,
  h5: defaultTags,
  h6: defaultTags,
  hgroup: defaultTags,
  main: defaultTags,
  nav: defaultTags,
  section: defaultTags,
  blockquote: defaultTags,
  dd: defaultTags,
  dl: defaultTags,
  dt: defaultTags,
  figcaption: defaultTags,
  hr: defaultTags,
  li: defaultTags,
  ol: defaultTags,
  p: defaultTags,
  pre: defaultTags,
  ul: defaultTags,
  a: [...defaultTags, 'href', 'rel'],
  abbr: defaultTags,
  b: defaultTags,
  bdi: defaultTags,
  bdo: defaultTags,
  br: defaultTags,
  cite: defaultTags,
  code: defaultTags,
  data: defaultTags,
  dfn: defaultTags,
  em: defaultTags,
  i: defaultTags,
  kbd: defaultTags,
  mark: defaultTags,
  q: defaultTags,
  rb: defaultTags,
  rp: defaultTags,
  rt: defaultTags,
  rtc: defaultTags,
  ruby: defaultTags,
  s: defaultTags,
  samp: defaultTags,
  small: defaultTags,
  span: defaultTags,
  strong: defaultTags,
  sub: defaultTags,
  sup: defaultTags,
  time: defaultTags,
  u: defaultTags,
  var: defaultTags,
  wbr: defaultTags,
  caption: defaultTags,
  col: defaultTags,
  colgroup: defaultTags,
  table: defaultTags,
  tbody: defaultTags,
  td: defaultTags,
  tfoot: defaultTags,
  th: defaultTags,
  thead: defaultTags,
  tr: defaultTags,
};
export const allowedIframeHostnamesSchema = [
  'www.youtube.com',
  'player.vimeo.com',
  'www.google.com/maps',
  'www.openstreetmap.org',
  'www.facebook.com',
  'twitter.com',
  'www.tiktok.com',
];
