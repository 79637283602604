import { useContext, useState, useRef, useEffect } from 'react';
import { GlobalContext } from 'contexts/GlobalContext';
import { toast } from 'react-toastify';
import { routes } from 'Routes';
import { Button } from 'shared/components/common/Button/Button';
import { EditableContent } from 'shared/components/common/EditableContent/EditableContent';
import { Icon } from 'shared/components/common/Icon/Icon';
import styles from './AboutUser.module.scss';
import * as Yup from 'yup';
import { IUser } from 'interfaces/user.interface';
import { useFormik } from 'formik';
import { GlobalDataContext } from 'contexts/GlobalDataContext';
import { EditAvatar } from './EditAvatar/EditAvatar';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import formatServerError from 'shared/utils/formatServerError';
import { IProfile } from 'interfaces/profile.interface';
import { EditBanner } from './EditBanner/EditBanner';
import { UserSocial } from './UserSocial/UserSocial';
import { profileService } from 'services';
import { useTranslation } from 'react-i18next';
import { allowedAttributesSchema, allowedIframeHostnamesSchema, allowedTagsSynitizer } from 'shared/constants/allowedTagsSynitizer';
import sanitizeHtml from 'sanitize-html';

type AboutUserProps = {
  profile: IProfile;
  className?: string;
};

export const AboutUser = ({ profile, className }: AboutUserProps) => {
  const { t } = useTranslation('profile');
  const { about, banner, public_avatar, public_name, social, username, is_subscribed } = profile;
  const {
    services: { userService },
    spinner: { showSpinner, hideSpinner },
  } = useContext(GlobalContext);
  const { user, setUser } = useContext(GlobalDataContext);
  const [userLocal, setUserLocal] = useState({
    ...profile,
    name: public_name,
    social,
    about: sanitizeHtml(about, {
      allowedTags: allowedTagsSynitizer,
      allowedAttributes: allowedAttributesSchema,
      allowedIframeHostnames: allowedIframeHostnamesSchema,
    }),
  });
  const [isEdit, setIsEdit] = useState(false);
  const [isEditImages, setIsEditImages] = useState<{ avatar: boolean; banner: boolean }>({ avatar: false, banner: false });
  const [userAvatar, setUserAvatar] = useState(public_avatar);
  const [userBanner, setUserBanner] = useState(banner);
  const [cropperAvatar, setCropperAvatar] = useState<Cropper>(null);
  const [cropperBanner, setCropperBanner] = useState<Cropper>(null);
  const [bannerWidth, setBannerWidth] = useState<number>(null);
  const [isLoadingSubscribe, setIsLoadingSubscribe] = useState<boolean>(false);
  const [isSubscribedState, setIsSubscribedState] = useState<boolean>(is_subscribed);
  const bannerWrapperRef = useRef(null);

  const validationSchema = Yup.object().shape({
    public_name: Yup.string()
      .test('trim', `${t('errors.field')} "${t('name')}" ${t('errors.required')}`, (val) => {
        return val?.trim() !== '';
      })
      .max(50, `${t('errors.field')} "${t('name')}" ${t('errors.maxLength')} 50 ${t('errors.symbols')}`),
    about: Yup.string()
      .test('max', `${t('errors.field')} "${t('about')}" ${t('errors.maxLength')} 500 ${t('errors.symbols')}`, (val) => {
        if (!val) return true;
        const strippedString = val.replace(/(<([^>]+)>)/gi, ' ');
        return strippedString.length <= 500;
      })
      .nullable(),
  });

  const onUpdateUser = async (updatedUser: IProfile) => {
    const userToUpdate: Partial<IUser> = {};
    Object.keys(updatedUser).forEach((key) => {
      if (key === 'is_public' || key === 'hiddens' || key === 'password' || key === '_confirm_password' || key[0] === '_') {
        return;
      }
      if (updatedUser[key] !== userLocal[key]) {
        userToUpdate[key] = updatedUser[key];
      }
    });
    if (cropperAvatar && isEditImages.avatar) {
      setUserAvatar(cropperAvatar.getCroppedCanvas().toDataURL());
    }
    if (cropperBanner && isEditImages.banner) {
      setUserBanner(cropperBanner.getCroppedCanvas().toDataURL());
    }

    await Promise.all([
      Object.keys(userToUpdate).length && userService.updateUser({ ...userToUpdate, id: Number(username) }),
      cropperAvatar && isEditImages.avatar && userService.updateAvatar(cropperAvatar.getCroppedCanvas().toDataURL()),
      cropperBanner && isEditImages.banner && userService.updateBanner(cropperBanner.getCroppedCanvas().toDataURL()),
    ]);
    if (Object.keys(userToUpdate).length) {
      setUser({
        ...user,
        ...userToUpdate,
        public_avatar: userAvatar,
      });
      setUserLocal({ ...userLocal, ...updatedUser });
    }
  };

  useEffect(() => {
    if (bannerWrapperRef?.current) {
      setBannerWidth(bannerWrapperRef?.current?.offsetWidth);
    }
  }, []);

  const toggleSubscribe = async () => {
    try {
      setIsLoadingSubscribe(true);
      if (!isSubscribedState) {
        await profileService.subs(username);
        setIsSubscribedState(true);
        toast(t('toast.subscribe'));
      } else {
        await profileService.unsubs(username);
        setIsSubscribedState(false);
        toast(t('toast.unsubscribe'));
      }
    } finally {
      setIsLoadingSubscribe(false);
    }
  };

  const { values, handleSubmit, setFieldValue, validateForm, errors } = useFormik({
    initialValues: userLocal,
    validationSchema: validationSchema,
    validate: () => {
      const errors: { [key: string]: string } = {};
      return errors;
    },
    onSubmit: async (values) => {
      try {
        showSpinner();
        await onUpdateUser(values);
        toast(t('toast.updatedInfo'));
        const profile = await profileService.getProfile();
        setUser({
          ...user,
          about: profile.about,
          public_avatar: profile.public_avatar,
          public_name: profile.public_name,
          social: profile.social,
          username: profile.username,
        });
        hideSpinner();
      } catch (e) {
        hideSpinner();
        toast(formatServerError(e));
      }
    },
  });

  const onAccept = () => {
    validateForm().then(() => {
      handleSubmit();
      setIsEdit(false);
    });
  };

  const close = () => {
    setFieldValue('public_name', user.public_name);
    setFieldValue(
      'about',
      sanitizeHtml(user.about, {
        allowedTags: allowedTagsSynitizer,
        allowedAttributes: allowedAttributesSchema,
        allowedIframeHostnames: allowedIframeHostnamesSchema,
      })
    );
    setFieldValue('social', userLocal.social);
    setIsEdit(false);
  };

  const isAdminister = String(user?.id) === username;

  if (!profile) {
    return null;
  }

  return (
    <div className={classNames(styles.wrapper)}>
      <div className={classNames(styles.wallpaper)}>
        <EditBanner
          setIsEditImage={(val) => setIsEditImages({ ...isEditImages, banner: val })}
          IsEditImage={isEditImages.banner}
          key={`editable-banner`}
          src={userBanner}
          setCropper={setCropperBanner}
          setImage={setUserBanner}
          isEdit={isEdit}
          width={bannerWidth}
        />
      </div>
      <div className={classNames(styles.cardContainer, className, { [styles.edit]: isEdit })}>
        <div className={styles.avatarContainer}>
          <EditAvatar
            setIsEditImage={(val) => setIsEditImages({ ...isEditImages, avatar: val })}
            IsEditImage={isEditImages.avatar}
            key={`editable-avatar`}
            src={userAvatar}
            isEdit={isEdit}
            setAvatar={setUserAvatar}
            setCropper={setCropperAvatar}
          />
        </div>
        <div className={classNames(styles.subContainer)}>
          <div className={styles.buttons}>
            {!isEdit && (
              <>
                {isAdminister && (
                  <div
                    className={classNames(styles.nonpermanent, styles.icon)}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setIsEdit(true);
                    }}
                  >
                    <Icon width={19} height={19} iconName="pencil" />
                  </div>
                )}
                {!isAdminister && user && (
                  <>
                    {/* <Button size="small" className={styles.commonButton} color="light">
                      Подписаться
                    </Button> */}
                    <Link
                      className={styles.chatLink}
                      to={{
                        pathname: routes.chat,
                        search: `id=${username}&type=user`,
                      }}
                    >
                      <Icon iconName={'chat'} />
                    </Link>
                  </>
                )}
                <div
                  className={styles.icon}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    navigator.clipboard.writeText(`${process.env.REACT_APP_BASE_URL}${routes.profile.getLink(username)}`);
                    toast(t('toast.copyLink'));
                  }}
                >
                  <Icon width={24} height={24} color="black" iconName="link" />
                </div>
              </>
            )}
            {isEdit && (
              <div
                className={styles.icon}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  close();
                }}
              >
                <Icon width={24} height={24} color="black" iconName="close" />
              </div>
            )}
          </div>
          <EditableContent
            isEdit={isEdit}
            onChange={(val) => {
              validateForm();
              setFieldValue('public_name', val, true);
            }}
            onAccept={onAccept}
            onCancel={() => {
              setIsEdit(false);
              setFieldValue('public_name', public_name);
            }}
            isEnterDefault={false}
            error={errors.public_name}
            value={values.public_name}
            variant="h4"
            placeholder={t('input.placeholder.name')}
            helperText={t('input.helperText.name')}
          />
        </div>
        {(isEdit || values.about) && (
          <div className={styles.subContainer}>
            <EditableContent
              isEdit={isEdit}
              onChange={(val) => {
                setFieldValue('about', val);
                validateForm();
              }}
              onAccept={onAccept}
              onCancel={() => {
                setFieldValue('about', about);
                setIsEdit(false);
              }}
              error={errors.about}
              value={values.about}
              variant="ReactQuill"
              placeholder={t('input.placeholder.about')}
              helperText={t('input.helperText.about')}
            />
          </div>
        )}
        {(isEdit || !!values.social?.length) && (
          <div className={styles.subContainer}>
            <UserSocial
              onChange={(val) => {
                setFieldValue('social', val);
              }}
              isEdit={isEdit}
              socials={values.social}
            />
          </div>
        )}
        {user?.id && username !== String(user?.id) && (
          <Button onClick={toggleSubscribe} disabled={isLoadingSubscribe} color={!isSubscribedState ? 'primary' : 'light'} size="small">
            {isSubscribedState ? t('buttons.unsubscribe') : t('buttons.subscribe')}
          </Button>
        )}

        {isEdit && (
          <div className={styles.editButtons}>
            <Button
              color="light"
              className={styles.commonButton}
              size="small"
              onClick={onAccept}
              type="submit"
              disabled={!!errors.about || !!errors.public_name || !values.public_name}
            >
              {t('buttons.save')}
            </Button>
            <Button size="small" color="light" className={styles.commonButton} onClick={close}>
              {t('buttons.cancel')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
