import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { GlobalDataContext } from 'contexts/GlobalDataContext';
import { routes } from 'Routes';
import { Button, ButtonColors } from 'shared/components/common/Button/Button';
import { RegovMembershipHelper } from 'regov/models/membership.helper';
import styles from './EnterButton.module.scss';
import { GlobalContext } from 'contexts/GlobalContext';
import { useTranslation } from 'react-i18next';

const EnterButton = ({ organisationId, publicStatus, maxWidth, regovHelper }: EnterButtonProps) => {
  const { t } = useTranslation('profile');
  const { user, unionEnterRequests, organisationMethods } = useContext(GlobalDataContext);
  const {
    services: { httpService },
  } = useContext(GlobalContext);

  const userInOrganisation = user?.membership?.find((organisation) => organisation.id === organisationId);

  const requestToEnter = unionEnterRequests.data[organisationId];

  const [authorized, setAuthorized] = useState(false);
  const history = useHistory();
  useEffect(() => {
    (async () => setAuthorized(!regovHelper.isAuthorizationRequired() || (await regovHelper.areRequirementsSatisfied())))();
  }, [user?.id, organisationId, regovHelper]);

  async function enterOrganization(e) {
    e.preventDefault();
    e.stopPropagation();
    const challengeResult = await regovHelper.challengeRequirements({ token: httpService.token });
    await organisationMethods.enterOrganisation(organisationId, challengeResult);
    history.push(routes.enterUnion.getLink(organisationId));
  }

  // let disabled = false;
  // let to;
  // let color;
  // let text = '';

  if ((user && !userInOrganisation && !requestToEnter) || [9, 20, 21].includes(requestToEnter?.status)) {
    // color = ButtonColors.primary;
    // text = 'Вступить';
  } else if (requestToEnter?.status === 0 && publicStatus === 1) {
    // disabled = true;
    // text = 'Заявка на рассмотрении';
  } else {
    // to = routes.union.getLink(organisationId);
    // text = 'Перейти';
  }

  return (
    <>
      {(() => {
        let disabled = false;
        let to;
        let color;
        let text = '';
        let onClick;

        if (!authorized) {
          disabled = true;
          if (regovHelper.isntAuthentiated()) {
            text = t('organization.progressBar.needSSI');
          } else {
            text = t('organization.progressBar.enter');
          }
        } else if ((user && !userInOrganisation && !requestToEnter) || [9, 20, 21].includes(requestToEnter?.status)) {
          onClick = enterOrganization;
          color = ButtonColors.primary;
          text = t('organization.progressBar.enter');
        } else if (requestToEnter?.status === 0 && publicStatus === 1) {
          disabled = true;
          text = t('organization.progressBar.review');
        } else {
          to = routes.union.getLink(organisationId);
          text = t('organization.progressBar.goTo');
        }

        return (
          <Button color={color} onClick={onClick} className={styles.button} disabled={disabled} to={to} maxWidth={maxWidth}>
            {text}
          </Button>
        );
      })()}
    </>
  );
};

export default EnterButton;

export type EnterButtonProps = {
  organisationId: number;
  publicStatus: number;
  maxWidth?: boolean;
  regovHelper?: RegovMembershipHelper;
};
