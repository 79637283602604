import { useTranslation } from 'react-i18next';
import styles from './Peculiarities.module.scss';

export const Peculiarities = () => {
  const { t } = useTranslation('home');
  return (
    <div className={styles.wrapper}>
      <h5 className={styles.title}>{t('peculiarities.title')}</h5>
      <div className={styles.list}>
        <ul>
          <li>{t('peculiarities.list.0')}</li>
          <li>{t('peculiarities.list.1')}</li>
          <li>{t('peculiarities.list.2')}</li>
          <li>{t('peculiarities.list.3')}</li>
          <li>{t('peculiarities.list.4')}</li>
          <li>{t('peculiarities.list.5')}</li>
          <li>{t('peculiarities.list.6')}</li>
          <li>{t('peculiarities.list.7')}</li>
          <li>{t('peculiarities.list.8')}</li>
        </ul>
      </div>
    </div>
  );
};
