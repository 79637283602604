import styles from './BadgeInfo.module.scss';
import { formatDistance, format } from 'date-fns';
import { ru, be, enGB, lt, uk } from 'date-fns/locale';
import { IBadge } from 'interfaces/badge.interface';
import { Link } from 'react-router-dom';
import { routes } from 'Routes';
import { GlobalContext } from 'contexts/GlobalContext';
import { useContext } from 'react';
import { BadgeView } from 'components/BadgeView/BadgeView';
import { CustomDescription } from '@owlmeans/regov-ext-custom';
import { REGISTRY_SECTION_OWN, WalletWrapper } from '@owlmeans/regov-ssi-core';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

type BadgeInfoProps = {
  badge: IBadge;
  children?: JSX.Element;
  wallet?: WalletWrapper;
  requested: boolean;
  descr: CustomDescription;
};

export const BadgeInfo = ({ badge, children, wallet, requested, descr }: BadgeInfoProps) => {
  const { t, i18n } = useTranslation('ssi');
  const locales = {
    ru,
    be,
    enGB,
    lt,
    uk,
  };
  const { closeModal } = useContext(GlobalContext);
  const userCred = wallet?.wallet?.credentials?.credentials[REGISTRY_SECTION_OWN].find((inst) => inst.credential.type.includes(badge.type));
  const duration = formatDistance(descr.expirationPeriod * 1000, 0, {
    locale: locales[i18n.language],
  });
  const expiration = format(new Date(userCred?.credential?.expirationDate || 0), "dd MMMM yyyy ':' HH:mm", {
    locale: locales[i18n.language],
  });
  const description = badge.description || t('enterOrganization.description');

  let status: string = t('badgeInfo.status.accessed');

  const isAccepted = !!userCred?.credential?.proof;
  status = isAccepted ? t('badgeInfo.status.accepted') : t('badgeInfo.status.refused');

  return (
    <div className={styles.wrapper}>
      <h4>{badge.name}</h4>
      <div className={styles.props}>
        <BadgeView badge={badge} isHideShadow />
        <div className={styles.propsList}>
          {!!badge.inner_type && <h5 className={styles.propTitle}>{t('badgeInfo.props.type')}</h5>}
          <h5 className={styles.propTitle}>{t('badgeInfo.props.organization')}</h5>
          {descr.expirationPeriod && !userCred && <h5 className={styles.propTitle}>{t('badgeInfo.props.expiration')}</h5>}
          {!!userCred && <h5 className={styles.propTitle}>{t('badgeInfo.props.validUntil')}</h5>}
          {!!wallet && <h5 className={styles.propTitle}>{t('badgeInfo.props.status')}</h5>}
          <h5 className={styles.propTitle}>{t('badgeInfo.props.visibility')}</h5>
        </div>
        <div className={styles.propsList}>
          {!!badge.inner_type && <p className={styles.propDescription}>{t(`badges.types.${badge.inner_type}`)}</p>}
          <Link
            onClick={() => {
              closeModal();
            }}
            to={routes.union.getLink(badge.can[0].id)}
          >
            <p className={styles.propDescription}>{badge.can[0].short_name}</p>
          </Link>
          {descr.expirationPeriod && !userCred && <p className={styles.propDescription}>{duration}</p>}
          {!!userCred && <p className={styles.propDescription}>{expiration}</p>}
          {!!wallet && (
            <p
              className={classNames(
                styles.propDescription,
                { [styles.accepted]: userCred && isAccepted },
                { [styles.rejected]: userCred && !isAccepted }
              )}
            >
              {!!userCred ? status : requested ? t('badgeInfo.status.inProgress') : t('badgeInfo.status.accessed')}
            </p>
          )}
          <p className={styles.propDescription}>{t('badgeInfo.visibility.all')}</p>
        </div>
      </div>
      <p>{description}</p>
      {children}
    </div>
  );
};
