import React, { useContext, useMemo, useState, useCallback, FC, Fragment, useEffect } from 'react';
import { GlobalContext } from 'contexts/GlobalContext';
import { useHistory, useLocation } from 'react-router';
import { regovConfig, regovExtensionRegistry, walletServerClient } from 'regov/config';
import { buildStorageHelper, CryptoLoaderProps } from '@owlmeans/regov-lib-react';
import { EncryptedStore, createWalletHandler } from '@owlmeans/regov-ssi-core';
import { i18n, i18nRegisterExtensions, setRegovPassword } from 'regov/persistent-wallet';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { buildRegovWallet, populateTrustedCredentials } from 'regov/components/Wallet/wallet.util';
import { toast } from 'react-toastify';
import { basicLogin } from 'regov/components/Wallet/basic-login';
import { GlobalDataContext } from 'contexts/GlobalDataContext';
import { TextField } from 'shared/components/common/TextField/TextField';
import { Button } from 'shared/components/common/Button/Button';
import styles from './WithSSI.module.scss';
import { Link } from 'react-router-dom';
import { routes } from 'Routes';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { Suspensed } from 'components/Suspense/Suspense';
import { isSSR } from 'utils/isSSR';
import { useRegov } from 'regov/context';

const STATE_LOADING = 'loading';
const STATE_LOADED_NO_WALLET = 'no_wallet';
const STATE_LOADED_WALLET = 'wallet';

type WithSSIProps = {
  isAdminRedirect?: boolean;
};

const FakeLoader: FC<CryptoLoaderProps> = ({ onFinish }) => {
  useEffect(() => onFinish());
  return <Fragment />;
};

export const WithSSI = ({ isAdminRedirect }: WithSSIProps) => {
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { closeModal } = useContext(GlobalContext);
  const globalState = useContext(GlobalContext);
  const globalDataState = useContext(GlobalDataContext);
  const { handler: regovHandler } = useRegov();
  const _handler = useMemo(() => createWalletHandler(), []);
  const storage = useMemo(() => buildStorageHelper(_handler, regovConfig), [regovConfig]);
  const handler = regovHandler ?? _handler;
  const {
    spinner: { showSpinner, hideSpinner },
  } = globalState;

  const [loading, setLoading] = useState<string>(STATE_LOADING);
  const { t } = useTranslation('auth');

  const validationSchema = Yup.object().shape({
    password: Yup.string().min(8, t('login.errors.min8')).max(50, t('login.errors.max50')).trim().required(t('login.errors.required')),
  });

  const [asyncErrors, setAsyncErrors] = useState<{ [key: string]: string }>({});

  const onDrop = useCallback(async (files: File[]) => {
    if (files.length) {
      const reader = new FileReader();

      reader.onabort = () => {
        toast.error(t('loginSSI.toasts.errors.downloadCanceled'));
      };

      reader.onerror = () => {
        toast.error(t('loginSSI.toasts.errors.loadingError'));
      };

      reader.onload = () => {
        const walletStore: EncryptedStore = JSON.parse(reader.result as string);
        if (walletStore.alias) {
          walletStore.alias = 'default';
          handler.stores['default'] = walletStore;
          handler.notify();
          setLoading(STATE_LOADED_WALLET);
          toast(t('loginSSI.toasts.walletLoaded'));
        } else {
          toast.warning(t('loginSSI.toasts.unknownFormat'));
        }
      };

      reader.readAsText(files[0]);
    }
  }, []);
  const acceptedFileTypes = {
    'application/json': ['.json'],
  };
  const { getInputProps, open } = useDropzone({ onDrop, noClick: true, maxFiles: 1, accept: acceptedFileTypes });

  const { handleSubmit, setFieldValue, validateForm, setErrors, errors, touched, values, isValid } = useFormik({
    validationSchema,
    initialValues: { password: '' },
    validate: () => asyncErrors,
    onSubmit: async ({ password }, actions) => {
      showSpinner();
      try {
        await handler.loadStore(async (handler) => buildRegovWallet(password, handler.stores['default']));
        await populateTrustedCredentials(handler);

        const baseIdentity = handler.wallet.getIdentityCredential();

        if (baseIdentity) {
          setRegovPassword(password);
          await basicLogin({
            handler,
            history,
            client: walletServerClient,
            credential: baseIdentity,
            extensions: regovExtensionRegistry,
            globalDataState,
            globalState,
            isRedirectToAdmin: isAdminRedirect,
          })();
        } else {
          throw new Error(t('loginSSI.toasts.errors.noCertificate'));
        }

        closeModal();
      } catch (e) {
        const err: Error = e;
        const msg = err.message.indexOf('ERROR_') === 0 ? t('loginSSI.toasts.errors.cannotBeDecoded') : `${e}`;
        const errors = { password: msg };
        setAsyncErrors(errors);
        actions.validateForm();
        toast.error(errors.password);
      } finally {
        hideSpinner();
      }
    },
  });

  const CryptoLoader: FC<CryptoLoaderProps> = isSSR() ? FakeLoader : React.lazy(() => import('regov/crypto-loader') as any);

  useEffect(() => {
    if (loading === STATE_LOADED_NO_WALLET) {
      toast.warning(
        <p>
          {t('loginSSI.toasts.warning.noWallet')}
          <br />
          {t('loginSSI.toasts.warning.can')} <strong> {t('loginSSI.toasts.warning.newSSI')} </strong>
          <br />
          {t('loginSSI.toasts.warning.or')}
          <strong> {t('loginSSI.toasts.warning.upload')} </strong>.
          <br /> {t('loginSSI.toasts.warning.bestRegards')}
        </p>
      );
    }
  }, [loading]);

  return (
    <Suspensed fallback={<div>Loading...</div>}>
      <div className={styles.wrapper}>
        <CryptoLoader
          onFinish={() => {
            i18nRegisterExtensions(i18n, regovExtensionRegistry);

            storage.init().then(async () => {
              if (handler.stores['default']) {
                setLoading(STATE_LOADED_WALLET);
              } else {
                setLoading(STATE_LOADED_NO_WALLET);
              }
            });
          }}
          deps={regovExtensionRegistry?.uiExtensions || []}
        />
        <form
          className={styles.form}
          onSubmit={(e) => {
            e.preventDefault();
            validateForm().then(() => handleSubmit());
          }}
        >
          <input {...getInputProps()} />
          <TextField
            variant="password"
            isDisabled={loading === STATE_LOADED_NO_WALLET}
            setValue={(value) => {
              setAsyncErrors({});
              setErrors({});
              setFieldValue('password', value.trim(), true);
            }}
            error={values.password.length && errors?.password && touched?.password && errors.password}
            helperText={t('login.inputs.password')}
            placeholder={t('login.inputs.passwordPlaceholder')}
            value={values.password}
            maxLength={256}
          />
          <div className={styles.enter_buttons_wrapper}>
            <Button size="small" type="submit" className={styles.button_login} disabled={!isValid || loading === STATE_LOADED_NO_WALLET}>
              {t('loginSSI.actions.signInSSI')}
            </Button>
            <button className={styles.button_download} type="button" onClick={open}>
              {t('loginSSI.actions.loadWallet')}
            </button>
            <Link type="button" to={`${routes.REGISTRATION}?${searchParams.toString()}`}>
              <p className={styles.register}>{t('login.actions.signUp')}</p>
            </Link>
          </div>
          <br />
        </form>
      </div>
    </Suspensed>
  );
};
