import { useContext } from 'react';
import { GlobalContext } from 'contexts/GlobalContext';
import { Checkbox } from 'shared/components/common/Checkbox/Checkbox';
import { CustomImage } from 'common/CustomImage/CustomImage';
import { StepsProgressBar } from 'common/StepsProgressBar/StepsProgressBar';
import { Helmet } from 'react-helmet';
import styles from './Task.module.scss';
import { UserShort } from 'shared/interfaces/user';
import Tooltip from 'shared/components/common/Tooltip/Tooltip';
import { IOrganisationShort } from 'interfaces/organisation.interface';
import generalStyles from '../NewsDetails/NewsDetails.module.scss';
import { Slider } from 'common/Slider/Slider';
import { useTranslation } from 'react-i18next';

export type TaskInfo = {
  title: string;
  image?: string;
  description: string;
  created_at: Date;
  users: UserShort[];
  user: UserShort;
  checklist: any;
  organization?: IOrganisationShort;
  images?: { image: string; id: number }[];
  suggestion_id?: number;
  id: number;
};

type Props = {
  task: TaskInfo;
};

export function Task({ task }: Props) {
  const { t } = useTranslation('polls');
  const { openModal } = useContext(GlobalContext);

  if (!task || !task.created_at) {
    // TODO: loading state
    return null;
  }

  const checklistKeys = Object.keys(task.checklist || {});
  const checkedItemsCount = Object.values(task.checklist || {})?.filter((val) => !!val).length;

  return (
    <>
      <Helmet>
        <title>{task.title}</title>
        <meta name="og:title" content={task.title} />
        <meta name="description" content={task.description || task.title} />
        {/* <meta name="og:image" content={task.image || process.env.REACT_APP_BASE_URL + '/' + require('../../public/logo.jpg').default} /> */}
      </Helmet>

      <div className={generalStyles.wrapper}>
        <div className={generalStyles.contentWrapper}>
          {!!task.images.length && (
            <Slider
              slidesOnPage={1}
              controlsVerticalOffset={0}
              controlsHorizontalOffset={0}
              children={task.images.map((image) => (
                <div className={styles.imageWrapper}>
                  <img
                    src={image.image}
                    alt={task.title}
                    onClick={(e) =>
                      openModal({
                        params: {
                          mainContainer: <img src={image.image} alt={task.title} />,
                        },
                      })
                    }
                  />
                </div>
              ))}
            />
          )}
          {task.users?.length > 0 && (
            <div className={styles.section}>
              <p>{`${t('members')}:`}</p>
              <div className={styles.avatarGroup}>
                {task.users.map((user) => {
                  const userName = `${user.public_name} ${user.public_family}`;
                  return (
                    <>
                      <div className={styles.avatar} data-tip data-for={`userTaskTooltip${user.id}`}>
                        <CustomImage src={user?.public_avatar || ''} alt={userName} width={37.5} height={37.5} />
                      </div>
                      <Tooltip title={userName} id={`userTaskTooltip${user.id}`} />
                    </>
                  );
                })}
              </div>
            </div>
          )}
          {checklistKeys.length > 0 && (
            <div className={styles.section}>
              <p>{`${t('checklist')}:`}</p>

              <div className={styles.progressBar}>
                <StepsProgressBar stepNumber={checkedItemsCount} stepsCount={checklistKeys.length} />
              </div>
              <div className={styles.checkboxesGroup}>
                {checklistKeys.map((item) => (
                  <Checkbox type="small" isDark value={!!task.checklist[item]} name={item} label={item} valueChange={() => {}} />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
