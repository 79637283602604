import { GlobalDataContext } from 'contexts/GlobalDataContext';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { useContext, useState } from 'react';
import { Input } from 'shared/components/common/Input/Input';
import styles from './EditUserEmail.module.scss';
import { toast } from 'react-toastify';
import formatServerError from 'utils/formatServerError';
import { GlobalContext } from 'contexts/GlobalContext';
import { useTranslation } from 'react-i18next';

const EditUserEmail = () => {
  const { t } = useTranslation('settings');
  const {
    services: { userService },
  } = useContext(GlobalContext);
  const { user, setUser } = useContext(GlobalDataContext);
  const [activeNewEmail, setActiveNewEmail] = useState<boolean>(!!user.new_email);

  const cancelChanges = async (setFieldValue) => {
    if (!!user.new_email) {
      try {
        await userService.cancelChangeEmail();
        setActiveNewEmail(false);
        setFieldValue('new_email', '');
        const user = await userService.getUser();
        setUser(user);
        toast(t('toast.emailCanceled'));
      } catch (e) {
        toast.error(formatServerError(e));
      }
    } else {
      setActiveNewEmail(false);
      setFieldValue('new_email', '');
    }
  };
  if (user.email.substring(user.email.length - 4).toLowerCase() === '@ssi') {
    return null;
  }
  return (
    <Formik
      initialValues={{
        email: user.email,
        new_email: user.new_email,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string(),
        new_email: Yup.string()
          .email(t('errors.invalidEmail'))
          .max(100, `${t('errors.max')} 100 ${t('errors.symbols')}`)
          .nullable(),
      })}
      onSubmit={async (values): Promise<void> => {
        try {
          await userService.updateEmail(values.new_email);
          const user = await userService.getUser();
          setUser(user);
          toast(t('toast.confirmation'));
        } catch (e) {
          toast.error(formatServerError(e));
        }
      }}
    >
      {({ isSubmitting, touched, errors, isValid, dirty, setFieldValue, setFieldTouched, values }): JSX.Element => (
        <Form className={styles.wrapper}>
          <h3>{t('email.title')}</h3>
          <Input
            name="email"
            value={values.email}
            valueChange={() => {}}
            label={t('email.input.email.label')}
            type="email"
            description={t('email.input.email.description')}
            disabled
          />
          {activeNewEmail && (
            <Input
              name="new_email"
              value={values.new_email}
              valueChange={(value) => setFieldValue('new_email', value)}
              label={t('email.input.newEmail.label')}
              type="email"
              disabled={!!user.new_email}
              description={t('email.input.newEmail.description')}
              onTouch={setFieldTouched.bind(null, 'new_email')}
              error={errors?.new_email && touched?.new_email && errors.new_email}
            />
          )}
          <div className={styles.buttonsWrapper}>
            {activeNewEmail && !user.new_email && (
              <button className={styles.addNewEmailButton} disabled={isSubmitting || !isValid || !dirty || !values.new_email} type="submit">
                {t('email.input.confirm')}
              </button>
            )}
            {!!user.new_email && (
              <button className={styles.addNewEmailButton} type="submit">
                {t('email.input.resend')}
              </button>
            )}
            {!activeNewEmail && (
              <button
                type="button"
                className={styles.addNewEmailButton}
                onClick={() => {
                  setActiveNewEmail(true);
                }}
              >
                {t('email.input.change')}
              </button>
            )}
            {!!activeNewEmail && (
              <button type="button" className={styles.addNewEmailButton} onClick={() => cancelChanges(setFieldValue)}>
                {t('email.input.cancel')}
              </button>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default EditUserEmail;
