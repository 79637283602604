import { useLayoutEffect, useState } from 'react';
import { toast } from 'react-toastify';
import formatServerError from 'shared/utils/formatServerError';
import Comment from '../Comment/Comment';
import styles from './Answers.module.scss';
import ReadAnswers from './ReadAnswers/ReadAnswers';
import { useSelector, useDispatch } from '../../../../redux-toolkit';
import { CommentsState, getCommentsAction } from 'shared/slices/comments';
import LoadMoreButton from '../LoadMoreButton/LoadMoreButton';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';

const Answers = ({ commentsId, commentAnswers }: { commentsId: number; commentAnswers: number }) => {
  const { comments, generalData } = useSelector((state) => state.comments as CommentsState);
  const { t } = useTranslation('shared');
  const dispatch = useDispatch();
  const [showAnswers, setShowAnswers] = useState(false);
  const [answers, setAnswers] = useState([]);
  const [openFirstTime, setOpenFirstTime] = useState(true);

  useLayoutEffect(() => {
    const copyComments = cloneDeep(comments);
    setAnswers(copyComments.find((comment) => comment?.id === commentsId)?.answersComments?.reverse() || []);
  }, [comments]);

  const uploadAnswers = async () => {
    if (!!openFirstTime) {
      setOpenFirstTime(false);
      try {
        await dispatch(
          getCommentsAction(
            generalData.essenceId,
            generalData.essenceName,
            commentsId,
            10,
            !!answers.length ? answers[0].id : null,
            generalData.isAdminApp
          )
        );
        setShowAnswers(!showAnswers);
      } catch (e) {
        toast.error(formatServerError(e));
      }
    } else {
      setShowAnswers(!showAnswers);
    }
  };

  return (
    <div className={styles.wrapper}>
      <ReadAnswers countAnswers={commentAnswers} uploadAnswers={uploadAnswers} />
      {!!showAnswers && !!answers.length && answers.length > 9 && answers.length !== commentAnswers && (
        <LoadMoreButton
          after_id={answers[0].id}
          parent_id={answers[0].parent_id}
          buttonText={`${t('comments.otherAnswers')} (${commentAnswers - answers.length})`}
        />
      )}
      <div>{!!showAnswers && !!answers.length && answers.map((comment) => <Comment item={comment} secondLevel />)}</div>
    </div>
  );
};

export default Answers;
