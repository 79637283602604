import { CreateContent } from 'components/Modals/CreateContent/CreateContent';
import { GlobalContext } from 'contexts/GlobalContext';
import { useContext } from 'react';
import { Icon } from 'shared/components/common/Icon/Icon';
import { Icons } from 'shared/components/common/Icon/Icon.interface';
import styles from './CreateButton.module.scss';
import { useTranslation } from 'react-i18next';

export const CreateButton = () => {
  const { t } = useTranslation('roy-global');
  const { openModal } = useContext(GlobalContext);
  const openSubscribersModal = () => {
    openModal({
      params: {
        mainContainer: <CreateContent />,
      },
    });
  };

  return (
    <div key={'create-btn'} className={styles.wrapper} title={t('buttons.create')} onClick={openSubscribersModal}>
      <Icon color={'white'} rotate={45} iconName={Icons.circleClose} width={33} height={33} />
    </div>
  );
};
