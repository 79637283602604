import HelpOffersModal from 'common/HelpOffersModal/HelpOffersModal';
import { GlobalContext } from 'contexts/GlobalContext';
import { Button } from 'shared/components/common/Button/Button';
import { Icon } from 'shared/components/common/Icon/Icon';
import styles from './HelpOffers.module.scss';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

interface IHelpOffersProps {
  unionId: number;
}

export const helpOffersBlockId = 'help-offers';

export const HelpOffers = ({ unionId }: IHelpOffersProps) => {
  const { t } = useTranslation('profile');
  const { openModal } = useContext(GlobalContext);
  function openHelpOffersModal() {
    openModal({
      params: {
        mainContainer: <HelpOffersModal unionId={unionId} />,
      },
    });
  }
  return (
    <div data-cy="ready-to-do" id={helpOffersBlockId} className={styles.wrapper}>
      <div className={styles.title}>
        <div className={styles.icon}>
          <Icon color="white" iconName="infoCircle" />
        </div>
        <h5 className={styles.titleText}>{t('organization.helpOffers.title')}</h5>
      </div>
      <Button color="light" className={styles.button} data-cy="choose-help-btn" onClick={openHelpOffersModal} size={'small'}>
        {t('organization.helpOffers.button')}
      </Button>
    </div>
  );
};
