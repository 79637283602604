import { IOrganisation } from 'interfaces/organisation.interface';
import { useReducer, Dispatch } from 'react';

// USE ORGANISATIONS REDUCER HOOK
export interface OrganisationsState {
  all: IOrganisation[];
  selected: IOrganisation | null;
  friendly: IOrganisation[] | null;
  detached: IOrganisation[] | null;
}
const _initialState: OrganisationsState = {
  all: [],
  selected: null,
  friendly: [],
  detached: [],
};

export enum featuresActionTypes {
  setAll = 'setAll',
  setSelected = 'setSelected',
  setFriendly = 'setFriendly',
  setDetached = 'setDetached',
}

function reducer(
  state: typeof _initialState,
  { type, payload }: { type: featuresActionTypes; payload: typeof _initialState[keyof typeof _initialState] }
) {
  switch (type) {
    case featuresActionTypes.setAll:
      return { ...state, all: payload };

    case featuresActionTypes.setSelected:
      return { ...state, selected: payload };

    case featuresActionTypes.setFriendly:
      return { ...state, friendly: payload };

    case featuresActionTypes.setDetached:
      return { ...state, detached: payload };

    default:
      break;
  }
  throw Error('Unknown organisations action: ' + type);
}

export const actions = {
  setAll: (payload: typeof _initialState['all']) => ({ type: featuresActionTypes.setAll, payload }),
  setFriendly: (payload: typeof _initialState['friendly']) => ({ type: featuresActionTypes.setFriendly, payload }),
  setSelected: (payload: typeof _initialState['selected']) => ({ type: featuresActionTypes.setSelected, payload }),
  setDetached: (payload: typeof _initialState['detached']) => ({ type: featuresActionTypes.setDetached, payload }),
};

export const useOrganisationsReducer = (
  initialState?: typeof _initialState
): [typeof _initialState, Dispatch<ReturnType<typeof actions[keyof typeof actions]>>, typeof actions] => {
  const [state, dispatch] = useReducer(reducer, initialState || _initialState);
  //@ts-ignore
  return [state, dispatch, actions];
};
