import css from 'classnames';
import { GlobalContext } from 'contexts/GlobalContext';
import { GlobalDataContext } from 'contexts/GlobalDataContext';
import { useContext, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { routes } from 'Routes';
import { CustomImage } from 'shared/components/common/CustomImage/CustomImage';
import { Icon } from 'shared/components/common/Icon/Icon';
import styles from './Header.module.scss';
import UnionsPopup from './UnionsPopup/UnionsPopup';
import UserButton from './UserButton/UserButton';
import { useTranslation } from 'react-i18next';
interface IHeader {
  title: string;
  className?: string;
  changeUserMenu?: boolean;
  setChangeUserMenu?: (e: boolean) => void;
}

const Header = ({ title, className, changeUserMenu, setChangeUserMenu }: IHeader) => {
  const { t } = useTranslation('roy-global');
  const { user } = useContext(GlobalDataContext);

  const { isSidebarExpanded, setSidebarExpandedState } = useContext(GlobalContext);

  const [hoverElement, setHoverElement] = useState<number | null>(null);

  const headerNavData = [
    {
      id: 1,
      text: t('pages.communities'),
      icon: <Icon iconName="taskSquare" />,
      src: routes.UNIONS,
      popupMenu: !!user && !!user.membership.length && <UnionsPopup user={user} />,
    },
    {
      id: 2,
      text: t('pages.event'),
      icon: <Icon iconName="star" />,
      src: routes.EVENTS,
    },
    {
      id: 3,
      text: t('pages.news'),
      icon: <Icon iconName="lampOn" />,
      src: routes.NEWS,
    },
    {
      id: 4,
      text: t('pages.blog'),
      icon: <Icon iconName="teacher" />,
      src: routes.KNOWLEDGE_BASE_MATERIALS,
    },
  ];

  return (
    <header className={css(styles.wrapper, className)}>
      <div className={styles.pageInfo}>
        {!!user?.id ? (
          <Link className={styles.link} to={routes.PROFILE}>
            <CustomImage src={user.public_avatar} alt={user.name} width={48} height={48} />
          </Link>
        ) : (
          <Link to={routes.HOME}>
            <CustomImage isOrganization src={'/logo.svg'} alt="Roy team" width={76} height={38} className={styles.imageRoy} />
          </Link>
        )}
        <h2>{title}</h2>
      </div>
      <div className={styles.menuIcon} onClick={setSidebarExpandedState.bind(null, !isSidebarExpanded)}>
        <Icon iconName="menu" />
      </div>
      <nav className={styles.navigation}>
        <ul>
          {headerNavData.map((item) => {
            return (
              <li key={item.id}>
                <NavLink
                  to={item.src}
                  className={styles.navItem}
                  activeClassName={styles.activeNavItem}
                  onMouseEnter={() => setHoverElement(item.id)}
                  onMouseLeave={() => setHoverElement(null)}
                >
                  <div className={styles.navIcon}>{item.icon}</div>
                  <span className={styles.navText}>{item.text}</span>
                </NavLink>
                {!!item.popupMenu && hoverElement === item.id && (
                  <div className={styles.popup} onMouseEnter={() => setHoverElement(item.id)} onMouseLeave={() => setHoverElement(null)}>
                    {item.popupMenu}
                  </div>
                )}
              </li>
            );
          })}
          <UserButton user={user} />
        </ul>
      </nav>
    </header>
  );
};

export default Header;
