import { EventsData } from 'interfaces/events.interface';
import { httpService } from 'services';

// temp file to connect with shared. need to combine news api
export async function uploadImage(orgId: string, image: string | ArrayBuffer): Promise<any> {
  return await httpService.axios.post(`/admin_org/${orgId}/events/upload`, { image });
}
export async function createEvents(orgId: string, data: Partial<EventsData>): Promise<EventsData> {
  const response = await httpService.axios.post(`/admin_org/${orgId}/events`, data);
  return response?.data?.data;
}

export async function updateEvents(orgId: string, eventsId: number, data: Partial<EventsData>): Promise<EventsData> {
  const response = await httpService.axios.put(`/admin_org/${orgId}/events/${eventsId}`, data);
  return response?.data?.data;
}

export async function deleteEvents(orgId: string, eventsId: number): Promise<void> {
  await httpService.axios.delete(`/admin_org/${orgId}/events/${eventsId}`);
}

export async function publishEvents(orgId: string, eventsId: number): Promise<void> {
  await httpService.axios.post(`/admin_org/${orgId}/events/${eventsId}/publish`);
}
