import { useTranslation } from 'react-i18next';
import styles from './RoyFeatures.module.scss';

export const RoyFeatures = () => {
  const { t } = useTranslation('home');
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <h5 className={styles.title}>{t('royFeatures.title')}</h5>
        <div className={styles.columns}>
          <div>
            <ul>
              <li>{t('royFeatures.leftList.0')}</li>
              <li>{t('royFeatures.leftList.1')}</li>
              <li>{t('royFeatures.leftList.2')}</li>
              <li>{t('royFeatures.leftList.3')}</li>
              <li>{t('royFeatures.leftList.4')}</li>
              <li>{t('royFeatures.leftList.5')}</li>
              <li>{t('royFeatures.leftList.6')}</li>
            </ul>
          </div>
          <div>
            <ul>
              <li>{t('royFeatures.rightList.0')}</li>
              <li>{t('royFeatures.rightList.1')}</li>
              <li>{t('royFeatures.rightList.2')}</li>
              <li>{t('royFeatures.rightList.3')}</li>
              <li>{t('royFeatures.rightList.4')}</li>
              <li>{t('royFeatures.rightList.5')}</li>
              <li>{t('royFeatures.rightList.6')}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
