import { LayerProps } from 'react-map-gl';
// import { Feature, GeoJsonProperties, Geometry } from './_types';
import { map } from 'lodash';
import { translate } from './handlers';

////// SLOTS //////
export const slotsLayer: LayerProps = {
  id: 'slots-layer',
  type: 'symbol',
  layout: {
    'icon-image': 'slot-pic',
    'icon-size': ['interpolate', ['linear'], ['zoom'], 0, 0.1, 40, 5],
    'icon-allow-overlap': true,
    'symbol-placement': 'point',
    'icon-offset': ['get', 'translate'],
  },
  paint: {
    'icon-opacity': ['case', ['==', ['feature-state', 'selected'], true], 0, 1],
  },
};
export const activeSlotsLayer: LayerProps = {
  id: 'active-slots-layer',
  type: 'symbol',
  layout: {
    'icon-image': 'active-slot-pic',
    'icon-size': ['interpolate', ['linear'], ['zoom'], 0, 0.1, 40, 5],
    'icon-allow-overlap': true,
    'symbol-placement': 'point',
    'icon-offset': ['get', 'translate'],
  },
  paint: {
    'icon-opacity': ['case', ['==', ['feature-state', 'selected'], true], 1, 0],
  },
};
////// /SLOTS //////

////// COMMUNITY //////
export const communityLayer: LayerProps = {
  id: 'community-layer',
  type: 'symbol',
  filter: ['!', ['has', 'point_count']],
  layout: {
    'icon-image': 'community-pic',
    'icon-size': ['interpolate', ['linear'], ['zoom'], 0, 0.1, 40, 5],
    'icon-allow-overlap': true,
    'symbol-placement': 'point',
  },
  paint: {
    'icon-opacity': ['case', ['==', ['feature-state', 'selected'], true], 0, 1],
  },
};

export const activeCommunityLayer: LayerProps = {
  id: 'active-community-layer',
  type: 'symbol',
  filter: ['!', ['has', 'point_count']],
  layout: {
    'icon-image': 'active-community-pic',
    'icon-size': ['interpolate', ['linear'], ['zoom'], 0, 0.1, 40, 5],
    'icon-allow-overlap': true,
    'symbol-placement': 'point',
  },
  paint: {
    'icon-opacity': ['case', ['==', ['feature-state', 'selected'], true], 1, 0],
  },
};
////// /COMMUNITY //////

////// COMMUNITY CLUSTERS //////
export const communityClusterLayer: LayerProps = {
  id: 'community-clusters-layer',
  type: 'symbol',
  filter: ['has', 'point_count'],
  // maxzoom: 10,
  layout: {
    'icon-image': 'slot-pic',
    'icon-size': ['interpolate', ['linear'], ['zoom'], 0, 0.18, 40, 5.5],
    'icon-allow-overlap': true,
    'symbol-placement': 'point',
  },
  paint: {
    'icon-opacity': ['case', ['==', ['feature-state', 'visible'], false], 0, 1],
  },
};

export const communityClusterTextLayer: LayerProps = {
  id: 'community-clusters-count-layer',
  beforeId: 'community-clusters-layer',
  type: 'symbol',
  source: 'points',
  filter: ['has', 'point_count'],
  layout: {
    'text-field': ['get', 'point_count_abbreviated'],
    'text-rotation-alignment': 'auto',
    'text-allow-overlap': true,
    'text-size': ['interpolate', ['linear'], ['zoom'], 0, 10, 12, 22],
    'text-padding': 5,
    'text-rotate': 0,
  },
  paint: {
    'text-color': 'white',
    'text-halo-width': 1,
  },
};
////// /COMMUNITY CLUSTERS //////

////// COMMUNITY LINES //////
export const linesLayer: LayerProps = {
  id: 'lines-layer',
  type: 'line',
  source: 'points',
  layout: {
    'line-round-limit': 1,
  },
  paint: {
    'line-color': 'white',
    'line-width': 1,
  },
};
////// /COMMUNITY LINES //////

////// COMMUNITY CIRCLES //////
const helpers = {
  equalTo: (featureProp: string, value: string | number): any[] => ['==', ['get', featureProp], value],
  multiply: (a: any[] | string | number, b: any[] | string | number): any[] => ['*', a, b],
  selectRadius: (amountFieldName, factor: number): any[] => [
    'case',
    helpers.equalTo(`${amountFieldName}`, 0),
    helpers.multiply(factor, 5),
    helpers.equalTo(`${amountFieldName}`, 1),
    helpers.multiply(factor, 6),
    helpers.equalTo(`${amountFieldName}`, 2),
    helpers.multiply(factor, 7),
    helpers.equalTo(`${amountFieldName}`, 3),
    helpers.multiply(factor, 8),
    helpers.equalTo(`${amountFieldName}`, 4),
    helpers.multiply(factor, 10),
    helpers.equalTo(`${amountFieldName}`, 5),
    helpers.multiply(factor, 12),
    helpers.multiply(factor, 20),
  ],
};

type CreateActivityCircleLayerFunc = (color: string, id: string, translation: number[], countFieldName: string) => LayerProps;
const createActivityCircleLayer: CreateActivityCircleLayerFunc = (color, id, translation, countFieldName) => ({
  id: `${id}`,
  type: 'circle',
  rad: ['get', `${countFieldName}`],
  filter: ['!', ['has', 'point_count']],

  paint: {
    'circle-radius': [
      'interpolate',
      ['linear'],
      ['zoom'],
      1,
      ['*', 0.2, helpers.selectRadius(countFieldName, 1) as any],
      50,
      ['*', 5, helpers.selectRadius(countFieldName, 1) as any],
    ],
    'circle-translate': [
      'interpolate',
      ['linear'],
      ['zoom'],
      1,
      ['literal', map(translation, (v) => v * 0.1)],
      50,
      ['literal', map(translation, (v) => v * 8)],
    ],

    'circle-opacity': ['case', ['==', ['get', `${countFieldName}`], 0], 0.5, 0.6],
    'circle-color': color,
    'circle-opacity-transition': {
      delay: 0.3,
      duration: 0.3,
    },
  },
});
////// /COMMUNITY CIRCLES //////

////// COMMUNITY CIRCLES TEXT //////
type CreateActivityTextLayerFunc = (name: string, translation: number[], countFieldName: string) => LayerProps;
export const createActivityTextLayer: CreateActivityTextLayerFunc = (id, translation, countFieldName) => ({
  id: `${id}`,
  type: 'symbol',
  filter: ['all', ['!', ['has', 'point_count']]],
  layout: {
    'text-field': ['get', `${countFieldName}`],
    'text-rotation-alignment': 'auto',
    'text-allow-overlap': true,
    'text-size': ['interpolate', ['linear'], ['zoom'], 1, 3, 50, 50],
    'text-padding': 5,
    'text-rotate': 0,
  },
  paint: {
    'text-translate': [
      'interpolate',
      ['linear'],
      ['zoom'],
      1,
      ['literal', map(translation, (v) => v * 0.1)],
      50,
      ['literal', map(translation, (v) => v * 8)],
    ],
    'text-color': 'white',
    'text-halo-width': 1,
    'text-opacity': ['case', ['==', ['feature-state', 'activityCircleHover'], true], 1, 0],
  },
});

//CONTENT_TYPE
export const activitiesLayersTemplateConfig = {
  newsLayer: {
    color: '#F91240',
    textId: ' activity-news-text-layer',
    circleId: ' activity-news-layer',
    countFieldName: 'news_count',
    translation: translate(0, 20, 8),
    activityName: 'news',
  },
  articlesLayer: {
    color: '#A21DCF',
    textId: ' activity-articles-text-layer',
    circleId: ' activity-articles-layer',
    countFieldName: 'articles_count',
    translation: translate(1, 20, 8),
    activityName: 'material',
  },
  eventsLayer: {
    color: '#06BAAB',
    textId: ' activity-events-text-layer',
    circleId: ' activity-events-layer',
    countFieldName: 'events_count',
    translation: translate(2, 20, 8),
    activityName: 'event',
  },
  tasksLayer: {
    color: '#FF7C01',
    textId: ' activity-tasks-text-layer',
    circleId: ' activity-tasks-layer',
    countFieldName: 'desk_tasks_count',
    translation: translate(3, 20, 8),
    activityName: 'task',
  },
  quizzesLayer: {
    color: '#50C0F9',
    textId: ' activity-quizzes-text-layer',
    circleId: ' activity-quizzes-layer',
    countFieldName: 'quizzes_count',
    translation: translate(4, 20, 8),
    activityName: 'quiz',
  },
  fundraisingLayer: {
    color: '#79AA15',
    textId: ' activity-fundraising-text-layer',
    circleId: ' activity-fundraising-layer',
    countFieldName: 'fundraising_count',
    translation: translate(5, 20, 8),
    activityName: 'DONATE',
  },
  announcementsLayer: {
    color: '#FFBB00',
    textId: ' activity-announcements-text-layer',
    circleId: ' activity-announcements-layer',
    countFieldName: 'announcements_count',
    translation: translate(6, 20, 8),
    activityName: 'ADVERTISEMENT',
  },
  vacanciesLayer: {
    color: '#6D6FFF',
    textId: ' activity-vacancy-text-layer',
    circleId: ' activity-vacancy-layer',
    countFieldName: 'vacancies_count',
    translation: translate(7, 20, 8),
    activityName: 'VACANCY',
  },
};

export const activityCircleLayersArray = map(activitiesLayersTemplateConfig, ({ color, circleId, translation, countFieldName }) =>
  createActivityCircleLayer(color, circleId, translation, countFieldName)
);

export const activityCircleLayersArray2 = map(activitiesLayersTemplateConfig, ({ color, circleId, translation, countFieldName }) =>
  createActivityCircleLayer(color, `${circleId}2`, translation, countFieldName)
);

export const activityTextLayersArray = map(activitiesLayersTemplateConfig, ({ textId, translation, countFieldName }) =>
  createActivityTextLayer(textId, translation, countFieldName)
);

export const activityTextLayersArray2 = map(activitiesLayersTemplateConfig, ({ textId, translation, countFieldName }) =>
  createActivityTextLayer(`${textId}2`, translation, countFieldName)
);

export const activityCircleLayerNamesArray = map(activityCircleLayersArray, ({ id }) => id);
export const activityTextLayerNamesArray = map(activityTextLayersArray, ({ id }) => id);
