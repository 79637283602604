import { CustomImage } from 'shared/components/common/CustomImage/CustomImage';
import NotificationModal from '../NotificationModal/NotificationModal';
import logoSystem from 'public/systemAvatar.png';
import defaultLogo from 'public/organisations/hero-logo-default.png';
import { formatDistanceToNow } from 'date-fns';
import { ru, be, enGB, lt, uk } from 'date-fns/locale';
import styles from './NotificationItem.module.scss';
import { useTranslation } from 'react-i18next';
import { MouseEventHandler, useContext } from 'react';
import { GlobalContext } from 'contexts/GlobalContext';
import { NotificationStatusEnum } from 'interfaces/notification.interface';
import { ScrollingTextWrapper } from 'shared/components/common/ScrollingText/ScrollingTextWrapper';
import { Icon } from 'shared/components/common/Icon/Icon';
import { toast } from 'react-toastify';
import formatServerError from 'utils/formatServerError';
import { getNotification } from 'services/notifications';
import { GlobalDataContext } from 'contexts/GlobalDataContext';
import { cloneDeep } from 'lodash';

type NotificationItemProps = {
  from: {
    name: string;
    logo: string;
  };
  id: number;
  title: string;
  created_at: string;
};
export const NotificationItem = ({ created_at, from, id, title }: NotificationItemProps) => {
  const { i18n } = useTranslation('roy-global');
  const {
    openModal,
    spinner: { showSpinner, hideSpinner },
  } = useContext(GlobalContext);
  const { notifications, setNotifications } = useContext(GlobalDataContext);
  const locales = {
    ru,
    be,
    enGB,
    lt,
    uk,
  };

  const deleteNotification: MouseEventHandler<HTMLDivElement> = async (ev) => {
    ev.stopPropagation();
    ev.preventDefault();
    showSpinner();
    try {
      await getNotification(id);

      const newNotifications = cloneDeep(notifications);
      newNotifications.find((notification) => notification.id === id).status = NotificationStatusEnum.read;
      setNotifications(newNotifications);
    } catch (e) {
      toast.error(formatServerError(e));
    } finally {
      hideSpinner();
    }
  };

  const time = formatDistanceToNow(new Date(created_at), {
    locale: locales[i18n.language],
    addSuffix: true,
  });
  return (
    <li key={id} className={styles.wrapper}>
      <button
        className={styles.notification}
        onClick={() => {
          openModal({
            params: { mainContainer: <NotificationModal id={id} /> },
          });
        }}
      >
        <div className={styles.content}>
          <CustomImage width={55} height={55} src={from.logo || logoSystem || defaultLogo} alt={from.name} />
          <div className={styles.textContent}>
            <ScrollingTextWrapper>
              <h5 className={styles.organization}>{from.name !== 'system' ? from.name : 'Roy.team'}</h5>
            </ScrollingTextWrapper>
            <ScrollingTextWrapper>
              <p className={styles.title}>{title}</p>
            </ScrollingTextWrapper>
            <ScrollingTextWrapper>
              <span className={styles.time}>{time}</span>
            </ScrollingTextWrapper>
          </div>
          <div onClick={deleteNotification} className={styles.icon}>
            <Icon iconName="close" />
          </div>
        </div>
      </button>
    </li>
  );
};
