import { Button } from 'shared/components/common/Button/Button';
import { GlobalDataContext } from 'contexts/GlobalDataContext';
import { useContext } from 'react';
import { GlobalContext } from 'contexts/GlobalContext';
import ExternalChatsModal from 'common/ExternalChatsModal/ExternalChatsModal';
import { useHistory } from 'react-router-dom';
import { routes } from 'Routes';
import { useTranslation } from 'react-i18next';

export const handleChatClick = async (selectedOrganisation, updateUnionActions, openModal, history?: any) => {
  if (!!selectedOrganisation.chats?.length) {
    openModal({
      params: {
        mainContainer: <ExternalChatsModal />,
      },
    });
    return;
  } else if (!!history) {
    history.push(`${routes.chat}?id=${selectedOrganisation.id}&type=organization`);
  }
};

const ChatButton = ({ organisationId }: { organisationId?: number }) => {
  const { t } = useTranslation('roy-global');
  const history = useHistory();
  const { selectedOrganisation, updateUnionActions } = useContext(GlobalDataContext);
  const { openModal } = useContext(GlobalContext);

  return (
    <Button
      icon="message"
      color="light"
      onClick={() => {
        if (!selectedOrganisation?.chats?.length || !!organisationId) {
          history.push(`${routes.chat}?id=${!!organisationId ? organisationId : selectedOrganisation.id}&type=organization`);
        } else {
          handleChatClick(selectedOrganisation, updateUnionActions, openModal);
        }
      }}
    >
      {organisationId || !selectedOrganisation?.chats?.length ? t('chat.org.chat') : t('chat.org.chats')}
    </Button>
  );
};

export default ChatButton;
