import Navigation from 'common/Navigation/Navigation';
import KbaseGroupContainer, { KbaseDataTypes, KbaseGroupDirection, KbaseVariety } from './KbaseGroupContainer/KbaseGroupContainer';
import { navigationKbase } from 'constans/navigationKbase';
import SEO from 'shared/utils/Seo';

const KnowledgeBaseSections = () => {
  return (
    <>
      <SEO image={'/logo-small.svg'} title={'Блог: разделы'} description={'Блог: разделы'} />
      <div>
        <Navigation navigations={navigationKbase()} />
        <KbaseGroupContainer variant={KbaseVariety.section} direction={KbaseGroupDirection.grid} type={KbaseDataTypes.all} />
      </div>
    </>
  );
};

export default KnowledgeBaseSections;
