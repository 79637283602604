import { httpService } from 'services';
import { KbaseMaterialAdmin, KbaseMaterialFront, KbaseSection, KbaseSectionLight } from 'shared/interfaces/kbase';
import { Api } from './http.service';
import { RequestMeta } from 'interfaces/request-meta.interface';

type IGetOrganizationMaterials = {
  organisationId: number;
  section?: number;
  sortDirection?: 'asc' | 'desc';
  page?: number;
  limit?: number;
  sortBy?: string;
};

type IGetMaterials = {
  section?: number;
  sortDirection?: 'asc' | 'desc';
  page?: number;
  limit?: number;
  tags?: string[];
  sortBy?: string;
};

export const getOrganizationSections = async (organisationId: number): Promise<{ data: KbaseSection[]; meta: RequestMeta }> => {
  const response = await httpService.axios.get(`${Api.ORGANISATIONS}/${organisationId}/kbase/sections`);
  return { data: response.data.data, meta: response.data.meta };
};

export const getSections = async (): Promise<{ data: KbaseSection[]; meta: RequestMeta }> => {
  const response = await httpService.axios.get(`/kbase/sections`);
  return { data: response.data.data, meta: response.data.meta };
};

export const getSectionMaterials = async (organisationId: number, sectionId: number): Promise<KbaseMaterialFront[]> => {
  const response = await httpService.axios.get(`${Api.ORGANISATIONS}/${organisationId}/kbase/sections/${sectionId}`, {
    params: { sortDirection: 'desc', sortBy: 'id' },
  });
  return response.data.data;
};

export const getOrganizationMaterials = async ({
  organisationId,
  section,
  page,
  limit,
  sortBy,
  sortDirection = 'desc',
}: IGetOrganizationMaterials): Promise<{ data: KbaseMaterialFront[]; meta: RequestMeta }> => {
  const response = await httpService.axios.get(`${Api.ORGANISATIONS}/${organisationId}/kbase/materials`, {
    params: { section, page, limit, sortDirection, sortBy },
  });
  return { data: response.data.data, meta: response.data.meta };
};

export const getMaterials = async ({
  section,
  page,
  limit,
  sortDirection = 'desc',
  tags,
  sortBy,
}: IGetMaterials): Promise<{ data: KbaseMaterialFront[]; meta: RequestMeta }> => {
  const response = await httpService.axios.get(`/kbase/materials`, {
    params: { section, page, limit, sortDirection, tags, sortBy },
  });
  return { data: response.data.data, meta: response.data.meta };
};

export const getMaterial = async (organisationId: number, materialId: number): Promise<KbaseMaterialFront> => {
  const response = await httpService.axios.get(`${Api.ORGANISATIONS}/${organisationId}/kbase/materials/${materialId}`);
  return response.data.data;
};

export async function createMaterial(orgId: string, data: Partial<KbaseMaterialAdmin>): Promise<KbaseMaterialAdmin> {
  const response = await httpService.axios.post(`/admin_org/${orgId}/kbase/materials`, data);
  return response?.data?.data;
}

export async function updateMaterial(orgId: string, newsId: number, data: Partial<KbaseMaterialAdmin>): Promise<KbaseMaterialAdmin> {
  const response = await httpService.axios.put(`/admin_org/${orgId}/kbase/materials/${newsId}`, data);
  return response?.data?.data;
}
export async function publishMaterial(orgId: string, newsId: number): Promise<void> {
  await httpService.axios.post(`/admin_org/${orgId}/kbase/materials/${newsId}/publish`);
}

export async function createSection(section: Omit<KbaseSectionLight, 'id'>, orgId: number): Promise<KbaseSectionLight> {
  const response = await httpService.axios.post(`/admin_org/${orgId}/kbase/sections`, section);
  return response?.data?.data;
}

export const getAdminSections = async (organisationId: number): Promise<KbaseSectionLight[]> => {
  const response = await httpService.axios.get(`/admin_org/${organisationId}/kbase/sections`);
  return response.data.data;
};
