import { Icons } from 'shared/components/common/Icon/Icon.interface';
import { CONTENT_TYPE } from 'interfaces/streamContent';
import styles from './CreateContent.module.scss';
import { routes } from 'Routes';
import { CreateButtons } from './CreateButtons/CreateButtons';
import { useTranslation } from 'react-i18next';
import { GlobalDataContext } from 'contexts/GlobalDataContext';
import { useContext } from 'react';

type Action = {
  type: CONTENT_TYPE | 'article' | 'linkMaterial';
  to: string;
  isActive: boolean;
  iconName?: keyof typeof Icons;
};

export const CreateContent = () => {
  const { t } = useTranslation('roy-global');
  const { user } = useContext(GlobalDataContext);
  const actions: Array<Action> = [
    {
      isActive: true,
      type: 'COMMUNITY',
      iconName: 'alliance',
      to: routes.CREATE_UNION,
    },
    {
      isActive: !!user?.administer?.length,
      type: 'news',
      iconName: 'news',
      to: routes.NEWS_EDITOR,
    },
    {
      isActive: !!user?.administer?.length,
      type: 'event',
      iconName: 'calendar',
      to: routes.EVENTS_EDITOR,
    },
    {
      isActive: !!user?.administer?.length,
      type: 'article',
      iconName: 'teacher',
      to: routes.MATERIALS_EDITOR,
    },
    {
      isActive: !!user?.administer?.length,
      type: 'linkMaterial',
      iconName: 'teacher',
      to: routes.MATERIALS_LINK_EDITOR,
    },
    {
      isActive: false,
      type: 'task',
      //
      iconName: 'taskSquare',
      to: '#',
    },
  ];
  return (
    <div className={styles.wrapper}>
      <h4>{t('buttons.create')}</h4>
      <div className={styles.content}>
        {actions.map((el, index) => {
          return (
            <CreateButtons isActive={el.isActive} to={el.to} iconName={el.iconName} type={el.type} key={`CreateContentButton-${index}`} />
          );
        })}
      </div>
    </div>
  );
};
