import { useTranslation } from 'react-i18next';
import styles from './Info.module.scss';

export const Info = () => {
  const { t } = useTranslation('home');
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.list}>
          <h5 className={styles.title}>{t('info.product.title')}</h5>
          <ul className={styles.with_padding}>
            <li>
              <span>{t('info.product.list.0.span')}</span>
              <br />
              {t('info.product.list.0.text')}
            </li>
            <li>
              <span>{t('info.product.list.1.span')}</span>
              <br />
              {t('info.product.list.1.text')}
            </li>
            <li>
              <span>{t('info.product.list.2.span')}</span>
              <br />
              {t('info.product.list.2.text')}
            </li>
            <li>
              <span>{t('info.product.list.3.span')}</span>
              <br />
              {t('info.product.list.3.text')}
            </li>
          </ul>
        </div>
        <div>
          <div className={styles.list}>
            <h5 className={styles.title}>{t('info.technologies.title')}</h5>
            <ul>
              <li>{t('info.technologies.list.0.text')}</li>
              <li>{t('info.technologies.list.1.text')}</li>
              <li>{t('info.technologies.list.2.text')}</li>
              <li>{t('info.technologies.list.3.text')}</li>
              <li>{t('info.technologies.list.4.text')}</li>
            </ul>
          </div>
          <div className={styles.list}>
            <h5 className={styles.title}>{t('info.competitors.title')}</h5>
            <ul>
              <li>{t('info.competitors.list.0.text')}</li>
              <li>{t('info.competitors.list.1.text')}</li>
              <li>{t('info.competitors.list.2.text')}</li>
              <li>{t('info.competitors.list.3.text')}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
