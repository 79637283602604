import styles from './BadgeList.module.scss';
import { CustomBadge } from '../CustomBadge/CustomBadge';
import { IBadge } from 'interfaces/badge.interface';
import { WalletHandler } from '@owlmeans/regov-ssi-core';
import { useTranslation } from 'react-i18next';
import { isSSR } from 'utils/isSSR';

type BadgeListType = {
  badges: Array<IBadge>;
  handler?: WalletHandler;
};
// user badge list. Used at src/pages/Profile/UserAchievements
export const BadgeList = ({ badges, handler }: BadgeListType) => {
  const { t } = useTranslation('ssi');
  const trust = badges.filter((el) => el.inner_type === 'trust');
  const expertise = badges.filter((el) => el.inner_type === 'expertise');
  const personal = badges.filter((el) => el.inner_type === 'personal');
  const achievements = badges.filter((el) => el.inner_type === 'achievements');
  return (
    <div className={styles.wrapper}>
      <h4>{t('badgeList.title')}</h4>
      {!!trust.length && (
        <div className={styles.block}>
          <h5 className={styles.title}>{`${t('badges.types.trust')}: ${trust.length}`}</h5>
          <div className={styles.badges}>
            {trust.map((el, index) => {
              return isSSR() ? undefined : <CustomBadge isHideShadow={!handler.wallet} badge={el} key={`badge-trust-${index}`} />;
            })}
          </div>
        </div>
      )}
      {!!expertise.length && (
        <div className={styles.block}>
          <h5 className={styles.title}>{`${t('badges.types.expertise')}: ${expertise.length}`}</h5>
          <div className={styles.badges}>
            {expertise.map((el, index) => {
              return <CustomBadge isHideShadow={!handler.wallet} badge={el} key={`badge-expertise-${index}`} />;
            })}
          </div>
        </div>
      )}
      {!!achievements.length && (
        <div className={styles.block}>
          <h5 className={styles.title}>{`${t('badges.types.achievements')}: ${achievements.length}`}</h5>
          <div className={styles.badges}>
            {achievements.map((el, index) => {
              return <CustomBadge isHideShadow={!handler.wallet} badge={el} key={`badge-achievements-${index}`} />;
            })}
          </div>
        </div>
      )}
      {!!personal.length && (
        <div className={styles.block}>
          <h5 className={styles.title}>{`${t('badges.types.personal')}: ${personal.length}`}</h5>
          <div className={styles.badges}>
            {personal.map((el, index) => {
              return <CustomBadge isHideShadow={!handler.wallet} badge={el} key={`badge-personal-${index}`} />;
            })}
          </div>
        </div>
      )}
    </div>
  );
};
