import { Redirect, Route, Switch } from 'react-router-dom';
import { Registration } from 'components/Auth/Registration/Registration';
import { Auth } from 'components/Auth/Auth';
import { VerifyEmail } from 'components/VerifyEmail/VerifyEmail';
import { ErrorPage } from 'components/ErrorPage/ErrorPage';
import { RulesService } from 'components/RulesService/RulesService';
import { PrivacyPolicy } from 'components/PrivacyPolicy/PrivacyPolicy';
import { PrivateRoute } from 'common/Routing/PrivateRoute/PrivateRoute';
import { CreateUnion } from 'components/CreateUnion/CreateUnion';
import { RoutingContainer } from 'common/Routing/RoutingContainer/RoutingContainer';
import EnterOrganization from 'containers/EnterOrganization/EnterOrganization';
import Unions from 'containers/Unions/Unions';
import { InvitationScreen } from 'components/InvitationScreen/InvitationScreen';
import { LaborUnion } from 'containers/LaborUnion/LaborUnion';
import { Security } from 'components/Security/Security';
import { InviteColleague } from 'components/InviteColleague/InviteColleague';
import { Landing } from 'components/Landing/Landing';
import { useContext } from 'react';
import { GlobalContext } from 'contexts/GlobalContext';
import Settings from 'pages/Settings/Settings';
import { TaskContainer } from 'pages/task/TaskContainer';
import News from 'pages/News/News';
import NewsDetails from 'pages/NewsDetails/NewsDetails';
import NewsSuggest from 'pages/NewsSuggest/NewsSuggest';
import Chat from 'shared/Chat/Chat';
import { toast } from 'react-toastify';
import PaymentStatus from 'components/PaymentStatus/PaymentStatus';
import TagsNews from 'pages/TagsNews/TagsNews';
import ContactUs from 'pages/ContactUs/ContactUs';
import Guarantee from 'pages/Guarantee/Guarantee';
import AboutUs from 'pages/AboutUs/AboutUs';
import SectionMaterials from 'pages/KnowledgeBase/SectionMaterials/SectionMaterials';
import MaterialDetails from 'pages/MaterialDetails/MaterialDetails';
import formatServerError from 'utils/formatServerError';
import KnowledgeBaseMaterials from 'pages/KnowledgeBase/KnowledgeBaseMaterials';
import TagsMaterials from 'pages/TagsMaterials/TagsMaterials';
import KnowledgeBaseSections from 'pages/KnowledgeBase/KnowledgeBaseSections';
import { Suggestion } from 'pages/Suggestion/Suggestion';
import OrganisationsNews from 'pages/OrganisationsNews/OrganisationsNews';
import Poll from 'pages/Poll/Poll';
import { GlobalDataContext } from 'contexts/GlobalDataContext';
import { Profile } from 'pages/Profile/Profile';
import { OrganizationPage } from 'pages/OrganizationPage/OrganizationPage';
import { VerifyGoogleLogin } from 'components/VerifyGoogleLogin/VerifyGoogleLogin';
import EventsDetails from 'pages/EventsDetails/EventsDetails';
import CalendarPage from 'pages/Calendar/Calendar';
import { useTranslation } from 'react-i18next';
// import { GlobalFeed } from 'pages/FeedPage/GlobalFeed';
import { EventsCreation } from 'pages/Editors/EventCreation/EventsCreation';
import { NewsCreation } from 'pages/Editors/NewsCreation/NewsCreation';
import Events from 'pages/Events/Events';
import { MaterialsCreation } from 'pages/Editors/MaterialsCreation/MaterialsCreation';
import { MaterialsLinkCreation } from 'pages/Editors/MaterialsLinkCreation/MaterialsLinkCreation';

export const routes = {
  enterUnion: {
    route: '/enter-union/:id',
    getLink(id) {
      return this.route.replace(':id', id);
    },
    requireAuth: true,
  },
  profile: {
    route: `/profile/:id`,
    getLink(id: number | string) {
      return this.route.replace(':id', id);
    },
  },
  union: {
    route: `/union/:id`,
    getLink(id) {
      return this.route.replace(':id', id);
    },
  },
  unionNews: {
    route: `/org/:organizationId/news`,
    getLink(id) {
      return this.route.replace(':organizationId', id);
    },
  },
  task: {
    route: `/union/:orgId/tasks/:taskId`,
    getLink(orgId, taskId) {
      return this.route.replace(':orgId', orgId).replace(':taskId', taskId);
    },
  },
  tasks: {
    route: `/union/:id/tasks`,
    getLink(id) {
      return this.route.replace(':id', id);
    },
  },

  newsDetails: {
    route: `/news/:orgId/:id`,
    getLink(orgId, id) {
      return this.route.replace(':orgId', orgId).replace(':id', id);
    },
  },

  eventsDetails: {
    route: `/events/:orgId/:eventId`,
    getLink(orgId, id) {
      return this.route.replace(':orgId', orgId).replace(':eventId', id);
    },
  },

  newsSuggest: {
    route: `/news/:organizationId/suggest`,
    getLink(orgId) {
      return this.route.replace(':organizationId', orgId);
    },
  },

  suggestion: {
    route: `/union/:organizationId/suggestion/:suggestionId`,
    getLink(orgId, suggestionId) {
      return this.route.replace(':organizationId', orgId).replace(':suggestionId', suggestionId);
    },
  },

  sectionMaterials: {
    // TODO: add /union/${id} to route
    route: `/:organizationId/section/:sectionId/materials`,
    getLink(orgId, sectionId) {
      return this.route.replace(':organizationId', orgId).replace(':sectionId', sectionId);
    },
  },

  poll: {
    route: `/:organizationId/poll/:pollId`,
    getLink(orgId, pollId) {
      return this.route.replace(':organizationId', orgId).replace(':pollId', pollId);
    },
  },

  closePoll: {
    route: `/:organizationId/poll/:pollId/closed`,
    getLink(orgId, pollId) {
      return this.route.replace(':organizationId', orgId).replace(':pollId', pollId);
    },
  },

  materialDetails: {
    route: `/:organizationId/materials/:materialId`,
    getLink(orgId, materialId) {
      return this.route.replace(':organizationId', orgId).replace(':materialId', materialId);
    },
  },
  chat: '/chat',

  settings: '/profile-settings',

  // old code, use small letter!!!
  HOME: '/',
  LOGIN: '/login',
  ABOUT_US: '/about-us',
  INVITATION_PAGE: '/invitation-page',
  FORGET_PASSWORD: '/forget-password',
  REGISTRATION: '/registration',
  PROFILE: '/profile',
  UNION: '/union',
  UNION_FU: '/union/22',
  UNIONS: '/unions',
  CONTACT_US: '/contact-us',
  LABOR_UNION: '/labor-union',
  EDIT_USER_PROFILE: '/edit-user-profile',
  VERIFY_EMAIL: '/email/verify',
  VERIFY_GOOGLE_LOGIN: '/google-check',
  RESET_PASSWORD: '/email/reset',
  ERROR_PAGE: '/error-page',
  SECURITY: '/security',
  GUARANTEE: '/guarantee',
  PRIVACY_POLICY: '/privacy-policy',
  RULES_SERVICE: '/rules-service',
  INVITE_COLLEAGUE: '/invite-colleague',
  LANDING: '/Landing',
  INVITATION_SCREEN: '/invitation-screen',
  SUGGESTIONS: '/suggestions',
  CREATE_UNION: '/union-new',
  ENTER_UNION: '/enter-union',
  NEWS: '/news',
  EVENTS: '/events',
  KNOWLEDGE_BASE_MATERIALS: '/kbase/materials',
  KNOWLEDGE_BASE_SECTIONS: '/kbase/sections',
  NEWS_BELARUS_FOR_UKRAINE: '/belarus-with-ukraine-news',
  SUPPORT_US: '/support-us',
  PAYMENT_STATUS: '/payment-status',
  TAGS_NEWS: '/tags-news',
  TAGS_MATERIALS: '/kbase/materials/tag',
  UNION_NEWS: '/org/:organizationId/news',
  SSI_CLAIM_ORGANIZATION: '/ssi/claim/organization',
  SSI_CLAIM_MEMBERSHIP: '/ssi/claim/membership',
  SSI_GUEST_DASHBOARD: '/ssi/guest/dashboard',
  CALENDAR: '/calendar',
  NEWS_EDITOR: '/editor/news',
  MATERIALS_EDITOR: '/editor/materials',
  MATERIALS_LINK_EDITOR: '/editor/materials-link',
  EVENTS_EDITOR: '/editor/events',
};

export function Routes() {
  const { t } = useTranslation('content');
  const {
    services: { userService },
    openModal,
    closeModal,
    setSidebarExpandedState,
  } = useContext(GlobalContext);

  const { user } = useContext(GlobalDataContext);

  const getUser = () => {
    try {
      return userService.getUser();
    } catch (error) {
      console.error(error);
      toast(formatServerError(error.data));
    }
  };

  return (
    <Switch>
      <Route exact path="/dashboard">
        <Redirect to={!!user ? routes.PROFILE : routes.HOME} />
      </Route>
      <Route exact path={routes.HOME}>
        <Landing />
      </Route>

      <Route path={`${routes.VERIFY_EMAIL}/:id/:hash`}>
        <VerifyEmail />
      </Route>

      <Route path={routes.VERIFY_GOOGLE_LOGIN}>
        <VerifyGoogleLogin />
      </Route>

      <Route path={routes.LOGIN}>
        <RoutingContainer>
          <Auth type="login" />
        </RoutingContainer>
      </Route>

      <Route path={routes.SUPPORT_US}>
        <Landing />
      </Route>

      <Route path={routes.INVITATION_PAGE}>
        <RoutingContainer>
          <Auth type="invitationPage" />
        </RoutingContainer>
      </Route>

      <Route path={routes.FORGET_PASSWORD}>
        <RoutingContainer>
          <Auth type="forgetPassword" />
        </RoutingContainer>
      </Route>

      <Route path={`${routes.RESET_PASSWORD}/:token`}>
        <RoutingContainer>
          <Auth type="resetPassword" />
        </RoutingContainer>
      </Route>

      <Route path={routes.REGISTRATION}>
        <RoutingContainer title={t('registration')}>
          <Registration />
        </RoutingContainer>
      </Route>

      <Route path={routes.ERROR_PAGE}>
        <ErrorPage />
      </Route>

      <Route path={routes.CONTACT_US}>
        <RoutingContainer title={t('contacts')} showCloseButton>
          <ContactUs />
        </RoutingContainer>
      </Route>

      <Route path={routes.SECURITY}>
        <RoutingContainer title={t('security')} showCloseButton={true}>
          <Security />
        </RoutingContainer>
      </Route>

      <Route path={routes.GUARANTEE}>
        <PrivateRoute>
          <Guarantee />
        </PrivateRoute>
      </Route>

      <Route path={routes.ABOUT_US}>
        <RoutingContainer title={t('aboutUs')} showCloseButton>
          <AboutUs />
        </RoutingContainer>
      </Route>

      <Route path={routes.RULES_SERVICE}>
        <RoutingContainer
          showCloseButton={userService.isLoggedIn}
          closeButtonRoute={!!user ? routes.PROFILE : routes.HOME}
          //showScrollToTopButton
        >
          <RulesService />
        </RoutingContainer>
      </Route>

      <Route path={routes.PRIVACY_POLICY}>
        <RoutingContainer
          showCloseButton={userService.isLoggedIn}
          closeButtonRoute={!!user ? routes.PROFILE : routes.HOME}
          //showScrollToTopButton
        >
          <PrivacyPolicy />
        </RoutingContainer>
      </Route>
      <Route path={routes.task.route}>
        <TaskContainer />
      </Route>

      <Route path={routes.suggestion.route}>
        <Suggestion />
      </Route>

      <Route path={[routes.profile.route, routes.PROFILE]}>
        <RoutingContainer title={t('profile')} withFooter={false}>
          <Profile />
        </RoutingContainer>
      </Route>

      <Route path={[routes.union.route, routes.UNION, routes.UNION_FU]}>
        <OrganizationPage />
      </Route>

      <Route path={routes.UNIONS}>
        <RoutingContainer title={t('match.community')} withFooter={false}>
          <Unions />
        </RoutingContainer>
      </Route>

      <Route path={routes.UNION_NEWS}>
        <OrganisationsNews />
      </Route>

      <Route path={routes.enterUnion.route}>
        <PrivateRoute>
          <EnterOrganization />
        </PrivateRoute>
      </Route>

      <Route path={routes.LABOR_UNION}>
        <PrivateRoute>
          <RoutingContainer>
            <LaborUnion />
          </RoutingContainer>
        </PrivateRoute>
      </Route>

      <Route path={routes.CREATE_UNION}>
        <PrivateRoute>
          <RoutingContainer title={`${t('create')} ${t('single.community').toLocaleLowerCase()}`} showCloseButton={true}>
            <CreateUnion />
          </RoutingContainer>
        </PrivateRoute>
      </Route>

      {/* <Route path={routes.EDIT_USER_PROFILE}>
        <PrivateRoute>
          <RoutingContainer title="Личные данные" showCloseButton={true}>
            <EditUserProfile />
          </RoutingContainer>
        </PrivateRoute>
      </Route> */}

      <Route path={routes.INVITE_COLLEAGUE}>
        <PrivateRoute>
          <InviteColleague />
        </PrivateRoute>
      </Route>

      <Route path={routes.INVITATION_SCREEN}>
        <PrivateRoute>
          <InvitationScreen />
        </PrivateRoute>
      </Route>

      <Route exact path={routes.EVENTS}>
        <RoutingContainer title={t('match.event')} withFooter={false}>
          <Events />
        </RoutingContainer>
      </Route>

      <Route exact path={routes.NEWS}>
        <RoutingContainer title={t('match.news')}>
          <News />
        </RoutingContainer>
      </Route>

      <Route exact path={routes.KNOWLEDGE_BASE_MATERIALS}>
        <RoutingContainer title={t('single.material')}>
          <KnowledgeBaseMaterials />
        </RoutingContainer>
      </Route>

      <Route exact path={routes.KNOWLEDGE_BASE_SECTIONS}>
        <RoutingContainer showCloseButton>
          <KnowledgeBaseSections />
        </RoutingContainer>
      </Route>

      <Route path={[routes.TAGS_NEWS, routes.NEWS_BELARUS_FOR_UKRAINE]}>
        <TagsNews />
      </Route>
      <Route path={routes.TAGS_MATERIALS}>
        <TagsMaterials />
      </Route>

      <Route path={routes.newsSuggest.route}>
        <PrivateRoute>
          <NewsSuggest />
        </PrivateRoute>
      </Route>

      <Route exact path={routes.poll.route}>
        <Poll />
      </Route>

      <Route exact path={routes.closePoll.route}>
        <Poll closed />
      </Route>

      <Route exact path={routes.newsDetails.route}>
        <NewsDetails />
      </Route>

      <Route exact path={routes.eventsDetails.route}>
        <EventsDetails />
      </Route>

      <Route exact path={routes.materialDetails.route}>
        <MaterialDetails />
      </Route>

      <Route path={routes.chat}>
        <PrivateRoute>
          <RoutingContainer withSidebar withFooter={false} title={t('chat')}>
            <Chat openModal={openModal} closeModal={closeModal} getUserData={getUser} onMenuClick={setSidebarExpandedState} isFront />
          </RoutingContainer>
        </PrivateRoute>
      </Route>
      <Route path={routes.sectionMaterials.route}>
        <RoutingContainer title={t('match.material')} showCloseButton>
          <SectionMaterials />
        </RoutingContainer>
      </Route>

      <Route path={routes.settings}>
        <PrivateRoute>
          <RoutingContainer title={t('settings')} showCloseButton>
            <Settings />
          </RoutingContainer>
        </PrivateRoute>
      </Route>

      <Route path={routes.CALENDAR}>
        <PrivateRoute>
          <RoutingContainer title={t('calendar')} withFooter={false}>
            <CalendarPage />
          </RoutingContainer>
        </PrivateRoute>
      </Route>

      <Route exact path={routes.PAYMENT_STATUS}>
        <RoutingContainer showCloseButton>
          <PaymentStatus />
        </RoutingContainer>
      </Route>

      <Route exact path={routes.NEWS_EDITOR}>
        <RoutingContainer title={t('create') + ' ' + t('single.news')} withFooter={false}>
          <NewsCreation key={`Editor-page-news`} />
        </RoutingContainer>
      </Route>
      <Route exact path={routes.MATERIALS_EDITOR}>
        <RoutingContainer title={t('create') + ' ' + t('article')} withFooter={false}>
          <MaterialsCreation key={`Editor-page-materials`} />
        </RoutingContainer>
      </Route>
      <Route exact path={routes.MATERIALS_LINK_EDITOR}>
        <RoutingContainer title={t('create') + ' ' + t('single.linkmaterial')} withFooter={false}>
          <MaterialsLinkCreation key={`Editor-page-materials-link`} />
        </RoutingContainer>
      </Route>
      <Route exact path={routes.EVENTS_EDITOR}>
        <RoutingContainer title={t('createEvent')} withFooter={false}>
          <EventsCreation key={`Editor-page-event`} />
        </RoutingContainer>
      </Route>
      <Route path="*">
        <div>{t('notFoundPage')}</div>
      </Route>
    </Switch>
  );
}
