import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch, AppThunk } from 'redux-toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  getComments,
  createComment,
  updateComment,
  deleteComment,
  updateReactionComment,
} from 'shared/services/comments.service';
import { ICommentReactions, IComments } from 'shared/interfaces/comments';
import { cloneDeep } from 'lodash';

export type CommentsState = {
  comments: IComments[];
  generalData: { essenceName: string; isAdminApp: boolean; user: any; essenceId: string; disable?: boolean };
};

const initialState = {
  comments: [],
  generalData: {
    essenceName: '',
    essenceId: '',
    isAdminApp: false,
    user: null,
  },
};

const slice = createSlice({
  name: 'comments',
  initialState,
  reducers: {
    setComments(
      state: CommentsState,
      { payload }: PayloadAction<{ comments: IComments[]; isFirst?: boolean }>
    ): CommentsState {
      let copyComments = cloneDeep(state.comments);
      if (!!copyComments && !!copyComments.length && !payload.isFirst) {
        copyComments.push(...payload.comments);
      } else {
        copyComments = payload.comments;
      }
      return {
        ...state,
        comments: copyComments,
      };
    },
    setAnswersComments(
      state: CommentsState,
      { payload }: PayloadAction<{ comments: IComments[]; parent_id: number }>
    ): CommentsState {
      const copy = cloneDeep(state.comments);
      const parentComment = copy.find((comment) => comment.id === payload.parent_id);
      if (!!parentComment.answersComments && !!parentComment.answersComments.length) {
        parentComment.answersComments.push(...payload.comments);
      } else {
        parentComment.answersComments = payload.comments;
      }

      return {
        ...state,
        comments: state.comments.map((comment) => ({
          ...comment,
          ...(comment?.id === payload.parent_id && parentComment),
        })),
      };
    },
    setGeneralData(
      state: CommentsState,
      {
        payload,
      }: PayloadAction<{
        essenceName: string;
        isAdminApp: boolean;
        user: any;
        essenceId: string;
        disable?: boolean;
      }>
    ): CommentsState {
      return {
        ...state,
        generalData: payload,
      };
    },
    createCommentReducer(state: CommentsState, { payload }: PayloadAction<IComments>): CommentsState {
      return {
        ...state,
        comments: [payload, ...state.comments],
      };
    },
    createAnswerCommentReducer(
      state: CommentsState,
      { payload }: PayloadAction<{ comment: IComments; parent_id: number }>
    ): CommentsState {
      const copy = cloneDeep(state.comments);
      const parentComment = copy.find((comment) => comment.id === payload.parent_id);
      if (!!parentComment.answersComments) {
        parentComment.answersComments = [payload.comment, ...parentComment.answersComments];
      } else {
        parentComment.answersComments = [payload.comment];
      }
      parentComment.answers = (parentComment.answers || 0) + 1;

      return {
        ...state,
        comments: state.comments.map((comment) => ({
          ...comment,
          ...(comment?.id === payload.parent_id && parentComment),
        })),
      };
    },
    updateCommentReducer(
      state: CommentsState,
      { payload }: PayloadAction<{ comment: IComments; parent_id: number }>
    ): CommentsState {
      if (!payload.parent_id) {
        return {
          ...state,
          comments: state.comments.map((comment) => ({
            ...comment,
            ...(comment?.id === payload.comment.id && payload.comment),
          })),
        };
      } else {
        const copy = cloneDeep(state.comments);
        const parentComment = copy.find((comment) => comment.id === payload.parent_id);
        parentComment.answersComments = parentComment.answersComments.map((comment) => ({
          ...comment,
          ...(comment?.id === payload.comment.id && payload.comment),
        }));
        return {
          ...state,
          comments: state.comments.map((comment) => ({
            ...comment,
            ...(comment?.id === parentComment.id && parentComment),
          })),
        };
      }
    },

    updateCommentReactionReducer(
      state: CommentsState,
      { payload }: PayloadAction<{ comment: IComments; parent_id: number }>
    ): CommentsState {
      const copyComments = cloneDeep(state.comments);
      if (!payload.parent_id) {
        let reactComment = copyComments.find((comment) => comment.id === payload.comment.id);
        reactComment.reactions = payload.comment.reactions;
        reactComment.my_reaction = payload.comment.my_reaction;
        return {
          ...state,
          comments: state.comments.map((comment) => ({
            ...comment,
            ...(comment?.id === payload.comment.id && reactComment),
          })),
        };
      } else {
        const parentComment = copyComments.find((comment) => comment.id === payload.parent_id);
        let reactComment = parentComment.answersComments.find((comment) => comment.id === payload.comment.id);
        reactComment.reactions = payload.comment.reactions;
        reactComment.my_reaction = payload.comment.my_reaction;

        parentComment.answersComments = parentComment.answersComments.map((comment) => ({
          ...comment,
          ...(comment?.id === payload.comment.id && reactComment),
        }));
        return {
          ...state,
          comments: state.comments.map((comment) => ({
            ...comment,
            ...(comment?.id === parentComment.id && parentComment),
          })),
        };
      }
    },

    deleteCommentReducer(
      state: CommentsState,
      { payload }: PayloadAction<{ commentId: number; parent_id: number }>
    ): CommentsState {
      const copyComments = cloneDeep(state.comments);
      if (!payload.parent_id) {
        let deleteComment = copyComments.find((comment) => comment.id === payload.commentId);
        deleteComment.comment = null;
        deleteComment.is_changed = true;
        deleteComment.deleted_at = new Date();
        return {
          ...state,
          comments: state.comments.map((comment) => ({
            ...comment,
            ...(comment?.id === payload.commentId && deleteComment),
          })),
        };
      } else {
        let parentComment = copyComments.find((comment) => comment.id === payload.parent_id);
        let deleteComment = parentComment.answersComments.find((comment) => comment.id === payload.commentId);
        deleteComment.comment = null;
        deleteComment.deleted_at = new Date();
        deleteComment.is_changed = true;
        parentComment.answersComments.forEach((comment) => ({
          ...comment,
          ...(comment?.id === payload.commentId && deleteComment),
        }));
        return {
          ...state,
          comments: state.comments.map((comment) => ({
            ...comment,
            ...(comment?.id === parentComment.id && parentComment),
          })),
        };
      }
    },
  },
});

export const {
  setComments,
  setGeneralData,
  createCommentReducer,
  setAnswersComments,
  createAnswerCommentReducer,
  updateCommentReducer,
  updateCommentReactionReducer,
  deleteCommentReducer,
} = slice.actions;

export const { reducer } = slice;

export const getGeneralActions =
  (user: any, essenceName, essenceId, isAdminApp?: boolean, disable?: boolean): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(setGeneralData({ user, essenceName, essenceId, isAdminApp, disable }));
  };

export const getCommentsAction =
  (
    essenceId: string,
    essenceName: string,
    parent_id?: number,
    limit?: number,
    after_id?: number,
    isAdminApp?: boolean,
    isFirstTime?: boolean
  ) =>
  async (dispatch: AppDispatch): Promise<IComments[]> => {
    const {
      data: { data },
    }: { data: { data: IComments[] } } = await getComments({
      essenceId: essenceId,
      essenceName: essenceName,
      isAdminApp: isAdminApp,
      limit: limit,
      parent_id: parent_id,
      after_id: after_id,
    });
    if (!!parent_id) {
      dispatch(setAnswersComments({ comments: data, parent_id: parent_id }));
    } else if (!!isFirstTime) {
      dispatch(setComments({ comments: data, isFirst: isFirstTime }));
    } else {
      dispatch(setComments({ comments: data }));
    }
    return data;
  };

export const createCommentAction =
  (essenceId: string, essenceName, comment: string, parent_id?: number, isAdminApp?: boolean) =>
  async (dispatch: AppDispatch): Promise<void> => {
    const {
      data: { data },
    }: { data: { data: IComments } } = await createComment({
      essenceId: essenceId,
      essenceName: essenceName,
      isAdminApp: isAdminApp,
      parent_id: parent_id,
      comment: comment,
    });

    if (!!parent_id) {
      dispatch(createAnswerCommentReducer({ comment: data, parent_id: parent_id }));
    } else {
      dispatch(createCommentReducer(data));
    }
  };

export const updateCommentAction =
  (essenceId: string, commentId: number, essenceName, comment: string, parent_id?: number, isAdminApp?: boolean) =>
  async (dispatch: AppDispatch): Promise<void> => {
    const {
      data: { data },
    }: { data: { data: IComments } } = await updateComment({
      essenceId: essenceId,
      essenceName: essenceName,
      isAdminApp: isAdminApp,
      comment: comment,
      commentId: commentId,
    });

    dispatch(updateCommentReducer({ comment: data, parent_id: parent_id }));
  };

export const deleteCommentAction =
  (essenceId: string, commentId: number, essenceName, parent_id?: number, isAdminApp?: boolean) =>
  async (dispatch: AppDispatch): Promise<void> => {
    await deleteComment({
      essenceId: essenceId,
      essenceName: essenceName,
      commentId: commentId,
      isAdminApp: isAdminApp,
    });

    dispatch(deleteCommentReducer({ commentId: commentId, parent_id: parent_id }));
  };

export const updateCommentReactionAction =
  (
    essenceId: string,
    commentId: number,
    reaction: ICommentReactions,
    essenceName,
    parent_id?: number,
    isAdminApp?: boolean
  ) =>
  async (dispatch: AppDispatch): Promise<void> => {
    const {
      data: { data },
    }: { data: { data: IComments } } = await updateReactionComment({
      essenceId: essenceId,
      essenceName: essenceName,
      commentId: commentId,
      reaction: reaction,
      isAdminApp: isAdminApp,
    });

    dispatch(updateCommentReactionReducer({ comment: data, parent_id: parent_id }));
  };

export default slice;
