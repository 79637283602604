import idea from 'public/images/about-us.jpg';
import roy from 'public/images/roy-team.svg';
import { Link } from 'react-router-dom';
import { routes } from 'Routes';
import styles from './RoyTeam.module.scss';
import { useTranslation } from 'react-i18next';

export const RoyTeam = () => {
  const { t } = useTranslation('home');

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.idea}>
          <img src={'/logo.svg'} alt={'Roy Team'} width={230} height={100} className={styles.logo} />
          <div>
            <img src={idea} alt={'Roy Team'} />
            <p>{t('main.welcomeText')}</p>
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          <div className={styles.roy_team}>
            <img src={roy} alt={'Roy Team'} className={styles.logo} />
          </div>
          <div className={styles.community}>
            <div className={styles.text_content}>
              <div>
                <img src={'/logo.svg'} alt={'Roy Team'} width={150} height={33} className={styles.logo} />
                <div className={styles.title}>ROY.TEAM</div>
                <div className={styles.about}>{t('main.description')}</div>
                <Link to={routes.LOGIN} rel="noreferrer" className={styles.button}>
                  {t('buttons.login')}
                </Link>
                <Link to={routes.REGISTRATION} rel="noreferrer" className={styles.button}>
                  {t('buttons.registration')}
                </Link>
              </div>
              <div className={styles.list}>
                <ul>
                  <li>{t('main.list.singlePlace')}</li>
                  <li>{t('main.list.socialLife')}</li>
                  <li>{t('main.list.firstSocial')}</li>
                  {/* <li>политически нейтральны</li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
