import { useContext, useEffect, useMemo, useRef } from 'react';
import { routes } from 'Routes';
import { useLocation, useParams } from 'react-router-dom';
import { GlobalContext } from 'contexts/GlobalContext';
import { useSsrEffect, useSsrState } from '@issr/core';
import { toast } from 'react-toastify';
import formatServerError from 'utils/formatServerError';
import { NewsDataTypes } from 'pages/News/NewsGroupContainer/NewsGroupContainer';
import { INewsDetails } from 'shared/interfaces/news';
import { getFundraisings } from 'services/finance';
import { PaymentCreated, PaymentTypeEnum } from 'shared/interfaces/finance';
import { GlobalDataContext } from 'contexts/GlobalDataContext';
import { RoutingContainer } from 'common/Routing/RoutingContainer/RoutingContainer';
import SEO from 'shared/utils/Seo';
import ContentLayout from 'containers/ContentLayout/ContentLayout';
import { EnumContentType, ILogo } from 'containers/ContentLayout/ContentLayoutTypes';

function NewsDetails() {
  const { id, orgId } = useParams<{ id: string; orgId: string }>();
  const { search, state } = useLocation<{ from?: string }>();

  function useQuery() {
    return useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();

  const category: NewsDataTypes = (query.get('category') as NewsDataTypes) || NewsDataTypes.organisationNews;

  let tags = useMemo(() => {
    return query.get('tags')?.split(',') || [];
  }, [query.get('tags')]);

  const {
    spinner: { showSpinner, hideSpinner },
    services: { organisationsService },
  } = useContext(GlobalContext);

  const { user } = useContext(GlobalDataContext);

  const [newsContent, setNewsContent] = useSsrState<INewsDetails>(null);

  const [logo, setLogo] = useSsrState<ILogo>(undefined);
  const [payments, setPayments] = useSsrState<PaymentCreated[]>([]);
  const returnPage = useRef(null);

  useEffect(() => {
    if (state?.from) {
      returnPage.current = state.from;
    }
  }, []);

  function getCloseRoute() {
    if (returnPage.current) {
      return returnPage.current;
    } else if (category === NewsDataTypes.organisationNews) {
      if (!user) {
        // not authorized
        return routes.NEWS;
      } else {
        return routes.union.getLink(orgId);
      }
    } else {
      return routes.NEWS;
    }
  }

  const loadNewsDetails = async () => {
    const newsContent = (
      await organisationsService.getOrganisationOneNews({
        organisationId: +orgId,
        newsId: +id,
        sortBy:
          category === NewsDataTypes.allNews || category === NewsDataTypes.bestNews
            ? 'id'
            : category === NewsDataTypes.popular
            ? 'popular'
            : undefined,
        organization_id: category === NewsDataTypes.organisationNews ? +orgId : undefined,
        featured: category === NewsDataTypes.bestNews && 1,
        tags: tags,
      })
    ).data.data;

    setNewsContent(newsContent);
    setLogo({
      src: newsContent.organization?.avatar,
      href: routes.union.getLink(orgId),
      alt: '',
    });
  };

  useEffect(() => {
    (async () => {
      try {
        const fundraisings = await getFundraisings(+orgId);
        setPayments(fundraisings);
      } catch (e) {
        toast.error(formatServerError(e));
        console.log(formatServerError(e));
      }
    })();
  }, [orgId]);

  useSsrEffect(async () => {
    try {
      if (category && id && orgId) {
        await loadNewsDetails();
      }
    } catch (error) {
      // TODO: need to think how to handle 404 server error
      console.log(error);
    }
  }, id);

  useEffect(() => {
    (async () => {
      try {
        showSpinner();
        if (category && id && orgId) {
          await loadNewsDetails();
        }
      } catch (e) {
        toast.error(formatServerError(e));
      } finally {
        hideSpinner();
      }
    })();
  }, [orgId, id, category]);

  const findPayment = payments
    ?.filter((payment) => !!payment.auto_payments?.length || !!payment.manual_payments?.length)
    ?.find((payment) => payment.type === PaymentTypeEnum.fundraising);

  return (
    <RoutingContainer withFooter={false} showCloseButton={true} closeButtonRoute={getCloseRoute()} title="Новости">
      <SEO title={newsContent?.title} description={newsContent?.excerpt || newsContent?.title} image={newsContent?.preview} />
      {newsContent && (
        <ContentLayout
          title={newsContent.title}
          contentId={newsContent.id}
          orgId={newsContent.organization.id}
          contentType={EnumContentType.news}
          content={newsContent.content}
          user={newsContent.user_id}
          published_at={newsContent.published_at}
          next={newsContent.next}
          prev={newsContent.prev}
          category={category}
          tags={newsContent.tags}
          logo={logo}
          commentsCount={newsContent.comments_count}
          choosePayment={findPayment}
        />
      )}
    </RoutingContainer>
  );
}

export default NewsDetails;
