import classNames from 'classnames';
import { useState } from 'react';
import { Cropper } from 'react-cropper';
import { Button } from 'shared/components/common/Button/Button';
import styles from './CropperModal.module.scss';
import { useTranslation } from 'react-i18next';

type CropperModalProps = {
  src: string;

  onAccept: (val: string) => void;
  onDelete?: () => void;
  onCancel?: () => void;
  aspectRatio?: number;
};

export const CropperModal = ({ src, onDelete, onAccept, onCancel, aspectRatio = 1 }: CropperModalProps) => {
  const { t } = useTranslation('roy-global');
  const [localCropper, setLocalCropper] = useState<Cropper>(null);
  return (
    <div className={styles.wrapper}>
      <Cropper
        className={styles.cropper}
        src={src}
        cropBoxResizable={false}
        toggleDragModeOnDblclick={false}
        cropBoxMovable={false}
        aspectRatio={aspectRatio}
        dragMode={'move'}
        autoCropArea={100}
        draggable
        onInitialized={(instance) => {
          setLocalCropper(instance);
        }}
      />
      <div className={styles.buttons}>
        {!!onDelete && (
          <Button className={classNames(styles.button, styles.secondary)} color="light" size="small" onClick={onDelete}>
            {t('buttons.delete')}
          </Button>
        )}
        {!!onCancel && (
          <Button
            className={classNames(styles.button, styles.secondary)}
            color="light"
            size="small"
            onClick={() => {
              onCancel();
              localCropper.reset();
            }}
          >
            {t('buttons.cancel')}
          </Button>
        )}
        {!!onAccept && (
          <Button
            className={styles.button}
            color="light"
            size="small"
            onClick={() => {
              onAccept(localCropper.getCroppedCanvas().toDataURL());
            }}
          >
            {t('buttons.save')}
          </Button>
        )}
      </div>
    </div>
  );
};
