import styles from './ProfileAbout.module.scss';
import classNames from 'classnames';
import { CustomImage } from 'shared/components/common/CustomImage/CustomImage';
import { EnterOrganizationStatuses, IOrganisation, IOrganizationEnterRequest } from 'interfaces/organisation.interface';
import { BannerSlideShow } from 'common/BannerSlideShow/BannerSlideShow';
import VerificationSign from 'common/VerificationSign/VerificationSign';
import { Icon } from 'shared/components/common/Icon/Icon';
import { useHistory } from 'react-router-dom';
import { routes } from 'Routes';
import { handleChatClick } from 'common/ChatButton/ChatButton';
import { useContext, MouseEventHandler } from 'react';
import { GlobalContext } from 'contexts/GlobalContext';
import { toast } from 'react-toastify';
import OrganisationProfileProgress from './OrganisationProfileProgress/OrganisationProfileProgress';
import { EditableSocial } from 'pages/Profile/UserProfileLayout/AboutUser/UserSocial/EditableSocial/EditableSocial';
import { Achievements } from 'components/Achievements/Achievements';
import { useTranslation } from 'react-i18next';
import { inviteLinksService } from 'services';
import formatServerError from 'shared/utils/formatServerError';

type Contats = {
  title: string;
  value: JSX.Element | null;
};

type ProfileAboutProps = {
  organization: IOrganisation;
  requestStatus: IOrganizationEnterRequest;
  setRequestStatus: (status: IOrganizationEnterRequest) => void;
  isOpenStream: boolean;
  className?: string;
  isMobile?: boolean;
  id: number;
};

export const ProfileAbout = ({
  organization,
  requestStatus,
  isOpenStream,
  className,
  isMobile,
  id,
  setRequestStatus,
}: ProfileAboutProps) => {
  const { t } = useTranslation('profile');
  const {
    openModal,
    spinner: { showSpinner, hideSpinner },
    services: { deviceService },
  } = useContext(GlobalContext);
  const history = useHistory();
  const { banners, avatar, short_name, is_verified, description, social } = organization;
  const smallBanners = banners?.map((el) => el.small);
  const largeBanners = banners?.map((el) => el.large);
  const generateInviteLink: MouseEventHandler = async () => {
    try {
      showSpinner();
      const { id, code } = await inviteLinksService.createInviteLink(organization.id);
      const link = inviteLinksService.generateInviteLink(id, code);
      await navigator?.clipboard?.writeText(t('invite.indeed') + link);
      toast(t('toast.copyLink'));
    } catch (error) {
      toast.error(formatServerError(error));
    } finally {
      hideSpinner();
    }
  };
  const getInfoSection: Array<Contats> = [
    {
      title: t('organization.about.contacts.website'),
      value: organization.site ? (
        <a href={organization.site} target="_blank" rel="noreferrer">
          {organization.site}
        </a>
      ) : null,
    },
    {
      title: t('organization.about.contacts.address'),
      value: organization.address ? (
        <a href={`https://www.google.com/maps/search/${organization.address}'`} target="_blank" rel="noreferrer">
          {organization.address}
        </a>
      ) : null,
    },
    {
      title: t('organization.about.contacts.phone'),
      value: organization.phone ? <a href={`tel:+${organization.phone}`}>{organization.phone}</a> : null,
    },
    {
      title: t('organization.about.contacts.E-mail'),
      value: organization.email ? (
        <a href={`mailto:${organization.email}`} target="_blank" rel="noreferrer">
          {organization.email}
        </a>
      ) : null,
    },
    {
      title: t('organization.about.contacts.activities'),
      value: organization.scopes.length ? (
        <p className={styles.sectionData}>{organization.scopes.map((interest) => interest?.name).join(', ')}</p>
      ) : null,
    },
    {
      title: t('organization.about.contacts.members'),
      value: (
        <div className={styles.members}>
          <p>
            {t('organization.about.contacts.total')}
            <span>{organization.members_count}</span>
          </p>
          <p>
            {t('organization.about.contacts.new')}
            <span>{organization.members_new_count}</span>
          </p>
        </div>
      ),
    },
    {
      title: t('organization.about.status.title'),
      value: organization.registration ? <p>{t(`organization.about.status.${organization.registration}`)}</p> : null,
    },
  ];
  return (
    <div className={styles.wrapper}>
      <div className={classNames(styles.wallpaper)}>
        <BannerSlideShow isMobile={isMobile} images={isOpenStream || deviceService.isMobile ? smallBanners : largeBanners} />
      </div>
      <div
        className={classNames(styles.wrapperCard, className, {
          [styles.mobileWrapper]: isMobile,
        })}
      >
        <div className={styles.cardContainer}>
          <div className={styles.avatarContainer}>
            <CustomImage src={avatar} alt={organization.short_name ?? organization.name} />
            <div className={styles.buttons}>
              {requestStatus && requestStatus.status === EnterOrganizationStatuses.active && (
                <div onClick={generateInviteLink} title={t('organization.about.invite')}>
                  <Icon color="black" width={20} height={20} iconName="userAdd" />
                </div>
              )}
              <div onClick={() => handleChatClick(organization, undefined, openModal, history)} title={t('organization.about.write')}>
                <Icon color="black" width={20} height={20} iconName="chat" />
              </div>
              <div
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  navigator.clipboard.writeText(`${process.env.REACT_APP_BASE_URL}${routes.union.getLink(organization.id)}`);
                  toast(t('toast.copyLink'));
                }}
                title={t('buttons.copyLink')}
              >
                <Icon color="black" width={20} height={20} iconName="link" />
              </div>
            </div>
          </div>
          <div className={classNames(styles.subContainer, styles.containerWithMargin)}>
            <h1>
              {short_name}
              {is_verified && <VerificationSign className={styles.verificationAllianceInfo} />}
            </h1>
          </div>
          {description && (
            <div className={styles.subContainer}>
              <p>{description}</p>
            </div>
          )}
          {!!social?.length && (
            <div className={styles.subContainer}>
              {social.map((el, index) => {
                return (
                  <EditableSocial
                    setActive={(ev) => {}}
                    onDelete={(ev) => {}}
                    href={el.value}
                    key={`organization-social-${index}`}
                    isEdit={false}
                    isActive={false}
                  />
                );
              })}
            </div>
          )}
          <OrganisationProfileProgress
            requestStatus={requestStatus}
            setRequestStatus={setRequestStatus}
            id={id}
            organization={organization}
          />
          <div className={classNames(styles.subContainer, styles.bordered)}>
            {getInfoSection.map((el, index) => {
              if (el.value) {
                return (
                  <div key={`organization-contacts-${el.title}-${index}`} className={styles.contacts}>
                    <h5>{el.title}</h5>
                    {el.value}
                  </div>
                );
              }
              return null;
            })}
          </div>
          {!!organization.badge_needs.length && <Achievements badges={organization.badge_needs} />}
        </div>
      </div>
    </div>
  );
};
