import { useEffect, useRef, useState } from 'react';
import type { FC } from 'react';
import { AutoSizeTextArea } from '../../components/common/AutoSizeTextArea/AutoSizeTextArea';
import { sendMessage } from 'shared/slices/chat';
import { useDispatch, useSelector } from '../../../redux-toolkit';
import { ReactComponent as FileIcon } from '../../icons/file.svg';
import { ReactComponent as DeleteIcon } from '../../icons/delete.svg';
import { ReactComponent as SendIcon } from '../../icons/send.svg';
import { ReactComponent as EmojiIcon } from '../../icons/emoji.svg';
import styles from './ChartMessageAdd.module.scss';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';
import { CHAT_INPUT_ID } from '../../interfaces/chat';
import { toast } from 'react-toastify';
import { allowedSizeFiles, toLargeFileSize } from 'shared/constants/allowedFileSize';
import emojiRu from 'shared/i18next/ru/emoji.json';
import emojiLt from 'shared/i18next/lt/emoji.json';
import emojiBe from 'shared/i18next/be/emoji.json';
import emojiUk from 'shared/i18next/uk/emoji.json';
import emojiEn from 'shared/i18next/en/emoji.json';
import emojiPl from 'shared/i18next/pl/emoji.json';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

const emojiTranslations = {
  en: emojiEn,
  ru: emojiRu,
  uk: emojiUk,
  be: emojiBe,
  lt: emojiLt,
  pl: emojiPl,
};

const ChatMessageAdd: FC = () => {
  const { t, i18n } = useTranslation('shared');
  const dispatch = useDispatch();
  const activeThreadId = useSelector((state) => state.chat.activeThreadId);
  const isReply = useSelector((state) => state.chat.isReply);

  const selectedMessage = useSelector((state) => {
    if (state.chat.selectedMessageId) {
      return state.chat.messages.filter(({ id }) => id === state.chat.selectedMessageId)[0];
    } else {
      return null;
    }
  });
  const threads = useSelector((state) => state.chat.threads);
  const [thread] = (threads || []).filter(({ id }) => activeThreadId === id);
  const emojiRef = useRef<HTMLInputElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const textAreaRef = useRef(null);

  const [body, setBody] = useState<string>('');
  const [file, setFile] = useState<any>(null);
  const [fileLink, setFileLink] = useState<string>('');
  const [image, setImage] = useState<any>(null);

  const [showEmoji, setShowEmoji] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (emojiRef.current && !emojiRef.current.contains(event.target)) {
        setShowEmoji(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [showEmoji]);

  const focusOnInput = () => {
    const elem = document.getElementById(CHAT_INPUT_ID);
    if (elem) {
      elem.focus();
    }
  };
  useEffect(() => {
    setBody('');
    resetFile();
    focusOnInput();
  }, [activeThreadId]);

  useEffect(() => {
    document.addEventListener('keypress', onEnterClick, true);
    return () => {
      document.removeEventListener('keypress', onEnterClick, true);
    };
  }, []);
  const onEnterClick = (event) => {
    if (event.keyCode === 13 && !event.shiftKey) {
      document.getElementById('send-message-button')?.click();
    }
  };

  const handleAttachFile = (): void => {
    fileInputRef.current.click();
  };

  const handleChange = (value): void => {
    if (value.trim().length === 0) {
      setBody('');
    } else {
      setBody(value);
    }
  };

  const handleSend = async () => {
    if (!body && !file && !image) {
      return;
    }
    let content = body;
    let type = 'text';
    if (!isReply) {
      if (file) {
        content = file;
        type = 'file';
      } else if (image) {
        content = image;
        type = 'image';
      }
    }
    setBody('');
    resetFile();
    await dispatch(sendMessage(activeThreadId, content, type));
  };

  const addEmoji = (e) => {
    setBody(`${body}${e.native}`);
    setShowEmoji(false);
    focusOnInput();
  };

  const resetFile = () => {
    setFile(null);
    setImage(null);
    setFileLink('');
    URL.revokeObjectURL(fileLink);
  };

  const handleImgUpload = async (file) => {
    if (file.size > allowedSizeFiles) {
      toast.error(toLargeFileSize);
      return;
    }
    if (file) {
      setFile(null);
    }
    setFileLink(URL.createObjectURL(file));
    setImage(file);
    setBody('');
  };
  return (
    <div className={styles.wrapper}>
      {!!fileLink && !isReply && (
        <div className={styles.fileLink}>
          <a target="_blank" href={fileLink} rel="noreferrer">
            {file?.name || image?.name || t('chat.defaultFileName')}
          </a>
          <DeleteIcon onClick={resetFile} />
        </div>
      )}
      {isReply && <div className={styles.fileLink}>{selectedMessage?.content}</div>}
      <div className={styles.InputWrapper} ref={inputRef}>
        <AutoSizeTextArea
          name={CHAT_INPUT_ID}
          disabled={!!fileLink || thread?.is_blocked}
          valueChange={handleChange}
          placeholder={t('chat.input.placeholder')}
          value={body}
          className={cn(styles.TextareaContent)}
          onPaste={(event) => {
            if (event.clipboardData.files.length) {
              handleImgUpload(event.clipboardData.files[0]);
            }
          }}
          textRef={textAreaRef}
        />
        <div className={styles.icons}>
          {!file && !image && (
            <button onClick={() => setShowEmoji(!showEmoji)} className={styles.FileIcon}>
              <EmojiIcon />
            </button>
          )}
        </div>
        {showEmoji && (
          <div ref={emojiRef} className={styles.emojiWrapper}>
            <Picker onSelect={addEmoji} i18n={emojiTranslations[i18n.language] || emojiEn} />
          </div>
        )}
        {!isReply && !body && !file && !image && (
          <>
            <button onClick={handleAttachFile} className={styles.FileIcon}>
              <FileIcon />
            </button>
          </>
        )}
        {(!!body || !!file || !!image) && (
          <button
            disabled={(!body && !fileLink) || thread?.is_blocked}
            onClick={handleSend}
            id="send-message-button"
            className={cn(styles.FileIcon, styles.sendIcon)}
          >
            <SendIcon />
          </button>
        )}
      </div>
      <input
        hidden
        ref={fileInputRef}
        type="file"
        onChange={async (e) => {
          if (e.target.files[0].size > allowedSizeFiles) {
            toast.error(toLargeFileSize);
            return;
          }
          if (!!e.target.files[0].type.includes('image')) {
            setFileLink(URL.createObjectURL(e.target.files[0]));
            setImage(e.target.files[0]);
            setFile(null);
          } else {
            setFileLink(URL.createObjectURL(e.target.files[0]));
            setFile(e.target.files[0]);
            setImage(null);
          }
          setBody('');
        }}
      />
    </div>
  );
};

export default ChatMessageAdd;
