export const bannersNewsMock = [
  {
    buttonTitle: 'Подать заявку',
    link: 'https://docs.google.com/forms/d/1P_txWUoCLwGNYITGzFngltyotSvHEYToSNuxbiu_gNs/edit',
    imageDesktop: 'https://files.roy.team/roy/bims/1716294178.large.jpg',
    imageMobile: 'https://files.roy.team/roy/bims/1716294178.small.jpg',
  },
];

export const bannersEventsMock = [
  {
    buttonTitle: 'Попробовать',
    link: 'https://roy.team/13490/materials/112',
    imageDesktop: 'https://files.roy.team/roy/bims/1716294195.large.jpg',
    imageMobile: 'https://files.roy.team/roy/bims/1716294195.small.jpg',
  },
];

export const bannersKbaseMock = [
  {
    buttonTitle: 'Начать использование',
    link: 'https://roy.team/13490/materials/89',
    imageDesktop: 'https://files.roy.team/roy/bims/1716294225.large.jpg',
    imageMobile: 'https://files.roy.team/roy/bims/1716294225.small.jpg',
  },
];
