import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import formatServerError from 'shared/utils/formatServerError';
import cn from 'classnames';
import Answers from './Answers/Answers';
import Comment from './Comment/Comment';
import styles from './Comments.module.scss';
import { useDispatch, useSelector } from '../../../redux-toolkit';
import { CommentsState, getCommentsAction, getGeneralActions } from 'shared/slices/comments';
import NewCommenteCreate from './NewCommenteCreate/NewCommenteCreate';
import LoadMoreButton from './LoadMoreButton/LoadMoreButton';
import { useTranslation } from 'react-i18next';

const Comments = ({
  name,
  user,
  essenceId,
  isAdminApp = false,
  isNotDetailsPage = false,
  disable = false,
  className,
}: {
  name: string; //suggestions, desk_tasks, in admin u should paste :orgId/:nameEssence
  user?: any;
  essenceId: string | number;
  isAdminApp?: boolean;
  isNotDetailsPage?: boolean;
  disable?: boolean;
  className?: string;
}) => {
  const { t } = useTranslation('shared');
  const dispatch = useDispatch();
  const { comments, generalData } = useSelector((state) => state.comments as CommentsState);
  const [closed, setClosed] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        await dispatch(getGeneralActions(user, name, essenceId, isAdminApp, disable || !user));
      } catch (e) {
        toast.error(formatServerError(e));
      }
    })();
  }, [user, name, essenceId, dispatch, isAdminApp, disable]);

  useEffect(() => {
    (async () => {
      try {
        await dispatch(getCommentsAction(`${essenceId}`, name, undefined, undefined, undefined, isAdminApp, true));
      } catch (e) {
        if (formatServerError(e) === 'Запрещено.') {
          setClosed(true);
        } else {
          toast.error(formatServerError(e));
        }
      }
    })();
  }, [name, essenceId, isAdminApp, dispatch]);

  if (!generalData.essenceName || !generalData.essenceId) {
    return null;
  }

  return (
    <div
      className={cn(styles.section, className, {
        [styles.isNotDetailsPageSection]: !!isNotDetailsPage,
      })}
    >
      <h3>{`${t('comments.comments')}:`}</h3>
      {!generalData.disable && !!user && !closed && (
        <div className={styles.textareaCreateWrapper}>
          <NewCommenteCreate />
        </div>
      )}
      {!user && (
        <div className={styles.noTextArea}>
          {`${t('comments.onlyCan')} `}
          <a onClick={() => window.open(process.env.REACT_APP_CLIENT_APP_URL + '/login')}>{t('comments.authorised')}</a>
          {` ${t('comments.users')}`}
        </div>
      )}

      {!!closed && !!user && <div className={styles.noTextArea}>{t('comments.cantComment')}</div>}

      <div className={styles.commentsGroup}>
        {!!comments?.length &&
          comments.map((comment) => (
            <div className={styles.commentWrapper} key={comment.id}>
              <Comment item={comment} />
              {!!comment.answers && <Answers commentsId={comment.id} commentAnswers={comment.answers} />}
            </div>
          ))}
        {!!comments?.length && comments.length > 9 && <LoadMoreButton after_id={comments[comments.length - 1].id} />}
      </div>
    </div>
  );
};

export default Comments;
