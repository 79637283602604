import styles from './Tab.module.scss';
import classNames from 'classnames';
import { useEffect, useState } from 'react';

type TabProps = {
  className?: string;
  tabList: Array<string | JSX.Element>;
  tabPanels?: Array<JSX.Element>;
  isDisabled?: Array<boolean>;
  selectDefault?: number;
  activeOutside?: number;
  setOutsidePanel?: (panelNumber: number) => void;
};

export const Tab = ({
  className,
  tabList,
  tabPanels,
  selectDefault = 0,
  activeOutside,
  setOutsidePanel = () => {},
  isDisabled = [],
}: TabProps) => {
  const [active, setActive] = useState(selectDefault);

  useEffect(() => {
    setOutsidePanel(selectDefault);
  }, []);

  useEffect(() => {
    if (activeOutside !== undefined) {
      setActive(activeOutside);
    }
  }, [activeOutside]);

  return (
    <div className={classNames({ [className]: className }, styles.wrapper)}>
      <div className={styles.select}>
        {tabList.map((el, index) => {
          return (
            <div
              key={`tabPanel-${index}`}
              className={classNames(
                { [styles.active]: active === index || activeOutside === index },
                { [styles.disabled]: isDisabled[index] },
                styles.option
              )}
              onClick={() => {
                if (!isDisabled[index]) {
                  setActive(index);
                  setOutsidePanel(index);
                }
              }}
            >
              {el}
            </div>
          );
        })}
      </div>
      {!!tabPanels?.length && (
        <div>
          {tabPanels.map((el, index) => {
            return <div key={`tabPanels-${index}`}>{active === index && el}</div>;
          })}
        </div>
      )}
    </div>
  );
};
