import { useTranslation } from 'react-i18next';
import { Card } from './Card/Card';
import styles from './HowItsWork.module.scss';

export const HowItsWork = () => {
  const { t } = useTranslation('home');
  const cards: string[] = [
    t('howItsWork.steps.accauntCreation'),
    t('howItsWork.steps.adminAccess'),
    t('howItsWork.steps.contentCreation'),
    t('howItsWork.steps.contentDistributing'),
    t('howItsWork.steps.recruitMembers'),
    t('howItsWork.steps.cooperative'),
  ];
  return (
    <div className={styles.wrapper}>
      <h5 className={styles.title}>{t('howItsWork.title')}</h5>
      <div className={styles.cards}>
        {cards.map((card, index) => (
          <Card text={card} step={index + 1} key={index + 1} />
        ))}
      </div>
    </div>
  );
};
