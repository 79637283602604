import section from './images/section-mock.jpg';
import material from './images/material-mock.jpg';
import news from './images/newsMock.jpg';
import polls from './images/polls-mock.jpg';
import payment from './images/mockPayment.png';
import event from './images/event_mock.png';

export const mockImages = {
  section,
  material,
  news,
  payment,
  polls,
  event,
};
