import { FC, Fragment, useContext, useEffect, useState } from 'react';
import { RouteProps } from 'react-router';
import { GlobalDataContext } from 'contexts/GlobalDataContext';
import { CryptoLoaderProps, buildRegovCryptoLoader, useRegovUIContext } from '@owlmeans/regov-lib-react';
import cn from 'classnames';
import styles from './RoutingContainer.module.scss';
import { GlobalContext } from '../../../contexts/GlobalContext';
import { isSSR } from '../../../utils/isSSR';
import { NavigationBar } from '../../../components/NavigationBar/NavigationBar';
import { CloseButton } from '../../CloseButton/CloseButton';
import { Sidebar } from '../../../components/Sidebar/Sidebar';
import { Footer } from '../../../components/Footer/Footer';
import Header from 'components/Header/Header';
import { regovConfig, regovExtensionRegistry } from 'regov';
import React from 'react';

type IRoutingContainerProps = RouteProps & {
  children?: any;
  withSidebar?: boolean;
  withFooter?: boolean;
  showCloseButton?: boolean;
  title?: string;
  hideHeader?: boolean;
  className?: string;
  closeButtonRoute?: any; // TODO: fix
};

const FakeLoader: FC<CryptoLoaderProps> = ({ onFinish }) => {
  useEffect(() => onFinish());
  return <Fragment />;
};

export function RoutingContainer({
  children,
  withSidebar = true,
  withFooter = true,
  showCloseButton = false,
  hideHeader = false,
  closeButtonRoute,
  title,
  className,
}: IRoutingContainerProps) {
  const { isSidebarExpanded, setSidebarExpandedState } = useContext(GlobalContext);
  const { user } = useContext(GlobalDataContext);
  const [changeUserMenu, setChangeUserMenu] = useState<boolean>(false);
  // const history = useHistory();
  // let { id } = useParams<{ id: string | undefined }>();

  const CryptoLoaderImpl: FC<CryptoLoaderProps> = isSSR() ? FakeLoader : React.lazy(() => import('regov/crypto-loader') as any);

  const regovUIContext = useRegovUIContext(regovConfig);
  const CryptoLoader = buildRegovCryptoLoader({
    context: regovUIContext,
    CryptoLoader: CryptoLoaderImpl,
    extensions: regovExtensionRegistry,
    noSuspense: isSSR(),
  });

  const isLoggedIn = !!user;
  return (
    <div
      className={cn(styles.RoutingContainer, className, 'custom-scroll', {
        [styles.withSidebar]: !!withSidebar && !!user,
      })}
    >
      <CryptoLoader />
      {showCloseButton && <CloseButton targetRoute={closeButtonRoute} className={styles.close} />}

      {!hideHeader && <Header title={title ?? ''} changeUserMenu={changeUserMenu} setChangeUserMenu={setChangeUserMenu} />}
      {/* // <header className={styles.headerRoutingContainer}>
        //   <Link to={routes.HOME} className={styles.logoTitle}>
        //     <Icon iconName={'logo'} width={182} height={40} className={styles.logo} />
        //   </Link>
        //   <div className={styles.headerButtons}>
        //     <Button to={routes.UNIONS} color={'light'}>
        //       Все сообщества
        //     </Button>
        //     {!!id && <Button onClick={onGenerateLink}>Присоединиться</Button>}
        //   </div>
        // </header> */}

      {!!withSidebar && !isSSR() && isLoggedIn && <Sidebar isExpanded={isSidebarExpanded} setExpandedState={setSidebarExpandedState} />}
      {!!withSidebar && isLoggedIn && (
        <NavigationBar isExpanded={isSidebarExpanded} setExpandedState={setSidebarExpandedState} changeUserMenu={changeUserMenu} />
      )}

      <div
        className={cn(styles.wrapper, {
          [styles.withSidebar]: !!withSidebar && !!user,
        })}
      >
        {children}
        {!!withFooter && (
          <div className={styles.wrapperFooter}>
            <Footer />
          </div>
        )}
      </div>
    </div>
  );
}
