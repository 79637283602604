import { useContext, useEffect, useMemo } from 'react';
import styles from './UserProfileLayout.module.scss';
import { AboutUser } from './AboutUser/AboutUser';
import { UserContacts } from './UserContacts/UserContacts';
import { HideProfileButton } from '../HideProfileButton/HideProfileButton';
import { useSsrEffect, useSsrState } from '@issr/core';
import { IProfile } from 'interfaces/profile.interface';
import { profileService } from 'services';
import { GlobalContext } from 'contexts/GlobalContext';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import formatServerError from 'shared/utils/formatServerError';
import SEO from 'shared/utils/Seo';
import { Achievements } from 'components/Achievements/Achievements';

interface IUserProfileLayout {
  isOpenUserInfo: boolean;
  setIsOpenUserInfo: React.MouseEventHandler<HTMLButtonElement>;
  profileId: string;
}

export const UserProfileLayout = ({ isOpenUserInfo, setIsOpenUserInfo, profileId }: IUserProfileLayout) => {
  const {
    spinner: { showSpinner, hideSpinner },
  } = useContext(GlobalContext);
  const [profile, setProfile] = useSsrState<IProfile>(null);
  const _lockProfileLoader = useMemo<{ value: boolean }>(() => ({ value: false }), [profileId]);

  useSsrEffect(() => {
    if (!profileId || profileId === '') {
      return;
    }
    (async () => {
      try {
        if (_lockProfileLoader.value) {
          return;
        }
        _lockProfileLoader.value = true;
        showSpinner();
        const resp = await profileService.getProfile(profileId);
        setProfile(resp);
      } catch (e) {
        toast(formatServerError(e));
      } finally {
        _lockProfileLoader.value = false;
        hideSpinner();
      }
    })();
  }, profileId);

  useEffect(() => {
    if (!profileId || profileId === '') {
      return;
    }
    (async () => {
      try {
        if (_lockProfileLoader.value) {
          return;
        }
        _lockProfileLoader.value = true;
        showSpinner();
        const resp = await profileService.getProfile(profileId);
        setProfile(resp);
      } catch (e) {
        toast(formatServerError(e));
      } finally {
        _lockProfileLoader.value = false;
        hideSpinner();
      }
    })();
  }, [profileId]);

  if (!profile) {
    return null;
  }
  return (
    <>
      <SEO title={profile.public_name || profile.username} image={profile.public_avatar || '/logo-small.svg'} description={profile.about} />
      <div
        className={classNames(styles.scrollBar, {
          [styles.hide]: !isOpenUserInfo,
        })}
      >
        <div className={styles.wrapper}>
          {isOpenUserInfo && <HideProfileButton isOpenUserInfo={isOpenUserInfo} onclick={setIsOpenUserInfo} />}
          <AboutUser className={styles.container} profile={profile} key={`user-${profile.username}`} />
          <UserContacts className={styles.container} profile={profile} />
          {!!profile.badges.length && <Achievements className={styles.container} badges={profile.badges} />}
          <div className={classNames(styles.cardContainerFiller, styles.cardContainer)}></div>
        </div>
      </div>
    </>
  );
};

export default UserProfileLayout;
