import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { routes } from 'Routes';

export function ErrorPage() {
  const { t } = useTranslation('createUnion');
  return (
    <div>
      <div>{t('somethingWrong')}</div>
      <div>
        {`${t('backTo')}`}
        <Link to={routes.PROFILE}>{t('mainPage')}</Link>
      </div>
    </div>
  );
}
