import { useState, useContext } from 'react';
import { Tab } from 'shared/components/common/Tab/Tab';
import styles from './ProfileKanban.module.scss';
import KanbanContainer from 'shared/components/Kanban/KanbanContainer';
import { GlobalContext } from 'contexts/GlobalContext';
import { GlobalDataContext } from 'contexts/GlobalDataContext';
import classNames from 'classnames';
import { useSelector } from 'redux-toolkit';
import { useTranslation } from 'react-i18next';

type ProfileKanbanProps = {
  organizationId: number;
  isMobile: boolean;
};

export const ProfileKanban = ({ organizationId, isMobile }: ProfileKanbanProps) => {
  const { t } = useTranslation('profile');
  const { openModal, closeModal } = useContext(GlobalContext);
  const { user } = useContext(GlobalDataContext);
  const tasks = useSelector((state) => state.tasks)
    .columns.map((el) => {
      return [...el.cards];
    })
    .flat();
  const [activeTab, setActiveTab] = useState<number>(0);
  const userInOrganization = !!user?.membership.find((org) => org.id === +organizationId);

  return (
    <div
      style={!tasks.length ? { display: 'none' } : undefined}
      className={classNames(styles.wrapper, { [styles.mobileWrapper]: isMobile })}
    >
      <div className={classNames(styles.title, { [styles.mobile]: isMobile })}>
        <h4 className={classNames({ [styles.titleTextDesc]: !isMobile })}>{t('organization.kanban.title')}</h4>
        <div className={styles.controls}></div>
        <Tab
          className={styles.tab}
          setOutsidePanel={setActiveTab}
          tabList={[t('organization.kanban.tabs.0'), t('organization.kanban.tabs.1')]}
        />
      </div>
      <div className={styles.kanban}>
        <KanbanContainer
          isAdminApp={false}
          openModal={openModal}
          closeModal={closeModal}
          getOrganizationUsers={() => {}}
          orgId={organizationId}
          profile={user}
          userInOrganization={userInOrganization}
          isOnlyMyCards={activeTab === 1}
          className={styles.kanbanWrapper}
        />
      </div>
    </div>
  );
};
