import classNames from 'classnames';
import { CustomSlider } from 'components/CustomSlider/CustomSlider';
import { IUser } from 'interfaces/user.interface';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { organisationsService } from 'services';
import { Icon } from 'shared/components/common/Icon/Icon';
import styles from './CustomMembers.module.scss';
import { UserCard } from './UserCard/UserCard';
import { useTranslation } from 'react-i18next';
import formatServerError from 'shared/utils/formatServerError';

type CustomMembersProps = {
  organisationId: number;
  slidesOnPage?: number;
  isMobile: boolean;
};

export const CustomMembers = ({ organisationId, slidesOnPage = 1, isMobile }: CustomMembersProps) => {
  const LIMIT = 20;
  const { t } = useTranslation('profile');
  const [activeSlide, setActiveSlide] = useState<number>(0);
  const [members, setMembers] = useState<IUser[]>([]);
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);

  const moveSliderRightHandler = () => {
    if (activeSlide === members.length - LIMIT && members.length !== total) {
      loadMembers();
    }
    setActiveSlide((val) => val + 1);
  };
  const moveSliderLeftHandler = () => setActiveSlide((val) => val - 1);

  const loadMembers = async (isNew = false) => {
    try {
      const {
        data,
        meta: { total },
      } = await organisationsService.getOrganisationMembers(organisationId, isNew ? 0 : page, LIMIT);
      setMembers(isNew ? data : [...members, ...data]);
      setTotal(total);
      setPage((val) => val + 1);
    } catch (e) {
      toast(formatServerError(e));
    }
  };
  useEffect(() => {
    setActiveSlide(0);
    setPage(1);
    setMembers([]);
    loadMembers(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisationId]);

  const slides = members.map((el, index) => {
    return <UserCard key={`user-card-${index}`} user={el} isMobile={isMobile} />;
  });
  return (
    <div className={classNames(styles.wrapper, { [styles.mobileWrapper]: isMobile })}>
      <div className={classNames(styles.title, { [styles.mobile]: isMobile })}>
        <h4 className={classNames({ [styles.titleTextDesc]: !isMobile })}>{`${t('organization.members.title')}`}</h4>
        <div className={styles.controls}>
          <button className={styles.button} disabled={activeSlide === 0} onClick={moveSliderLeftHandler} title={t('buttons.slider.prev')}>
            <Icon width={24} height={24} iconName={'arrowLeft'} color="black" />
          </button>
          <button
            className={styles.button}
            onClick={moveSliderRightHandler}
            disabled={activeSlide >= slides.length - 1}
            title={t('buttons.slider.next')}
          >
            <Icon width={24} height={24} iconName={'arrowRight'} color="black" />
          </button>
        </div>
      </div>
      <CustomSlider activeSlide={activeSlide} slides={slides} slidesOnPage={slidesOnPage} />
    </div>
  );
};
