import { SocialNetworks } from 'components/Landing/SocialNetworks/SocialNetworks';
import { contacts } from 'shared/constants/contacts';
import styles from './ContactUs.module.scss';
import Unity from '../../public/landing_page/unity.png';
import { routes } from 'Routes';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { GlobalContext } from 'contexts/GlobalContext';
import SEO from 'shared/utils/Seo';
import { useTranslation } from 'react-i18next';

const ContactUs = () => {
  const { t } = useTranslation('roy-global');
  const {
    services: {
      userService: { isLoggedIn },
    },
  } = useContext(GlobalContext);

  const { REACT_APP_SUPPORT_ORG_ID } = process.env;

  return (
    <div className={styles.contactUs}>
      <SEO title={'Контакты'} image={'/logo-small.svg'} description={'Контакты'} />

      {isLoggedIn && REACT_APP_SUPPORT_ORG_ID && (
        <Link
          to={{
            pathname: routes.chat,
            search: `id=${REACT_APP_SUPPORT_ORG_ID}&type=organization`,
          }}
        >
          {t('contactUs.chat')}
        </Link>
      )}

      <span>
        {`${t('contactUs.mail')} - `}
        <a href={`mailto:${contacts.email}`} target="_blank" rel="noreferrer">
          {contacts.email}
        </a>
      </span>
      <a href={`https://www.google.com/maps/search/${contacts.place}'`} target="_blank" rel="noreferrer">
        {contacts.place}
      </a>
      <a href={contacts.telegramBot} target="_blank" rel="noreferrer">
        {t('contactUs.bot')}
      </a>

      <div className={styles.socialLinks}>
        {' '}
        <h3>{t('contactUs.social')}</h3>
        <SocialNetworks />
      </div>
      <img src={Unity} alt="Unity" className={styles.unity} />
    </div>
  );
};

export default ContactUs;
