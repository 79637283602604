import { Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import styles from './ModalCard.module.scss';
import { formatDistance } from 'date-fns';
import { ru, be, enGB, lt, uk } from 'date-fns/locale';
import { FooterItem, StreamCardFooter } from 'components/Crads/StreamCardFooter/StreamCardFooter';
import { CustomImage } from 'shared/components/common/CustomImage/CustomImage';
import { routes } from 'Routes';
import classNames from 'classnames';
import { Icon } from 'shared/components/common/Icon/Icon';
import { format } from 'date-fns';
import { Icons } from 'shared/components/common/Icon/Icon.interface';
import { allowedAttributesSchema, allowedTagsSynitizer, allowedIframeHostnamesSchema } from 'shared/constants/allowedTagsSynitizer';
import sanitizeHtml from 'sanitize-html';
import { GlobalContext } from 'contexts/GlobalContext';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

type ModalCardProps = {
  image: string;
  title: string;
  content: string | JSX.Element;
  link?: string;
  maxHeight?: { title?: number; content?: number };
  organization?: { logo: string; name: string; id: number };
  author?: { logo: string; name: string; id: number };
  publishedAt?: Date;
  commentsCount?: number;
  withFooter: boolean;
  eventExtra?: {
    price?: string;
    place?: string;
    date_start: Date;
    date_end: Date;
  };
};

export const ModalCard = ({
  content,
  image,
  title,
  author,
  link,
  withFooter = false,
  commentsCount,
  publishedAt,
  organization,
  eventExtra,
  maxHeight,
}: ModalCardProps) => {
  const { t, i18n } = useTranslation('profile');
  const locales = {
    ru,
    be,
    enGB,
    lt,
    uk,
  };
  const { closeModal } = useContext(GlobalContext);
  const location = useLocation();

  const icons: FooterItem[] = [
    {
      iconName: 'clock',
      content: formatDistance(new Date(publishedAt), new Date(Date.now()), {
        locale: locales[i18n.language],
        addSuffix: false,
      }),
    },
    { iconName: 'comments', content: `${commentsCount || 0}` },
    link && {
      iconName: 'link',
      onClick: (e) => {
        e.preventDefault();
        e.stopPropagation();
        navigator.clipboard.writeText(`${process.env.REACT_APP_BASE_URL}${link}`);
        toast(t('toast.copyLink'));
      },
    },
  ];

  return (
    <div className={classNames(styles.wrapper)}>
      <div className={styles.image}>
        <img src={image} alt={title} />
        {organization && (
          <Link onClick={closeModal} to={routes.union.getLink(organization.id)} className={styles.link}>
            <CustomImage className={styles.organization} width={56} height={56} alt={organization.name} src={organization.logo} />
          </Link>
        )}
        {eventExtra && (
          <div className={styles.extra}>
            <div className={styles.eventWrapper}>
              <Icon iconName="noteAdd" color="white" />
              <span className={styles.eventText}>
                {`${format(new Date(eventExtra.date_start), 'dd.MM.yyyy')} ${
                  eventExtra.date_end ? `- ${format(new Date(eventExtra.date_end), 'dd.MM.yyyy')}` : ''
                }`}
              </span>
            </div>
            {!!eventExtra.place && (
              <div className={styles.eventWrapper}>
                <Icon iconName={Icons.location} color="white" /> <span className={styles.eventText}>{eventExtra.place}</span>
              </div>
            )}
          </div>
        )}
      </div>
      <div className={styles.content}>
        <div className={styles.author}>
          <div className={styles.title}>
            <Link
              onClick={closeModal}
              style={maxHeight?.title && { maxHeight: `${maxHeight.title}px` }}
              className={classNames(styles.contentLink, styles.titleLink)}
              to={{
                pathname: link,
                state: {
                  from: location.pathname,
                },
              }}
            >
              <h4>{title}</h4>
            </Link>
            {author && (
              <Link onClick={closeModal} to={routes.profile.getLink(author.id)}>
                <p>
                  <span>@{author.name}</span>
                </p>
              </Link>
            )}
          </div>
        </div>

        <Link
          onClick={closeModal}
          style={maxHeight?.content && { maxHeight: `${maxHeight.content}px` }}
          className={styles.contentLink}
          to={{
            pathname: link,
            state: {
              from: location.pathname,
            },
          }}
        >
          {typeof content === 'string' ? (
            <>
              {eventExtra && (
                <div className={classNames(styles.mobileOnly)}>
                  <div className={styles.eventWrapper}>
                    <Icon iconName="noteAdd" color="black" />
                    <span className={styles.eventText}>
                      {`${format(new Date(eventExtra.date_start), 'dd.MM.yyyy')} ${
                        eventExtra.date_end ? `- ${format(new Date(eventExtra.date_end), 'dd.MM.yyyy')}` : ''
                      }`}
                    </span>
                  </div>
                  {!!eventExtra.place && (
                    <div className={styles.eventWrapper}>
                      <Icon iconName={Icons.location} color="black" /> <span className={styles.eventText}>{eventExtra.place}</span>
                    </div>
                  )}
                </div>
              )}
              <div
                className={styles.description}
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(content, {
                    allowedTags: allowedTagsSynitizer,
                    allowedAttributes: allowedAttributesSchema,
                    allowedIframeHostnames: allowedIframeHostnamesSchema,
                  }),
                }}
              />
            </>
          ) : (
            <>
              {eventExtra && (
                <div className={classNames(styles.mobileOnly)}>
                  <div className={styles.eventWrapper}>
                    <Icon iconName="noteAdd" color="black" />
                    <span className={styles.eventText}>
                      {`${format(new Date(eventExtra.date_start), 'dd.MM.yyyy')} ${
                        eventExtra.date_end ? `- ${format(new Date(eventExtra.date_end), 'dd.MM.yyyy')}` : ''
                      }`}
                    </span>
                  </div>
                  {!!eventExtra.place && (
                    <div className={styles.eventWrapper}>
                      <Icon iconName={Icons.location} color="black" /> <span className={styles.eventText}>{eventExtra.place}</span>
                    </div>
                  )}
                </div>
              )}
              {content}
            </>
          )}
        </Link>
        {withFooter && <StreamCardFooter icons={icons} />}
      </div>
    </div>
  );
};
