import { IEvents } from 'interfaces/events.interface';
import { RequestMeta } from 'interfaces/request-meta.interface';
import { httpService } from 'services';
import { Api } from './http.service';
import { IContentPreview } from 'shared/interfaces/feed';

export type IGetOrganizationEvents = {
  organisationId: number;
  section?: number;
  sortDirection?: 'asc' | 'desc';
  page?: number;
  limit?: number;
  sortBy?: string;
  tags?: string[];
  featured?: number;
};

export const getEvents = async ({
  sortBy = 'id',
  limit,
  sortDirection = 'desc',
  page = 1,
  featured,
  tags,
}: IGetOrganizationEvents): Promise<{ data: IContentPreview[]; meta: RequestMeta }> => {
  const response = await httpService.axios.get(`${Api.EVENTS}`, { params: { page, limit, sortDirection, sortBy, tags, featured } });
  const events = response?.data?.data;
  return { data: events, meta: response.data.meta };
};

export const getOrganizationEvents = async ({
  organisationId,
  section,
  page,
  limit,
  sortBy,
  sortDirection = 'desc',
}: IGetOrganizationEvents): Promise<{ data: any[]; meta: RequestMeta }> => {
  const response = await httpService.axios.get(`${Api.ORGANISATIONS}/${organisationId}/events`, {
    params: { section, page, limit, sortDirection, sortBy },
  });
  return { data: response.data.data, meta: response.data.meta };
};

export const getEvent = async (organisationId: number, eventId: number): Promise<any> => {
  const response = await httpService.axios.get(`${Api.ORGANISATIONS}/${organisationId}/events/${eventId}`);
  return response.data.data;
};

interface IGetCalendarEvents {
  from: Date;
  to: Date;
}

export const getCalendarEvents = async ({ from, to }: IGetCalendarEvents): Promise<IEvents[]> => {
  const response = await httpService.axios.get(`${Api.EVENTS}`, { params: { from, to } });
  return response.data.data;
};
