import classNames from 'classnames';
import { Icon } from 'shared/components/common/Icon/Icon';
import styles from './UserSocial.module.scss';
import { useSsrState } from '@issr/core';
import { EditableSocial } from './EditableSocial/EditableSocial';
import { TextField } from 'shared/components/common/TextField/TextField';
import { formatDomainType } from 'shared/utils/formatDomainType';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

type UserSocialProps = {
  socials: Array<string>;
  isEdit: boolean;
  onChange: (val: Array<string>) => void;
};

export const UserSocial = ({ socials, isEdit, onChange }: UserSocialProps) => {
  const { t } = useTranslation('profile');
  const [activeSocial, setActiveSocial] = useSsrState<number | null>(null);
  const [isCanAdd, setIsCanAdd] = useSsrState<boolean>(true);

  const updateSocial = (src: string, index: number) => {
    let active = index;
    if (index === null) {
      active = socials.length;
      setActiveSocial(socials.length);
    }
    setIsCanAdd(true);
    const newSocials = [...socials];
    newSocials[active] = src;
    if (!src || src === '') {
      newSocials.splice(active, 1);
      setActiveSocial(null);
    }
    onChange(newSocials.slice(0, 8));
  };

  const addNewSocial = () => {
    changeActive(null);
    setIsCanAdd(false);
  };

  const validateLink = (href: string) => {
    if (href === '' || !href) {
      return '';
    }
    const type = formatDomainType(href);
    if (!type) {
      toast(t('social.errors.format'));
      return '';
    }
    return href;
  };

  const changeActive = (index: number) => {
    if (activeSocial !== null) {
      const link = validateLink(socials[activeSocial]);
      updateSocial(link, activeSocial);
    }
    if (index === activeSocial) {
      setActiveSocial(null);
    } else {
      setActiveSocial(index);
    }
    setIsCanAdd(true);
  };
  const onAccept = () => {
    const link = validateLink(socials[activeSocial]);
    updateSocial(link, activeSocial);
    setActiveSocial(null);
    setIsCanAdd(true);
  };

  const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (ev) => {
    if (ev.code === 'Escape' || ev.code === 'Enter' || ev.code === 'NumpadEnter') {
      return onAccept();
    }
  };

  return (
    <div className={styles.wrapper}>
      {isEdit && <h5>{t('social.title')}</h5>}
      <div className={styles.socials}>
        {socials.map((href, index) => {
          return (
            <EditableSocial
              setActive={() => {
                changeActive(index);
              }}
              onDelete={(ev) => {
                ev.preventDefault();
                ev.stopPropagation();
                updateSocial('', index);
              }}
              href={href}
              key={`socials-${href}-${index}`}
              isEdit={isEdit}
              isActive={activeSocial === index}
            />
          );
        })}
      </div>
      {isEdit && (
        <div className={styles.addContainer}>
          <TextField
            value={activeSocial === null ? '' : socials[activeSocial]}
            setValue={(val) => updateSocial(val, activeSocial)}
            variant="text"
            /* key={`edit-socials-text-field-${activeSocial}`} */
            placeholder={'https://'}
            onBlur={() => {
              const link = validateLink(socials[activeSocial]);
              updateSocial(link, activeSocial);
              setActiveSocial(null);
            }}
            error={activeSocial > 7 && t('social.errors.limit')}
            helperText={t('social.helperText')}
            onKeyDown={onKeyDown}
            maxLength={256}
          />
          {isEdit && socials.length < 8 && (
            <button disabled={!isCanAdd} onClick={addNewSocial} className={styles.add} title={t('social.addButton')}>
              <Icon width={30} height={30} rotate={45} iconName="circleClose" />
            </button>
          )}
        </div>
      )}
      {isEdit && (
        <div className={classNames(styles.info, styles.green)}>
          <Icon iconName="infoCircle" />
          <p>{t('social.info')}</p>
        </div>
      )}
    </div>
  );
};
