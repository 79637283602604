import { useState, useContext, useEffect, useCallback, useRef } from 'react';
import { IOrganisation, IOrganisationWithPoint } from 'interfaces/organisation.interface';
import { Helmet } from 'react-helmet';
import { RequestMeta } from '../../interfaces/request-meta.interface';
import { Spinner } from 'common/Spinner/Spinner';
import InfiniteScrollList from 'components/InfiniteScrollList/InfiniteScrollList';
// import { WalletPersistentIntegrationReact } from 'regov/presistent-wallet';
// import { regovConfig, regovExtensionRegistry, regovNavigatorBuilder, walletServerClient } from 'regov/config';
// import { WalletHandler } from '@owlmeans/regov-ssi-core';
// import { useHistory } from 'react-router-dom';
// import { useRegov } from 'regov/context';
import UnionMap from 'components/Map/Map';
import classNames from 'classnames';
import { LngLatBounds, MapboxGeoJSONFeature } from 'mapbox-gl';
import { Input } from 'shared/components/common/Input/Input';
import { GlobalContext } from 'contexts/GlobalContext';
import styles from './Unions.module.scss';
import formatServerError from 'utils/formatServerError';
import { toast } from 'react-toastify';
import { useOrganisationsReducer } from './hooks/useOrganisationsReducer';
import { getFriendlyOrgs, getOrgsOnTheArea } from 'services/map';
import { debounce } from 'lodash';
import SwitchButton from './SwitchButton/SwitchButton';
import { MapActivityModal } from 'components/Modals/MapModals/Activity/MapActivityModal';
import { useTranslation } from 'react-i18next';
import AllianceInfoOnMap from 'components/AllianceInfoOnMap/AllianceInfoOnMap';

interface GetOrganisationParams {
  name?: string;
  infinityFetch?: boolean;
  filters?: object;
  page?: number;
}

function Unions() {
  const { t } = useTranslation('map');
  const {
    openModal,
    services: { organisationsService },
  } = useContext(GlobalContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  // const [organisations, setOrganizations] = useSsrState<IOrganisationShort[]>([]);
  // const history = useHistory();
  // const navigatorBuilder = (handler: WalletHandler) => regovNavigatorBuilder(handler, regovConfig, history);
  const [showUnionsBar, setShowUnionsBar] = useState<boolean>(true);
  const [metaPage, setMetaPage] = useState<Partial<RequestMeta>>({
    last_page: 1,
    current_page: 1,
    per_page: 15,
  });
  const [organisations, dispatch, organisActions] = useOrganisationsReducer();
  const [displayDetachedOrganisations, setDisplayDetachedOrganisations] = useState(true);
  const [inputValue, setInputValue] = useState('');

  const getOrganizations = async ({ name, infinityFetch, filters, page }: GetOrganisationParams) => {
    try {
      const { data: requestedOrganizations, meta } = await organisationsService.getOrganisations({
        name,
        page: infinityFetch ? metaPage.current_page + 1 : 1,
        type_id: [],
      });

      setMetaPage(meta);
      const _organisations = infinityFetch ? [...organisations.all, ...requestedOrganizations] : requestedOrganizations;
      dispatch(organisActions.setAll(_organisations));
      displayDetachedOrganisations && dispatch(organisActions.setDetached(_organisations));
    } catch (error) {
      toast.error(formatServerError(error));
    } finally {
      if (!infinityFetch) {
        setIsLoading(false);
      }
    }
  };

  const debouncedGetOrganisations = useCallback(debounce(getOrganizations, 1000), [displayDetachedOrganisations]);

  useEffect(() => {
    //@ts-ignore
    debouncedGetOrganisations('');
    return () => {
      debouncedGetOrganisations.cancel();
    };
  }, []);

  const handleInputChange = (value) => {
    setInputValue(value);
    setDisplayDetachedOrganisations(true);
    debouncedGetOrganisations({ name: value });
  };

  const handleInputFocus = () => {
    dispatch(organisActions.setSelected(null));
    dispatch(organisActions.setFriendly([]));
  };

  const handleMapClick = async (
    selectedOrganisationFeature: MapboxGeoJSONFeature,
    activityFeature: mapboxgl.MapboxGeoJSONFeature,
    activityName
  ) => {
    !selectedOrganisationFeature &&
      activityFeature &&
      openModal({
        params: {
          mainContainer: <MapActivityModal type={activityName} organizationId={activityFeature?.properties?.organizationId} />,
        },
      });
    const _selectedFeatureId = selectedOrganisationFeature?.id;
    const selectedOrganisation: IOrganisation = organisations.all?.find(({ id }) => id === _selectedFeatureId);
    const friendlyOrganisations = _selectedFeatureId && (await getFriendlyOrgs(`${_selectedFeatureId}`));
    setDisplayDetachedOrganisations(false);
    dispatch(organisActions.setFriendly(friendlyOrganisations ?? []));
    dispatch(organisActions.setSelected(selectedOrganisation ?? null));
  };

  const handleMapIconClick = async (e: React.MouseEvent<HTMLButtonElement>, organisationId: string) => {
    e.stopPropagation();
    e.preventDefault();

    const friendlyOrganisations = await getFriendlyOrgs(`${organisationId}`);
    const selectedOrganisation = organisations.all?.find(({ id }) => id === Number(organisationId));
    dispatch(organisActions.setFriendly(friendlyOrganisations ?? []));
    dispatch(organisActions.setSelected(selectedOrganisation ?? null));
  };

  const _handleMapRefresh = (bounds?: LngLatBounds) => {
    //@ts-ignore
    const { _ne, _sw } = bounds || {};
    const requestPointsOnTheArea = async () => {
      try {
        const organisationsOnTheArea: IOrganisationWithPoint[] = await getOrgsOnTheArea({
          polygon: `${_ne.lat},${_ne.lng}~${_sw.lat},${_sw.lng}`,
        });
        dispatch(organisActions.setAll(organisationsOnTheArea));
      } catch (error) {
        console.warn(error);
      }
    };
    requestPointsOnTheArea();
  };

  // const { handler } = useRegov();
  const infiniteScrollListRef = useRef<any>(null);
  useEffect(() => {
    const selectedItemId = organisations.selected?.id;
    if (selectedItemId && infiniteScrollListRef.current) {
      const selectedItem = infiniteScrollListRef.current?.querySelector?.(`[data-id="${selectedItemId}"]`);
      if (selectedItem) {
        selectedItem.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' });
      }
    }
  }, [organisations.selected?.id, organisations.all, organisations.detached]);
  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>
      <div className={styles.mapWrapper}>
        <div className={classNames(styles.mapUnionsBar, { [styles.close]: !showUnionsBar })}>
          <SwitchButton
            className={classNames(styles.switchButton, { [styles.displayBtn]: !showUnionsBar })}
            open={showUnionsBar}
            onClick={() => {
              setShowUnionsBar(!showUnionsBar);
            }}
          />
          <div className={styles.searchSection}>
            <Input
              onFocus={handleInputFocus}
              placeholder={t('input.placeholder')}
              className={styles.searchInput}
              value={inputValue}
              valueChange={handleInputChange}
              enableClean
            />
            {/* <OrganizationFilter handleSubmit={() => {}} /> */}
          </div>
          {/* <WalletPersistentIntegrationReact
              config={regovConfig}
              extensions={regovExtensionRegistry}
              source="unions"
              navigatorBuilder={navigatorBuilder}
              serverClient={walletServerClient}
              passedHandler={handler}
            > */}
          {isLoading ? (
            <div className={styles.spinnerWrapper}>
              <Spinner />
            </div>
          ) : (
            <>
              <div style={{ position: 'sticky', top: 0, right: 10, zIndex: 10, paddingLeft: 10 }}>
                {displayDetachedOrganisations
                  ? // ? `${organisations.detached.length} ${metaPage.total ? `/ ${metaPage.total}` : ''}`
                    `${t('total')} ${metaPage.total ? `${metaPage.total}` : '0'}`
                  : `${t('total')} ${organisations.all.length}`}
              </div>
              <div id="scrollableDiv" ref={infiniteScrollListRef} className={styles.scrollWrapper}>
                <InfiniteScrollList
                  scrollableTarget="scrollableDiv"
                  dataLength={displayDetachedOrganisations ? organisations.detached.length : organisations.all.length}
                  next={() => {
                    getOrganizations({ name: inputValue, infinityFetch: true });
                  }}
                  hasMore={metaPage.current_page < metaPage.last_page}
                  className={styles.infiniteScroll}
                  endMessage={t('endMessage')}
                  listContent={(displayDetachedOrganisations ? organisations.detached : organisations.all)
                    // ?.sort((_, b) => {
                    //   return b.id === organisations.selected?.id ? 0 : -1;
                    // })
                    .map((organisation, index) => {
                      return (
                        <AllianceInfoOnMap
                          key={organisation.id}
                          id={organisation.id}
                          state={organisation.id === organisations.selected?.id ? 'active' : undefined}
                          organisation={organisation as IOrganisation}
                          index={index + 1}
                          onMapIconClick={handleMapIconClick}
                        />
                      );
                    })}
                  dontFindData={t('dontFindData')}
                />
              </div>
            </>
          )}
          {/* </WalletPersistentIntegrationReact> */}
        </div>
        <div className={styles.mapboxWrapper}>
          <UnionMap
            organisations={{
              ...organisations,
              friendly: organisations.friendly?.filter(({ point }) => point !== null) || [],
              all: organisations.all?.filter(({ point }) => point !== null) || [],
            }}
            onMapRefresh={!displayDetachedOrganisations ? _handleMapRefresh : () => {}}
            onMapClick={handleMapClick}
            onActivityClick={(e) => {}}
          />
        </div>
      </div>
    </>
  );
}

export default Unions;
