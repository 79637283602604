import { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styles from './OrganizationPage.module.scss';
import { OrganizationProfile } from './OrganizationProfile/OrganizationProfile';
import { FeedLayout } from './FeedLayout/FeedLayout';
import { RoutingContainer } from 'common/Routing/RoutingContainer/RoutingContainer';
import { useSsrEffect, useSsrState } from '@issr/core';
import { IOrganisation, IOrganizationEnterRequest } from 'interfaces/organisation.interface';
import { GlobalContext } from 'contexts/GlobalContext';
import { GlobalDataContext } from 'contexts/GlobalDataContext';
import { toast } from 'react-toastify';
import formatServerError from 'utils/formatServerError';
import { routes } from 'Routes';
import { HideProfileButton } from './HideProfileButton/HideProfileButton';
import SEO from 'shared/utils/Seo';
import { useTranslation } from 'react-i18next';

export function OrganizationPage() {
  const { t } = useTranslation('roy-global');
  const history = useHistory();
  const {
    services: { organisationsService },
    spinner: { showSpinner, hideSpinner },
  } = useContext(GlobalContext);
  const { user, selectedOrganisation, selectOrganisation, selectOrganisations } = useContext(GlobalDataContext);
  const { id } = useParams<{ id: string | undefined }>();
  const [isOpenOrgInfo, setIsOpenOrgInfo] = useState<boolean>(true);
  const [isOpenStream, setIsOpenStream] = useState<boolean>(false);
  const [organization, setOrganization] = useSsrState<IOrganisation>(null);
  const [requestStatus, setRequestStatus] = useSsrState<IOrganizationEnterRequest>(null);

  const isLoggedIn = !!user;
  const membership = user?.membership || [];

  const getOrganizationEnterRequest = async (id: number) => {
    try {
      const enterRequestStatus = await organisationsService.getOrganizationEnterRequestById(id);

      setRequestStatus(enterRequestStatus);
    } catch (e) {
      if (e.request.status === 404) {
        setRequestStatus(null);
        return;
      }
      toast.error(formatServerError(e));
    }
  };

  const getOrganization = async (organisationId: number) => {
    try {
      const organisation = await organisationsService.getOrganisationById(organisationId);
      setImmediate(() => setOrganization(organisation));
      selectOrganisation(organisation.id, organisation);
    } catch (error) {
      console.error(error);
      toast.error(formatServerError(error?.data));
    }
  };

  useEffect(() => {
    (async () => {
      if (!id) {
        setOrganization(null);
        return;
      }
      showSpinner();
      await Promise.all([getOrganization(+id), isLoggedIn ? getOrganizationEnterRequest(+id) : undefined]);

      hideSpinner();
    })();
  }, [id, isLoggedIn]);

  useSsrEffect(async () => {
    // later add getOrganizationEnterRequest for ssr
    await getOrganization(Number(id));
  });

  useEffect(() => {
    if (isLoggedIn && id && membership.find((org) => org.id === Number(id))) {
      selectOrganisation(Number(id));
      selectOrganisations(+id);
    }
  }, [id, membership, isLoggedIn]);

  useEffect(() => {
    if (!id && selectedOrganisation?.id) {
      setImmediate(() => history.push(routes.union.getLink(selectedOrganisation.id)));
    }
  }, [id, selectedOrganisation]);

  return (
    <RoutingContainer title={t('pages.community')} withFooter={false}>
      {organization && (
        <div className={styles.profileWrapper}>
          <SEO
            image={organization.avatar || '/logo-small.svg'}
            title={organization.name || organization.short_name}
            description={organization.description}
          />
          <HideProfileButton
            setIsOpenStream={setIsOpenStream}
            setIsOpenInfo={setIsOpenOrgInfo}
            isOpenStream={isOpenStream}
            isOpenInfo={isOpenOrgInfo}
          />

          <OrganizationProfile
            key={`organization-${id}-page`}
            isOpenStream={isOpenStream}
            setRequestStatus={setRequestStatus}
            requestStatus={requestStatus}
            isOpenOrgInfo={isOpenOrgInfo}
            organization={organization}
            orgId={+id}
          />
          <FeedLayout organizationId={+id} isOpenOrgInfo={isOpenOrgInfo} isOpenStream={isOpenStream} key={`organization-${id}-stream`} />
        </div>
      )}
    </RoutingContainer>
  );
}
