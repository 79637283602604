import { NewsCard } from 'components/Crads/NewsCards';
import { routes } from 'Routes';
import { KbaseMaterialFront } from 'shared/interfaces/kbase';
import { NewsInterface } from 'shared/interfaces/news';
import styles from './CardsAssemble.module.scss';
import { MainGroupCards } from './MainGroupCards';

type Props = NewsProps | MaterialProps;
type NewsProps = {
  cards: NewsInterface[];
  isMobile?: boolean;
};
type MaterialProps = {
  cards: KbaseMaterialFront[];
  isMobile?: boolean;
};

export const CardsAssemble = ({ cards, isMobile = false }: Props) => {
  const firstItems = cards.length > 4 ? cards.slice(0, 4) : null;
  const secondItems = cards.length > 8 ? cards.slice(4, 8) : null;
  const lastItems = cards.length > 4 ? (cards.length > 8 ? cards.slice(8) : cards.slice(4)) : cards;
  const extractAuthor = (el) => {
    const author = {
      id: el.user_id ? el.user_id.id : el.user.id,
      logo: el.user_id ? el.user_id.public_avatar : el.user.public_avatar,
      name: el.user_id ? el.user_id.public_name : el.user.public_name,
    };
    return author;
  };
  const getLink = (el) => {
    const link = el.user_id
      ? routes.newsDetails.getLink(el.organization.id, el.id)
      : routes.materialDetails.getLink(el.organization.id, el.id);
    return link;
  };

  return (
    <div className={styles.wrapper}>
      {firstItems && <MainGroupCards isMobile={isMobile} isReverse={false} items={firstItems} />}
      {secondItems && <MainGroupCards isMobile={isMobile} isReverse={true} items={secondItems} />}
      {lastItems && (
        <div className={styles.double}>
          {lastItems.map((el: NewsInterface | KbaseMaterialFront, index: number) => {
            return (
              <NewsCard
                variant="main"
                content={el.excerpt}
                image={el.image}
                title={el.title}
                author={extractAuthor(el)}
                commentsCount={el.comments_count || 0}
                organization={{ id: el.organization.id, logo: el.organization.avatar, name: el.organization.name }}
                publishedAt={el.published_at}
                withFooter={true}
                link={getLink(el)}
                key={`news-main-card-${index}`}
                isMobile={isMobile}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};
