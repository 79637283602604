import { NewsInterface } from 'shared/interfaces/news';
import { RequestMeta } from 'interfaces/request-meta.interface';
import { httpService } from 'services';
import { Api } from './http.service';
import { CreationNews, NewsData } from 'interfaces/news.interface';

export type getNewsFilters = {
  sortBy?: 'popular' | 'id';
  limit?: number;
  sortDirection?: 'asc' | 'desc';
  page?: number;
  tags?: string[];
  featured?: number;
};

export type getOrgNewsFilter = getNewsFilters & {
  organisationId: number;
};

export const getNews = async ({
  sortBy = 'id',
  limit,
  sortDirection = 'desc',
  page = 1,
  featured,
  tags,
}: getNewsFilters): Promise<{ data: NewsInterface[]; meta: RequestMeta }> => {
  const response = await httpService.axios.get(`${Api.NEWS}`, { params: { page, limit, sortDirection, sortBy, tags, featured } });
  const news = response?.data?.data;
  return { data: news, meta: response.data.meta };
};

export const getOrganisationNews = async ({
  organisationId,
  sortBy = 'id',
  limit,
  sortDirection = 'desc',
  page = 1,
}: getOrgNewsFilter): Promise<{ data: NewsInterface[]; meta: RequestMeta }> => {
  try {
    const response = await httpService.axios.get(`${Api.ORGANISATIONS}/${organisationId}/news`, {
      params: { organization_id: organisationId, sortDirection: sortDirection, limit: limit, sortBy, page },
    });
    const news = response?.data?.data;
    return { data: news, meta: response.data.meta };
  } catch (e) {
    return e.response.data;
  }
};

interface suggestNewsArgs {
  title: string;
  content: string;
  preview?: string;
  comment?: string;
}

export async function suggestNews(organisationId, news: suggestNewsArgs) {
  const {
    data: { data },
  } = await httpService.axios.post(`${Api.ORGANISATIONS}/${organisationId}/news`, news);
  return data;
}
export async function createNews(orgId: string, data: Partial<CreationNews>): Promise<NewsData> {
  const response = await httpService.axios.post(`/admin_org/${orgId}/news`, data);
  return response?.data?.data;
}

export async function updateNews(orgId: string, newsId: number, data: Partial<CreationNews>): Promise<NewsData> {
  const response = await httpService.axios.put(`/admin_org/${orgId}/news/${newsId}`, data);
  return response?.data?.data;
}
export async function publishNews(orgId: string, newsId: number): Promise<void> {
  await httpService.axios.post(`/admin_org/${orgId}/news/${newsId}/publish`);
}
