import { GlobalDataContext } from 'contexts/GlobalDataContext';
import { useContext } from 'react';
import { NotificationStatusEnum } from 'interfaces/notification.interface';
import { useTranslation } from 'react-i18next';
import { NotificationItem } from '../NotificationItem/NotificationItem';
import styles from './NotificationsList.module.scss';
import { Button } from 'shared/components/common/Button/Button';
import { ReadAllNotifications } from 'services/notifications';
import { toast } from 'react-toastify';
import formatServerError from 'shared/utils/formatServerError';
import { GlobalContext } from 'contexts/GlobalContext';

function NotificationsList() {
  const { t } = useTranslation('roy-global');
  const { notifications, setNotifications } = useContext(GlobalDataContext);
  const {
    spinner: { showSpinner, hideSpinner },
    closeModal,
  } = useContext(GlobalContext);
  const unreadNotifications = notifications.filter((notification) => notification.status === NotificationStatusEnum.unread);
  const unreadNotificationsId = unreadNotifications.map((el) => el.id);

  const closeAll = async () => {
    showSpinner();
    try {
      await ReadAllNotifications(unreadNotificationsId);
      const newNotifications = notifications.map((el) => {
        return { ...el, status: NotificationStatusEnum.read };
      });
      setNotifications(newNotifications);
      closeModal();
      toast(t('notifications.empty'));
    } catch (e) {
      toast.error(formatServerError(e));
    } finally {
      hideSpinner();
    }
  };

  return (
    <div className={styles.wrapper}>
      <h4 className={styles.title}>{t('notifications.title')}</h4>
      {!unreadNotifications.length ? (
        <div className={styles.noContent}>
          <p>{t('notifications.empty')}</p>
        </div>
      ) : (
        <ul className={styles.list}>
          {unreadNotifications.map(({ from, id, title, created_at }, index) => {
            return <NotificationItem created_at={created_at} from={from} id={id} title={title} key={`notification-item-${index}`} />;
          })}
        </ul>
      )}
      {
        // ToDo add closeAll btn
      }
      <Button disabled={!unreadNotifications.length} color="light" size="small" className={styles.button} onClick={closeAll}>
        {t('notifications.closeAll')}
      </Button>
    </div>
  );
}

export default NotificationsList;
