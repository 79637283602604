import classNames from 'classnames';
import { CustomSlider } from 'components/CustomSlider/CustomSlider';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { routes } from 'Routes';
import { Icon } from 'shared/components/common/Icon/Icon';
import { Tab } from 'shared/components/common/Tab/Tab';
import formatServerError from 'shared/utils/formatServerError';
import styles from './OrganizationPolls.module.scss';
import { PollFront } from 'shared/interfaces/polls';
import { getOrganisationPolls } from 'services/polls';
import { MainCard } from 'components/Crads/NewsCards/MainCard/MainCard';
import { mockImages } from 'shared/constants/MockImages/MockImages';
import { useTranslation } from 'react-i18next';

type OrganizationPollsProps = {
  organizationId?: number;
  slidesOnPage: number;
  isMobile: boolean;
};

export const OrganizationPolls = ({ organizationId, slidesOnPage = 1, isMobile }: OrganizationPollsProps) => {
  const { t } = useTranslation('profile');
  const [activePolls, setActivePolls] = useState<PollFront[]>([]);
  const [completedPolls, setComlpetedPolls] = useState<PollFront[]>([]);
  const [activeSlideMaterials, setActiveSlideMaterials] = useState<number>(0);
  const [activeSlideSections, setActiveSlideSections] = useState<number>(0);
  const [activeTab, setActiveTab] = useState<number>(0);
  const activeSlide = [activeSlideMaterials, activeSlideSections];
  const setActiveSlide = [setActiveSlideMaterials, setActiveSlideSections];

  useEffect(() => {
    (async () => {
      try {
        const response = await getOrganisationPolls(organizationId);
        setActivePolls(response?.reverse());
      } catch (e) {
        toast.error(formatServerError(e));
        console.log(e);
      }
    })();
    (async () => {
      try {
        const response = await getOrganisationPolls(organizationId, 1);
        setComlpetedPolls(response);
      } catch (e) {
        toast.error(formatServerError(e));
        console.log(e);
      }
    })();
  }, [organizationId]);

  const slides = [
    activePolls.map((el, index) => {
      if (!el) {
        return null;
      }
      return (
        <MainCard
          maxHeight={{ content: 95, title: 56 }}
          content={el.description}
          image={el.images[0]?.url || mockImages.polls}
          link={routes.poll.getLink(el.organization.id, el.id)}
          withFooter={true}
          title={el.name}
          publishedAt={el.published_at}
          organization={{ id: el.organization.id, logo: el.organization.avatar, name: el.organization.short_name }}
          key={`active-poll-card-${index}`}
          isMobile={isMobile}
        />
      );
    }),
    completedPolls.map((el, index) => {
      if (!el) {
        return null;
      }
      return (
        <MainCard
          maxHeight={{ content: 95, title: 56 }}
          content={el.description}
          image={el.images[0]?.url || mockImages.polls}
          link={routes.poll.getLink(el.organization.id, el.id)}
          withFooter={true}
          title={el.name}
          publishedAt={el.published_at}
          organization={{ id: el.organization.id, logo: el.organization.avatar, name: el.organization.short_name }}
          key={`completed-poll-card-${index}`}
          isMobile={isMobile}
        />
      );
    }),
  ];

  if (!activePolls?.length && !completedPolls?.length) {
    return null;
  }
  const selected = !!slides[0].length ? 0 : 1;
  const isDisabled = [!slides[0].length, !slides[1].length];

  return (
    <div className={classNames(styles.wrapper, { [styles.mobileWrapper]: isMobile })}>
      <div className={classNames(styles.title, { [styles.mobile]: isMobile })}>
        <h4 className={classNames({ [styles.titleTextDesc]: !isMobile })}>{t('organization.polls.title')}</h4>
        <div className={classNames(styles.controls)}>
          <button
            className={styles.button}
            disabled={activeSlide[activeTab] === 0}
            onClick={() => setActiveSlide[activeTab](activeSlide[activeTab] - 1)}
            title={t('buttons.slider.prev')}
          >
            <Icon width={24} height={24} iconName={'arrowLeft'} color="black" />
          </button>
          <button
            className={styles.button}
            onClick={() => setActiveSlide[activeTab](activeSlide[activeTab] + 1)}
            disabled={activeSlide[activeTab] >= slides[activeTab].length - 1}
            title={t('buttons.slider.next')}
          >
            <Icon width={24} height={24} iconName={'arrowRight'} color="black" />
          </button>
        </div>
        <Tab
          isDisabled={isDisabled}
          selectDefault={selected}
          className={styles.tab}
          tabPanels={[]}
          setOutsidePanel={setActiveTab}
          tabList={[t('organization.polls.tabs.0'), t('organization.polls.tabs.1')]}
        />
      </div>

      {activeTab === 0 && <CustomSlider activeSlide={activeSlideMaterials} slides={slides[0]} slidesOnPage={slidesOnPage} />}
      {activeTab === 1 && <CustomSlider activeSlide={activeSlideSections} slides={slides[1]} slidesOnPage={slidesOnPage} />}
    </div>
  );
};
