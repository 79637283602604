import { Link } from 'react-router-dom';
import { routes } from 'Routes';
import styles from './JoinUs.module.scss';
import { useTranslation } from 'react-i18next';

export const JoinUs = () => {
  const { t } = useTranslation('home');
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.text}>
          <div>
            <Link to={routes.REGISTRATION} target={'_blank'} rel="noreferrer" className={styles.button}>
              <div className={styles.button}>
                <p>{t('buttons.comeFirst')}</p>
              </div>
            </Link>
          </div>
          <div className={styles.title}>{t('joinUs.description')}</div>
        </div>
        <div className={styles.image}>
          <img src={'/images/join.jpg'} alt={'Join us'} width={1080} height={1080} />
        </div>
      </div>
    </div>
  );
};
