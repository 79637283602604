import { NewsInterface } from 'shared/interfaces/news';
import styles from './NewsGroup.module.scss';
import InfiniteScrollList from 'components/InfiniteScrollList/InfiniteScrollList';
import { NewsSlider } from '../NewsSlider/NewsSlider';
import { NewsCard } from '../../../components/Crads/NewsCards';
import { routes } from 'Routes';
import { CardsAssemble } from '../../../components/Crads/CardsAssemble/CardsAssemble';
import { useContext } from 'react';
import { GlobalContext } from 'contexts/GlobalContext';
import { useTranslation } from 'react-i18next';

export enum NewsGroupDirection {
  slider,
  grid,
  list,
}
interface Props {
  loadData: () => void;
  hasMoreData?: boolean;
  news: NewsInterface[];
  title?: string;
  direction?: NewsGroupDirection;
}
function NewsGroup({ hasMoreData, loadData, news, title, direction = NewsGroupDirection.slider }: Props) {
  const { t } = useTranslation('news');
  const {
    services: { deviceService },
    screen,
  } = useContext(GlobalContext);
  const isMobile = deviceService.isMobile || screen.innerWidth <= 1024;

  const formatArr = (arr: NewsInterface[]) => {
    const result: NewsInterface[][] = [];
    const size = 10;
    arr.forEach((el, index) => {
      if (!result[Math.floor(index / size)]) {
        result[Math.floor(index / size)] = [];
      }
      result[Math.floor(index / size)].push(el);
    });
    return result;
  };

  const newsGroupContent = {
    [NewsGroupDirection.slider]: (
      <NewsSlider loadNews={loadData} isMobile={isMobile} news={news} slidesOnPage={isMobile ? 1 : 2} hasMore={hasMoreData} />
    ),
    [NewsGroupDirection.grid]: (
      <InfiniteScrollList
        dataLength={news?.length}
        next={() => loadData()}
        hasMore={hasMoreData}
        listContent={
          <div className={styles.scroll}>
            {formatArr(news).map((el, index) => {
              return <CardsAssemble key={`newsGroup-${index}`} cards={el} isMobile={isMobile} />;
            })}
          </div>
        }
        endMessage={t('actions.InfiniteScrollList.endMessage')}
        dontFindData={t('actions.InfiniteScrollList.dontFindData')}
      />
    ),
    [NewsGroupDirection.list]: (
      <InfiniteScrollList
        dataLength={news?.length}
        next={() => loadData()}
        hasMore={hasMoreData}
        listContent={
          <div className={styles.list}>
            {news.map((newsData) => {
              return (
                <NewsCard
                  key={newsData.id}
                  variant="main"
                  content={newsData.excerpt}
                  image={newsData.image}
                  title={newsData.title}
                  author={{ id: newsData.user_id.id, logo: newsData.user_id.public_avatar, name: newsData.user_id.public_name }}
                  commentsCount={newsData.comments_count || 0}
                  organization={{ id: newsData.organization.id, logo: newsData.organization.avatar, name: newsData.organization.name }}
                  publishedAt={newsData.published_at}
                  withFooter={true}
                  link={routes.newsDetails.getLink(newsData.organization.id, newsData.id)}
                  isMobile={isMobile}
                />
              );
            })}
          </div>
        }
        endMessage={t('actions.InfiniteScrollList.endMessage')}
        dontFindData={t('actions.InfiniteScrollList.dontFindData')}
      />
    ),
  };

  return (
    <div className={styles.news}>
      {title && direction !== NewsGroupDirection.slider && <h2 className={styles.title}>{title}</h2>}
      <div className={styles.content}>{newsGroupContent[direction]}</div>
    </div>
  );
}

export default NewsGroup;
