import styles from './ElementWrapper.module.scss';
import { useState } from 'react';
import classNames from 'classnames';
import { Icon } from 'shared/components/common/Icon/Icon';

type Props = {
  title: string;
  children?: Array<JSX.Element | false> | JSX.Element;
  isOpenDefault?: boolean;
};

export const PublicationSettingsElementWrapper = ({ children, title, isOpenDefault = false }: Props) => {
  const [isOpen, setIsOpen] = useState(isOpenDefault);
  return (
    <div className={classNames(styles.wrapper, { [styles.open]: isOpen })}>
      <div className={styles.title} onClick={() => setIsOpen((val) => !val)}>
        <h4>{title}</h4>
        <Icon className={styles.icon} width={24} rotate={isOpen ? 180 : 0} iconName="arrowBottom" />
      </div>
      {children}
    </div>
  );
};
