import { useEffect, useMemo } from 'react';
import { ChatSidebar, ChatThread } from './components';
import styles from './Chat.module.scss';
import { createThread, getThreads, setUserData, getMessages, setActiveThreadId } from 'shared/slices/chat';
import { useDispatch, useSelector } from '../../redux-toolkit';
import DialogModal from './components/Dialog/DialogModal';
import { toast } from 'react-toastify';
import { useLocation, useHistory } from 'react-router';
import { ChatModalProps } from 'shared/interfaces/chat';
import { Helmet } from 'react-helmet';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

type ChatProps = ChatModalProps & {
  getUserData: () => void;
  orgId?: string;
  isFront?: boolean;
};

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

const Chat = ({ openModal, getUserData, closeModal, getOrganizationUsers, orgId, onMenuClick, isFront }: ChatProps) => {
  const { t } = useTranslation('shared');
  const dispatch = useDispatch();
  const { search } = useLocation();
  const history = useHistory();
  const { user, threads } = useSelector((state) => state.chat);
  const newMessages = threads.reduce((prev, { new_messages }) => prev + new_messages, 0);

  let query = useQuery();
  const loadThreads = () => {
    dispatch(getThreads());
  };

  const loadMessages = async (conversationId) => {
    dispatch(setActiveThreadId(conversationId));
    dispatch(getMessages(conversationId));
  };

  useEffect(() => {
    if (!(query.get('id') && query.get('type') && user)) {
      (async () => {
        await loadThreads();
        if (query.get('conversation_id')) {
          loadMessages(query.get('conversation_id'));
        }
      })();
    }
    (async () => {
      try {
        const user = await getUserData();
        dispatch(setUserData(user));
      } catch (error) {
        toast(error);
      }
    })();
    const interval = setInterval(loadThreads, 1000 * 600);
    return () => {
      clearInterval(interval);
    };
  }, [orgId]);

  useEffect(() => {
    if (query.get('id') && query.get('type') && user) {
      dispatch(createThread(query.get('id'), query.get('type')));
      history.replace({
        search: '',
      });
    }
  }, [search, user]);

  const getTitle = () => {
    let title = t('chat.getTitle.default');
    if (newMessages > 0) {
      title = `${t('chat.getTitle.newMessages')} ${newMessages}`;
    }
    return title;
  };

  const title = getTitle();

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <DialogModal
        modalProps={{
          openModal,
          closeModal,
        }}
      />
      <div
        className={cn(styles.ChatSidebarContainer, {
          [styles.front]: !!isFront,
        })}
      >
        <ChatSidebar
          modalProps={{
            openModal,
            closeModal,
            getOrganizationUsers,
            onMenuClick,
          }}
        />
        <ChatThread />
      </div>
    </>
  );
};

export default Chat;
