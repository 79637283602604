import { FormikErrors } from 'formik';
import { CreationEvent } from 'interfaces/events.interface';
import { useTranslation } from 'react-i18next';
import { mockImages } from 'shared/constants/MockImages/MockImages';
import { useContext } from 'react';
import { GlobalContext } from 'contexts/GlobalContext';
import { SettingsSkeleton } from 'components/PublicationSettings/SettingsSkeleton';
import { PublicationSettingsElementWrapper } from 'components/PublicationSettings/ElementWrapper/ElementWrapper';
import { PublicationOrgSelection } from 'components/PublicationSettings/PublicationOrgSelection/PublicationOrgSelection';
import { PublicationVisibility } from 'components/PublicationSettings/PublicationVisibility/PublicationVisibility';
import { PreviewEditor } from 'components/PublicationSettings/PreviewEditor/PreviewEditor';
import { PublicationSettingDatePicker } from 'components/PublicationSettings/DatePicker/DatePicker';
import { PlaceEditor } from 'components/PublicationSettings/PlaceEditor/PlaceEditor';
import { RoyPass } from 'components/PublicationSettings/RoyPass/RoyPass';
import { Agreements } from 'components/PublicationSettings/Agreements/Agreements';

type Props = {
  values: CreationEvent;
  setValue: <K extends keyof CreationEvent>(field: K, val: CreationEvent[K]) => void;
  onDraft: () => Promise<void> | void;
  onPublish: () => Promise<void> | void;
  errors: FormikErrors<CreationEvent>;
  administer: Array<{ id: string; short_name: string; avatar?: string }>;
  selectedOrg: string;
  setSelectedOrg: (val: string) => void;
  onDrop: (files: File[]) => Promise<void>;
  setLocalCropper: (val: Cropper) => void;
};

const PublicationEventSettings = ({
  administer,
  onDraft,
  errors,
  onDrop,
  onPublish,
  selectedOrg,
  setLocalCropper,
  setSelectedOrg,
  setValue,
  values,
}: Props) => {
  const { t } = useTranslation('editor');
  const { openModal, closeModal } = useContext(GlobalContext);

  const openModalHandler = (element: JSX.Element) => {
    openModal({
      params: {
        mainContainer: element,
      },
    });
  };

  return (
    <SettingsSkeleton
      onDraft={onDraft}
      onPublish={onPublish}
      isActionsDisabled={!(!!values.date_start && !errors.date_start && !errors.date_end) || !values.isAgree || values.needRoyPass}
      isSaveDisabled={!values.isAgree}
      isEventRoyPass={values.needRoyPass}
    >
      <PublicationSettingsElementWrapper title={t('settings.general.title')} isOpenDefault>
        <PublicationOrgSelection administer={administer} selectedOrg={selectedOrg} setSelectedOrg={setSelectedOrg} />
        <PublicationVisibility setVisible={(val) => setValue('visible', val)} visible={values.visible} />
        <PreviewEditor onDrop={onDrop} preview={values.preview || mockImages.event} setLocalCropper={setLocalCropper} />
        <PublicationSettingDatePicker
          date_end={values.date_end}
          date_start={values.date_start}
          setDateEnd={(val) => setValue('date_end', val)}
          setDateStart={(val) => setValue('date_start', val)}
          openModal={openModalHandler}
          closeModal={closeModal}
        />
        <PlaceEditor
          point={values.point}
          place={values.place}
          setPlace={(val) => setValue('place', val)}
          setPoint={(val) => setValue('point', val)}
        />
      </PublicationSettingsElementWrapper>
      <PublicationSettingsElementWrapper title={t('settings.qr.title')} isOpenDefault>
        <RoyPass setValue={setValue} values={values} />
      </PublicationSettingsElementWrapper>
      <Agreements setValue={setValue} values={values} />
    </SettingsSkeleton>
  );
};

export default PublicationEventSettings;
