import { useContext, useEffect } from 'react';
import { GlobalContext } from '../../contexts/GlobalContext';
import { ModalWindow } from 'shared/components/ModalWindow/ModalWindow';

export function GlobalModal() {
  const {
    globalModalOptions: {
      params: { mainContainer },
    },
    closeModal,
  } = useContext(GlobalContext);

  const handleKeyUp = (event) => {
    if (event.keyCode === 27) {
      closeModal();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyUp, false);
    return () => {
      document.removeEventListener('keydown', handleKeyUp, false);
    };
  }, []);
  return (
    <ModalWindow isOpen={true} setIsOpen={closeModal}>
      {mainContainer}
    </ModalWindow>
  );
}
