import { useContext, useEffect, useLayoutEffect, useState } from 'react';
import styles from './Auth.module.scss';
import { Login } from './Login/Login';
import { InvitationPage } from './InvitationPage/InvitationPage';
import { useHistory, Redirect, useLocation } from 'react-router-dom';
import { GlobalContext } from '../../contexts/GlobalContext';
import { IInviteInfo } from '../../interfaces/user.interface';
import moment from 'moment';
import 'moment/locale/ru';
import { ForgetPassword } from './ForgetPassword/ForgetPassword';
import { ResetPassword } from './ResetPassword/ResetPassword';
import { routes } from 'Routes';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

moment.locale('ru');

type IAuthProps = {
  type: 'login' | 'invitationPage' | 'forgetPassword' | 'resetPassword';
};

export function Auth({ type }: IAuthProps) {
  const {
    services: { authService, httpService },
    spinner: { showSpinner, hideSpinner },
  } = useContext(GlobalContext);
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation('auth');

  const [inviteInfo, setInviteInfo] = useState<IInviteInfo>(null);
  const [outsidePanel, setOutsidePanel] = useState<number>(0);
  const [isRedirectToAdmin, setIsRedirectToAdmin] = useState<boolean>(false);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(location.search);
    const ssi = JSON.parse(urlSearchParams.get('ssi')) as boolean;
    const admin = JSON.parse(urlSearchParams.get('admin')) as boolean;

    if (ssi && typeof ssi === 'boolean') {
      setOutsidePanel(1);
    }
    if (admin && typeof admin === 'boolean') {
      if (httpService.token) {
        const { REACT_APP_ADMIN_URL } = process.env;
        window?.open(`${REACT_APP_ADMIN_URL}/signin?token=${httpService.token}`, 'roy-team-admin');
        window?.close();
      }
      setIsRedirectToAdmin(admin);
    }
    if (admin || ssi) {
      history.replace(location.pathname);
    }
  }, [history, location]);

  useLayoutEffect(() => {
    if (type === 'invitationPage') {
      (async () => {
        showSpinner();
        const params = new URLSearchParams(history.location.search);
        const invite_id = params.get('invite_id');
        const invite_code = params.get('invite_code');
        const inviteInfo = await authService.getInviteInfo(+invite_id, invite_code).finally(() => hideSpinner());
        setInviteInfo(inviteInfo);
        hideSpinner();
      })();
    }
  }, []);

  let children: JSX.Element;

  function durationFromNow(date: string) {
    if (!date) {
      return t('invitation.day');
    }

    return moment.utc(date).fromNow(true);
  }

  const info = [
    {
      title: t('login.title'),
      info: (
        <>
          <p>{t('login.description')}</p>
          <br />
          <p className={styles.blue}>{t('login.info')}</p>
        </>
      ),
    },
    {
      title: t('loginSSI.title'),
      info: (
        <>
          <p>{t('loginSSI.description')}</p>
          <br />
          <p className={styles.blue}>{t('loginSSI.info')}</p>
          <br />
        </>
      ),
    },
  ];

  switch (type) {
    case 'login': {
      children = <Login isRedirectToAdmin={isRedirectToAdmin} setOutsidePanel={setOutsidePanel} outsidePanel={outsidePanel} />;
      break;
    }
    case 'invitationPage': {
      children = <InvitationPage inviteInfo={inviteInfo} />;
      break;
    }
    case 'forgetPassword': {
      children = <ForgetPassword />;
      break;
    }
    case 'resetPassword': {
      children = <ResetPassword />;
      break;
    }
  }

  if (httpService.token) {
    return <Redirect to={routes.PROFILE} />;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.content}>{children}</div>
      </div>
      <div className={styles.container}>
        <div className={classNames(styles.content, styles.info)}>
          <h3>{info[outsidePanel].title}</h3>
          <br />
          {info[outsidePanel].info}
          {type === 'invitationPage' && (
            <p className="highlight">
              <span>
                {t('invitation.linkIsValid')} {durationFromNow(inviteInfo?.limit)}
              </span>
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
