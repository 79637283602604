import { IModalOptions } from 'contexts/GlobalContext';
import { IGlobalDataState } from 'contexts/GlobalDataContext';
import { AuthService } from 'services/auth.service';
import * as H from 'history';
import { routes } from 'Routes';
import { UserService } from 'services/user.service';

export const auth = (props: RegovAuthPropsProps) => async (data: AuthResult, loading) => {
  const history = props.history;

  if (!data.ok) {
    const error = data.errors[0] ? data.errors[0] : 'Неизвестная ошибка';
    loading.error(error);
    return false;
  }

  if (data.token && data.notificationToken) {
    props.authService.authenticate({
      token: data.token,
      notificationToken: data.notificationToken,
    });

    await props.auth.authenticate();

    window.dataLayer.push({
      event: 'event',
      eventProps: {
        category: 'account',
        action: 'login-ssi',
      },
    });

    if (!props.doNotClose) {
      setImmediate(() => {
        props.closeModal();
        setImmediate(async () => {
          if (props.authService.inviteParams || !!localStorage.getItem('firstSession')) {
            history.push(routes.INVITATION_SCREEN);
          } else {
            history.push(routes.profile.getLink((await props.userService.getUser()).id));
          }
        });
      });
    }

    return true;
  }

  loading.error(data.errors[0] ? data.errors[0] : 'Неизвестная ошибка');
  return false;
};

export type AuthResult = {
  ok: boolean;
  errors?: string[];
  token?: string;
  notificationToken?: string;
};

export type RegovAuthPropsProps = {
  doNotClose?: boolean;
  openModal: (options?: Pick<IModalOptions, 'params'>) => void;
  closeModal: () => void;
  authService: AuthService;
  userService: UserService;
  auth: IGlobalDataState['auth'];
  history: H.History<H.LocationState>;
};
