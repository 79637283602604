import { useEffect, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import formatServerError from 'shared/utils/formatServerError';
import { getMaterial } from 'services/knowledge-base';
import { useSsrEffect, useSsrState } from '@issr/core';
import { routes } from 'Routes';
import { KbaseMaterialFront } from 'shared/interfaces/kbase';
import { toast } from 'react-toastify';
import { getFundraisings } from 'services/finance';
import { PaymentCreated, PaymentTypeEnum } from 'shared/interfaces/finance';
import SEO from 'shared/utils/Seo';
import ContentLayout from 'containers/ContentLayout/ContentLayout';
import { RoutingContainer } from 'common/Routing/RoutingContainer/RoutingContainer';
import { useTranslation } from 'react-i18next';

type ILogo = {
  src: string;
  alt: string;
  href?: string;
};

const MaterialDetails = () => {
  const { t } = useTranslation('kbase');
  const { organizationId, materialId } = useParams<{ organizationId: string; materialId: string }>();
  const [material, setMaterial] = useSsrState<KbaseMaterialFront>(undefined);
  const [logo, setLogo] = useSsrState<ILogo>(undefined);
  const [payments, setPayments] = useSsrState<PaymentCreated[]>([]);

  const { state } = useLocation<{ from?: string }>();
  useEffect(() => {
    (async () => {
      try {
        const fundraisings = await getFundraisings(+organizationId);
        setPayments(fundraisings);
      } catch (e) {
        toast.error(formatServerError(e));
        console.log(formatServerError(e));
      }
    })();
  }, [organizationId]);

  const returnPage = useRef(null);

  useEffect(() => {
    if (state?.from) {
      returnPage.current = state.from;
    }
  }, []);

  async function loadMaterials() {
    const response = await getMaterial(+organizationId, +materialId);
    setMaterial(response);
    setLogo({
      src: response.organization?.avatar,
      href: routes.union.getLink(organizationId),
      alt: response.organization.name,
    });
  }

  useSsrEffect(async () => {
    try {
      await loadMaterials();
    } catch (e) {
      console.log(formatServerError(e));
    }
  });

  useEffect(() => {
    (async () => {
      try {
        await loadMaterials();
      } catch (e) {
        toast.error(formatServerError(e));
      }
    })();
  }, [materialId]);

  const findPayment = payments
    ?.filter((payment) => !!payment.auto_payments?.length || !!payment.manual_payments?.length)
    ?.find((payment) => payment.type === PaymentTypeEnum.fundraising);

  return (
    <RoutingContainer withFooter={false} showCloseButton={true} title={t('materials')}>
      <SEO title={material?.title} description={material?.excerpt || material?.title} image={material?.image} />
      {material && (
        <ContentLayout
          title={material.title}
          contentId={material.id}
          orgId={material.organization.id}
          contentType={'material'}
          content={material.content}
          user={material.user}
          published_at={material.published_at}
          tags={material.tags}
          logo={logo}
          commentsCount={material.comments_count}
          choosePayment={findPayment}
          materialExtra={material.section}
        />
      )}
    </RoutingContainer>
  );
};

export default MaterialDetails;
