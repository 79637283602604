// comm
import ruComm from './ru/ext-comm.json';
import enComm from './en/ext-comm.json';
import beComm from './be/ext-comm.json';
import ltComm from './lt/ext-comm.json';
import plComm from './pl/ext-comm.json';
import ukComm from './uk/ext-comm.json';
// identity
import ruIdentity from './ru/ext-identity.json';
import enIdentity from './en/ext-identity.json';
import beIdentity from './be/ext-identity.json';
import ltIdentity from './lt/ext-identity.json';
import plIdentity from './pl/ext-identity.json';
import ukIdentity from './uk/ext-identity.json';
// auth
import ruAuth from './ru/ext-auth.json';
import enAuth from './en/ext-auth.json';
import beAuth from './be/ext-auth.json';
import ltAuth from './lt/ext-auth.json';
import plAuth from './pl/ext-auth.json';
import ukAuth from './uk/ext-auth.json';
// group
import ruGroup from './ru/ext-group.json';
import enGroup from './en/ext-group.json';
import beGroup from './be/ext-group.json';
import ltGroup from './lt/ext-group.json';
import plGroup from './pl/ext-group.json';
import ukGroup from './uk/ext-group.json';
// dopamoga
import ruDopamoga from './ru/dapamoga.json';
import enDopamoga from './en/dapamoga.json';
import beDopamoga from './be/dapamoga.json';
import ltDopamoga from './lt/dapamoga.json';
import plDopamoga from './pl/dapamoga.json';
import ukDopamoga from './uk/dapamoga.json';
// roy
import ruRoy from './ru/roy.json';
import enRoy from './en/roy.json';
import beRoy from './be/roy.json';
import ltRoy from './lt/roy.json';
import plRoy from './pl/roy.json';
import ukRoy from './uk/roy.json';
// militaryMedic
import ruMilitaryMedic from './ru/militaryMedic.json';
import enMilitaryMedic from './en/militaryMedic.json';
import beMilitaryMedic from './be/militaryMedic.json';
import ltMilitaryMedic from './lt/militaryMedic.json';
import plMilitaryMedic from './pl/militaryMedic.json';
import ukMilitaryMedic from './uk/militaryMedic.json';
// ssi
import enSSI from '../../lib/locales/en/ssi.json';
import ruSSI from '../../lib/locales/ru/ssi.json';
import beSSI from '../../lib/locales/be/ssi.json';
import plSSI from '../../lib/locales/pl/ssi.json';
import ltSSI from '../../lib/locales/lt/ssi.json';
import ukSSI from '../../lib/locales/uk/ssi.json';

type Schema = {
  be?: Object;
  en: Object;
  lt?: Object;
  pl?: Object;
  ru?: Object;
  uk?: Object;
};
export const ssiLocalesSchema: Record<string, Schema> = {
  identity: {
    en: enIdentity,
    ru: ruIdentity,
    be: beIdentity,
    lt: ltIdentity,
    uk: ukIdentity,
    pl: plIdentity,
  },
  ssi: {
    en: enSSI,
    ru: ruSSI,
    be: beSSI,
    pl: plSSI,
    lt: ltSSI,
    uk: ukSSI,
  },
  auth: {
    ru: ruAuth,
    en: enAuth,
    be: beAuth,
    lt: ltAuth,
    uk: ukAuth,
    pl: plAuth,
  },
  comm: {
    en: enComm,
    ru: ruComm,
    be: beComm,
    lt: ltComm,
    uk: ukComm,
    pl: plComm,
  },
  group: {
    en: {
      ...enGroup,
      ...enDopamoga,
      ...enRoy,
      ...enMilitaryMedic,
    },
    ru: {
      ...ruGroup,
      ...ruDopamoga,
      ...ruRoy,
      ...ruMilitaryMedic,
    },
    be: {
      ...beGroup,
      ...beDopamoga,
      ...beRoy,
      ...beMilitaryMedic,
    },
    lt: {
      ...ltGroup,
      ...ltDopamoga,
      ...ltRoy,
      ...ltMilitaryMedic,
    },
    uk: {
      ...ukGroup,
      ...ukDopamoga,
      ...ukRoy,
      ...ukMilitaryMedic,
    },
    pl: {
      ...plGroup,
      ...plDopamoga,
      ...plRoy,
      ...plMilitaryMedic,
    },
  },
};
