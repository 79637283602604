import styles from './EditableSocial.module.scss';
import Vk from './images/vk.png';
import YouTube from './images/youtube.png';
import Facebook from './images/facebook.png';
import Telegram from './images/telegram.png';
import GitHub from './images/gitHub.png';
import GitLab from './images/gitLab.png';
import Linkedin from './images/linkedin.png';
import Snapchat from './images/snapchat.png';
import Pinterest from './images/pinterest.png';
import Wechat from './images/wechat.png';
import Twitter from './images/twitter.png';
import Whatsapp from './images/whatsapp.png';
import Ok from './images/ok.png';
import TikTok from './images/tiktok.png';
import Instagram from './images/instagram.png';
import Other from './images/other.png';
import { Socials as SocialsEnum } from 'shared/interfaces/organization';
import { formatDomainType } from 'shared/utils/formatDomainType';
import classNames from 'classnames';
import { Icon } from 'shared/components/common/Icon/Icon';

const icons: Record<SocialsEnum, string> = {
  vk: Vk,
  youtube: YouTube,
  facebook: Facebook,
  telegram: Telegram,
  ok: Ok,
  instagram: Instagram,
  tiktok: TikTok,
  gitHub: GitHub,
  gitLab: GitLab,
  linkedin: Linkedin,
  pinterest: Pinterest,
  snapchat: Snapchat,
  twitter: Twitter,
  wechat: Wechat,
  whatsapp: Whatsapp,
  other: Other,
};

interface EditableSocialProps {
  isEdit: boolean;
  href: string;
  setActive: React.MouseEventHandler<HTMLDivElement>;
  onDelete: React.MouseEventHandler<HTMLDivElement>;
  isActive: boolean;
}

export const EditableSocial = ({ isEdit: isEditMode, href, isActive, setActive, onDelete }: EditableSocialProps): JSX.Element => {
  const type = formatDomainType(href);

  if (!isEditMode) {
    return (
      <a className={styles.socialIcon} href={href} target="_blank" rel="noreferrer">
        <img title={`${type}: ${href}`} src={icons[type] || icons.other} alt={`${type} logo`} />
      </a>
    );
  } else {
    return (
      <div className={classNames(styles.socialIcon, { [styles.active]: isActive })} onClick={setActive}>
        <div className={styles.delete} onClick={onDelete}>
          <Icon width={10} height={10} iconName="close" />
        </div>
        <img title={`${href}`} src={icons[type] || icons.other} alt={`${type} logo`} />
      </div>
    );
  }
};
