import styles from './StepCredentials.module.scss';
import { Tab } from 'shared/components/common/Tab/Tab';
import { WithoutEmail } from './WithoutEmail';
import { useContext, useState } from 'react';
import GoogleButton from 'common/GoogleButton/GoogleButton';
import { WithEmail } from './WithEmail';
import { UserAgreement } from 'regov/components/Registration/UserAgreement';
import { GlobalContext } from 'contexts/GlobalContext';
import { Button } from 'shared/components/common/Button/Button';
import { useTranslation } from 'react-i18next';

type IStepCredentialsProps = {
  goNext?: () => void;
  goBack?: () => void;
};

export function StepCredentials({ goNext, goBack }: IStepCredentialsProps) {
  const { openModal } = useContext(GlobalContext);
  const [active, setActive] = useState(0);
  const { t } = useTranslation('auth');
  const openDetails = () => {
    openModal({
      params: {
        mainContainer: (
          <div className={styles.description}>
            <UserAgreement />
          </div>
        ),
      },
    });
  };
  const description = [
    <div className={styles.description}>
      <h3>{t('registration.title')}</h3>
      <p>{t('registration.description')}</p>
      <p className={styles.primary}>{t('registration.info')}</p>
    </div>,
    <div className={styles.description}>
      <h3>{t('registrationSSI.title')}</h3>
      <p>{t('registrationSSI.welcomeText')}</p>
      <p>
        <strong>{t('registrationSSI.howItWorks')} </strong>
        {t('registrationSSI.info')}
      </p>
      <Button onClick={openDetails} size="small" color="light">
        {t('registrationSSI.readMore')}
      </Button>
    </div>,
  ];
  return (
    <div className={styles.StepCredentials}>
      <div className={styles.wrapper}>
        <GoogleButton />
        <Tab
          className={styles.content}
          setOutsidePanel={setActive}
          tabPanels={[<WithEmail goBack={goBack} goNext={goNext} />, <WithoutEmail goBack={goBack} />]}
          tabList={[t('tabs.email'), t('tabs.noEmail')]}
        />
      </div>
      {description[active]}
    </div>
  );
}
