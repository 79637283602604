import { useTranslation } from 'react-i18next';
import styles from './GiveSuggestion.module.scss';

type IGiveSuggestionProps = {
  setSuggestionBlockStatus: (status: boolean) => void;
};

export function GiveSuggestion({ setSuggestionBlockStatus }: IGiveSuggestionProps) {
  const { t } = useTranslation('suggestion');
  return (
    <div className={styles.GiveSuggestion}>
      <h3>{t('noMoreSuggestions')}</h3>
    </div>
  );
}
