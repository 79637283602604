import { Redirect, useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import { routes } from 'Routes';
import Navigation from 'common/Navigation/Navigation';
import KbaseGroupContainer, {
  KbaseDataTypes,
  KbaseGroupDirection,
  KbaseVariety,
} from 'pages/KnowledgeBase/KbaseGroupContainer/KbaseGroupContainer';
import { navigationKbase } from 'constans/navigationKbase';
import { RoutingContainer } from 'common/Routing/RoutingContainer/RoutingContainer';
import SEO from 'shared/utils/Seo';
import { useTranslation } from 'react-i18next';

const TagsMaterials = () => {
  const { t } = useTranslation('content');
  function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  }

  const query = useQuery();

  let tags = [];

  const urlTags = query.get('tags')?.split(',') || [];
  tags = urlTags;

  if (!tags.length) {
    return <Redirect to={routes.KNOWLEDGE_BASE_MATERIALS} />;
  }

  return (
    <RoutingContainer showCloseButton title={t('match.article')}>
      <SEO image={'/logo-small.svg'} title={`Материалы по теме: ${tags.join(',')}`} />

      <div>
        <Navigation navigations={navigationKbase()} />

        {!!tags.length && (
          <KbaseGroupContainer type={KbaseDataTypes.all} tags={tags} direction={KbaseGroupDirection.grid} variant={KbaseVariety.material} />
        )}
      </div>
    </RoutingContainer>
  );
};

export default TagsMaterials;
