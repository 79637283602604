import { CustomMap } from 'shared/components/common/CustomMap/CustomMap';
import { NavigationControl, GeolocateControl, Marker } from 'react-map-gl';
import { Icon } from 'shared/components/common/Icon/Icon';
import styles from './EventOnMap.module.scss';
import { useEffect, useState } from 'react';
import type { Map } from 'mapbox-gl';

type Props = {
  point?: {
    lat: number;
    lng: number;
  };
  onChange: (val: { lat: number; lng: number }) => void;
};
const INITIAL_ZOOM = 0.8421037738871756;
const INITIAL_LAT = 46.377544625540565;
const INITIAL_LNG = 40.75580202613284;

export const EventOnMap = ({ point, onChange }: Props) => {
  const [mapState, setMapState] = useState<Map>(undefined);

  useEffect(() => {
    if (mapState && point) {
      mapState.jumpTo({ center: { lat: point.lat, lng: point.lng }, zoom: 15 });
    }
  }, [point]);

  return (
    <div className={styles.wrapper}>
      <CustomMap
        initialViewState={{
          latitude: INITIAL_LAT,
          longitude: INITIAL_LNG,
          zoom: INITIAL_ZOOM,
        }}
        onLoad={(e) => setMapState(e.target)}
        onClick={(e) => {
          onChange(e.lngLat);
        }}
        /* onDragEnd={(ev) => onChange({ lat: ev.viewState.latitude, lng: ev.viewState.longitude })} */
      >
        <NavigationControl position="top-right" />
        <GeolocateControl />
        {point && (
          <Marker longitude={point.lng} latitude={point.lat}>
            <Icon width={32} height={32} color="white" iconName="location" />
          </Marker>
        )}
      </CustomMap>
    </div>
  );
};
