import { useState } from 'react';
import ChatThreadList from '../ChatThreadList';
import styles from './ChatSidebar.module.scss';
import { ReactComponent as AddIcon } from '../../../icons/add-circle.svg';
import { ReactComponent as SettingIcon } from '../../../icons/setting-ring.svg';
import Settings from '../Settings/Settings';
import { Input } from 'shared/components/common/Input/Input';
import UsersModal from '../UsersModal/UsersModal';
import { useDispatch, useSelector } from '../../../../redux-toolkit';
import { setSearchQuery } from 'shared/slices/chat';
import { ChatModalProps } from 'shared/interfaces/chat';
import { Spinner } from 'shared/components/common/Spinner/Spinner';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

interface ChatSidebarProps {
  modalProps: ChatModalProps;
}
const ChatSidebar = ({ modalProps }: ChatSidebarProps) => {
  const { t } = useTranslation('shared');
  const dispatch = useDispatch();
  const [isSettingModalOpened, setIsSettingModalOpened] = useState(false);
  const { searchQuery, organizationId, isThreadsLoaded, activeThreadId } = useSelector((state) => state.chat);
  const handleSearchChange = async (value): Promise<void> => {
    dispatch(setSearchQuery(value));
  };
  if (isSettingModalOpened) {
    return (
      <div className={styles.ChatSidebarContainer}>
        <Settings setIsSettingModalOpened={setIsSettingModalOpened} />
      </div>
    );
  }
  return (
    <div className={`${styles.ChatSidebarContainer} ${activeThreadId ? styles.closeSidebar : styles.openSidebar}`}>
      <div className={styles.ChatSidebarHeader}>
        <div className={styles.wrapperNavChat}>
          {/* <div className={styles.arrowButton}>
            <ArrowButton
              arrowDirection={ArrowDirection.back}
              onClick={() => {
                if (!!modalProps.onMenuClick) {
                  modalProps.onMenuClick(true);
                } else {
                  // eslint-disable-next-line no-restricted-globals
                  history.go(-1);
                }
              }}
            />
          </div> */}

          <div className={styles.Icons}>
            {!!organizationId && (
              <button
                className={styles.Icon}
                onClick={(ev) => {
                  ev.stopPropagation();
                  if (organizationId) {
                    modalProps.openModal(<UsersModal modalProps={modalProps} />, { title: t('chat.allMembers') });
                  } else {
                    modalProps.openModal({ params: { mainContainer: <UsersModal modalProps={modalProps} /> } });
                  }
                }}
              >
                <AddIcon />
              </button>
            )}
            <button className={styles.Icon} onClick={() => setIsSettingModalOpened(true)}>
              <SettingIcon />
            </button>
          </div>
        </div>

        <Input valueChange={handleSearchChange} placeholder={t('search')} value={searchQuery} />
      </div>

      <div className={classNames(styles.ChatSidebarContent, { [styles.spinner]: !isThreadsLoaded })}>
        {isThreadsLoaded ? <ChatThreadList /> : <Spinner />}
      </div>
    </div>
  );
};

export default ChatSidebar;
