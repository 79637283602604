import styles from './ContentEditorSkeleton.module.scss';
import classNames from 'classnames';
import { useContentEditorWrapper } from './hooks/contentEditorWrapper';
import { Icon } from 'shared/components/common/Icon/Icon';
import { Button } from 'shared/components/common/Button/Button';
import { FC, useRef } from 'react';
import { addGeneralImage } from 'services/general';
import { ContentQuillEditor } from '../ContentQuillEditor/ContentQuillEditor';
import { CreationEvent } from 'interfaces/events.interface';
import { FormikErrors } from 'formik';

type Props = {
  ContentSettings: FC<SettingsProps>;
  uploadImage: (image: string | ArrayBuffer | Array<string | ArrayBuffer>) => Promise<{ url: string }[]>;
  onPublish: (orgId: string, val: CreationEvent) => Promise<void>;
  onSave: (orgId: string, val: CreationEvent) => Promise<void>;
  initialValues: CreationEvent;
  onlySettings?: boolean;
};
type SettingsProps = {
  values: CreationEvent;
  setValue: <K extends keyof CreationEvent>(field: K, val: CreationEvent[K]) => void;
  onDraft: () => Promise<void> | void;
  onPublish: () => Promise<void> | void;
  errors: FormikErrors<CreationEvent>;
  administer: Array<{ id: string; short_name: string; avatar?: string }>;
  selectedOrg: string;
  setSelectedOrg: (val: string) => void;
  onDrop: (files: File[]) => Promise<void>;
  setLocalCropper: (val: Cropper) => void;
};

export const ContentEditorSkeleton = ({ ContentSettings, initialValues, onPublish, onSave, uploadImage, onlySettings }: Props) => {
  const scrollingRef = useRef<HTMLDivElement>(null);

  const {
    t,
    isSettingsOpen,
    setIsSettingsOpen,
    isQuillFocused,
    setIsQuillFocused,
    handleClose,
    errors,
    handleSubmitDraft,
    handleSubmitPublish,
    hideSpinner,
    onDrop,
    selectedOrg,
    setFieldValue,
    setLocalCropper,
    setSelectedOrg,
    showSpinner,
    user,
    values,
    administer,
  } = useContentEditorWrapper({
    initialValues,
    onPublish,
    onSave,
    uploadImage,
  });

  return (
    <div className={styles.wrapper}>
      <div className={styles.close} onClick={handleClose}>
        <Icon width={32} height={32} iconName="close" />
      </div>
      <div
        className={classNames(styles.settings, styles.slide, {
          [styles.open]: onlySettings || isSettingsOpen,
        })}
      >
        {
          <ContentSettings
            onDrop={onDrop}
            administer={administer}
            errors={errors}
            onDraft={handleSubmitDraft}
            onPublish={handleSubmitPublish}
            setValue={setFieldValue}
            setSelectedOrg={setSelectedOrg}
            values={values}
            selectedOrg={selectedOrg}
            setLocalCropper={setLocalCropper}
          />
        }
      </div>
      <div className={styles.slide} ref={scrollingRef}>
        <div className={styles.content}>
          <textarea
            className={styles.textarea}
            placeholder={t('inputs.title.placeholder')}
            value={values.title}
            onChange={(e) => {
              setFieldValue('title', e.target.value);
              e.target.style.height = 'auto';
              e.target.style.height = e.target.scrollHeight + 'px';
            }}
            onFocus={() => setIsQuillFocused(false)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                setIsQuillFocused(true);
              }
            }}
          />
          <ContentQuillEditor
            placeholder={t('inputs.decryption.placeholder')}
            initialValue={values.content}
            setValue={(val) => setFieldValue('content', val)}
            focused={isQuillFocused}
            scrollingRef={scrollingRef}
            hideSpinner={hideSpinner}
            showSpinner={showSpinner}
            uploadImage={addGeneralImage}
          />
          <div className={styles.buttons}>
            <Button
              size="small"
              type="button"
              onClick={() => setIsSettingsOpen(true)}
              disabled={
                !user || !user.administer.length || !values.content.length || values.content === '<p><br></p>' || !values.title.length
              }
            >
              {t('create')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
