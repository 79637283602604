import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { handleChatClick } from 'common/ChatButton/ChatButton';
import { GlobalContext } from 'contexts/GlobalContext';
import { GlobalDataContext } from 'contexts/GlobalDataContext';
import { EnterOrganizationStatuses, IOrganisation, IOrganizationEnterRequest } from 'interfaces/organisation.interface';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from 'shared/components/common/Button/Button';
import { Icons } from 'shared/components/common/Icon/Icon.interface';
import formatServerError from 'shared/utils/formatServerError';
import { helpOffersBlockId } from 'common/HelpOffers/HelpOffers';
import styles from './OrganisationProfileProgress.module.scss';
import HelpOffersModal from 'common/HelpOffersModal/HelpOffersModal';
import { RegovOrganizationRequirementsList } from 'regov/components/Organization/Requirement/List';
import { useRegov } from 'regov/context';
import { buildMembershipHelper } from 'regov/models/membership.helper';
import { useTranslation } from 'react-i18next';
import { getRegovPassword, isRegovPasswordSet } from '@owlmeans/regov-lib-react';
import { buildRegovWallet } from 'regov/components/Wallet/wallet.util';
import { Suspensed } from 'components/Suspense/Suspense';

interface IOrganisationProfileProgress {
  id: number;
  requestStatus: IOrganizationEnterRequest;
  setRequestStatus: (status: IOrganizationEnterRequest) => void;
  organization: IOrganisation;
}

type ActionButton = {
  text: string;
  iconName?: keyof typeof Icons;
  onClick: () => void;
};

const OrganisationProfileProgress = ({ requestStatus, setRequestStatus, id, organization }: IOrganisationProfileProgress) => {
  const { t } = useTranslation('profile');
  const history = useHistory();
  const {
    services: { organisationsService, httpService },
    openModal,
    closeModal,
  } = useContext(GlobalContext);
  const { user, selectedOrganisation, organisationMethods, updateUnionActions } = useContext(GlobalDataContext);
  const [actions, setActions] = useState<ActionButton[]>([]);

  const userInOrganisation = requestStatus?.status === EnterOrganizationStatuses.active;
  const isAdmin = userInOrganisation && user?.id === selectedOrganisation?.owner_id;

  const { handler } = useRegov();
  const regovHelper = buildMembershipHelper(organization, handler);
  const [authorized, setAuthorized] = useState(false);

  /**
   * This is a dirty hack that returns the wallet on its place, but it's a good idea
   * to find out why it dissapears
   */
  useEffect(() => {
    if (!handler.wallet && handler.stores['default'] && isRegovPasswordSet()) {
      void (async () => {
        handler.wallet = await buildRegovWallet(getRegovPassword(), handler.stores['default']);
      })();
    }
  }, [handler.wallet, getRegovPassword()]);

  useEffect(() => {
    void (async () => {
      setAuthorized(!regovHelper.isAuthorizationRequired() || (await regovHelper.areRequirementsSatisfied()));
    })();
  }, [userInOrganisation, user?.id, organization?.id, handler.wallet]);

  function openHelpOffersModal() {
    openModal({
      params: {
        mainContainer: <HelpOffersModal unionId={selectedOrganisation.id} />,
      },
    });
  }

  useEffect(() => {
    if (!user) {
      return;
    }
    let actions: ActionButton[] = [];
    let currentUnionActions = user.settings?.unionActions?.find((i) => i.organisationId === id) || {
      chooseHelpOffers: false,
      joinChat: false,
    };

    if (
      // @ts-ignore
      !user.membership.find((org) => org.id === id)?.help_offers?.length &&
      !currentUnionActions.chooseHelpOffers
    ) {
      actions.push({
        text: t('organization.progressBar.getToWork'),
        onClick: () => {
          const helpOffers = document.getElementById(helpOffersBlockId);
          helpOffers.scrollIntoView({ block: 'start', behavior: 'smooth' });

          setTimeout(() => {
            // UX
            // later maybe replace timeout to IntersectionObserver logic
            openHelpOffersModal();
          }, 500);
        },
      });
    }

    if (organization?.chats?.length && !currentUnionActions.joinChat) {
      actions.push({
        text: t('organization.progressBar.chatConnect'),
        onClick: () => {
          handleChatClick(organization, updateUnionActions, openModal);
        },
      });
    }

    setActions(actions);
  }, [user, organization]);

  // if (!actions.length) {
  //   return null;
  // }

  const renderButtonFirstButton = () => {
    switch (requestStatus?.status) {
      case EnterOrganizationStatuses.pending: {
        return (
          <Button
            size="small"
            onClick={async () => {
              try {
                await organisationsService.cancelUnionRequest(id);
                setRequestStatus(null);
                toast(t('toast.cancelledReq'));
              } catch (error) {
                toast.error(formatServerError(error));
              }
            }}
          >
            {t('buttons.cancelReq')}
          </Button>
        );
      }
      case EnterOrganizationStatuses.approved: {
        break;
      }
      case EnterOrganizationStatuses.rejected: {
        return (
          <Button size="small" disabled>
            {t('organization.progressBar.requestDenied')}
          </Button>
        );
      }
      default: {
        if (!userInOrganisation && requestStatus !== undefined && !isAdmin) {
          return (
            <Button
              size="small"
              disabled={regovHelper.isAuthorizationRequired() && regovHelper.isntAuthentiated()}
              onClick={async () => {
                if (authorized && !regovHelper.isAuthorizationRequired()) {
                  const challengeResult = await regovHelper.challengeRequirements({ token: httpService.token });
                  const { status, status_text } = await organisationMethods.enterOrganisation(id, challengeResult);
                  setRequestStatus({ status, request: status_text });
                  toast(t('toast.joinReq'));
                } else {
                  openModal({
                    params: {
                      mainContainer: (
                        <Suspensed fallback={<div>Loading...</div>}>
                          <RegovOrganizationRequirementsList
                            setRequestStatus={setRequestStatus}
                            closeModal={closeModal}
                            openModal={openModal}
                            handler={handler}
                            history={history}
                            organisation={organization}
                          />
                        </Suspensed>
                      ),
                    },
                  });
                }
                /* history.push(routes.enterUnion.getLink(id)); */
              }}
            >
              {authorized
                ? t('organization.progressBar.enter')
                : regovHelper.isntAuthentiated()
                ? t('organization.progressBar.needSSI')
                : t('organization.progressBar.enter')}
            </Button>
          );
        } else {
          return null;
        }
      }
    }
  };

  if (!user) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      {!userInOrganisation ? (
        <div className={styles.buttonWrapper}>
          {renderButtonFirstButton()}
          <span className={styles.arrow}></span>
        </div>
      ) : null}
      {!!actions &&
        !!actions?.length &&
        actions.map((item, index) => {
          return (
            <div key={`enter-button${index}`} className={styles.buttonWrapper}>
              <Button size="small" onClick={item.onClick} color="light" disabled={!userInOrganisation}>
                {index + 1}. {item.text}
              </Button>
              {actions.length !== index + 1 && (
                <span
                  className={classNames(styles.arrow, {
                    [styles.disabledArrow]: !userInOrganisation,
                  })}
                />
              )}
            </div>
          );
        })}
    </div>
  );
};

export default OrganisationProfileProgress;
