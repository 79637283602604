import { PaymentCreated } from 'shared/interfaces/finance';
import { UserId } from 'shared/interfaces/user';
import { NextPrevNewsButtons } from 'shared/interfaces/news';
import { CONTENT_TYPE } from 'interfaces/streamContent';
import { KbaseSection } from 'shared/interfaces/kbase';

export enum EnumContentType {
  //here names should be like in comments name
  news = 'news',
  material = 'kbase/materials',
  suggestion = 'suggestions',
  task = 'desk_tasks',
}

export type ILogo = {
  src: string;
  alt: string;
  href?: string;
};

export interface IContentLayout {
  contentId: number;
  title: string;
  contentType: CONTENT_TYPE;
  user?: UserId;
  published_at?: Date;
  choosePayment?: PaymentCreated;
  content?: string;
  tags?: string[];
  next?: NextPrevNewsButtons;
  prev?: NextPrevNewsButtons;
  category?: string;
  logo?: ILogo;
  commentsCount?: number;
  orgId?: number;
  uniqueContent?: JSX.Element;
  eventExtra?: {
    price?: string;
    place?: string;
    date_start: Date;
    date_end: Date;
    point?: { lat: number; lng: number };
    needRoyPass?: boolean;
    is_active?: boolean;
  };
  materialExtra?: KbaseSection;
}
