import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useCallback, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { GlobalDataContext } from 'contexts/GlobalDataContext';
import { useHistory } from 'react-router-dom';
import { CreationEvent } from 'interfaces/events.interface';
import { imageParser } from 'shared/utils/imageParser';
import { GlobalContext } from 'contexts/GlobalContext';
import formatServerError from 'shared/utils/formatServerError';
import type { AnyObject } from 'yup/lib/types';

type Props = {
  uploadImage: (image: string | ArrayBuffer | Array<string | ArrayBuffer>) => Promise<{ url: string }[]>;
  onPublish: (orgId: string, val: CreationEvent) => Promise<void>;
  onSave: (orgId: string, val: CreationEvent) => Promise<void>;
  initialValues: CreationEvent;
};

export const useContentEditorWrapper = ({ uploadImage, onPublish, onSave, initialValues }: Props) => {
  const { t } = useTranslation('editor');
  const { user } = useContext(GlobalDataContext);
  const {
    spinner: { hideSpinner, showSpinner },
  } = useContext(GlobalContext);
  const history = useHistory();
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isQuillFocused, setIsQuillFocused] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState<string>('');
  const [localCropper, setLocalCropper] = useState<Cropper>(null);

  const validationSchema = Yup.object().shape({
    title: Yup.string().max(255, '').required(t('inputs.errors.required')),
    content: Yup.string().required(t('inputs.errors.required')).max(64999, ''),
    excerpt: Yup.string().required(t('inputs.errors.required')).max(64999, ''),
    link: Yup.string().required(t('inputs.errors.required')).max(64999, ''),
    political: Yup.boolean().required(t('inputs.errors.required')),
    sensitive: Yup.boolean().required(t('inputs.errors.required')),
    preview: Yup.string().required(t('inputs.errors.required')),
    visible: Yup.number().required(t('inputs.errors.required')),
    section: Yup.number().required(t('inputs.errors.required')),
    date_start: Yup.date().min(new Date(), t('inputs.errors.afterNow')).required(t('inputs.errors.required')),
    date_end: Yup.date().when('date_start', (date_start: Date, schema: Yup.DateSchema<Date, AnyObject, Date>) => {
      return date_start ? schema.min(date_start, t('inputs.errors.endAfterStart')) : schema;
    }),
    price: Yup.string(),
    place: Yup.string(),
    point: Yup.mixed().when('place', (place, schema) => {
      return place ? schema.required(t('inputs.errors.required')) : schema;
    }),
    isAgree: Yup.boolean().isTrue(t('inputs.errors.required')).required(t('inputs.errors.required')),
    needRoyPass: Yup.boolean(),
  });

  const { setFieldValue, errors, values } = useFormik({
    validationSchema,
    initialValues,
    initialTouched: {
      title: false,
      content: false,
      excerpt: false,
      political: false,
      sensitive: false,
      preview: false,
      visible: false,
      date_end: false,
      date_start: false,
      section: false,
      place: false,
      point: { lat: false, lng: false },
      price: false,
      tags: false,
    },
    onSubmit: (val, helpers) => {
      hideSpinner();
    },
  });

  const handleSubmitDraft = async () => {
    showSpinner();
    try {
      const image = await uploadCropperImage();
      await onSave(selectedOrg, { ...values, preview: image || undefined });
      setIsSettingsOpen(false);
      localStorage.removeItem('editorDecryption');
      localStorage.removeItem('editorTitle');
    } catch (error) {
      toast(formatServerError(error));
    }

    hideSpinner();
  };
  const handleSubmitPublish = async () => {
    showSpinner();
    try {
      const image = await uploadCropperImage();
      await onPublish(selectedOrg, { ...values, preview: image || undefined });
      localStorage.removeItem('editorDecryption');
      localStorage.removeItem('editorTitle');
    } catch (error) {
      toast(formatServerError(error));
    }
    hideSpinner();
  };

  const uploadCropperImage = async () => {
    if (
      values.preview &&
      (values.preview.startsWith('https://img.youtube.com/vi/') ||
        values.preview.startsWith('/static') ||
        values.preview.startsWith('/images'))
    ) {
      return;
    }
    if (localCropper) {
      const image = localCropper.getCroppedCanvas();
      if (!image) {
        return;
      }
      const { url } = (await uploadImage(image.toDataURL()))[0];
      return url;
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const content = localStorage.getItem('editorDecryption');
      const title = localStorage.getItem('editorTitle');
      if (content) {
        setFieldValue('content', content);
      }
      if (title) {
        setFieldValue('title', title);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setFieldValueHandler: <K extends keyof CreationEvent>(field: K, val: CreationEvent[K]) => void = (field, val) => {
    setFieldValue(field, val);
    if (typeof window !== 'undefined' && field === 'content' && typeof val === 'string') {
      try {
        localStorage.setItem('editorDecryption', val);
      } catch (error) {
        console.error(error);
      }
    }
    if (typeof window !== 'undefined' && field === 'title' && typeof val === 'string') {
      try {
        localStorage.setItem('editorTitle', val);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleClose = () => {
    if (isSettingsOpen) {
      setIsSettingsOpen(false);
    } else {
      history.goBack();
    }
  };

  const parseContent = useCallback(() => {
    const imageSet = new Set([...(values.preview ? [values.preview] : []), ...imageParser(values.content)]);
    const imageList = Array.from(imageSet);
    if (!values.preview) {
      setFieldValue('preview', imageList[0]);
    }
  }, [setFieldValue, values.content, values.preview]);

  const setIsSettingsOpenHandler = (val: boolean) => {
    setIsSettingsOpen(val);
    if (val) {
      parseContent();
      if (!selectedOrg && user && user.administer && user.administer.length) {
        setSelectedOrg(String(user.administer[0].id));
      }
    }
  };
  const onDrop = useCallback(
    async (files: File[]) => {
      showSpinner();
      if (files.length) {
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onloadend = async () => {
          try {
            setFieldValue('preview', reader.result as string);
          } catch (error) {
            toast.error(formatServerError(error));
            console.error(error);
          }
        };
        hideSpinner();
      }
    },
    [setFieldValue]
  );

  const administer: Array<{ id: string; short_name: string; avatar?: string }> =
    user?.administer?.map((el) => {
      return {
        id: String(el.id),
        short_name: el.short_name,
        avatar: el.avatar,
      };
    }) || [];
  return {
    handleSubmitDraft: handleSubmitDraft,
    handleSubmitPublish: handleSubmitPublish,
    setFieldValue: setFieldValueHandler,
    errors,
    values,
    t,
    isSettingsOpen,
    setIsSettingsOpen: setIsSettingsOpenHandler,
    isQuillFocused,
    setIsQuillFocused,
    handleClose,
    user,
    selectedOrg,
    setSelectedOrg,
    onDrop,
    setLocalCropper,
    hideSpinner,
    showSpinner,
    administer,
  };
};
