import { OrganizationShort } from 'shared/interfaces/organization';

export interface StreamContentInterface {
  created_at: string;
  id: number;
  image?: string;
  published?: boolean;
  published_at: Date;
  title: string;
  updated_at?: string;
  author: Author;
  likes: number;
  watches: number;
  commentsCount?: number;
  excerpt: string;
  tags: string[];
  organization: OrganizationShort;
}

export interface Author {
  id: number;
  public_avatar?: string;
  public_family?: string;
  public_name?: string;
}

export enum StreamContentType {
  NEWS = 'Новость',
  ARTICLE = 'Статья',
  EVENT = 'Мероприятие',
  TASK = 'Задача',
  SURVEY = 'Опрос',
  DONATE = 'Сбор средств',
  ADVERTISEMENT = 'Объявление',
  COMMUNITY = 'Сообщество',
  VACANCY = 'Вакансия',
}

export const contentType: Record<CONTENT_TYPE, { color: string }> = {
  news: { color: 'red' },
  material: { color: '#A21DCF' },
  event: { color: '#06BAAB' },
  task: { color: '#FF7C01' },
  quiz: { color: '#50C0F9' },
  DONATE: { color: '#79AA15' },
  ADVERTISEMENT: { color: '#FFBB00' },
  COMMUNITY: { color: '#6D6FFF' },
  VACANCY: { color: '#7F3F26' },
  SECTIONS: { color: '#E6E8F2' },
  suggestion: { color: '#a7eda5' },
  post: { color: '#FF00D6' },
};

export type CONTENT_TYPE =
  | 'news'
  | 'material'
  | 'event'
  | 'task'
  | 'quiz'
  | 'DONATE'
  | 'ADVERTISEMENT'
  | 'COMMUNITY'
  | 'VACANCY'
  | 'suggestion'
  | 'SECTIONS'
  | 'post';

export type ActivityType = 'news' | 'event' | 'material' | 'quiz' | 'suggestion' | 'task' | 'post';
