import { useEffect } from 'react';
import { CloseButton } from '../common/CloseButton/CloseButton';
import styles from './ModalWindow.module.scss';
import { isSSR } from '../../services/helper';

type UserModalProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  children?: JSX.Element;
  width?: string;
  height?: string;
};

export function ModalWindow({ isOpen, setIsOpen, children, height, width }: UserModalProps) {
  const closeModal = () => setIsOpen(false);

  const eventClose = (ev: KeyboardEvent) => {
    if (ev.code === 'Escape') {
      setIsOpen(false);
      window.removeEventListener('keydown', eventClose);
    }
  };

  useEffect(() => {
    if (!isSSR()) {
      document.body.style.overflowY = 'hidden';

      window.addEventListener('keydown', eventClose);

      return () => {
        document.body.style.overflowY = 'auto';

        window.removeEventListener('keydown', eventClose);
      };
    }
  }, []);

  return (
    <div className={styles.box_shadow} style={{ display: !isOpen ? 'none' : undefined }} onClick={closeModal}>
      <div className={styles.scroll_container}>
        <section
          className={styles.modal_wrapper}
          onClick={(e) => e.stopPropagation()}
          style={{
            height: height || 'fit-content',
            width: width || 'auto',
          }}
        >
          <CloseButton size="large" className={styles.close_btn} onClick={closeModal} />
          {children}
        </section>
      </div>
    </div>
  );
}
