import { Icons } from 'shared/components/common/Icon/Icon.interface';
import { ILinkListList } from 'common/LinkList/LinkList';
import { IGlobalDataState } from 'contexts/GlobalDataContext';
import { routes } from 'Routes';
import NotificationBell from 'components/notifications/NotificationBell/NotificationBell';
import { IUserMenuModel } from 'components/NavigationBar/UserNavigation/UserMenu/UserMenu';
import ChatBell from 'components/ChatBell/ChatBell';
import { SSIProfileWidget } from 'regov/components/Wallet/ProfileWidget';
import { regovConfig } from 'regov';
export class NavigationBuilder {
  // private isAlertProgress(globalDataState: IGlobalDataState): boolean {
  //   return false;
  // }
  // private isAlertChat(globalDataState: IGlobalDataState): boolean {
  //   return false;
  // }

  public getUserMenuStructure = (globalDataState: IGlobalDataState): IUserMenuModel => [
    {
      title: 'pages.calendar',
      isActive: true,
      icon: Icons.calendar,
      link: routes.CALENDAR,
    },
    {
      title: 'pages.techSupport',
      isActive: true,
      icon: Icons.polls,
      link: routes.chat + `?id=${process.env.REACT_APP_SUPPORT_ORG_ID}&type=organization`,
    },
    {
      title: 'buttons.notification',
      isActive: true,
      Component: NotificationBell,
    },
    {
      title: 'pages.chat',
      isActive: true,
      Component: ChatBell,
    },
    // {
    //   id: 'news-suggest',
    //   title: 'Предложить',
    //   isActive: true,
    //   icon: Icons.news,
    //   dataCy: 'user-menu-btn',
    // },
    // {
    //   title: 'мой прогресс',
    //   isActive: false,
    //   link: '#',
    //   icon: this.isAlertProgress(glogalDataState) ? Icons.notificationFavoriteChart : Icons.favoriteChart,
    // },

    // {
    //   title: 'чат',
    //   isActive: false,
    //   icon: this.isAlertChat(glogalDataState) ? Icons.notificationChat : Icons.chat,
    //   link: '#',
    // },
    // {
    //   title: 'новость',
    //   isActive: false,
    //   icon: Icons.news,
    //   link: '#',
    // },

    // {
    //   title: 'Админка',
    //   id: 'admin',
    //   dataCy: 'admin-menu-btn',
    //   isActive: true,
    //   icon: Icons.securitySafe,
    //   action: () => {
    //     window.dataLayer.push({
    //       event: 'event',
    //       eventProps: {
    //         category: 'user',
    //         action: 'click',
    //         label: 'Admin dashboard button',
    //       },
    //     });
    //     window.open(`${REACT_APP_ADMIN_URL}/${globalDataState.selectedOrganisationId}/union`);
    //   },
    // },
    // {
    //   title: 'Данные',
    //   isActive: true,
    //   icon: Icons.briefcase,
    //   link: routes.EDIT_USER_PROFILE,
    // },
    // {
    //   title: 'Создать',
    //   isActive: true,
    //   icon: Icons.edit,
    //   link: routes.CREATE_UNION,
    // },
    ...(regovConfig.enabled
      ? [
          {
            title: 'buttons.ssiWallet',
            isActive: true,
            Component: SSIProfileWidget,
          },
        ]
      : []),
  ];

  public getTopMenuStructure = (globalDataState: IGlobalDataState): IUserMenuModel => [
    // {
    //   title: 'Моя страница',
    //   isActive: true,
    //   link: routes.PROFILE,
    //   icon: Icons.user,
    //   dataCy: 'user-menu-btn',
    // },
    // {
    //   title: 'Сообщество',
    //   isActive: true,
    //   dataCy: 'union-menu-btn',
    //   link: routes.UNION,
    //   icon: Icons.union,
    //   id: 'union',
    // },
  ];

  public getUserContentStructure = (): ILinkListList => [
    // {
    //   title: 'Блог',
    //   isActive: false,
    //   icon: Icons.teacher,
    //   link: '#',
    // },
    // {
    //   title: 'Все cобытия',
    //   isActive: false,
    //   icon: Icons.noteAdd,
    //   link: '#',
    // },
    // {
    //   title: 'Все финансы',
    //   isActive: false,
    //   icon: Icons.presentionChart,
    //   link: '#',
    // },
    // {
    //   title: 'Все сообщества',
    //   dataCy: 'all-unions-btn',
    //   isActive: true,
    //   icon: Icons.taskSquare,
    //   link: routes.UNIONS,
    // },
    // {
    //   title: 'Создать сообщества',
    //   isActive: true,
    //   icon: Icons.edit,
    //   link: routes.CREATE_UNION,
    // },
    // {
    //   title: 'Все новости',
    //   isActive: true,
    //   icon: Icons.lampOn,
    //   link: routes.NEWS,
    // },
    // {
    //   title: 'Блог',
    //   isActive: true,
    //   icon: Icons.teacher,
    //   link: routes.KNOWLEDGE_BASE_MATERIALS,
    // },
    // {
    //   title: 'Помощь нашей команде!',
    //   isActive: true,
    //   icon: Icons.finance,
    //   link: routes.SUPPORT_US,
    // },
  ];

  public getFooterContentStructure = (logoutAction: () => void): ILinkListList => {
    return [
      // {
      //   title: 'Контакты',
      //   isActive: true,
      //   icon: Icons.contacts,
      //   link: routes.CONTACT_US,
      // },
      {
        title: 'pages.security',
        isActive: true,
        icon: Icons.security,
        link: routes.SECURITY,
      },
      {
        title: 'pages.settings',
        isActive: true,
        icon: Icons.setting,
        link: routes.settings,
      },
      {
        title: 'buttons.logout',
        isActive: true,
        icon: Icons.out,
        action: logoutAction,
      },
    ];
  };
}
