import classNames from 'classnames';
import styles from './ProfileModalTabs.module.scss';
import { useHistory } from 'react-router-dom';
import { GlobalContext } from 'contexts/GlobalContext';
import { routes } from 'Routes';
import { useContext, useState } from 'react';
import { useSsrState } from '@issr/core';
import { ProfileModalItem } from '../ProfileModalItem/ProfileModalItem';
import { IOrganisation } from 'interfaces/organisation.interface';
import { UserShort } from 'shared/interfaces/user';
import { Icon } from 'shared/components/common/Icon/Icon';
import { TextField } from 'shared/components/common/TextField/TextField';
import { useTranslation } from 'react-i18next';

//ToDo: fix type
type SubscribesTabProps = {
  organizations: Array<Partial<IOrganisation>>;
  people: Array<Partial<UserShort>>;
};

export const SubscribesTab = ({ organizations, people }: SubscribesTabProps) => {
  const { t } = useTranslation('modals');
  const maxLength = 3;
  const [searchValue, setSearchValue] = useSsrState('');
  const [isOrganizationsOpen, setIsOrganizationsOpen] = useState(false);
  const [isPeopleOpen, setIsPeopleOpen] = useState(false);
  const history = useHistory();
  const { closeModal } = useContext(GlobalContext);
  const action = (id: number) => {
    history.push(`${routes.chat}?id=${id}&type=organization`);
    closeModal();
  };

  const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (ev) => {
    if (ev.code === 'Esc') {
      setSearchValue('');
    }
  };

  const organizationsToRender = organizations.filter((val) => {
    return val.short_name.toLowerCase().includes(searchValue.toLowerCase());
  });
  const peopleToRender = people.filter((val) => {
    return val.public_name.toLowerCase().includes(searchValue.toLowerCase());
  });

  return (
    <div className={classNames(styles.wrapper)}>
      <TextField
        variant="text"
        value={searchValue}
        setValue={setSearchValue}
        helperText={t('controls.search.helperText')}
        placeholder={t('controls.search.placeholder')}
        onKeyDown={onKeyDown}
        maxLength={256}
      />
      <div className={classNames(styles.wrapper)}>
        <div
          className={classNames({ [styles.openBtn]: organizations.length > maxLength })}
          onClick={() => setIsOrganizationsOpen(!isOrganizationsOpen)}
        >
          <h5>{`${t('subscribes.organizations')} ${organizationsToRender.length}`}</h5>
          {organizationsToRender.length > maxLength && (
            <Icon rotate={isOrganizationsOpen ? -90 : 90} width={24} height={24} iconName="arrowRight" />
          )}
        </div>
        {organizationsToRender.slice(0, isOrganizationsOpen ? organizations.length : maxLength).map((el, index) => {
          return (
            <ProfileModalItem
              isOrganization={true}
              id={el.id}
              image={el.avatar}
              name={el.name}
              action={() => {
                action(el.id);
              }}
              /* isCollapsible */
              iconName="chat"
              actionTitle={t('icons.chat')}
              key={`subscribes-organizations-${el.id}-${index}`}
            />
          );
        })}
      </div>
      <div className={classNames(styles.wrapper)}>
        <div className={classNames({ [styles.openBtn]: people.length > maxLength })} onClick={() => setIsPeopleOpen(!isPeopleOpen)}>
          <h5>{`${t('subscribes.users')} ${peopleToRender.length}`}</h5>
          {peopleToRender.length > maxLength && <Icon rotate={isPeopleOpen ? -90 : 90} width={24} height={24} iconName="arrowRight" />}
        </div>
        {peopleToRender.slice(0, isPeopleOpen ? people.length : maxLength).map((el, index) => {
          return (
            <ProfileModalItem
              isOrganization={false}
              id={el.id}
              image={el.public_avatar}
              name={el.public_name}
              action={() => {
                action(el.id);
              }}
              /* isCollapsible */
              iconName="chat"
              actionTitle={t('icons.chat')}
              key={`subscribes-people-${el.id}-${index}`}
            />
          );
        })}
      </div>
    </div>
  );
};
