import { useTranslation } from 'react-i18next';
import styles from './Plan.module.scss';

export type PlanType = { text: string; year: number; plan: number };

export const Plan = ({ year, text, plan }: PlanType) => {
  const { t } = useTranslation('home');
  return (
    <div className={styles.wrapper}>
      <div className={styles.year}>{year}</div>
      <div className={styles.plan}>
        {plan}
        {` ${t('users')}`}
      </div>
      <div className={styles.text}>{text}</div>
    </div>
  );
};
