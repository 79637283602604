import InfiniteScroll from 'react-infinite-scroll-component';
import { Spinner } from 'common/Spinner/Spinner';
import styles from './InfiniteScrollList.module.scss';
import cn from 'classnames';

type InfiniteScrollListProps = {
  listContent: JSX.Element | any;
  dataLength?: number;
  dontFindData?: string;
  endMessage?: string;
  hasMore?: boolean;
  next?: () => void;
  scrollableTarget?: string; //id container
  endMessageComponent?: React.ReactNode;
  className?: string;
};

const InfiniteScrollList = ({
  dataLength,
  next,
  hasMore,
  listContent,
  endMessage,
  dontFindData,
  scrollableTarget,
  endMessageComponent,
  className,
}: InfiniteScrollListProps): JSX.Element => {
  return !dataLength ? (
    <h4 className={styles.dontFindData}>{dontFindData}</h4>
  ) : (
    <InfiniteScroll
      scrollableTarget={scrollableTarget}
      dataLength={dataLength}
      next={next}
      className={cn(styles.infinityScroll, className)}
      hasMore={hasMore}
      loader={
        <div className={styles.spinnerWrapper}>
          <Spinner size={40} />
        </div>
      }
      endMessage={endMessageComponent || <h4 className={styles.dontFindData}>{endMessage}</h4>}
    >
      {listContent}
    </InfiniteScroll>
  );
};

export default InfiniteScrollList;
