import { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { LS_KEY } from '../../../constants/lsKey';
import styles from './Feedback.module.scss';
import { useTranslation } from 'react-i18next';

export function checkIfShowFeedbackModal() {
  const lastDate = localStorage?.getItem(LS_KEY);

  if (lastDate) {
    return Date.now() - +lastDate >= 1000 * 60 * 60 * 24 * 7; // 1 week
  }

  return true;
}

function Feedback({ chatLink, closeModal }: { chatLink: string; closeModal: () => void }) {
  const { t } = useTranslation('shared');
  useEffect(() => {
    localStorage?.setItem(LS_KEY, String(Date.now()));
  }, []);

  const history = useHistory();

  return (
    <div className={styles.wrapper}>
      <h3>{t('feedBack.title')}</h3>
      <p>
        {`${t('feedBack.we')} `}
        <br />
        {`${t('feedBack.letter')} `}
        <Link
          to={chatLink}
          onClick={() => {
            closeModal();

            // blocking navigation until modal is open, maybe need fix
            setTimeout(() => {
              history.push(chatLink + `?id=${process.env.REACT_APP_SUPPORT_ORG_ID}&type=organization`);
            }, 100);
          }}
        >
          {t('feedBack.chat')}
        </Link>{' '}
        {t('feedBack.offers')}
        <ul>
          <li>{t('feedBack.like')}</li>
          <li>{t('feedBack.change')}</li>
          <li>{t('feedBack.missing')}</li>
        </ul>
        {`${t('feedBack.please')} `}
        <br />
        {t('feedBack.thnx')}
      </p>

      {/* <video width="100%" controls>
        <source src={require('./assets/chat.mp4').default} type="video/mp4" />
        Your browser does not support the video tag.
        <img src={require('./assets/chat.png').default} alt="Скриншот нахождения чата" />
      </video> */}
    </div>
  );
}

export default Feedback;
