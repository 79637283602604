import styles from './StreamPostCard.module.scss';
import { Author, CONTENT_TYPE } from 'interfaces/streamContent';
import { CustomImage } from 'shared/components/common/CustomImage/CustomImage';
import { useContext, useState, useRef, useEffect } from 'react';
import Tags from 'common/Tags/Tags';
import { Link, useLocation } from 'react-router-dom';
import { routes } from 'Routes';
import { Icon } from 'shared/components/common/Icon/Icon';
import { formatDistanceToNow } from 'date-fns';
import { ru, be, enGB, lt, uk } from 'date-fns/locale';
import { EnumContentType } from 'containers/ContentLayout/ContentLayoutTypes';
import { ImagesSlider } from '../../ImagesSlider/ImagesSlider';
import { FooterItem, StreamCardFooter } from 'components/Crads/StreamCardFooter/StreamCardFooter';
import sanitizeHtml from 'sanitize-html';
import { allowedAttributesSchema, allowedTagsSynitizer, allowedIframeHostnamesSchema } from 'shared/constants/allowedTagsSynitizer';
import { GlobalDataContext } from 'contexts/GlobalDataContext';
import { deletePost } from 'services/feed';
import { toast } from 'react-toastify';
import formatServerError from 'shared/utils/formatServerError';
import { Button } from 'shared/components/common/Button/Button';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

type StreamPostCardProps = {
  type: CONTENT_TYPE;
  id: number;
  images?: Array<string>;
  author: Author;
  excerpt: string;
  commentsCount: number;
  tags: Array<string>;
  watches?: number;
  published_at: Date;
  updateFeed?: () => void;
};

export const StreamPostCard = ({
  author,
  commentsCount,
  excerpt,
  id,
  published_at,
  tags,
  type,
  images,
  watches,
  updateFeed,
}: StreamPostCardProps): JSX.Element => {
  const { t, i18n } = useTranslation('profile');
  const locales = {
    ru,
    be,
    enGB,
    lt,
    uk,
  };
  const { user } = useContext(GlobalDataContext);
  const location = useLocation();
  const [showHashtag, setShowHashtag] = useState(true);
  const [showActions, setShowActions] = useState(false);
  const [showTextButton, setShowTextButton] = useState(false);
  const [showFullText, setShowFullText] = useState(false);
  const containerRef = useRef(null);
  const isAuthor = user?.id === author?.id;

  useEffect(() => {
    if (containerRef.current.scrollHeight > containerRef.current.offsetHeight) {
      setShowTextButton(true);
    } else {
      setShowFullText(true);
    }
  }, []);

  const icons: FooterItem[] = [
    {
      iconName: 'clock',
      content: formatDistanceToNow(new Date(published_at), {
        locale: locales[i18n.language],
        addSuffix: false,
      }),
    },
    // {iconName: 'like', content: `${likes}`},
    // { iconName: 'eye', content: `${watches}` },
    { iconName: 'comments', content: `${commentsCount}` },
    {
      iconName: 'hashtag',
      onClick: () => setShowHashtag(!showHashtag),
      content: <Icon rotate={showHashtag ? 180 : 0} iconName="arrowBottom" />,
    },
    // {
    //   iconName: 'link',
    //   onClick: (e) => {
    //     e.preventDefault();
    //     e.stopPropagation();
    //     navigator.clipboard.writeText(`${process.env.REACT_APP_BASE_URL}${routes.newsDetails.getLink(organization.id, id)}`);
    //     toast(t('toast.copyLink'));
    //   },
    // },
  ];
  const onDelete = async () => {
    try {
      await deletePost(String(id));
      toast(t('toast.deletePost'));
      updateFeed && updateFeed();
    } catch (e) {
      toast(formatServerError(e));
    }
  };
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.author}>
            <Link
              to={{
                pathname: routes.profile.getLink(author.id),
                state: {
                  from: location.pathname,
                },
              }}
              className={styles.imageLink}
            >
              <CustomImage
                isOrganization
                src={author.public_avatar}
                alt={author.public_name}
                width={55}
                height={55}
                className={styles.image}
              />
            </Link>
            <div className={styles.title}>
              <Link
                to={{
                  pathname: routes.profile.getLink(author.id),
                  state: {
                    from: location.pathname,
                  },
                }}
              >
                <h4>{author.public_name}</h4>
              </Link>
            </div>
          </div>
          {isAuthor && (
            <div
              className={styles.actionsButton}
              onBlur={() => {
                setShowActions(false);
              }}
            >
              <div className={styles.actionsIcon} onClick={(ev) => setShowActions(!showActions)}>
                <Icon iconName={showActions ? 'circleClose' : 'properties'} />
              </div>
              {showActions && (
                <div className={styles.actionsPanel}>
                  <Button size="small" color="danger" className={classNames(styles.button, styles.delete)} onClick={onDelete}>
                    <Icon iconName={'trash'} />
                    {t('buttons.deletePost')}
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
        <div className={classNames(styles.content, { [styles.visible]: showFullText })} ref={containerRef}>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(excerpt, {
                allowedTags: allowedTagsSynitizer,
                allowedAttributes: allowedAttributesSchema,
                allowedIframeHostnames: allowedIframeHostnamesSchema,
              }),
            }}
          />
          {showTextButton && (
            <>
              <div
                className={classNames(styles.showBtn, { [styles.visible]: showFullText })}
                onClick={() => setShowFullText(!showFullText)}
              >
                <span>{showFullText ? t('buttons.hide') : t('buttons.show')}</span>
              </div>
              <div className={styles.bottomShadow} />
            </>
          )}
        </div>
        {!!images.length && <ImagesSlider key={`${type}-${id}-slider`} type={type} images={images} id={String(id)} />}
        <StreamCardFooter icons={icons} />
        {showHashtag && <Tags tags={[type, ...tags]} contentTypes={EnumContentType[type]} />}
      </div>
    </>
  );
};
