import { httpService } from 'services';

export function getNotifications() {
  return httpService.axios.get('/me/notifications', {
    params: {
      status: 0,
    },
  });
}

export function getNotification(id) {
  return httpService.axios.get(`/me/notifications/${id}`);
}

export const ReadAllNotifications = async (notifications: Array<string | number>) => {
  await httpService.axios.post('/me/notifications/read', { ids: notifications });
};
