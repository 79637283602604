import type { FC } from 'react';
import { detachUsers } from 'shared/services/tasks.service';
import styles from './Users.module.scss';
import { UserShort } from 'shared/interfaces/user';
import { CustomImage } from 'shared/components/common/CustomImage/CustomImage';
import Tooltip from 'shared/components/common/Tooltip/Tooltip';
import { Icon } from 'shared/components/common/Icon/Icon';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

interface Props {
  user: UserShort;
  cardId: string;
  onUsersDetach: (users: UserShort[]) => void;
  orgId: string;
}

const UsersAvatar: FC<Props> = ({ user, cardId, onUsersDetach, orgId }) => {
  const { t } = useTranslation('shared');
  return (
    <div className={styles.wrapper}>
      <div
        className={styles.deleteIcon}
        onClick={async () => {
          const {
            data: {
              data: { users },
            },
          } = await detachUsers(orgId, cardId, [String(user.id)]);
          onUsersDetach(users);
          toast(t('toast.deleteUser'));
        }}
        title={t('actions.delete')}
        data-tip
        data-for={`deleteFromTask${user.id}`}
      >
        <Icon iconName="close" width="12px" height="15px" />
      </div>
      <Tooltip title={t('kanban.user.delete')} id={`deleteFromTask${user.id}`}></Tooltip>

      <div className={styles.imgWrapper} data-tip data-for={`userTaskTooltip${user.id}`}>
        <CustomImage key={user.id} src={user.public_avatar} width={40} height={40} alt="" />
      </div>

      <Tooltip title={`${user.public_name}`} id={`userTaskTooltip${user.id}`} />
    </div>
  );
};

export default UsersAvatar;
