import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import styles from './PublicationOrgSelection.module.scss';
import { SelectedUnion } from './SelectedUnion/SelectedUnion';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'shared/components/common/Spinner/Spinner';

type Props = {
  administer: Array<{ id: string; short_name: string; avatar?: string }>;
  selectedOrg: string;
  setSelectedOrg: (val: string) => Promise<void> | void;
};

export const PublicationOrgSelection = ({ administer, selectedOrg, setSelectedOrg }: Props) => {
  const { t } = useTranslation('editor');
  return (
    <div className={styles.wrapper}>
      <FormControl fullWidth>
        <InputLabel id="organization-id-selection">{t('organization')}</InputLabel>
        <Select
          labelId="organization-id-selection"
          value={selectedOrg}
          label={t('organization')}
          required
          onChange={(ev) => {
            console.log(String(ev.target.value));
            setSelectedOrg(ev.target.value);
          }}
        >
          {!administer?.length ? (
            <Spinner position="center" color="purple" size={24} />
          ) : (
            administer.map((el) => {
              return (
                <MenuItem value={el.id} key={`org-${el.id}`}>
                  <SelectedUnion short_name={el.short_name} avatar={el.avatar} />
                </MenuItem>
              );
            })
          )}
        </Select>
      </FormControl>
    </div>
  );
};
