import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
// home
import ltHome from './locales/lt/homepage.json';
import ruHome from './locales/ru/homepage.json';
import enHome from './locales/en/homepage.json';
import ukHome from './locales/uk/homepage.json';
import beHome from './locales/be/homepage.json';
import plHome from './locales/pl/homepage.json';
// global
import enGlobal from './locales/en/global.json';
import ruGlobal from './locales/ru/global.json';
import beGlobal from './locales/be/global.json';
import ltGlobal from './locales/lt/global.json';
import plGlobal from './locales/pl/global.json';
import ukGlobal from './locales/uk/global.json';
// auth
import ruAuth from './locales/ru/authpage.json';
import enAuth from './locales/en/authpage.json';
import beAuth from './locales/be/authpage.json';
import plAuth from './locales/pl/authpage.json';
import ltAuth from './locales/lt/authpage.json';
import ukAuth from './locales/uk/authpage.json';
// profile
import ruProfile from './locales/ru/profile.json';
import enProfile from './locales/en/profile.json';
import beProfile from './locales/be/profile.json';
import ltProfile from './locales/lt/profile.json';
import ukProfile from './locales/uk/profile.json';
import plProfile from './locales/pl/profile.json';
// ssi
import enSSI from './locales/en/ssi.json';
import ruSSI from './locales/ru/ssi.json';
import beSSI from './locales/be/ssi.json';
import plSSI from './locales/pl/ssi.json';
import ltSSI from './locales/lt/ssi.json';
import ukSSI from './locales/uk/ssi.json';
// modals
import enModals from './locales/en/modals.json';
import ruModals from './locales/ru/modals.json';
import beModals from './locales/be/modals.json';
import plModals from './locales/pl/modals.json';
import ltModals from './locales/lt/modals.json';
import ukModals from './locales/uk/modals.json';
// payment
import ruPayment from './locales/ru/payment.json';
import enPayment from './locales/en/payment.json';
import bePayment from './locales/be/payment.json';
import plPayment from './locales/pl/payment.json';
import ltPayment from './locales/lt/payment.json';
import ukPayment from './locales/uk/payment.json';
// map
import ruMap from './locales/ru/map.json';
import enMap from './locales/en/map.json';
import beMap from './locales/be/map.json';
import ltMap from './locales/lt/map.json';
import ukMap from './locales/uk/map.json';
import plMap from './locales/pl/map.json';
// shared
import plShared from 'shared/i18next/pl/shared.json';
import ltShared from 'shared/i18next/lt/shared.json';
import ruShared from 'shared/i18next/ru/shared.json';
import enShared from 'shared/i18next/en/shared.json';
import ukShared from 'shared/i18next/uk/shared.json';
import beShared from 'shared/i18next/be/shared.json';
// Privacy
import enPrivacy from './locales/en/privacy.json';
import ruPrivacy from './locales/ru/privacy.json';
// rules
import enRules from './locales/en/rules.json';
import ruRules from './locales/ru/rules.json';
// news
import enNews from './locales/en/news.json';
import ruNews from './locales/ru/news.json';
import beNews from './locales/be/news.json';
import plNews from './locales/pl/news.json';
import ltNews from './locales/lt/news.json';
import ukNews from './locales/uk/news.json';
// events
import enEvents from './locales/en/events.json';
import ruEvents from './locales/ru/events.json';
import beEvents from './locales/be/events.json';
import plEvents from './locales/pl/events.json';
import ltEvents from './locales/lt/events.json';
import ukEvents from './locales/uk/events.json';
// kbase
import ruKbase from './locales/ru/kbase.json';
import beKbase from './locales/be/kbase.json';
import enKbase from './locales/en/kbase.json';
import ltKbase from './locales/lt/kbase.json';
import ukKbase from './locales/uk/kbase.json';
import plKbase from './locales/pl/kbase.json';
// polls
import enPolls from './locales/en/polls.json';
import ruPolls from './locales/ru/polls.json';
import bePolls from './locales/be/polls.json';
import plPolls from './locales/pl/polls.json';
import ltPolls from './locales/lt/polls.json';
import ukPolls from './locales/uk/polls.json';
// Security
import ruSecurity from './locales/ru/security.json';
import enSecurity from './locales/en/security.json';
import beSecurity from './locales/be/security.json';
import plSecurity from './locales/pl/security.json';
import ltSecurity from './locales/lt/security.json';
import ukSecurity from './locales/uk/security.json';
// setting
import enSettings from './locales/en/settings.json';
import ruSettings from './locales/ru/settings.json';
import beSettings from './locales/be/settings.json';
import plSettings from './locales/pl/settings.json';
import ltSettings from './locales/lt/settings.json';
import ukSettings from './locales/uk/settings.json';
//Suggestion
import ruSuggestion from './locales/ru/suggestion.json';
import enSuggestion from './locales/en/suggestion.json';
import beSuggestion from './locales/be/suggestion.json';
import ltSuggestion from './locales/lt/suggestion.json';
import ukSuggestion from './locales/uk/suggestion.json';
import plSuggestion from './locales/pl/suggestion.json';
// content
import ruContent from './locales/ru/content.json';
import enContent from './locales/en/content.json';
import beContent from './locales/be/content.json';
import ukContent from './locales/uk/content.json';
import ltContent from './locales/lt/content.json';
import plContent from './locales/pl/content.json';
// CreateUnion
import enCreateUnion from './locales/en/createUnion.json';
import beCreateUnion from './locales/be/createUnion.json';
import ltCreateUnion from './locales/lt/createUnion.json';
import ukCreateUnion from './locales/uk/createUnion.json';
import ruCreateUnion from './locales/ru/createUnion.json';
import plCreateUnion from './locales/pl/createUnion.json';
//regov тут скорее всего надо будет переделывать
import beMainSSI from './locales/be/mainSSI.json';
import ruMainSSI from './locales/ru/mainSSI.json';
import plMainSSI from './locales/pl/mainSSI.json';
import ukMainSSI from './locales/uk/mainSSI.json';
import ltMainSSI from './locales/lt/mainSSI.json';
import enMainSSI from './locales/en/mainSSI.json';
// editor
import ruEditor from 'shared/i18next/ru/editor.json';
import enEditor from 'shared/i18next/en/editor.json';
import beEditor from 'shared/i18next/be/editor.json';
import ukEditor from 'shared/i18next/uk/editor.json';
import ltEditor from 'shared/i18next/lt/editor.json';
import plEditor from 'shared/i18next/pl/editor.json';

const isProduction = process.env.REACT_APP_ENV === 'production';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    react: {
      useSuspense: false,
    },
    resources: {
      en: {
        map: enMap,
        auth: enAuth,
        'roy-global': enGlobal,
        events: enEvents,
        shared: enShared,
        createUnion: enCreateUnion,
        content: enContent,
        home: enHome,
        kbase: enKbase,
        modals: enModals,
        news: enNews,
        payment: enPayment,
        polls: enPolls,
        profile: enProfile,
        security: enSecurity,
        settings: enSettings,
        ssi: enSSI,
        suggestion: enSuggestion,
        privacy: enPrivacy,
        rules: enRules,
        editor: enEditor,
        'regov-wallet-main': enMainSSI,
      },
      ru: {
        map: ruMap,
        auth: ruAuth,
        events: ruEvents,
        'roy-global': ruGlobal,
        shared: ruShared,
        createUnion: ruCreateUnion,
        content: ruContent,
        home: ruHome,
        kbase: ruKbase,
        modals: ruModals,
        news: ruNews,
        payment: ruPayment,
        polls: ruPolls,
        profile: ruProfile,
        security: ruSecurity,
        settings: ruSettings,
        ssi: ruSSI,
        suggestion: ruSuggestion,
        privacy: ruPrivacy,
        rules: ruRules,
        editor: ruEditor,
        'regov-wallet-main': ruMainSSI,
      },
      be: {
        map: beMap,
        auth: beAuth,
        events: beEvents,
        'roy-global': beGlobal,
        shared: beShared,
        createUnion: beCreateUnion,
        content: beContent,
        home: beHome,
        kbase: beKbase,
        modals: beModals,
        news: beNews,
        payment: bePayment,
        polls: bePolls,
        profile: beProfile,
        security: beSecurity,
        settings: beSettings,
        ssi: beSSI,
        suggestion: beSuggestion,
        editor: beEditor,
        'regov-wallet-main': beMainSSI,
        // todo
        privacy: ruPrivacy,
        rules: ruRules,
      },
      uk: {
        map: ukMap,
        auth: ukAuth,
        events: ukEvents,
        'roy-global': ukGlobal,
        shared: ukShared,
        createUnion: ukCreateUnion,
        content: ukContent,
        home: ukHome,
        kbase: ukKbase,
        modals: ukModals,
        news: ukNews,
        payment: ukPayment,
        polls: ukPolls,
        profile: ukProfile,
        security: ukSecurity,
        settings: ukSettings,
        ssi: ukSSI,
        suggestion: ukSuggestion,
        'regov-wallet-main': ukMainSSI,
        editor: ukEditor,
        // todo
        privacy: ruPrivacy,
        rules: ruRules,
      },
      lt: {
        map: ltMap,
        auth: ltAuth,
        events: ltEvents,
        'roy-global': ltGlobal,
        shared: ltShared,
        createUnion: ltCreateUnion,
        content: ltContent,
        home: ltHome,
        kbase: ltKbase,
        modals: ltModals,
        news: ltNews,
        payment: ltPayment,
        polls: ltPolls,
        profile: ltProfile,
        security: ltSecurity,
        settings: ltSettings,
        ssi: ltSSI,
        suggestion: ltSuggestion,
        'regov-wallet-main': ltMainSSI,
        editor: ltEditor,
        // todo
        privacy: enPrivacy,
        rules: enRules,
      },
      pl: {
        map: plMap,
        auth: plAuth,
        events: plEvents,
        'roy-global': plGlobal,
        shared: plShared,
        createUnion: plCreateUnion,
        content: plContent,
        home: plHome,
        kbase: plKbase,
        modals: plModals,
        news: plNews,
        payment: plPayment,
        polls: plPolls,
        profile: plProfile,
        security: plSecurity,
        settings: plSettings,
        ssi: plSSI,
        suggestion: plSuggestion,
        'regov-wallet-main': plMainSSI,
        editor: plEditor,
        // todo
        privacy: enPrivacy,
        rules: enRules,
      },
    },
    ns: [
      'home',
      'roy-global',
      'auth',
      'profile',
      'content',
      'modals',
      'ssi',
      'map',
      'suggestion',
      'payment',
      'shared',
      'privacy',
      'security',
      'kbase',
      'news',
      'polls',
      'events',
      'settings',
      'createUnion',
      'rules',
      'regov-wallet-main',
      'editor',
    ],
    fallbackLng: 'en',
    debug: !isProduction,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
