import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { getCalendarEvents } from 'services/events';
import { useHistory } from 'react-router-dom';
import { routes } from 'Routes';
import { toast } from 'react-toastify';
import formatServerError from 'shared/utils/formatServerError';
import styles from './Calendar.module.scss';
import { useTranslation } from 'react-i18next';

moment.locale('ru-RU');

export interface ICalendarEvents {
  id: number;
  title: string;
  allDay?: boolean;
  start: Date;
  end: Date;
  orgId?: number;
}

const CalendarPage = () => {
  const history = useHistory();
  const [eventsData, setEventsData] = useState<ICalendarEvents[]>([]);
  const { i18n, t } = useTranslation('roy-global');
  const handleSelect = ({ start, end }) => {
    const title = window.prompt('New Event name');
    if (title) setEventsData([...eventsData]);
  };

  useEffect(() => {
    let date = new Date();
    let firstDay = new Date(date.getFullYear(), date.getMonth(), -4);
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 5);
    getEvents(firstDay, lastDay);
  }, []);

  const getEvents = async (start, end) => {
    try {
      const eventsNew = await getCalendarEvents({ from: start, to: end });
      const eventsFiltered = eventsNew.map((i) => {
        return {
          id: i.id,
          title: i.title,
          start: new Date(i.date_start),
          end: new Date(i.date_end),
          desc: i.excerpt,
          orgId: i.organization.id,
        };
      });
      setEventsData(eventsFiltered);
    } catch (e) {
      toast.error(formatServerError(e));
    }
  };

  const onRangeChange = (data) => {
    let dataStart = null;
    let dataEnd = null;

    if (!Array.isArray(data)) {
      dataStart = data.start;
      dataEnd = data.end;
    } else {
      if (data.length === 1) {
        dataStart = data[0];
        dataEnd = new Date(new Date(data[0]).setDate(data[0].getDate() + 2));
      } else {
        dataStart = data[0];
        dataEnd = data[6];
      }
    }
    if (dataStart && dataEnd) {
      getEvents(dataStart, dataEnd);
    }
  };

  const onSelectEvent = (e) => {
    history.push(routes.eventsDetails.getLink(e.orgId, e.id));
  };

  const localizer = momentLocalizer(moment);
  return (
    <div className={styles.wrapper}>
      <Calendar
        views={['day', 'week', 'month']}
        culture={i18n.language}
        localizer={localizer}
        defaultDate={new Date()}
        defaultView="month"
        events={eventsData}
        onRangeChange={onRangeChange}
        style={{ height: '100%', width: '100%' }}
        onSelectEvent={onSelectEvent}
        onSelectSlot={handleSelect}
        messages={{
          allDay: t('calendar.allDay'),
          previous: '<',
          today: t('calendar.today'),
          next: '>',
          month: t('calendar.month'),
          week: t('calendar.week'),
          day: t('calendar.day'),
          date: t('calendar.date'),
          time: t('calendar.time'),
          event: t('calendar.event'),
        }}
      />
    </div>
  );
};

export default CalendarPage;
