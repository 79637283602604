import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './PaymentStatus.module.scss';
import Success from './images/success.png';
import { Button } from 'shared/components/common/Button/Button';
import { routes } from 'Routes';
import Cancel from './images/cancel.png';
import { useTranslation } from 'react-i18next';

enum PaymentStatusEnum {
  cancel = 'cancel',
  success = 'success',
}

const PaymentStatus = () => {
  const { t } = useTranslation('roy-global');
  function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  const status = query.get('status');

  const isSuccess = status === PaymentStatusEnum.success;

  return (
    <div className={styles.wrapper}>
      <h3>{isSuccess ? t('payment.success') : t('payment.error')}</h3>
      <img src={isSuccess ? Success : Cancel} alt={isSuccess ? t('payment.success') : t('payment.error')} />
      <Button to={routes.PROFILE}>{t('payment.backToService')}</Button>
    </div>
  );
};

export default PaymentStatus;
