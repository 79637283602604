import { Input } from 'shared/components/common/Input/Input';
import styles from './TextAnswer.module.scss';
import { AnswerProps } from '../PollQuestions';
import { useTranslation } from 'react-i18next';

const TextAnswer = ({ answer, setAnswer }: AnswerProps) => {
  const { t } = useTranslation('polls');
  return (
    <div className={styles.wrapper}>
      <Input value={answer} valueChange={(value) => setAnswer(value)} label={t('text.label')} placeholder={t('text.placeholder')} />
    </div>
  );
};

export default TextAnswer;
