import { useContext } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { routes } from 'Routes';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styles from './ForgetPassword.module.scss';
import { GlobalContext } from '../../../contexts/GlobalContext';
import { Input } from '../../../shared/components/common/Input/Input';
import { Button } from '../../../shared/components/common/Button/Button';

export function ForgetPassword() {
  const {
    services: { authService },
    spinner: { showSpinner, hideSpinner },
  } = useContext(GlobalContext);
  const { t } = useTranslation('auth');
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const validationSchema = Yup.object().shape({
    email: Yup.string().trim().email(t('forgetPassword.errors.invalidEmail')).max(100).required(t('forgetPassword.errors.required')),
  });

  const { errors, touched, values, handleSubmit, setFieldValue, validateForm, setFieldTouched } = useFormik({
    initialValues: { email: '' },
    validationSchema,
    onSubmit: async (values) => {
      showSpinner();
      const result = await authService.forgetPassword(values.email).finally(() => hideSpinner());
      if (result.ok) {
        toast(() => {
          return (
            <a href={'//' + values.email.split('@')[1]} target="_blank" rel="noreferrer">
              {t('forgetPassword.toast')}
            </a>
          );
        });
      }
      hideSpinner();
    },
  });

  return (
    <div className={styles.ForgetPassword}>
      <h3>{t('forgetPassword.title')}</h3>

      <form
        onSubmit={async (event) => {
          event.preventDefault();
          await validateForm();
          handleSubmit();
        }}
      >
        <Input
          name="email"
          value={values.email}
          valueChange={(value) => {
            setFieldValue('email', value, true);
          }}
          label={t('forgetPassword.input.label')}
          type="email"
          description={t('forgetPassword.input.description')}
          placeholder={t('forgetPassword.input.placeholder')}
          onTouch={setFieldTouched}
          error={errors?.email && touched?.email && errors.email}
          required
        />

        <div className={styles.actions}>
          <Button type="submit">{t('forgetPassword.actions.send')}</Button>
          <Button type="submit" color="light" to={`${routes.LOGIN}?${searchParams.toString()}`}>
            {t('forgetPassword.actions.goBack')}
          </Button>
        </div>
      </form>
    </div>
  );
}
