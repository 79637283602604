import { useContext } from 'react';
import ReactDOMServer from 'react-dom/server';
import { toast } from 'react-toastify';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { routes } from 'Routes';
import { formatDateForGoogle } from 'utils/formatDateForGoogle';
import { GlobalContext } from 'contexts/GlobalContext';
import sanitizeHtml from 'sanitize-html';
import { allowedAttributesSchema, allowedTagsSynitizer, allowedIframeHostnamesSchema } from 'shared/constants/allowedTagsSynitizer';
import { formatDistance } from 'date-fns';
import { ru, be, enGB, lt, uk } from 'date-fns/locale';
import { NewsNavButtons } from 'pages/NewsDetails/NewsNavButtons/NewsNavButtons';
import Tags from 'common/Tags/Tags';
import PaymentModal from 'common/PaymentModal/PaymentModal';
import Comments from 'shared/components/Comments/Comments';
import { Icon } from 'shared/components/common/Icon/Icon';
import { Button } from 'shared/components/common/Button/Button';
import { CustomImage } from 'shared/components/common/CustomImage/CustomImage';
import { Icons } from 'shared/components/common/Icon/Icon.interface';
import styles from './ContentLayout.module.scss';
import { EnumContentType, IContentLayout } from './ContentLayoutTypes';
import NewsErrorModal from 'pages/News/NewsErrorModal/NewsErrorModal';
import InviteBanner from './InviteBanner/InviteBanner';
import { GlobalDataContext } from 'contexts/GlobalDataContext';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { MapModal } from 'components/Modals/MapModals/MapModal/MapModal';
import classNames from 'classnames';
import { isSSR } from 'utils/isSSR';

const ContentLayout = ({
  title,
  content,
  contentType,
  contentId,
  next,
  prev,
  tags,
  category,
  logo,
  published_at,
  commentsCount,
  choosePayment,
  orgId,
  uniqueContent,
  user: author,
  eventExtra,
  materialExtra,
}: IContentLayout) => {
  const { t } = useTranslation(['kbase', 'regov-wallet-main']);
  const {
    openModal,
    services: { userService },
  } = useContext(GlobalContext);
  const { user } = useContext(GlobalDataContext);
  const location = useLocation();
  const history = useHistory();
  const { i18n } = useTranslation();
  const locales = {
    ru,
    be,
    enGB,
    lt,
    uk,
  };
  const icons: { iconName: keyof typeof Icons; content: string | number }[] = [
    {
      iconName: 'clock',
      content:
        formatDistance(new Date(published_at), new Date(Date.now()), {
          locale: locales[i18n.language],
          addSuffix: false,
        }) ?? 0,
    },
    { iconName: 'comments', content: commentsCount ?? 0 },
  ];

  const InviteBannerString = ReactDOMServer.renderToString(<InviteBanner />);

  const contentForRender = !!userService.isLoggedIn ? content : content + InviteBannerString;

  function openFundraisingModal(choosePayment) {
    openModal({
      params: {
        mainContainer: <PaymentModal payment={choosePayment} />,
      },
    });
  }

  function openHelpOffersModal() {
    openModal({
      params: {
        mainContainer: <NewsErrorModal newsId={contentId} organisationId={orgId} />,
      },
    });
  }

  const openMapModal = () => {
    openModal({
      params: {
        mainContainer: <MapModal lat={eventExtra.point.lat} lng={eventExtra.point.lng} title={title} />,
      },
    });
  };

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.titleWrapper}>
          {logo && (
            <Link
              to={{
                pathname: routes.union.getLink(orgId),
                state: {
                  from: location.pathname,
                },
              }}
              className={styles.imageLink}
            >
              <CustomImage isOrganization src={logo.src} alt={logo.alt} width={55} height={55} className={styles.image} />
            </Link>
          )}
          <div className={styles.wrapperTextInfo}>
            <h1 className={styles.title}>{title}</h1>
            {author && (
              <Link to={routes.profile.getLink(author.id)}>
                <span className={styles.authorName}>@{author.public_name ?? author.public_family ?? 'Anonim'}</span>
              </Link>
            )}
            {materialExtra && (
              <span>
                {t('kbase:section')}:
                <Link to={routes.sectionMaterials.getLink(orgId, materialExtra.id)}>
                  <span className={styles.section}> {materialExtra.name}</span>
                </Link>
              </span>
            )}
          </div>
        </div>
        {contentType === 'event' && !!eventExtra ? (
          <div className={styles.events}>
            {(!!eventExtra.date_end || !!eventExtra.date_start) && (
              <div className={styles.eventWrapper}>
                <Icon iconName="noteAdd" />{' '}
                <span className={styles.eventText}>{`${format(new Date(eventExtra.date_start), 'dd.MM.yyyy HH:mm')} - ${format(
                  new Date(eventExtra.date_end),
                  'dd.MM.yyyy HH:mm'
                )}`}</span>
              </div>
            )}
            {/* {!!eventExtra.place && (
              <a href={`https://www.google.com/maps/search/${eventExtra.place}'`} target="_blank" rel="noreferrer" className={styles.link}>
                <div className={styles.eventWrapper}>
                  <Icon iconName={Icons.location} color="black" /> <span className={styles.eventText}>{eventExtra.place}</span>
                </div>
              </a>
            )} */}
            {!!eventExtra.place && (
              <div className={classNames(styles.eventWrapper, styles.pointer)} onClick={openMapModal}>
                <Icon iconName={Icons.location} color="black" /> <span className={styles.eventText}>{eventExtra.place}</span>
              </div>
            )}
            {!!eventExtra.price && (
              <div className={styles.eventWrapper}>
                <Icon iconName="wallet" color="black" /> <span className={styles.eventText}>{eventExtra.price}</span>
              </div>
            )}
            <div className={styles.addToGoogle}>
              <Icon iconName="googleCalendar" />
              <a
                href={`http://www.google.com/calendar/event?action=TEMPLATE&text=${title}&dates=${formatDateForGoogle(
                  eventExtra.date_start
                )}${eventExtra.date_end ? `/${formatDateForGoogle(eventExtra.date_end)}` : ''}&details=<span>${t(
                  'kbase:eventLink'
                )}: </span><a href='${process.env.REACT_APP_CLIENT_APP_URL}${location.pathname}${
                  location.search
                }' target='_blank'>${title}</a><br />${title}&location=${
                  eventExtra.place ? eventExtra.place : ''
                }&trp=false&sprop=&sprop=name:`}
                target="_blank"
                rel="nofollow noreferrer"
              >
                {t('kbase:googleCalendar')}
              </a>
            </div>
          </div>
        ) : null}
        <div className={styles.createrWrapper}>
          <div className={styles.infoWrapper}>
            <Tags tags={[contentType]} contentTypes={EnumContentType[contentType]} />
            {icons.map((i) => {
              return (
                <div className={styles.iconWrapper} key={i.iconName}>
                  <Icon iconName={i.iconName} color="disabled" />
                  <span>{i.content}</span>
                </div>
              );
            })}
          </div>

          <div className={styles.buttonWrapper}>
            {choosePayment && (
              <Button
                onlyIcon
                icon="wallet"
                color="light"
                size="small"
                className={styles.walletButton}
                onClick={() => {
                  openFundraisingModal(choosePayment);
                }}
              ></Button>
            )}
            <Button
              icon="link"
              color="light"
              size="small"
              className={styles.linkButton}
              onClick={() => {
                navigator.clipboard.writeText(
                  `${process.env.REACT_APP_CLIENT_APP_URL}${location.pathname}${location.search}${location.hash}`
                );
                toast(t('kbase:copyLink'));
              }}
            ></Button>
            {contentType === 'event' && eventExtra.needRoyPass && (
              <Button
                color="light"
                size="small"
                disabled={!eventExtra.is_active}
                onClick={() => {
                  if (!user) {
                    toast(t('regov-wallet-main:error.authenticated'));
                    history.push({
                      pathname: routes.LOGIN,
                      search: `?redirect=${location.pathname + location.search}`,
                    });
                    return;
                  }
                  const link = `${process.env.REACT_APP_ROY_PASS_URL}events/${contentId}?rid=${user?.id}`;
                  if (!isSSR()) {
                    window.open(link, '_blank', 'noopener,noreferrer');
                  }
                }}
              >
                {t('kbase:join')}
              </Button>
            )}
          </div>
        </div>
        <div className={styles.contentWrapper}>
          <div
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(contentForRender, {
                allowedTags: allowedTagsSynitizer,
                allowedAttributes: allowedAttributesSchema,
                allowedIframeHostnames: allowedIframeHostnamesSchema,
              }),
            }}
            className={styles.content}
          />
          {uniqueContent}
          {contentType === EnumContentType.news && (
            <div className={styles.contentBottom}>
              <div className={styles.buttons}>
                <div className={styles.pageButtons}>
                  <NewsNavButtons next={next} prev={prev} category={category} tags={tags} />
                </div>
                {orgId && (
                  <button className={styles.findError} onClick={openHelpOffersModal}>
                    {t('kbase:mistake')}
                  </button>
                )}
              </div>
            </div>
          )}
          {!!tags && !!tags.length && <Tags tags={[contentType, ...tags]} contentTypes={EnumContentType[contentType]} />}
        </div>
        {EnumContentType[contentType] && (
          <Comments name={EnumContentType[contentType]} user={user} essenceId={contentId} className={styles.comments} />
        )}
      </div>
    </>
  );
};

export default ContentLayout;
