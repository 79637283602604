export const contacts = {
  telegram: 'https://t.me',
  vk: 'https://vk.com',
  instagram: 'https://www.instagram.com',
  facebook: 'https://www.facebook.com',
  youtube: 'https://www.youtube.com',
  odnoklassniki: 'https://ok.ru',
  place: '',
  telegramBot: 'https://t.me/',
  email: 'roy.team@gmail.com',
};
