import { useDropzone } from 'react-dropzone';
import styles from './PreviewEditor.module.scss';
import { Cropper } from 'react-cropper';
import { Dispatch, SetStateAction } from 'react';
import { Icon } from 'shared/components/common/Icon/Icon';
import { useTranslation } from 'react-i18next';

type Props = {
  preview: string;
  onDrop: (files: File[]) => Promise<void>;
  setLocalCropper: Dispatch<SetStateAction<Cropper>>;
};

export const PreviewEditor = ({ preview, setLocalCropper, onDrop }: Props) => {
  const { t } = useTranslation('editor');
  const acceptedFileTypes = {
    'image/png': ['.png'],
    'image/jpg': ['.jpg'],
    'image/jpeg': ['.jpeg'],
  };
  const { getInputProps, open } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: acceptedFileTypes,
    maxSize: 10 * 1024 * 1024,
  });
  return (
    <div className={styles.wrapper}>
      <p>{t('cover')}</p>
      <input {...getInputProps()} />
      <div onClick={open} className={styles.addIcon} title={t('download')}>
        <Icon color="white" iconName="addSquare" />
      </div>
      {preview.startsWith('https://img.youtube.com/vi/') || preview.startsWith('/static') || preview.startsWith('/images') ? (
        <img className={styles.image} width={1600} height={900} src={preview} alt="preview" />
      ) : (
        <Cropper
          className={styles.cropper}
          src={preview}
          cropBoxResizable={false}
          toggleDragModeOnDblclick={false}
          cropBoxMovable={false}
          aspectRatio={16 / 9}
          dragMode={'move'}
          autoCropArea={100}
          draggable
          onInitialized={(instance) => {
            setLocalCropper(instance);
          }}
        />
      )}
    </div>
  );
};
