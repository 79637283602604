import 'react-quill/dist/quill.snow.css';
import { useContext, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { allowedSizeFiles, toLargeFileSize } from 'shared/constants/allowedFileSize';
import formatServerError from 'shared/utils/formatServerError';
import styles from './PostRedactor.module.scss';
import { ReactComponent as GalleryAdd } from './icons/gallery-add.svg';
import { addGeneralImage } from 'services/general';
import { uploadPost } from 'services/feed';
import classNames from 'classnames';
import { GlobalContext } from 'contexts/GlobalContext';
import { Button } from 'shared/components/common/Button/Button';
import { CroppedImage } from './components/CroppedImage/CroppedImage';
import { CustomReactQuill } from 'shared/components/common/CustomReactQuill/CustomReactQuill';
import { FormError } from 'shared/components/common/Validator/FormError/FormError';
import { useTranslation } from 'react-i18next';

const PostRedactor = ({ savePost }: { savePost: () => void }) => {
  const { t } = useTranslation('profile');
  const {
    spinner: { showSpinner, hideSpinner },
    closeModal,
  } = useContext(GlobalContext);
  const imageInput = useRef(null);
  const [editorContent, setEditorContent] = useState('');
  const [images, setImages] = useState<Array<string | ArrayBuffer>>([]);
  const [croppedImages, setCroppedImages] = useState<Array<string | ArrayBuffer>>([]);
  const [inputCounter, setInputCounter] = useState(0);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const handleImageAddClick = () => {
    imageInput.current.click();
  };

  const uploadImages = async () => {
    const result = await addGeneralImage(croppedImages);
    return result.map((el) => el.uuid);
  };

  const handleImageInput = async (e) => {
    showSpinner();
    if (e.target.files[0].size > allowedSizeFiles) {
      toast.error(toLargeFileSize);
      hideSpinner();
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);

    reader.onloadend = async () => {
      try {
        setInputCounter(inputCounter + 1);
        setImages([...images, reader.result]);
        setCroppedImages([...croppedImages, reader.result]);
      } catch (error) {
        toast.error(formatServerError(error));
        console.error(error);
      } finally {
        hideSpinner();
      }
    };
  };

  const createPost = async () => {
    if (isMaxLength || editorContent.length === 0) {
      return;
    }
    showSpinner();
    try {
      const uploadedImages = await uploadImages();
      await uploadPost({ content: editorContent, images: uploadedImages });
      setEditorContent('');
      setImages([]);
      toast(t('toast.postCreated'));
      setTimeout(() => {
        savePost();
      }, 1000);
      setCroppedImages([]);
      setImages([]);
    } catch (e) {
      toast.error(formatServerError(e));
      console.log(e);
    } finally {
      hideSpinner();
    }
  };

  const deleteImage = (index: number) => {
    closeModal();
    const defaultImgArr = [...images];
    defaultImgArr.splice(index, 1);
    const croppedImgArr = [...croppedImages];
    croppedImgArr.splice(index, 1);
    setImages(defaultImgArr);
    setCroppedImages(croppedImgArr);
  };
  const updateImage = (src: string, index) => {
    const currentImages = [...croppedImages];
    currentImages[index] = src;
    setCroppedImages(currentImages);
  };
  const getDefaultImage = (index: number) => {
    return images[index] as string;
  };
  const isMaxLength = editorContent.replace(/(<([^>]+)>)/gi, ' ').length > 4000;
  const customButtons = (
    <>
      <button className={styles.iconButton} onClick={handleImageAddClick} disabled={images.length === 10}>
        <GalleryAdd />
      </button>
      <Button
        className={styles.button}
        disabled={editorContent.length === 0 || isMaxLength}
        type="button"
        color="light"
        size="small"
        onClick={createPost}
      >
        {t('buttons.save')}
      </Button>
    </>
  );
  return (
    <div className={classNames(styles.postFieldWrapper, { [styles.active]: isEdit })}>
      <input
        type="file"
        key={`post-input-${inputCounter}`}
        ref={imageInput}
        onChange={(e) => handleImageInput(e)}
        accept="image/png, image/jpeg, image/jpg"
        style={{ display: 'none' }}
      />
      <div className={styles.postField} onClick={() => setIsEdit(true)}>
        <CustomReactQuill
          onChange={setEditorContent}
          className={classNames(styles.quillWrapper, { [styles.minHeight]: !isEdit })}
          quillClassName={classNames(styles.quillEditor, { [styles.fullWidth]: !isEdit })}
          tollBarClassName={classNames(styles.toolbar)}
          value={editorContent}
          customButton={customButtons}
          placeholder={t('feed.posts.whatsNew')}
        />
      </div>
      {isMaxLength && <FormError>{t('feed.posts.maxLength')}</FormError>}
      {images.length ? (
        <div className={styles.attachedImages}>
          {croppedImages.map((item, index) => {
            return (
              <CroppedImage
                getDefaultImage={() => getDefaultImage(index)}
                setImage={(src) => updateImage(src, index)}
                deleteImage={() => deleteImage(index)}
                image={item as string}
                key={`new-post-image-${index}`}
              />
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export default PostRedactor;
