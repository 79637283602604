import classNames from 'classnames';
import { CustomSlider } from 'components/CustomSlider/CustomSlider';
import { IOrganisation } from 'interfaces/organisation.interface';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { routes } from 'Routes';
import { getOrganizationMaterials, getOrganizationSections } from 'services/knowledge-base';
import { Icon } from 'shared/components/common/Icon/Icon';
import { Tab } from 'shared/components/common/Tab/Tab';
import { KbaseMaterialFront, KbaseSection } from 'shared/interfaces/kbase';
import formatServerError from 'shared/utils/formatServerError';
import styles from './ProfileBlog.module.scss';
import { MainCard } from 'components/Crads/NewsCards/MainCard/MainCard';
import { mockImages } from 'shared/constants/MockImages/MockImages';
import { useTranslation } from 'react-i18next';

type ProfileBlogProps = {
  organizationId?: number;
  slidesOnPage: number;
  organization: IOrganisation;
  isMobile: boolean;
};

export const ProfileBlog = ({ organizationId, slidesOnPage = 1, organization, isMobile }: ProfileBlogProps) => {
  const { t } = useTranslation('profile');
  const [sections, setSections] = useState<KbaseSection[]>([]);
  const [materials, setMaterials] = useState<KbaseMaterialFront[]>([]);
  const [activeSlideMaterials, setActiveSlideMaterials] = useState<number>(0);
  const [activeSlideSections, setActiveSlideSections] = useState<number>(0);
  const [activeTab, setActiveTab] = useState<number>(0);
  const activeSlide = [activeSlideMaterials, activeSlideSections];
  const setActiveSlide = [setActiveSlideMaterials, setActiveSlideSections];

  useEffect(() => {
    (async () => {
      try {
        const { data: response } = await getOrganizationSections(organizationId);
        setSections(response.reverse());
      } catch (e) {
        toast.error(formatServerError(e));
      }
    })();
    (async () => {
      try {
        const { data: response } = await getOrganizationMaterials({
          organisationId: organizationId,
          limit: 15,
          sortBy: 'id',
          sortDirection: 'desc',
        });
        setMaterials(response);
      } catch (e) {
        toast.error(formatServerError(e));
      }
    })();
    return () => {
      setActiveSlideMaterials(0);
      setActiveSlideSections(0);
    };
  }, [organizationId]);

  if (!materials.length && !sections.length) {
    return null;
  }

  const slides = [
    materials.map((el, index) => {
      return (
        <MainCard
          maxHeight={{ content: 95, title: 56 }}
          author={{ id: el.user.id, logo: el.user.public_avatar, name: el.user.public_name }}
          content={el.content}
          image={el.image || mockImages.material}
          link={routes.materialDetails.getLink(el.organization.id, el.id)}
          externalLink={el.link}
          withFooter={true}
          title={el.title}
          publishedAt={el.published_at}
          organization={{ id: el.organization.id, logo: el.organization.avatar, name: el.organization.short_name }}
          commentsCount={el.comments_count || 0}
          key={`materials-card-${index}`}
          isMobile={isMobile}
        />
      );
    }),
    sections.map((el, index) => {
      return (
        <MainCard
          maxHeight={{ content: 95, title: 56 }}
          link={routes.sectionMaterials.getLink(el.organization_id, el.id)}
          withFooter={false}
          content={el.description}
          image={el.cover || mockImages.section}
          title={el.name}
          organization={{ id: organization.id, logo: organization.avatar, name: organization.short_name }}
          publishedAt={el.updated_at}
          commentsCount={0}
          key={`sections-card-${index}`}
          isMobile={isMobile}
        />
      );
    }),
  ];
  const selected = !!slides[0].length ? 0 : 1;
  const isDisabled = [!slides[0].length, !slides[1].length];
  return (
    <div className={classNames(styles.wrapper, { [styles.mobileWrapper]: isMobile })}>
      <div className={classNames(styles.title, { [styles.mobile]: isMobile })}>
        <h4 className={classNames({ [styles.titleTextDesc]: !isMobile })}>{t('organization.blog.title')}</h4>
        <div className={classNames(styles.controls)}>
          <button
            className={styles.button}
            disabled={activeSlide[activeTab] === 0}
            onClick={() => setActiveSlide[activeTab](activeSlide[activeTab] - 1)}
            title={t('buttons.slider.prev')}
          >
            <Icon width={24} height={24} iconName={'arrowLeft'} color="black" />
          </button>
          <button
            className={styles.button}
            onClick={() => setActiveSlide[activeTab](activeSlide[activeTab] + 1)}
            disabled={activeSlide[activeTab] >= slides[activeTab].length - 1}
            title={t('buttons.slider.next')}
          >
            <Icon width={24} height={24} iconName={'arrowRight'} color="black" />
          </button>
        </div>
        <Tab
          isDisabled={isDisabled}
          selectDefault={selected}
          className={styles.tab}
          tabPanels={[]}
          setOutsidePanel={setActiveTab}
          tabList={[t('organization.blog.tabs.0'), t('organization.blog.tabs.1')]}
        />
      </div>

      {activeTab === 0 && <CustomSlider activeSlide={activeSlideMaterials} slides={slides[0]} slidesOnPage={slidesOnPage} />}
      {activeTab === 1 && <CustomSlider activeSlide={activeSlideSections} slides={slides[1]} slidesOnPage={slidesOnPage} />}
    </div>
  );
};
