import React, { useEffect, useRef, useState } from 'react';
import { CustomImage } from 'shared/components/common/CustomImage/CustomImage';
import { AutoSizeTextArea } from 'shared/components/common/AutoSizeTextArea/AutoSizeTextArea';
import { Button } from 'shared/components/common/Button/Button';
import cn from 'classnames';
import { Picker } from 'emoji-mart';
import styles from './CommentTextArea.module.scss';
import { ReactComponent as EmojiIcon } from '../../../icons/emoji.svg';
import { useSelector } from '../../../../redux-toolkit';
import emojiRu from 'shared/i18next/ru/emoji.json';
import emojiLt from 'shared/i18next/lt/emoji.json';
import emojiBe from 'shared/i18next/be/emoji.json';
import emojiUk from 'shared/i18next/uk/emoji.json';
import emojiEn from 'shared/i18next/en/emoji.json';
import emojiPl from 'shared/i18next/pl/emoji.json';
import { useTranslation } from 'react-i18next';

const emojiTranslations = {
  en: emojiEn,
  ru: emojiRu,
  uk: emojiUk,
  be: emojiBe,
  lt: emojiLt,
  pl: emojiPl,
};

const CommentTextArea = React.memo(
  ({
    value,
    setValue,
    isChange,
    setChange,
    buttonText,
    placeholder,
    onClick,
    id,
  }: {
    value: string;
    setValue: (value: any) => any;
    isChange?: boolean;
    setChange?: (change: boolean) => void;
    buttonText?: string;
    placeholder?: string;
    onClick?: () => void;
    id?: string | number;
  }) => {
    const { t, i18n } = useTranslation('shared');
    const emojiRef = useRef<HTMLInputElement | null>(null);
    const textRef = useRef<HTMLTextAreaElement | null>(null);
    const { generalData } = useSelector((state) => state.comments);

    const user = generalData.user;
    const [showEmoji, setShowEmoji] = useState(false);

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (emojiRef.current && !emojiRef.current.contains(event.target)) {
          setShowEmoji(false);
        }
      };
      document.addEventListener('click', handleClickOutside, true);
      return () => {
        document.removeEventListener('click', handleClickOutside, true);
      };
    }, [showEmoji]);

    const onPickerSelect = (e) => {
      setShowEmoji(false);

      const start = textRef.current.selectionStart;
      const end = textRef.current.selectionEnd;

      setValue(
        (prevString) => prevString.substring(0, start) + e.native + prevString.substring(end, prevString.length)
      );

      textRef.current.focus();
    };

    if (!user) {
      return null;
    }

    return (
      <div
        className={cn(styles.commentTextareaWrapper, {
          [styles.changeCommentTextareaWrapper]: !!isChange,
        })}
      >
        <CustomImage
          src={user?.public_avatar || ''}
          alt={user?.public_name}
          width={37.5}
          height={37.5}
          className={styles.imageComment}
        />
        <div className={styles.textareaWrapper}>
          <div className={styles.textareaEmojiWrapper}>
            <AutoSizeTextArea
              valueChange={(e) => setValue(e)}
              value={value}
              placeholder={placeholder || t('comments.placeholderDefault')}
              className={styles.textarea}
              isComment
              textRef={textRef}
            />
            <button onClick={() => setShowEmoji(!showEmoji)} className={styles.fileIcon}>
              <EmojiIcon />
            </button>
            {showEmoji && (
              <div ref={emojiRef} className={styles.emojiWrapper}>
                <Picker
                  onSelect={onPickerSelect}
                  i18n={emojiTranslations[i18n.language] || emojiEn}
                  showPreview={false}
                  showSkinTones={false}
                />
              </div>
            )}
          </div>

          <div className={styles.wrapperButtons}>
            {!!isChange && (
              <Button size="small" color="light" onClick={() => setChange(false)} className={styles.closeChangeButton}>
                {t('actions.cancel')}
              </Button>
            )}
            <Button size="small" onClick={onClick} disabled={!value?.length}>
              {buttonText || t('comments.buttonTextDefault')}
            </Button>
          </div>
        </div>
      </div>
    );
  }
);

export default CommentTextArea;
