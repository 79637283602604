import { Icon } from 'shared/components/common/Icon/Icon';
import { useState } from 'react';
import styles from './UserAgreement.module.scss';
import { useTranslation } from 'react-i18next';

type UserAgreementProps = {
  withTitle?: boolean;
};

export const UserAgreement = ({ withTitle = true }: UserAgreementProps) => {
  const { t } = useTranslation('ssi');
  const [isOpenDescription, setIsOpenDescription] = useState(false);
  return (
    <div className={`${styles.UserAgreement}`}>
      {withTitle && <h4>{t('userAgreement.title')}</h4>}
      <p>
        {`${t('userAgreement.description.noMail')}
          ${t('userAgreement.description.creationWallet')}
          ${t('userAgreement.description.walletSave')}
          ${t('userAgreement.description.differences.1')}`}
        {!isOpenDescription ? (
          <button className={styles.button} onClick={() => setIsOpenDescription(true)}>
            {t('userAgreement.description.readMore')}
          </button>
        ) : (
          <>
            {`
              ${t('userAgreement.description.differences.2')}
              ${t('userAgreement.description.contents')}
              ${t('userAgreement.description.credentials')}
              ${t('userAgreement.description.passport')}
              ${t('userAgreement.description.login')}
              ${t('userAgreement.description.transfer')}
              `}
            <br />
            {`
              ${t('userAgreement.description.safe')}
              ${t('userAgreement.description.benefits.title')}
              `}
            <br />
            <strong>{t('userAgreement.description.benefits.privacy.title')}</strong>
            {t('userAgreement.description.benefits.privacy.description')}
            <br />
            <strong>{t('userAgreement.description.benefits.convenience.title')}</strong>
            {t('userAgreement.description.benefits.convenience.description')}
            <br />
            <strong>{t('userAgreement.description.benefits.fast.title')}</strong>
            {t('userAgreement.description.benefits.fast.description')}
            <br />
            <strong>{t('userAgreement.description.benefits.reliability.title')}</strong>
            {t('userAgreement.description.benefits.reliability.description')}
            <br />
            <strong>{t('userAgreement.description.benefits.scalability.title')}</strong>
            {t('userAgreement.description.benefits.scalability.description')}
            <br />
            {t('userAgreement.description.benefits.conclusion')}
            <button className={styles.button} onClick={() => setIsOpenDescription(false)}>
              {t('userAgreement.description.hide')}
            </button>
          </>
        )}
      </p>
      <div className={styles.reminder}>
        <div className={styles.icon}>
          <Icon color="black" iconName="ssiWallet" />
        </div>
        <p>{t('userAgreement.description.walletInfo')}</p>
      </div>
      <p>
        <strong>{t('userAgreement.description.pincode')}</strong>
        <br />
        {t('userAgreement.description.remember')}
      </p>
    </div>
  );
};
