import { FormControl, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = {
  title: string;
  setTitle: (val: string) => Promise<void> | void;
  description: string;
  setDescription: (val: string) => Promise<void> | void;
  link: string;
  setLink: (val: string) => Promise<void> | void;
};

export const PublicationLinkData = ({ title, setTitle, description, setDescription, link, setLink }: Props) => {
  const { t } = useTranslation(['editor', 'profile']);

  return (
    <FormControl
      fullWidth
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
      }}
    >
      <TextField
        id="materials-link-title"
        fullWidth
        label={t('editor:inputs.title.placeholder')}
        variant="outlined"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <TextField
        id="materials-link-description"
        fullWidth
        label={t('editor:inputs.decryption.placeholder')}
        variant="outlined"
        value={description}
        multiline
        minRows={2}
        onChange={(e) => setDescription(e.target.value)}
      />
      <TextField
        id="materials-link-link"
        fullWidth
        label={t('editor:pasteLink')}
        variant="outlined"
        value={link}
        onChange={(e) => setLink(e.target.value)}
      />
    </FormControl>
  );
};
