import { FunctionComponent, useContext, useEffect, useState } from 'react';
import * as H from 'history';
import { WalletHandler } from '@owlmeans/regov-ssi-core';
import { GlobalContext, IModalOptions } from 'contexts/GlobalContext';
import { IOrganisation, IOrganizationEnterRequest } from 'interfaces/organisation.interface';
import { Button } from 'shared/components/common/Button/Button';
import { Icon } from 'shared/components/common/Icon/Icon';
import styles from './RegovOrganizationRequirementsList.module.scss';
import { buildMembershipHelper } from 'regov/models/membership.helper';
import { GlobalDataContext } from 'contexts/GlobalDataContext';
import { toast } from 'react-toastify';
import { CustomBadge } from 'regov/components/CustomBadge/CustomBadge';
import formatServerError from 'shared/utils/formatServerError';
import { routes } from 'Routes';
import { useTranslation } from 'react-i18next';

export const RegovOrganizationRequirementsList: FunctionComponent<
  RegovOrganizationRequirementsListProps & { setRequestStatus: (status: IOrganizationEnterRequest) => void }
> = ({ organisation, handler, history, closeModal, setRequestStatus }) => {
  const { t } = useTranslation('ssi');
  const [walletTick, setWalletTick] = useState(0);
  useEffect(() => handler.observe(setWalletTick, (_) => walletTick + 1), []);

  const {
    services: { httpService },
  } = useContext(GlobalContext);
  const { organisationMethods } = useContext(GlobalDataContext);
  const helper = buildMembershipHelper(organisation, handler);

  const [allowed, setAllowed] = useState(false);
  useEffect(() => {
    void (async () => {
      setAllowed(helper.isAuthorizationRequired() ? !helper.isntAuthentiated() && (await helper.areRequirementsSatisfied()) : true);
    })();
  }, [walletTick]);

  return (
    <div className={styles.wrapper}>
      <h4>{t('enterOrganization.title')}</h4>
      <p>{t('enterOrganization.needed')}</p>
      <p>
        {t('enterOrganization.important')}
        <br />
        {t('enterOrganization.description')}
      </p>
      <div className={styles.badges}>
        {organisation.badge_needs.map((badge, index) => {
          return <CustomBadge handler={handler} badge={badge} key={`badge-org-${organisation.id}-${badge.type}-${index}`} />;
        })}
      </div>
      <div className={styles.footer}>
        <div className={styles.info}>
          <Icon color="black" iconName="infoCircle" />
          <p className={styles.infoText}>{t('enterOrganization.info')}</p>
        </div>
        <Button
          size="small"
          className={styles.button}
          disabled={!allowed}
          onClick={async () => {
            try {
              const challengeResult = await helper.challengeRequirements({ token: httpService.token });
              organisationMethods.enterOrganisation(organisation.id, challengeResult);
              closeModal();
              setRequestStatus({ status: 10, request: '' });
              history.push(routes.union.getLink(organisation.id));
            } catch (error) {
              toast(formatServerError(error));
            }
          }}
        >
          {t('buttons.enterOrg')}
        </Button>
      </div>
    </div>
  );
};

export type RegovOrganizationRequirementsListProps = {
  organisation: IOrganisation;
  handler: WalletHandler;
  openModal: (options?: Pick<IModalOptions, 'params'>) => void;
  closeModal: () => void;
  history: H.History<H.LocationState>;
};
