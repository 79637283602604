import { GlobalProvider } from './contexts/GlobalContext';
import { GlobalDataProvider } from './contexts/GlobalDataContext';
import App from './containers/App/App';
import { Provider as ReduxProvider } from 'react-redux';
import { store } from './redux-toolkit';
import { ErrorBoundary } from 'react-error-boundary';
import i18n from './lib/i18n';
import { I18nextProvider } from 'react-i18next';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from 'styles/theme';

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
}

function Boot() {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <ReduxProvider store={store()}>
        <ThemeProvider theme={theme}>
          <GlobalProvider>
            <GlobalDataProvider>
              <I18nextProvider i18n={i18n}>
                <App />
              </I18nextProvider>
            </GlobalDataProvider>
          </GlobalProvider>
        </ThemeProvider>
      </ReduxProvider>
    </ErrorBoundary>
  );
}

export default Boot;
