import styles from './SettingsSkeleton.module.scss';
import { useTranslation } from 'react-i18next';
import { Button } from 'shared/components/common/Button/Button';

type Props = {
  children: JSX.Element | Array<JSX.Element>;
  onDraft: () => Promise<void> | void;
  onPublish: () => Promise<void> | void;
  isActionsDisabled: boolean;
  isSaveDisabled?: boolean;
  isEventRoyPass?: boolean;
};

export const SettingsSkeleton = ({ onDraft, onPublish, isActionsDisabled, children, isSaveDisabled, isEventRoyPass }: Props) => {
  const { t } = useTranslation(['editor', 'auth']);

  return (
    <div className={styles.wrapper}>
      {children}
      <div className={styles.buttons}>
        {!isEventRoyPass ? (
          <>
            <Button className={styles.button} disabled={isSaveDisabled} color="light" size="small" onClick={onDraft}>
              {`${t('editor:save')}${t('editor:draft').toLowerCase()}`}
            </Button>
            <Button className={styles.button} disabled={isActionsDisabled} color="primary" size="small" onClick={onPublish}>
              {t('editor:publish')}
            </Button>
          </>
        ) : (
          <Button className={styles.button} disabled={isSaveDisabled} color="light" size="small" onClick={onDraft}>
            {`${t('auth:registration.actions.next')}`}
          </Button>
        )}
      </div>
    </div>
  );
};
