import { Link } from 'react-router-dom';
import { routes } from 'Routes';
import styles from './OrgPopup.module.scss';
import { CustomImage } from 'common/CustomImage/CustomImage';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

interface OrgPopupProps {
  id: number;
  avatar: string;
  name: string;
  description: string;
  members_count: number;
  members_new_count: number;
  className?: string;
}
const OrgPopup = ({ id, avatar, name, description, members_count, members_new_count, className }: OrgPopupProps): JSX.Element => {
  const { t } = useTranslation('map');
  return (
    <Link to={routes.union.getLink(id)} className={classNames(styles.wrapper, { [className]: className })}>
      <div className={styles.header}>
        <div className={styles.headerIcon}>
          <CustomImage width={56} height={56} src={avatar || ''} alt={name} errorImage="noImage" rounded />
        </div>
        <h4 className={styles.headerText}>{name?.toUpperCase()}</h4>
      </div>
      <div className={styles.description}>
        <p className={styles.descriptionText}>{description}</p>
      </div>

      <div className={styles.shortInfo}>
        <div className={styles.organizationShortInfo}>
          <p className={styles.shortInfoTitle}>{t('subscribers')}</p>
          <span className={styles.organizationCount}>{members_count ?? 0}</span>
        </div>
        <div className={styles.organizationShortInfo}>
          <p className={styles.shortInfoTitle}>{t('activity')}</p>
          <span className={styles.organizationCount}>{members_new_count ?? 0}</span>
        </div>
      </div>
    </Link>
  );
};

export default OrgPopup;
