import { useState } from 'react';
import cn from 'classnames';
import styles from './ReadAnswers.module.scss';
import ArrowReadMore from '../../../../public/icons/arrowReadMore.svg';
import { useTranslation } from 'react-i18next';

const ReadAnswers = ({ countAnswers, uploadAnswers }: { countAnswers: number; uploadAnswers?: () => void }) => {
  const [read, setRead] = useState(false);
  const { t } = useTranslation('shared');
  return (
    <button
      className={styles.answerCount}
      onClick={() => {
        setRead(!read);
        uploadAnswers();
      }}
    >
      {`${t('comments.answers')} ${countAnswers}`}
      <img
        src={ArrowReadMore}
        alt="Arrow"
        className={cn(styles.arrow, {
          [styles.open]: !!read,
        })}
      />
    </button>
  );
};

export default ReadAnswers;
