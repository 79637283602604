import cn from 'classnames';
import { GlobalContext } from 'contexts/GlobalContext';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getFeed, getFeedTG, getUserFeed } from 'services/feed';
import { IContentPreview } from 'shared/interfaces/feed';
import formatServerError from 'utils/formatServerError';
import InfiniteScrollList from 'components/InfiniteScrollList/InfiniteScrollList';
import styles from './FeedLayout.module.scss';
import NoPost from './NoPost/NoPost';
import { Tab } from 'shared/components/common/Tab/Tab';
import { GlobalDataContext } from 'contexts/GlobalDataContext';
import PostRedactor from './PostRedactor/PostRedactor';
import { StreamCardsAssignment } from 'components/Crads/StreamCards/StreamCardsAssignment';
import { useTranslation } from 'react-i18next';

interface IFeedLayout {
  isOpenUserInfo: boolean;
  setIsOpenUserInfo: (e: boolean) => void;
  profileId: string;
}

const FeedLayout = ({ isOpenUserInfo, profileId }: IFeedLayout) => {
  const { t } = useTranslation('profile');
  const [content, setContent] = useState<IContentPreview[]>([]);
  const [activeContent, setActiveContent] = useState<number>(0);
  const [isMore, setIsMore] = useState<boolean>(true);
  const [tabList, setTablist] = useState<Array<string>>(['feed.tabs.all', 'feed.tabs.subscriptions', 'feed.tabs.my']);
  const [noPostDescription, setNoPostDescription] = useState<string>('');

  const {
    isMounted,
    spinner: { showSpinner, hideSpinner },
  } = useContext(GlobalContext);

  const { user } = useContext(GlobalDataContext);

  useEffect(() => {
    if (String(user?.id) !== profileId) {
      setTablist(['feed.tabs.publications']);
      setNoPostDescription(t('feed.noPost.description.noUserContent'));
      setActiveContent(0);
    } else {
      setTablist(['feed.tabs.subscriptions', 'feed.tabs.all', 'feed.tabs.my']);
    }
  }, [profileId, user?.id]);

  useEffect(() => {
    if (String(user?.id) === profileId) {
      if (activeContent === 0) {
        setNoPostDescription(t('feed.noPost.description.default'));
      } else if (activeContent === 2) {
        setNoPostDescription(t('feed.noPost.description.create'));
      }
    } else {
      setNoPostDescription(t('feed.noPost.description.noUserContent'));
    }
  }, [activeContent, profileId, user?.id]);

  async function getContent(time?: Date, contentType?: number, isNew?: boolean) {
    try {
      let response;
      if ((contentType === 0 && String(user?.id) !== profileId) || (contentType === 2 && String(user?.id) === profileId)) {
        if (profileId) {
          response = await getUserFeed({ beforeTime: time, username: profileId });
        }
      } else {
        response = await getFeed({ beforeTime: time, organizations: activeContent === 1 ? 0 : 1 });
      }
      setIsMore(response?.length === 10);

      if (isNew) {
        setContent(response);
      } else {
        setContent([...content, ...response]);
      }
    } catch (e) {
      toast.error(formatServerError(e));
    }
  }

  const savePost = () => {
    (async () => {
      try {
        showSpinner();
        await getContent(new Date(), activeContent, true);
      } finally {
        hideSpinner();
      }
    })();
  };

  const openTg = async () => {
    try {
      const resp = await getFeedTG();

      if (resp) {
        window.open(resp, '_blank');
      }
    } catch (e) {
      toast.error(formatServerError(e));
    }
  };

  useEffect(() => {
    if (isMounted) {
      (async () => {
        try {
          showSpinner();
          await getContent(new Date(), activeContent, true);
        } finally {
          hideSpinner();
        }
      })();
    } else {
      return () => {
        setContent([]);
      };
    }
  }, [isMounted, activeContent, profileId, user?.id]);

  if (!content) {
    return <NoPost />;
  }

  return (
    <div
      className={cn(styles.wrapper, {
        [styles.fullWidthWrapper]: !isOpenUserInfo,
      })}
      id="feed-profile-wrapper"
    >
      <div className={styles.content}>
        <Tab
          tabList={tabList.map((el) => t(el))}
          setOutsidePanel={setActiveContent}
          className={styles.tabs}
          activeOutside={activeContent}
        />
        {profileId === String(user?.id) ? <PostRedactor savePost={() => savePost()} /> : null}
        {activeContent === 0 && !!user && String(user?.id) === profileId ? (
          <span className={styles.tgFeed} onClick={openTg}>
            {t('feed.posting.telegram')}
          </span>
        ) : null}
        {!!content.length ? (
          <InfiniteScrollList
            dataLength={content.length}
            next={() => getContent(content[content.length - 1].published_at, activeContent)}
            scrollableTarget="feed-profile-wrapper"
            hasMore={isMore}
            listContent={
              <>
                {content.map((el, index) => (
                  <StreamCardsAssignment updateFeed={savePost} key={`stream-card-${el.type}-${index}`} data={el} />
                ))}
              </>
            }
          />
        ) : (
          <NoPost description={noPostDescription} />
        )}
      </div>
    </div>
  );
};
export default FeedLayout;
