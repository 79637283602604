import { contentType } from 'interfaces/streamContent';
import { Link } from 'react-router-dom';
import { routes } from 'Routes';
import styles from './Tags.module.scss';
import cn from 'classnames';
import { EnumContentType } from 'containers/ContentLayout/ContentLayoutTypes';
import sanitizeHtml from 'sanitize-html';
import { allowedAttributesSchema, allowedTagsSynitizer, allowedIframeHostnamesSchema } from 'shared/constants/allowedTagsSynitizer';
import { useTranslation } from 'react-i18next';

const Tags = ({ tags, isMaterial, contentTypes }: { tags: string[]; isMaterial?: boolean; contentTypes: EnumContentType }) => {
  const { t } = useTranslation('content');
  if (!tags.length) {
    return null;
  }

  const getRouteOfTag = (tag: string, isType: boolean) => {
    if (isType) {
      switch (contentTypes) {
        case EnumContentType.news:
          return routes.NEWS;
        case EnumContentType.material:
          return routes.KNOWLEDGE_BASE_MATERIALS;
      }
    } else {
      let mainRoutePart = '';
      switch (contentTypes) {
        case EnumContentType.news:
          mainRoutePart = routes.TAGS_NEWS;
          break;
        case EnumContentType.material:
          mainRoutePart = routes.TAGS_MATERIALS;
          break;
      }
      return mainRoutePart + '?' + new URLSearchParams({ tags: tag }).toString();
    }
  };

  return (
    <div>
      {/* <span>{!isMaterial ? 'Хэш-теги новости:' : 'Хэш-теги материала:'}</span> */}
      <div className={styles.wrapperTags}>
        {tags.map((tag, index) => {
          if (!tag) {
            return null;
          }
          const isType = Object.keys(contentType).includes(tag);
          return (
            <Link
              style={{ backgroundColor: isType ? contentType[tag].color : '#fff' }}
              className={cn(
                {
                  [styles.type]: isType,
                },
                styles.tag
              )}
              key={index}
              to={getRouteOfTag(tag, isType) || '#'}
            >
              <span>
                {isType
                  ? t(`single.${tag.toLocaleLowerCase()}`)
                  : `${sanitizeHtml(tag.slice(0, 30), {
                      allowedTags: allowedTagsSynitizer,
                      allowedAttributes: allowedAttributesSchema,
                      allowedIframeHostnames: allowedIframeHostnamesSchema,
                    })}${tag.length > 30 ? '...' : ''}`}
              </span>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default Tags;
