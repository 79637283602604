import classNames from 'classnames';
import { CustomSlider } from 'components/CustomSlider/CustomSlider';
import Costs from 'components/Finance/Costs/Costs';
import { IOrganisation } from 'interfaces/organisation.interface';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getCosts, getFundraisings } from 'services/finance';
import { Icon } from 'shared/components/common/Icon/Icon';
import { Tab } from 'shared/components/common/Tab/Tab';
import { PaymentCosts, PaymentCreated } from 'shared/interfaces/finance';
import formatServerError from 'shared/utils/formatServerError';
import styles from './ProfileFinance.module.scss';
import { ProfilePayments } from './ProfilePayments/ProfilePayments';
import { useTranslation } from 'react-i18next';

type ProfileFinanceProps = {
  organizationId?: number;
  slidesOnPage: number;
  organization: IOrganisation;
  isMobile: boolean;
};

export const ProfileFinance = ({ organizationId, slidesOnPage = 1, organization, isMobile }: ProfileFinanceProps) => {
  const { t } = useTranslation('profile');
  const [payments, setPayments] = useState<PaymentCreated[]>([]);
  const [costs, setCosts] = useState<PaymentCosts[]>([]);
  const [activeSlidePayments, setActiveSlidePayments] = useState<number>(0);
  const [activeSlideCosts, setActiveSlideCosts] = useState<number>(0);
  const [activeTab, setActiveTab] = useState<number>(0);
  const activeSlide = [activeSlidePayments, activeSlideCosts];
  const setActiveSlide = [setActiveSlidePayments, setActiveSlideCosts];

  useEffect(() => {
    (async () => {
      try {
        const fundraisings = await getFundraisings(+organizationId);
        setPayments(fundraisings);
        const response = await getCosts(+organizationId);
        setCosts(response);
      } catch (e) {
        toast.error(formatServerError(e));
      }
    })();
    return () => {
      setActiveSlidePayments(0);
      setActiveSlideCosts(0);
    };
  }, [organizationId]);

  const filterPayments = payments?.filter((i) => !!i.auto_payments?.length || !!i.manual_payments?.length);

  if (!filterPayments?.length && !costs?.length) {
    return null;
  }

  const slides = filterPayments.map((el, index) => {
    return <ProfilePayments key={`ProfilePayments-${index}`} payment={el} isMobile={isMobile} />;
  });

  const selected = !!slides.length ? 0 : 1;
  const isDisabled = [!slides.length, !costs?.length];
  return (
    <div className={classNames(styles.wrapper, { [styles.mobileWrapper]: isMobile })}>
      <div className={classNames(styles.title, { [styles.mobile]: isMobile })}>
        <h4 className={classNames({ [styles.titleTextDesc]: !isMobile })}>{t('organization.finance.title')}</h4>
        {activeTab === 0 && slides.length > 1 && (
          <div className={classNames(styles.controls)}>
            <button
              className={styles.button}
              disabled={activeSlide[activeTab] === 0}
              onClick={() => setActiveSlide[activeTab](activeSlide[activeTab] - 1)}
              title={t('buttons.slider.prev')}
            >
              <Icon width={24} height={24} iconName={'arrowLeft'} color="black" />
            </button>
            <button
              className={styles.button}
              onClick={() => setActiveSlide[activeTab](activeSlide[activeTab] + 1)}
              disabled={activeSlide[activeTab] >= slides.length - 1}
              title={t('buttons.slider.next')}
            >
              <Icon width={24} height={24} iconName={'arrowRight'} color="black" />
            </button>
          </div>
        )}
        <Tab
          selectDefault={selected}
          isDisabled={isDisabled}
          className={styles.tab}
          tabPanels={[]}
          setOutsidePanel={setActiveTab}
          tabList={[t('organization.finance.tabs.0'), t('organization.finance.tabs.1')]}
        />
      </div>

      {activeTab === 0 && <CustomSlider activeSlide={activeSlidePayments} slides={slides} slidesOnPage={slidesOnPage} />}
      {activeTab === 1 && !!costs?.length && <Costs isMobile={isMobile} costs={costs} />}
    </div>
  );
};
