import styles from './LoadMoreButton.module.scss';
import { useDispatch, useSelector } from '../../../../redux-toolkit';
import { CommentsState, getCommentsAction } from 'shared/slices/comments';
import { toast } from 'react-toastify';
import formatServerError from 'shared/utils/formatServerError';
import { useState } from 'react';
import ArrowReadMore from '../../../public/icons/arrowReadMore.svg';
import { useTranslation } from 'react-i18next';

interface ILoadMoreButton {
  after_id: number;
  parent_id?: number;
  buttonText?: string;
}

const LoadMoreButton = ({ after_id, parent_id, buttonText }: ILoadMoreButton) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('shared');
  const { generalData } = useSelector((state) => state.comments as CommentsState);
  const [isLoadMore, setIsLoadMore] = useState(true);

  const loadMore = async () => {
    try {
      const response = await dispatch(
        getCommentsAction(
          generalData.essenceId,
          generalData.essenceName,
          parent_id,
          10,
          after_id,
          generalData.isAdminApp
        )
      );
      if (!response?.length || response?.length < 9) {
        setIsLoadMore(false);
      }
    } catch (e) {
      toast.error(formatServerError(e));
    }
  };

  if (isLoadMore) {
    return (
      <button className={styles.loadMore} onClick={loadMore}>
        {buttonText || t('comments.loadMore')}
        <img src={ArrowReadMore} alt="Arrow" className={styles.arrow} />
      </button>
    );
  } else {
    return null;
  }
};

export default LoadMoreButton;
