import styles from './SectionMaterials.module.scss';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import formatServerError from 'utils/formatServerError';
import { getSectionMaterials } from 'services/knowledge-base';
import { KbaseMaterialFront } from 'shared/interfaces/kbase';
import { useSsrEffect, useSsrState } from '@issr/core';
import { useTranslation } from 'react-i18next';
import ActivityGroup from 'components/ActivityGroup/ActivityGroup';
import SEO from 'shared/utils/Seo';

const SectionMaterials = () => {
  const { t } = useTranslation('kbase');
  const { organizationId, sectionId } = useParams<{ organizationId: string; sectionId: string }>();
  const [materials, setMaterials] = useSsrState<KbaseMaterialFront[]>([]);
  const [serverError, setServerError] = useSsrState(null);

  async function loadMaterials() {
    if (!!organizationId && !!sectionId) {
      const response = await getSectionMaterials(+organizationId, +sectionId);
      setMaterials(response);
    }
  }

  useEffect(() => {
    setMaterials([]);
  }, []);

  useSsrEffect(async () => {
    try {
      setMaterials([]);
      await loadMaterials();
    } catch (error) {
      console.log(formatServerError(error));
      setServerError(formatServerError(error));
    }
    return () => {
      setMaterials([]);
    };
  });

  useEffect(() => {
    (async () => {
      try {
        await loadMaterials();
      } catch (e) {
        toast.error(formatServerError(e));
      }
    })();
  }, [organizationId, sectionId]);

  if (serverError) {
    return <div>{serverError}</div>;
  }

  if (!materials.length) return null;

  return (
    <>
      <SEO
        image={'/logo-small.svg'}
        title={`${t('section')}: ${materials[0].section.name}`}
        description={`${t('section')}: ${materials[0].section.name}`}
      />

      <div className={styles.wrapper}>
        <ActivityGroup
          loadData={loadMaterials}
          hasMoreData={false}
          translateType="kbase"
          activities={materials.map((eachNews) => {
            return { ...eachNews, type: 'material', user: eachNews.user };
          })}
          title={`${t('section')}: ${materials[0].section.name}`}
          type="material"
          organization={materials[0]?.organization}
        />
      </div>
    </>
  );
};

export default SectionMaterials;
