import enEmojiLocale from './locales/en/emoji.json';
import ruEmojiLocale from './locales/ru/emoji.json';
import ukEmojiLocale from './locales/uk/emoji.json';
import beEmojiLocale from './locales/be/emoji.json';
import ltEmojiLocale from './locales/lt/emoji.json';
import plEmojiLocale from './locales/pl/emoji.json';
import { BaseEmoji, Picker } from 'emoji-mart';
import { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import styles from './CustomEmojiPicker.module.scss';
import { useTranslation } from 'react-i18next';

type Props = {
  onSelect: (val: string) => void;
  position?: {
    top?: string | number;
    bottom?: string | number;
    left?: string | number;
    right?: string | number;
  };
  showEmoji: boolean;
  setShowEmoji: Dispatch<SetStateAction<boolean>>;
};

export const CustomEmojiPicker = ({ onSelect, position = { right: 'auto', top: 0 }, setShowEmoji, showEmoji }: Props) => {
  const { i18n } = useTranslation();
  const emojiRef = useRef<HTMLDivElement>(null);
  const emojiTranslations = {
    en: enEmojiLocale,
    ru: ruEmojiLocale,
    uk: ukEmojiLocale,
    be: beEmojiLocale,
    lt: ltEmojiLocale,
    pl: plEmojiLocale,
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (emojiRef.current && !emojiRef.current.contains(event.target as Node)) {
        setShowEmoji(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [setShowEmoji, showEmoji]);

  const onSelectHandler: (emoji: BaseEmoji) => void = (ev) => {
    onSelect(ev.native);
  };

  return (
    <div className={styles.wrapper}>
      {showEmoji && (
        <div
          ref={emojiRef}
          className={styles.emojiWrapper}
          style={{
            left: position.left,
            right: position.right,
            top: position.top,
            bottom: position.bottom,
          }}
        >
          <Picker
            onSelect={onSelectHandler}
            i18n={emojiTranslations[i18n.language] || enEmojiLocale}
            showPreview={false}
            showSkinTones={false}
          />
        </div>
      )}
    </div>
  );
};
