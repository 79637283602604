import { useState } from 'react';
import CommentTextArea from '../CommentTextArea/CommentTextArea';
import { useDispatch, useSelector } from '../../../../redux-toolkit';
import { CommentsState, createCommentAction } from 'shared/slices/comments';
import { toast } from 'react-toastify';
import formatServerError from 'shared/utils/formatServerError';
import { useTranslation } from 'react-i18next';

const NewCommenteCreate = () => {
  const { t } = useTranslation('shared');
  const dispatch = useDispatch();
  const { generalData } = useSelector((state) => state.comments as CommentsState);
  const [comment, setComment] = useState('');

  const createNewComment = async () => {
    try {
      await dispatch(
        createCommentAction(generalData.essenceId, generalData.essenceName, comment, undefined, generalData.isAdminApp)
      );
      setComment('');
      toast(t('toast.addComment'));
    } catch (e) {
      toast.error(formatServerError(e));
    }
  };

  return <CommentTextArea value={comment} setValue={setComment} onClick={createNewComment} id="new" />;
};

export default NewCommenteCreate;
