import { Icon } from 'shared/components/common/Icon/Icon';
import styles from './HideProfileButton.module.scss';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
type CloseButtonProps = {
  onclick: React.MouseEventHandler<HTMLButtonElement>;
  isOpenUserInfo: boolean;
};

export const HideProfileButton = ({ onclick, isOpenUserInfo }: CloseButtonProps) => {
  const { t } = useTranslation('profile');
  return (
    <button
      className={cn(styles.toggleButton, {
        [styles.openButton]: !isOpenUserInfo,
      })}
      onClick={onclick}
      title={isOpenUserInfo ? t('feed.buttons.hideProfile') : t('feed.buttons.showProfile')}
    >
      <Icon rotate={isOpenUserInfo ? 0 : 180} iconName={'arrowBorderLeft'} color="black" />
    </button>
  );
};
