import { Button } from 'shared/components/common/Button/Button';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styles from './GoogleButton.module.scss';

const GoogleButton = () => {
  const { t } = useTranslation('auth');
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const redirect = urlSearchParams.get('redirect') ?? '';

  const openGoogleLink = () => {
    localStorage?.setItem('redirect', redirect);
    window?.open(
      `https://accounts.google.com/o/oauth2/auth?client_id=320595447927-km1i5vmcab8jgasbb1b0vj3v4fs043cg.apps.googleusercontent.com&redirect_uri=http${
        window?.location?.host !== 'localhost:3000' ? 's' : ''
      }%3A%2F%2F${window?.location?.host}/google-check&scope=openid+profile+email&response_type=code`,
      '_self'
    );
  };

  return (
    <Button className={styles.google} type="button" color="light" maxWidth onClick={openGoogleLink}>
      <img className={styles.image} src="/google.svg" alt="Google-icon" />
      <h4>{t('googleButton')}</h4>
    </Button>
  );
};

export default GoogleButton;
