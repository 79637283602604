import NewsGroupContainer, { NewsDataTypes } from './NewsGroupContainer/NewsGroupContainer';
import styles from './News.module.scss';
// import Navigation from 'common/Navigation/Navigation';
// import { routes } from 'Routes';
import { NewsGroupDirection } from './NewsGroup/NewsGroup';
import SEO from 'shared/utils/Seo';
import BannerWithButton from 'common/BannerWithButton/BannerWithButton';
import { bannersNewsMock } from 'common/BannerWithButton/bannersMock';

function News() {
  // const navigations = [
  //   {
  //     title: 'Все новости',
  //     route: routes.NEWS,
  //   },
  //   // {
  //   //   title: 'Беларусы за Украину',
  //   //   route: routes.NEWS_BELARUS_FOR_UKRAINE,
  //   // },
  // ];
  return (
    <>
      <SEO image={'/logo-small.svg'} title={'Новости'} description={'Новости'} />

      <div className={styles.newsWrapper}>
        <BannerWithButton banners={bannersNewsMock} />
        {/* <Navigation navigations={navigations} /> */}

        {/* <NewsGroupContainer type={NewsDataTypes.bestNews} key={'bestNews'} /> */}

        {/* <NewsGroupContainer type={NewsDataTypes.popular} key={'popular'} direction={NewsGroupDirection.slider} /> */}

        <NewsGroupContainer type={NewsDataTypes.allNews} key={'allNews'} direction={NewsGroupDirection.grid} isNewDesign />
      </div>
    </>
  );
}

export default News;
