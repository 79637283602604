import classNames from 'classnames';
import ReasonLeaveOrganization from 'components/Modals/ReasonLeaveOrganization/ReasonLeaveOrganization';
import { SuggestionForm } from 'components/Suggestions/SuggestionForm/SuggestionForm';
import { GlobalContext } from 'contexts/GlobalContext';
import { GlobalDataContext } from 'contexts/GlobalDataContext';
import { EnterOrganizationStatuses, Hiddens, IOrganisation, IOrganizationEnterRequest } from 'interfaces/organisation.interface';
import { ISuggestion } from 'interfaces/suggestion.interface';
import { useContext, useEffect, useState } from 'react';
import { Button } from 'shared/components/common/Button/Button';
import { CustomMembers } from './CustomMembers/CustomMembers';
import { HelpOffers } from './HelpOffers/HelpOffers';
import { OrganizationPolls } from './OrganizationPolls/OrganizationPolls';
import styles from './OrganizationProfile.module.scss';
import { ProfileAbout } from './ProfileAbout/ProfileAbout';
import { ProfileBlog } from './ProfileBlog/ProfileBlog';
import { ProfileEvents } from './ProfileEvents/ProfileEvents';
import { ProfileFinance } from './ProfileFinance/ProfileFinance';
import { ProfileKanban } from './ProfileKanban/ProfileKanban';
import { ProfileNews } from './ProfileNews/ProfileNews';
import { ProfileSuggestions } from './ProfileSuggestions/ProfileSuggestions';
import { SuggestionButton } from './SuggestionButton/SuggestionButton';
import { useTranslation } from 'react-i18next';

type OrganizationProfileProps = {
  organization: IOrganisation;
  requestStatus: IOrganizationEnterRequest;
  isOpenOrgInfo: boolean;
  isOpenStream: boolean;
  setRequestStatus: (status: IOrganizationEnterRequest) => void;
  orgId: number;
};

export const OrganizationProfile = ({
  organization,
  isOpenOrgInfo,
  requestStatus,
  setRequestStatus,
  isOpenStream,
  orgId,
}: OrganizationProfileProps) => {
  const { t } = useTranslation('profile');
  const { user } = useContext(GlobalDataContext);
  const {
    openModal,
    services: { deviceService },
    screen: { innerWidth },
  } = useContext(GlobalContext);
  const [suggestionBlockIsActive, setSuggestionBlockStatus] = useState<boolean>(false);
  const [suggestionEdit, setSuggestionEdit] = useState<ISuggestion>(null);
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    setIsMobile(isOpenStream || deviceService.isMobile);
    if (suggestionBlockIsActive) {
      document.documentElement.classList.remove('autoscroll');
    } else {
      document.documentElement.classList.add('autoscroll');
      setSuggestionEdit(null);
    }
  }, [suggestionBlockIsActive, isOpenStream]);

  const userInOrganization = requestStatus?.status === EnterOrganizationStatuses.active;

  if (!organization || !orgId) {
    return null;
  }

  return (
    <div
      className={classNames(styles.scrollBar, {
        [styles.fullWidthWrapper]: isOpenOrgInfo && !isOpenStream,
        [styles.hide]: !isOpenOrgInfo,
      })}
    >
      <div className={styles.wrapper}>
        <ProfileAbout
          className={classNames(styles.container, { [styles.mobileContainer]: isMobile })}
          isOpenStream={isOpenStream}
          requestStatus={requestStatus}
          organization={organization}
          setRequestStatus={setRequestStatus}
          isMobile={isMobile}
          id={orgId}
        />

        <CustomMembers isMobile={isMobile} organisationId={orgId} slidesOnPage={isMobile ? 1 : 3} />

        <ProfileNews isMobile={isMobile} organizationId={orgId} slidesOnPage={isMobile ? 1 : 2} />

        <ProfileBlog isMobile={isMobile} slidesOnPage={isMobile ? 1 : 2} organization={organization} organizationId={orgId} />

        <ProfileEvents isMobile={isMobile} organizationId={orgId} slidesOnPage={isMobile ? 1 : 2} />

        {!!user?.id && <OrganizationPolls isMobile={isMobile} slidesOnPage={isMobile ? 1 : 2} organizationId={orgId} />}

        {!!orgId && !(organization?.hiddens.includes(Hiddens.tasks) && !userInOrganization) && (
          <ProfileKanban isMobile={isMobile} organizationId={orgId} />
        )}

        {user && userInOrganization && <HelpOffers unionId={orgId} />}

        {/* {!!orgId && !!userInOrganization && (

          <PollsTabs organisationId={orgId} />

        )} */}
        <ProfileFinance isMobile={isMobile} organizationId={orgId} organization={organization} slidesOnPage={isMobile ? 1 : 2} />

        {(!isMobile || innerWidth < 768) && userInOrganization && (
          <div className={classNames(styles.container, { [styles.mobileContainer]: isMobile })}>
            {suggestionBlockIsActive ? (
              <SuggestionForm setSuggestionBlockStatus={setSuggestionBlockStatus} suggestionEdit={suggestionEdit} />
            ) : (
              <ProfileSuggestions
                setSuggestionEdit={setSuggestionEdit}
                setSuggestionBlockStatus={setSuggestionBlockStatus}
                isMobile={isMobile}
                organizationId={orgId}
                userMembership={user?.membership}
              />
            )}
            {!suggestionBlockIsActive && !!userInOrganization && <SuggestionButton setSuggestionBlockStatus={setSuggestionBlockStatus} />}
          </div>
        )}

        {!!userInOrganization && (
          <div className={classNames(styles.container, { [styles.mobileContainer]: isMobile })}>
            <Button
              color="light"
              size="small"
              dataCy="leave-organization"
              className={styles.exit}
              onClick={() =>
                openModal({
                  params: {
                    mainContainer: (
                      <ReasonLeaveOrganization
                        organisationId={organization.id}
                        onSuccess={() => {
                          setRequestStatus(null);
                        }}
                      />
                    ),
                  },
                })
              }
            >
              {t('buttons.exitOrg')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
