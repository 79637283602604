import { FormikErrors } from 'formik';
import { CreationEvent } from 'interfaces/events.interface';
import { useTranslation } from 'react-i18next';
import { mockImages } from 'shared/constants/MockImages/MockImages';
import { SettingsSkeleton } from 'components/PublicationSettings/SettingsSkeleton';
import { PublicationSettingsElementWrapper } from 'components/PublicationSettings/ElementWrapper/ElementWrapper';
import { PublicationOrgSelection } from 'components/PublicationSettings/PublicationOrgSelection/PublicationOrgSelection';
import { PublicationVisibility } from 'components/PublicationSettings/PublicationVisibility/PublicationVisibility';
import { PreviewEditor } from 'components/PublicationSettings/PreviewEditor/PreviewEditor';
import { Agreements } from 'components/PublicationSettings/Agreements/Agreements';

type Props = {
  values: CreationEvent;
  setValue: <K extends keyof CreationEvent>(field: K, val: CreationEvent[K]) => void;
  onDraft: () => Promise<void> | void;
  onPublish: () => Promise<void> | void;
  errors: FormikErrors<CreationEvent>;
  administer: Array<{ id: string; short_name: string; avatar?: string }>;
  selectedOrg: string;
  setSelectedOrg: (val: string) => void;
  onDrop: (files: File[]) => Promise<void>;
  setLocalCropper: (val: Cropper) => void;
};

const PublicationEventSettings = ({
  administer,
  onDraft,
  errors,
  onDrop,
  onPublish,
  selectedOrg,
  setLocalCropper,
  setSelectedOrg,
  setValue,
  values,
}: Props) => {
  const { t } = useTranslation('editor');

  return (
    <SettingsSkeleton onDraft={onDraft} onPublish={onPublish} isActionsDisabled={!selectedOrg || !values.isAgree}>
      <PublicationSettingsElementWrapper title={t('settings.general.title')} isOpenDefault>
        <PublicationOrgSelection administer={administer} selectedOrg={selectedOrg} setSelectedOrg={setSelectedOrg} />
        <PublicationVisibility setVisible={(val) => setValue('visible', val)} visible={values.visible} />
        <PreviewEditor onDrop={onDrop} preview={values.preview || mockImages.news} setLocalCropper={setLocalCropper} />
      </PublicationSettingsElementWrapper>
      <Agreements setValue={setValue} values={values} />
    </SettingsSkeleton>
  );
};

export default PublicationEventSettings;
