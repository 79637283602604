import { routes } from 'Routes';
import { CreationEvent, EventsData } from 'interfaces/events.interface';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createEvents, publishEvents } from 'services/api/events';
import { addGeneralImage } from 'services/general';
import formatServerError from 'shared/utils/formatServerError';

export const useEventsCreation = () => {
  const { t } = useTranslation('editor');
  const history = useHistory();

  const onPublish = async (orgId: string, data: Partial<EventsData>) => {
    const created = await saveHandler(orgId, data);
    await publishEvents(orgId, created.id);
    history.push(routes.eventsDetails.getLink(orgId, created.id));
  };
  const onSave = async (orgId: string, data: Partial<EventsData>) => {
    await saveHandler(orgId, data);
    /* history.push(routes.eventsDetails.getLink(orgId, created.id)); */
  };

  const saveHandler = async (orgId: string, data: Partial<EventsData>) => {
    try {
      const created = await createEvents(orgId, data);
      toast(t('toasts.success.eventCreated'));
      if (created.linkRoyPass) {
        window.open(created.linkRoyPass, '_blank', 'noopener,noreferrer');
        history.push(routes.PROFILE);
      }
      return created;
    } catch (error) {
      toast.error(formatServerError(error));
    }
  };

  const getInitialDateEnd = () => {
    const dateEnd: Date = new Date();
    dateEnd.setHours(23);
    dateEnd.setMinutes(59);
    dateEnd.setSeconds(59);
    return dateEnd;
  };

  const initialValues: CreationEvent = {
    title: '',
    content: '',
    political: false,
    sensitive: false,
    tags: [],
    preview: undefined,
    visible: 0,
    date_start: new Date(Date.now() + 1000 * 60 * 5),
    date_end: getInitialDateEnd(),
    place: undefined,
    price: undefined,
    point: undefined,
    isAgree: false,
    needRoyPass: false,
  };
  return {
    uploadImage: addGeneralImage,
    onPublish,
    onSave,
    initialValues,
  };
};
