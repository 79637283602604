import { ContentEditorSkeleton } from 'components/ContentEditor/ContentEditorSkeleton';
import PublicationEventSettings from './PublicationSettings/PublicationSettings';
import { useEventsCreation } from './hooks/eventsCreation';

export const EventsCreation = () => {
  const { onPublish, onSave, uploadImage, initialValues } = useEventsCreation();

  return (
    <ContentEditorSkeleton
      ContentSettings={PublicationEventSettings}
      uploadImage={uploadImage}
      onPublish={onPublish}
      onSave={onSave}
      initialValues={initialValues}
    />
  );
};
