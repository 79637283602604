import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createSection, getAdminSections } from 'services/knowledge-base';
import { Button } from 'shared/components/common/Button/Button';
import { KbaseSectionLight } from 'shared/interfaces/kbase';

import styles from './PublicationSection.module.scss';
import { toast } from 'react-toastify';
import { Icon } from 'shared/components/common/Icon/Icon';
import formatServerError from 'shared/utils/formatServerError';
import { Spinner } from 'shared/components/common/Spinner/Spinner';

const CREATE_ID = 9999999;

type Props = {
  orgId?: string;
  section: number;
  setSection: (val: number) => Promise<void> | void;
};

export const PublicationSection = ({ orgId, section, setSection }: Props) => {
  const { t } = useTranslation('editor');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCreation, setIsCreation] = useState<boolean>(false);
  const [sections, setSections] = useState<KbaseSectionLight[]>([{ name: `${t('create')}${t('section')}`, id: CREATE_ID }]);
  const [createSectionValue, setCreateSectionValue] = useState('');

  useEffect(() => {
    (async () => {
      if (!orgId) {
        return;
      }
      try {
        setIsLoading(true);

        const responseSections = await getAdminSections(Number(orgId));
        setSections(() => {
          return [...responseSections, { name: `${t('create')}${t('section')}`, id: CREATE_ID }];
        });
      } catch (e) {
        console.log(formatServerError(e));
        toast.error(formatServerError(e));
      } finally {
        setIsLoading(false);
      }
    })();
  }, [orgId]);

  const createSectionFunc = async () => {
    const newSection = await createSection(
      {
        name: createSectionValue,
      },
      Number(orgId)
    );
    const newSections = sections;
    newSections.unshift(newSection);
    setSections(newSections);
    toast(t('toasts.success.sectionCreated'));
    setCreateSectionValue('');
    setIsCreation(false);
  };

  return (
    <FormControl fullWidth>
      <InputLabel disabled={!orgId} id="section-selection">
        {t('section')}
      </InputLabel>
      <Select
        disabled={!orgId}
        labelId="section-selection"
        value={section || 0}
        label={t('section')}
        required
        onChange={(ev) => {
          if (ev.target.value === CREATE_ID) {
            ev.preventDefault();
            ev.stopPropagation();
            setIsCreation(true);
            setSection(undefined);
            return;
          }
          setSection(Number(ev.target.value));
        }}
      >
        {isLoading ? (
          <Spinner position="center" color="purple" size={24} />
        ) : (
          sections?.map((el) => {
            return (
              <MenuItem value={el.id} key={`org-${el.id}`}>
                {el.id === CREATE_ID && <Icon className={styles.iconSquare} color="black" iconName="addSquare" />} {el.name}
              </MenuItem>
            );
          })
        )}
      </Select>
      {isCreation && (
        <div className={styles.wrapperCreation}>
          <TextField
            fullWidth
            label={`${t('create')}${t('section')}`}
            placeholder="..."
            variant="outlined"
            value={createSectionValue}
            onChange={(e) => setCreateSectionValue(e.target.value)}
          />
          <Button disabled={!createSectionValue} maxWidth onClick={createSectionFunc}>
            {t('create')} {t('section')}
          </Button>
        </div>
      )}
    </FormControl>
  );
};
