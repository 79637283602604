// import PaymentModal from 'common/PaymentModal/PaymentModal';
// import { StepsProgressBar } from 'common/StepsProgressBar/StepsProgressBar';
import { ModalCard } from 'components/Crads/ModalCards/ModalCard';
// import { currencyLabel } from 'components/Finance/Finance';
import InfiniteScrollList from 'components/InfiniteScrollList/InfiniteScrollList';
import { GlobalContext } from 'contexts/GlobalContext';
import { CONTENT_TYPE } from 'interfaces/streamContent';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { routes } from 'Routes';
import { suggestionsService } from 'services';
import { getOrganizationEvents } from 'services/events';
// import { getFundraisings } from 'services/finance';
import { getOrganizationMaterials, getOrganizationSections } from 'services/knowledge-base';
import { getOrganisationNews, getOrgNewsFilter } from 'services/news';
// import { Button } from 'shared/components/common/Button/Button';
import { mockImages } from 'shared/constants/MockImages/MockImages';
import { getTasks } from 'shared/services/tasks.service';
import formatServerError from 'utils/formatServerError';
import styles from './MapActivityModal.module.scss';
import { useTranslation } from 'react-i18next';

type MapModalProps = {
  type: CONTENT_TYPE;
  organizationId: number;
};

type Config = {
  method: (val: getOrgNewsFilter) => Promise<Array<JSX.Element>>;
};

export const MapActivityModal = ({ organizationId, type }: MapModalProps) => {
  const { t } = useTranslation('content');
  const listId = `${type}-modal-container`;
  const {
    isMounted,
    closeModal,
    // openModal,
    spinner: { showSpinner, hideSpinner },
  } = useContext(GlobalContext);
  // const openPaymentModal = (payment) => {
  //   openModal({
  //     params: {
  //       mainContainer: <PaymentModal payment={payment} />,
  //     },
  //   });
  // };
  const [hasMore, setHasMore] = useState(false);
  const [metaPageData, setMetaPageData] = useState<{ last_page: number; current_page: number }>({ last_page: 0, current_page: 0 });
  const [data, setData] = useState<Array<JSX.Element>>([]);
  const config: Partial<Record<CONTENT_TYPE, Config>> = {
    news: {
      method: async (data) => {
        const response = await getOrganisationNews(data);
        return response.data.map((el, index) => {
          return (
            <ModalCard
              content={el.excerpt}
              image={el.image || mockImages.news}
              title={el.title}
              author={{ id: el.user_id.id, logo: el.user_id.public_avatar, name: el.user_id.public_name }}
              commentsCount={el.comments_count || 0}
              organization={{ id: el.organization.id, logo: el.organization.avatar, name: el.organization.name }}
              publishedAt={el.published_at}
              withFooter={true}
              link={routes.newsDetails.getLink(el.organization.id, el.id)}
              key={`news-card-${index}`}
            />
          );
        });
      },
    },
    /* DONATE: {
      method: async (data) => {
        const response = await getFundraisings(data.organisationId);
        return response.map((payment, index) => {
          const isPromotion = payment.type === 1;
          const content = (
            <div className={styles.content}>
              {isPromotion && <StepsProgressBar stepNumber={payment.collected} stepsCount={payment.ammount} />}
              <div className={styles.payButtonWrapper}>
                <Button className={styles.button} maxWidth color="light" size="small" onClick={() => openPaymentModal(payment)}>
                  Помочь
                </Button>
                {isPromotion && (
                  <div className={styles.paidCount}>
                    <span>Оплачено:</span>
                    <div className={styles.count}>
                      {payment.collected}
                      {currencyLabel[payment.currency]}
                    </div>
                  </div>
                )}
              </div>
              <span>{payment.description}</span>
              {isPromotion && <span>{`Действует до ${payment.date_end}`}</span>}
            </div>
          );
          return (
            <ModalCard
              content={content}
              image={payment.image || mockImages.payment}
              title={payment.title}
              publishedAt={payment.created_at}
              withFooter={false}
              eventExtra={{ date_start: payment.created_at, date_end: payment.date_end, price: payment.currency }}
              key={`fundraisings-card-${index}`}
            />
          );
        });
      },
      title: 'Сбор средств',
    }, */
    event: {
      method: async (data) => {
        const response = await getOrganizationEvents(data);
        return response.data.map((el, index) => {
          return (
            <ModalCard
              content={el.excerpt}
              image={el.image || mockImages.news}
              title={el.title}
              eventExtra={{ date_start: el.date_start, date_end: el.date_end, place: el.place, price: el.price }}
              author={{ id: el.user.id, logo: el.user.public_avatar, name: el.user.public_name }}
              commentsCount={el.comments_count || 0}
              organization={{ id: el.organization.id, logo: el.organization.avatar, name: el.organization.name }}
              publishedAt={el.published_at}
              withFooter={true}
              link={routes.newsDetails.getLink(el.organization.id, el.id)}
              key={`events-card-${index}`}
            />
          );
        });
      },
    },
    material: {
      method: async (data) => {
        const response = await getOrganizationMaterials(data);
        return response.data.map((el, index) => {
          return (
            <ModalCard
              author={{ id: el.user.id, logo: el.user.public_avatar, name: el.user.public_name }}
              content={el.content}
              image={el.image || mockImages.material}
              link={routes.materialDetails.getLink(el.organization.id, el.id)}
              withFooter={true}
              title={el.title}
              publishedAt={el.published_at}
              organization={{ id: el.organization.id, logo: el.organization.avatar, name: el.organization.short_name }}
              commentsCount={el.comments_count || 0}
              key={`materials-card-${index}`}
            />
          );
        });
      },
    },
    SECTIONS: {
      method: async (data) => {
        const response = await getOrganizationSections(data.organisationId);
        return response.data.map((el, index) => {
          return (
            <ModalCard
              link={routes.sectionMaterials.getLink(el.organization_id, el.id)}
              withFooter={false}
              content={el.description}
              image={el.cover || mockImages.section}
              title={el.name}
              publishedAt={el.updated_at}
              commentsCount={0}
              key={`sections-card-${index}`}
            />
          );
        });
      },
    },
    suggestion: {
      method: async (data) => {
        const response = await suggestionsService.getSuggestions(data.organisationId);
        return response.map((el, index) => {
          return (
            <ModalCard
              link={routes.sectionMaterials.getLink(el.organization_id, el.id)}
              withFooter={false}
              content={el.description}
              image={el.images[0]?.url || mockImages.section}
              title={el.title}
              publishedAt={el.updated_at}
              commentsCount={0}
              key={`suggestions-card-${index}`}
            />
          );
        });
      },
    },
    task: {
      method: async (data) => {
        const res = await getTasks(String(data.organisationId));
        return res.data.data.map((card, i: number) => {
          if (card.column_id !== 0) {
            const { user } = card;
            const link = `/union/${organizationId}/tasks/${card.id}`;
            return (
              <ModalCard
                link={link}
                withFooter={true}
                commentsCount={card.comments_count}
                author={{ id: user.id, logo: user.public_avatar, name: user.public_name }}
                content={card.description}
                image={card.image || mockImages.section}
                title={card.title}
                publishedAt={card.created_at}
                key={`modal-tasks-card-${card.id}-${i}`}
              />
            );
          } else {
            return <></>;
          }
        });
      },
    },
  };

  const loadData = async (limit: number = 10) => {
    let requestedData = [];

    if (type) {
      const { method } = config[type];

      const obj = {
        ...{
          organisationId: organizationId,
          limit: limit,
          page: metaPageData?.current_page ? metaPageData.current_page + 1 : 1,
        },
      };

      showSpinner();
      requestedData = await method(obj);
      setHasMore(requestedData.length === limit);
      setMetaPageData({ current_page: metaPageData.current_page + 1, last_page: metaPageData.current_page });

      hideSpinner();
    }

    setData(!isMounted ? [] : [...data, ...requestedData]);
  };
  useEffect(() => {
    if (config[type] && isMounted) {
      (async () => {
        try {
          await loadData(10);
        } catch (e) {
          toast.error(formatServerError(e));
        }
      })();
    }
    return () => {
      setData([]);
      setMetaPageData(null);
    };
  }, [type, organizationId, isMounted]);

  if (!config[type]) {
    closeModal();
    return null;
  }
  return (
    <div className={styles.wrapper} id={listId}>
      <h4>{t(`match.${type.toLocaleLowerCase()}`)}</h4>
      <InfiniteScrollList
        scrollableTarget={listId}
        dataLength={data?.length}
        next={() => loadData()}
        hasMore={hasMore}
        listContent={<div className={styles.content}>{data}</div>}
      />
    </div>
  );
};
