import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DragDropContext } from 'react-beautiful-dnd';
import type { DropResult } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from '../../../redux-toolkit';
import { getBoard, getColumn, moveCard, moveCardWithComment, setBoard } from 'shared/slices/tasks';
import { toast } from 'react-toastify';
import { Column, getTask, dragTask } from 'shared/services/tasks.service';
import KanbanColumn from './KanbanColumn/KanbanColumn';
import styles from './KanbanContainer.module.scss';
import formatServerError from 'shared/utils/formatServerError';
import { Spinner } from '../common/Spinner/Spinner';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

/* const COLUMN_ID_LIST_TASKS = 2;
const COLUMN_ID_NOT_DONE = 4; */

const Kanban = ({
  closeModal,
  openModal,
  isOnlyMyCards,
  getOrganizationUsers,
  isAdminApp,
  orgId,
  profile,
  userInOrganization,
  className,
}: any) => {
  const { t } = useTranslation('shared');
  const dispatch = useDispatch();
  const tasks = useSelector((state) => state.tasks);
  let { organizationId } = useParams<{ organizationId?: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  if (orgId) {
    organizationId = orgId;
  }
  const fetchTasks = useCallback(async () => {
    try {
      setIsLoading(true);
      await dispatch(getBoard(organizationId, isAdminApp));
    } catch (e) {
      toast.error(formatServerError(e));
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, isAdminApp, organizationId]);

  useEffect(() => {
    fetchTasks();

    return () => {
      dispatch(setBoard([]));
    };
  }, [organizationId, dispatch, fetchTasks]);

  const getCardUsers = async (cardId: string) => {
    try {
      const {
        data: { data },
      } = await getTask(organizationId, cardId);
      return [...data.users, data.user];
    } catch (err) {
      toast.error(formatServerError(err));
      console.error(err);
    }
    return [];
  };

  const handleDragEnd = async ({ source, destination, draggableId }: DropResult): Promise<void> => {
    try {
      // Dropped outside the column
      if (!destination) {
        return;
      }

      // Card has not been moved
      if (source.droppableId === destination.droppableId && source.index === destination.index) {
        return;
      }
      /* if (
        Math.abs(Number(source.droppableId) - Number(destination.droppableId)) > 1 &&
        !(Number(source.droppableId) === COLUMN_ID_LIST_TASKS && Number(destination.droppableId) === COLUMN_ID_NOT_DONE)
      ) {
        return;
      } */

      if (source.droppableId === destination.droppableId) {
        // Moved to the same column on different position
        let rowIndex = tasks.columns[destination.droppableId].cards[destination.index].index;
        if (source.index > destination.index) {
          rowIndex -= 1;
        } else if (destination.index === 0) {
          rowIndex = 1;
        }
        await dragTask(orgId, draggableId, rowIndex);
        dispatch(getColumn(organizationId, destination.droppableId, isAdminApp));
        return;
      } else {
        // Moved to another column
        const isAdmin = !!profile.administer.find((org) => org.id === +orgId);
        if (!isAdmin) {
          const users = await getCardUsers(draggableId);
          if (users.filter(({ id }) => id === profile.id).length === 0) {
            toast.error(t('toast.notWorkingAtTask'));
            return;
          }
        }

        dispatch(moveCard({ source, destination, draggableId, columns: tasks.columns, orgId: organizationId }));
        dispatch(
          moveCardWithComment({
            orgId: organizationId,
            isAdminApp,
          })
        );
      }
    } catch (err) {
      console.error(err);
    }
  };

  return !!isLoading ? (
    <div className={styles.wrapperSpinner}>
      <Spinner size={100} borderWidth="8px" color="purple" />
    </div>
  ) : (
    <DragDropContext onDragEnd={handleDragEnd}>
      <div className={classNames(styles.wrapper, className)}>
        {tasks.columns.map((column: Column, index) => {
          if (index === 0 && !isAdminApp) return null;
          return (
            <KanbanColumn
              isSuggestion={index === 0}
              isOnlyMyCards={isOnlyMyCards}
              user={profile}
              userInOrganization={userInOrganization}
              orgId={organizationId}
              isAdminApp={isAdminApp}
              closeModal={closeModal}
              getOrganizationUsers={getOrganizationUsers}
              column={column}
              key={column?.id}
              fetchTasks={fetchTasks}
              openModal={openModal}
            />
          );
        })}
      </div>
    </DragDropContext>
  );
};

export default Kanban;
