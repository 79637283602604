import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'shared/components/common/Icon/Icon';
import { formatDateForGoogle } from 'utils/formatDateForGoogle';
import { format } from 'date-fns';
import { ru, be, enGB, lt, uk } from 'date-fns/locale';
import styles from './EventsInfo.module.scss';
import { ExtraContentPreview } from '../props';

const EventsInfo = ({ eventsAdditionalInfo, title }: { eventsAdditionalInfo: ExtraContentPreview; title: string }) => {
  const [isOpenFullEventsInfom, setIsOpenFullEventsInfo] = useState<boolean>(false);
  const { t, i18n } = useTranslation('profile');
  const locales = {
    ru,
    be,
    enGB,
    lt,
    uk,
  };

  const onEventInfoClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOpenFullEventsInfo((prev) => !prev);
  };

  const eventDate = (full?: boolean) => {
    return `${format(new Date(eventsAdditionalInfo.date_start || Date.now()), "dd/MM/yyyy',' HH:mm", {
      locale: locales[i18n.language],
    })}${
      eventsAdditionalInfo.date_end && full
        ? ` - ${format(new Date(eventsAdditionalInfo.date_end || Date.now()), "dd/MM/yyyy',' HH:mm", {
            locale: locales[i18n.language],
          })}`
        : ''
    }`;
  };
  return (
    <div className={styles.eventsInfoWrapper} onClick={onEventInfoClick}>
      {isOpenFullEventsInfom && (
        <div className={styles.fullEventsInfo}>
          <a
            href={`http://www.google.com/calendar/event?action=TEMPLATE&text=${title}&dates=${formatDateForGoogle(
              eventsAdditionalInfo.date_start
            )}${eventsAdditionalInfo.date_end ? `/${formatDateForGoogle(eventsAdditionalInfo.date_end)}` : ''}&details=<span>${t(
              'social.helperText'
            )}: </span><a href='${window?.location}' target='_blank'>${title}</a><br />${title}&location=${
              eventsAdditionalInfo.place || ''
            }&trp=false&sprop=&sprop=name:`}
            target="_blank"
            onClick={(e) => {
              e.stopPropagation();
            }}
            className={styles.eventDataWrapper}
            rel="nofollow noreferrer"
          >
            <Icon color="primary" height={24} width={24} iconName={'calendar'} className={styles.iconCalendar} />
            {eventDate(true)}
          </a>
          {!!eventsAdditionalInfo.place && (
            <a
              href={`https://www.google.com/maps/search/${eventsAdditionalInfo.place}'`}
              target="_blank"
              onClick={(e) => {
                e.stopPropagation();
              }}
              rel="noreferrer"
              className={styles.eventDataWrapper}
            >
              <Icon color="primary" height={24} width={24} iconName={'location'} />
              {eventsAdditionalInfo.place}
            </a>
          )}
          {!!eventsAdditionalInfo.price && eventsAdditionalInfo.price !== '0' && (
            <div className={styles.eventDataWrapper}>
              <Icon color="primary" height={24} width={24} iconName={'wallet'} />
              {eventsAdditionalInfo.price}
            </div>
          )}
        </div>
      )}

      <div className={styles.eventDataWrapper} rel="nofollow noreferrer">
        <Icon color="primary" height={24} width={24} iconName={'calendar'} className={styles.iconCalendar} />
        {eventDate()}
      </div>
      <Icon
        color="black"
        height={24}
        width={24}
        iconName={'arrowRight'}
        rotate={isOpenFullEventsInfom ? 90 : 270}
        className={styles.iconArrow}
      />
    </div>
  );
};

export default EventsInfo;
