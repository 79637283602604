import styles from './NoPost.module.scss';
import NoPostImage from 'public/images/nopost.png';
import { useTranslation } from 'react-i18next';

const NoPost = ({ description }: { description?: string }) => {
  const { t } = useTranslation('profile');
  return (
    <div className={styles.noPost}>
      <img src={NoPostImage} alt="no posts" className={styles.noPostImage} />
      <div className={styles.noPostContent}>
        <h4 className={styles.title}>{t('feed.noPost.title')}</h4>
        <span className={styles.subtitle}>{description || t('feed.noPost.description.default')}</span>
      </div>
    </div>
  );
};

export default NoPost;
