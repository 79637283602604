import styles from './Landing.module.scss';
import { Helmet } from 'react-helmet';
import { RoyTeam } from './modules/RoyTeam/RoyTeam';
import { HowItsWork } from './modules/HowItsWork/HowItsWork';
import { Peculiarities } from './modules/Peculiarities/Peculiarities';
import { MotivationalQuote } from './modules/MotivationalQuote/MotivationalQuote';
import { RoyFeatures } from './modules/RoyFeatures/RoyFeatures';
import { Mission } from './modules/Mission/Mission';
import { BusinessGoals } from './modules/BusinessGoals/BusinessGoals';
import { Info } from './modules/Info/Info';
import { JoinUs } from './modules/JoinUs/JoinUs';
import { Together } from './modules/Together/Together';
import { LanguageSelect } from './LanguageSelect/LanguageSelect';
import BannerWithButton from 'common/BannerWithButton/BannerWithButton';
import { bannersEventsMock } from 'common/BannerWithButton/bannersMock';
import { useTranslation } from 'react-i18next';

export function Landing() {
  const { t } = useTranslation('content');
  return (
    <div className={styles.main}>
      <Helmet>
        <title>Roy Team</title>
        <meta
          name="description"
          content="Сервисная платформа для самоорганизации и работы людей в общественных сообществах и инициативах"
        />

        <meta
          property="og:description"
          content="Сервисная платформа для самоорганизации и работы людей в общественных сообществах и инициативах"
        />
        <meta property="og:title" content="Roy Team" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Roy Team" />
        <meta property="og:url" content={`${process.env.REACT_APP_BASE_URL}`} />
        <meta property="og:image" content={`${process.env.REACT_APP_BASE_URL}${process.env.PUBLIC_URL}/logo-small.svg`} />
        <link
          href="https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
          rel="stylesheet"
        />
      </Helmet>

      {/* Hero start */}
      <h1 style={{ display: 'none' }}>Roy Team</h1>
      <LanguageSelect />
      <RoyTeam />
      <BannerWithButton banners={[{ ...bannersEventsMock[0], buttonTitle: t('try') }]} />
      <HowItsWork />
      <Peculiarities />
      <MotivationalQuote />
      <RoyFeatures />
      <Mission />
      <BusinessGoals />
      <Info />
      <JoinUs />
      <Together />
    </div>
  );
}
