import { format } from 'date-fns';
import { ru, be, enGB, lt, uk } from 'date-fns/locale';
import { Icon } from 'shared/components/common/Icon/Icon';
import cn from 'classnames';
import styles from './Comment.module.scss';
import { useEffect, useRef, useState } from 'react';
import CommentTextArea from '../CommentTextArea/CommentTextArea';
import { ICommentReactions, IComments } from 'shared/interfaces/comments';
import {
  CommentsState,
  createCommentAction,
  deleteCommentAction,
  updateCommentAction,
  updateCommentReactionAction,
} from 'shared/slices/comments';
import { useSelector, useDispatch } from '../../../../redux-toolkit';
import { toast } from 'react-toastify';
import formatServerError from 'shared/utils/formatServerError';
import { CustomImage } from 'shared/components/common/CustomImage/CustomImage';
import sanitizeHtml from 'sanitize-html';
import linkifyHtml from 'linkifyjs/html';
import {
  allowedAttributesSchema,
  allowedTagsSynitizer,
  allowedIframeHostnamesSchema,
} from 'shared/constants/allowedTagsSynitizer';
import { useTranslation } from 'react-i18next';

const Comment = ({ item, secondLevel }: { item: IComments; secondLevel?: boolean }) => {
  const { t, i18n } = useTranslation('shared');
  const locales = {
    ru,
    be,
    enGB,
    lt,
    uk,
  };
  const textRef = useRef(null);
  const { generalData } = useSelector((state) => state.comments as CommentsState);
  const dispatch = useDispatch();

  const [readMore, setReadMore] = useState(false);
  const [readMoreButton, setReadMoreButton] = useState(false);
  const [isChangeComment, setIsChangeComment] = useState(false);
  const [textChangeComment, setTextChangeComment] = useState('');
  const [answerComment, setAnswerComment] = useState(false);
  const [textAnswerComment, setTextAnswerComment] = useState('');

  useEffect(() => {
    if (!!item.comment) {
      setTextChangeComment(item.comment);
    }
  }, [item?.comment]);

  useEffect(() => {
    if (textRef.current?.clientHeight / 30 > 3.9) {
      setReadMoreButton(true);
      setReadMore(true);
    }
  }, [textRef, isChangeComment]);

  const answerOnComment = async () => {
    try {
      await dispatch(
        createCommentAction(
          generalData.essenceId,
          generalData.essenceName,
          textAnswerComment,
          !secondLevel ? item.id : item.parent_id,
          generalData.isAdminApp
        )
      );
      setTextAnswerComment('');
      setAnswerComment(false);
      toast(t('toast.addComment'));
    } catch (e) {
      toast.error(formatServerError(e));
    }
  };
  const changeComment = async () => {
    try {
      await dispatch(
        updateCommentAction(
          generalData.essenceId,
          item.id,
          generalData.essenceName,
          textChangeComment,
          item.parent_id,
          generalData.isAdminApp
        )
      );
      setIsChangeComment(false);
      toast(t('toast.updateComment'));
    } catch (e) {
      toast.error(formatServerError(e));
    }
  };
  const deleteComment = async () => {
    const isDelete = window.confirm(t('toast.confirmDeleteComment'));
    if (isDelete) {
      try {
        await dispatch(
          deleteCommentAction(
            generalData.essenceId,
            item.id,
            generalData.essenceName,
            item.parent_id,
            generalData.isAdminApp
          )
        );
        toast(t('toast.deleteComment'));
      } catch (e) {
        toast.error(formatServerError(e));
      }
    }
  };

  const updateReaction = async (reaction: ICommentReactions) => {
    try {
      await dispatch(
        updateCommentReactionAction(
          generalData.essenceId,
          item.id,
          reaction,
          generalData.essenceName,
          item.parent_id,
          generalData.isAdminApp
        )
      );
    } catch (e) {
      toast.error(formatServerError(e));
    }
  };

  return (
    <div className={styles.comment}>
      {!isChangeComment ? (
        <>
          <div className={styles.imageWrapper}>
            <CustomImage src={item.user?.public_avatar || ''} alt={item.user.public_name} width={37.5} height={37.5} />
          </div>
          <div className={styles.commentContent}>
            <div>
              <span className={styles.commentAuthor}>{item.user?.public_name || item.user?.username}</span>
              <span className={styles.commentDate}>
                {format(new Date(item.deleted_at || item?.published_at), "dd MMMM yyyy ':' HH:mm", {
                  locale: locales[i18n.language],
                })}
              </span>
              {item.is_changed && (
                <span className={styles.commentDate}>
                  {!item.deleted_at ? ` (${t('comments.changed')})` : ` (${t('comments.deleted')})`}
                </span>
              )}
            </div>
            {!item.deleted_at ? (
              <div
                className={cn(styles.commentText, {
                  [styles.readAll]: readMore,
                })}
                ref={textRef}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(
                      linkifyHtml(item.comment?.replace(/[\r\n]/gm, '<br />'), { target: '_blank' }),
                      {
                        allowedTags: allowedTagsSynitizer,
                        allowedAttributes: allowedAttributesSchema,
                        allowedIframeHostnames: allowedIframeHostnamesSchema,
                      }
                    ),
                  }}
                />
              </div>
            ) : (
              <span className={styles.commentDate}>{t('comments.deletedByUser')}</span>
            )}

            {readMoreButton && (
              <button className={styles.readMoreButton} onClick={() => setReadMore(!readMore)}>
                {readMore ? t('actions.readMore') : t('actions.hide')}
              </button>
            )}
            <div className={styles.reactionsWrapper}>
              <div
                className={cn(styles.reaction, {
                  [styles.activeReaction]: !!item.my_reaction && item.my_reaction === ICommentReactions.thumbs_up,
                  [styles.deletedComment]: !!item.deleted_at || !!generalData.disable,
                })}
              >
                <button
                  onClick={() =>
                    updateReaction(
                      item.my_reaction === ICommentReactions.thumbs_up
                        ? ICommentReactions.deleteReaction
                        : ICommentReactions.thumbs_up
                    )
                  }
                  disabled={!!item.deleted_at || !!generalData.disable}
                >
                  <Icon
                    iconName="like"
                    color={!!item.my_reaction && item.my_reaction === ICommentReactions.thumbs_up ? 'primary' : 'black'}
                  />
                </button>
                <div className={styles.countReaction}>
                  <span>{!Array.isArray(item.reactions) ? item.reactions[ICommentReactions.thumbs_up] || 0 : 0}</span>
                </div>
              </div>
              <div
                className={cn(styles.reaction, {
                  [styles.activeReaction]: !!item.my_reaction && item.my_reaction === ICommentReactions.thumbs_down,
                  [styles.deletedComment]: !!item.deleted_at || !!generalData.disable,
                })}
              >
                <button
                  onClick={() =>
                    updateReaction(
                      item.my_reaction === ICommentReactions.thumbs_down
                        ? ICommentReactions.deleteReaction
                        : ICommentReactions.thumbs_down
                    )
                  }
                  disabled={!!item.deleted_at || !!generalData.disable}
                >
                  <Icon
                    iconName="dislike"
                    color={
                      !!item.my_reaction && item.my_reaction === ICommentReactions.thumbs_down ? 'primary' : 'black'
                    }
                  />
                </button>
                <div className={styles.countReaction}>
                  <span>{!Array.isArray(item.reactions) ? item.reactions[ICommentReactions.thumbs_down] || 0 : 0}</span>
                </div>
              </div>
              {!generalData.disable && (
                <button
                  className={styles.answer}
                  onClick={() => {
                    setAnswerComment(true);
                    if (!!secondLevel) {
                      setTextAnswerComment(`${item.user.public_family || ''} ${item.user.public_name}, `);
                    }
                  }}
                >
                  {t('actions.answer')}
                </button>
              )}

              {item.user?.id === generalData.user?.id && !item?.deleted_at && !generalData?.disable && (
                <>
                  <button className={styles.answer} onClick={() => setIsChangeComment(true)}>
                    {t('actions.change')}
                  </button>
                  <button className={styles.answer} onClick={deleteComment}>
                    {t('actions.delete')}
                  </button>
                </>
              )}
            </div>
            {!!answerComment && (
              <div className={styles.wrapperAnswerTextArea}>
                <CommentTextArea
                  value={textAnswerComment}
                  setValue={setTextAnswerComment}
                  isChange
                  setChange={setAnswerComment}
                  placeholder={t('comments.enterAnswer')}
                  buttonText={t('actions.answer')}
                  onClick={answerOnComment}
                  id={`${item.id}-${item.parent_id}`}
                />
              </div>
            )}
          </div>
        </>
      ) : (
        <CommentTextArea
          value={textChangeComment}
          setValue={setTextChangeComment}
          isChange
          setChange={setIsChangeComment}
          buttonText={t('actions.change')}
          onClick={changeComment}
          id={item.id}
        />
      )}
    </div>
  );
};

export default Comment;
