import { useReducer, Dispatch } from 'react';
// import { Feature, GeoJsonProperties, Geometry } from '../_types';

// USE FEATURE REDUCER HOOK
const _initialState = {
  organisations: [],
  friendly: [],
  slots: [],
  lines: [],
  selected: null, //selectedOrgFeature
  hovered: null,
};

export enum featuresActionTypes {
  setOrganisations = 'setOrganisations',
  setFriendly = 'setFriendly',
  setSlots = 'setSlots',
  setLines = 'setLines',
  setSelected = 'setSelected',
  setHovered = 'setHovered',
}

function reducer(
  state: typeof _initialState,
  { type, payload }: { type: featuresActionTypes; payload: typeof _initialState[keyof typeof _initialState] }
) {
  switch (type) {
    case featuresActionTypes.setOrganisations:
      return { ...state, organisations: payload };

    case featuresActionTypes.setFriendly:
      return { ...state, friendly: payload };

    case featuresActionTypes.setSlots:
      return { ...state, slots: payload };

    case featuresActionTypes.setLines:
      return { ...state, lines: payload };

    case featuresActionTypes.setSelected:
      return { ...state, selected: payload };

    case featuresActionTypes.setHovered:
      return { ...state, hovered: payload };

    default:
      break;
  }
  throw Error('Unknown features action: ' + type);
}

export const actions = {
  setOrganisations: (payload: typeof _initialState['organisations']) => ({ type: featuresActionTypes.setOrganisations, payload }),
  setFriendly: (payload: typeof _initialState['friendly']) => ({
    type: featuresActionTypes.setFriendly,
    payload,
  }),
  setSlots: (payload: typeof _initialState['slots']) => ({ type: featuresActionTypes.setSlots, payload }),
  setLines: (payload: typeof _initialState['lines']) => ({ type: featuresActionTypes.setLines, payload }),
  setSelected: (payload: typeof _initialState['selected']) => ({ type: featuresActionTypes.setSelected, payload }),
  setHovered: (payload: typeof _initialState['hovered']) => ({ type: featuresActionTypes.setHovered, payload }),
};

export const useFeaturesReducer = (
  initialState?: typeof _initialState
): [typeof _initialState, Dispatch<ReturnType<typeof actions[keyof typeof actions]>>, typeof actions] => {
  const [state, dispatch] = useReducer(reducer, initialState || _initialState);
  return [state, dispatch, actions];
};
