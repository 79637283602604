import { useContext, useEffect, useState } from 'react';
import cn from 'classnames';
import styles from './NavigationBar.module.scss';
import { GlobalDataContext } from '../../contexts/GlobalDataContext';
import { UserMenu, UserMenuMode } from './UserNavigation/UserMenu/UserMenu';
import { OrganizationMenuMode } from './OrganizationNavigation/OrganizationMenu/OrganizationMenu';
import { OrganizationContent } from './OrganizationNavigation/OrganizationContent/OrganizationContent';
import { Drawer } from 'common/Drawer/Drawer';
import { UserContent } from './UserNavigation/UserContent/UserContent';

export type MenuMode = OrganizationMenuMode | UserMenuMode;

export enum ContentMode {
  USER = 'user',
  ORGANIZATION = 'organization',
}

type INavigationBarProps = {
  isExpanded: boolean;
  setExpandedState: (state: boolean) => void;
  changeUserMenu?: boolean;
};

export function NavigationBar({ isExpanded, setExpandedState, changeUserMenu }: INavigationBarProps) {
  const [menuMode, setMenuMode] = useState<MenuMode>(UserMenuMode.INIT);
  const [contentMode, setContentMode] = useState<ContentMode>(ContentMode.USER);

  const { user } = useContext(GlobalDataContext);

  const isHaveMembership = !!user?.membership.length;

  useEffect(() => {
    if (changeUserMenu) {
      openUserContent();
    } else {
      closeContent();
    }
  }, [changeUserMenu]);

  const handleMouseLeave = () => {
    if (isExpanded) {
      if (contentMode === ContentMode.ORGANIZATION) {
        setMenuMode(OrganizationMenuMode.EXPANDED);
      }
      if (contentMode === ContentMode.USER) {
        setMenuMode(UserMenuMode.EXPANDED);
      }
    } else {
      setMenuMode(UserMenuMode.INIT);
    }
  };

  const openUserContent = () => {
    setMenuMode(UserMenuMode.EXPANDED);
    setContentMode(ContentMode.USER);
    setExpandedState(true);
  };

  const toggleUserContent = () => (isExpanded ? closeContent() : openUserContent());

  const openOrganizationContent = () => {
    setMenuMode(OrganizationMenuMode.EXPANDED);
    setContentMode(ContentMode.ORGANIZATION);
    setExpandedState(true);
  };
  const closeContent = () => {
    setMenuMode(UserMenuMode.INIT);
    setExpandedState(false);
  };

  const toggleOrganizationContent = () =>
    isExpanded && contentMode === ContentMode.ORGANIZATION ? closeContent() : openOrganizationContent();

  // const organizationContentExpanded = (mode: MenuMode) => mode === OrganizationMenuMode.EXPANDED;

  const getMenu = (mode: MenuMode): JSX.Element => {
    switch (mode) {
      case UserMenuMode.INIT:
      case OrganizationMenuMode.FULL:
      default:
        //@ts-ignore
        return <UserMenu mode={mode} toggleContent={toggleUserContent} toggleOrganizationContent={toggleOrganizationContent} />;
    }
  };

  const getContent = (mode: ContentMode): JSX.Element => {
    switch (mode) {
      case ContentMode.USER:
        return <UserContent />;
      case ContentMode.ORGANIZATION:
        return <OrganizationContent />;
      default:
        return <UserContent />;
    }
  };

  return (
    <aside className={styles.NavigationBar}>
      <nav
        className={cn(styles.menu, {
          // [styles.expanded]: isExpanded,
          [styles.hideToggleOrganization]: !isHaveMembership,
        })}
        onMouseLeave={handleMouseLeave}
      >
        {/* <button className={styles.ToggleOrganization} onClick={toggleUserContent}>
          <CustomImage src={user?.public_avatar ?? ''} alt={user?.name ?? ''} width={52} height={52} background="white" />
        </button> */}

        {getMenu(menuMode)}
      </nav>
      <Drawer className={styles.drawer} open={isExpanded} onClose={closeContent}>
        {getContent(contentMode)}
      </Drawer>
    </aside>
  );
}
