import { GlobalContext } from 'contexts/GlobalContext';
import styles from 'components/NavigationBar/UserNavigation/UserMenu/UserMenu.module.scss';
import { FunctionComponent, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import React from 'react';
import { RegovWidgetExtendedProps } from '../types';
import { regovConfig } from 'regov/config';
import { Icon } from 'shared/components/common/Icon/Icon';
import { Suspensed } from 'components/Suspense/Suspense';

const SSIProfileButton: FunctionComponent<RegovWidgetExtendedProps> = React.lazy(() => import('./Profile'));

export const SSIProfileWidget = () => {
  const {
    openModal,
    closeModal,
    spinner: { showSpinner, hideSpinner },
  } = useContext(GlobalContext);
  const history = useHistory();

  return regovConfig.enabled ? (
    <Suspensed
      fallback={
        <a className={`${styles.Icon} ${styles.disable}`}>
          <Icon iconName="ssiWallet" width={28} height={28} />
        </a>
      }
    >
      <SSIProfileButton
        history={history}
        openModal={openModal}
        closeModal={closeModal}
        showSpinner={showSpinner}
        hideSpinner={hideSpinner}
      />
    </Suspensed>
  ) : (
    <></>
  );
};
