import { RegovProviderWrapperProps, RegovProviderWrapper as Wallet } from '@owlmeans/regov-lib-react';
import { FC, PropsWithChildren } from 'react';
import { pluginIntegratedWalletKickoff, pluginIntegratedWalletInbox } from '@owlmeans/regov-ext-comm';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import createTheme from '@mui/material/styles/createTheme';

export { i18nRegisterExtensions, setRegovPassword, i18n, getRegovPassword, isRegovPasswordSet } from '@owlmeans/regov-lib-react';

export const RegovProviderWrapper: FC<PropsWithChildren<RegovProviderWrapperProps>> = ({ children, ...props }) => {
  return (
    <ThemeProvider
      theme={createTheme({
        palette: { primary: { main: '#6339fb' } },
        components: {
          MuiTypography: {
            styleOverrides: {
              body1: { fontSize: '0.9rem' },
            },
          },
        },
      })}
    >
      <Wallet
        {...props}
        integrationConfig={{ trustedIssuersUri: '/organizations/ssi/list' }}
        plugins={
          props.setInboxCount
            ? {
                onKickOff: [pluginIntegratedWalletKickoff],
                onStorageLoaded: [pluginIntegratedWalletInbox],
              }
            : undefined
        }
      >
        {children}
      </Wallet>
    </ThemeProvider>
  );
};
