import { Icon } from '../Icon/Icon';
import { Icons } from '../Icon/Icon.interface';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import styles from './Button.module.scss';
import { KeyboardEventHandler } from 'react';

export enum ButtonColors {
  primary = 'primary',
  light = 'light',
  danger = 'danger',
}

export type IButtonProps = {
  onClick?: (...params: any[]) => void;
  children?: any;
  size?: 'default' | 'small';
  disabled?: boolean;
  color?: 'primary' | 'light' | 'danger';
  icon?: keyof typeof Icons | null;
  iconPosition?: 'left' | 'right';
  type?: 'button' | 'submit' | 'reset';
  className?: string;
  to?: string;
  target?: string;
  id?: string;
  dataCy?: string;
  onlyIcon?: boolean;
  maxWidth?: boolean;
  onKeyDown?: KeyboardEventHandler<HTMLAnchorElement>;
};

export function Button({
  onClick,
  color = ButtonColors.primary,
  size = 'default',
  dataCy,
  disabled = false,
  icon,
  iconPosition = 'right',
  children,
  target,
  type = 'button',
  className,
  to,
  maxWidth = false,
  onlyIcon = false,
  onKeyDown,
  ...rest
}: IButtonProps) {
  let Component: string | Link = 'button';

  let elementParams: { [key: string]: any } = {
    type,
    disabled,
  };

  if (to) {
    elementParams = {};

    if (to.includes('http')) {
      Component = 'a';
      elementParams.href = to;

      if (target) {
        elementParams.target = target;
      }

      if (target === '_blank') {
        elementParams.rel = 'noreferrer';
      }
    } else {
      // @ts-ignore
      Component = Link;
      elementParams.to = to;
    }
  }

  return (
    <div style={{ zIndex: 0, position: 'relative', width: maxWidth ? '100%' : 'auto' }}>
      {
        // @ts-ignore
        <Component
          className={cn(styles.button, className, {
            [styles.withIcon]: icon,
            [styles.iconRigth]: iconPosition === 'right',
            [styles.iconLeft]: iconPosition === 'left',
            [styles.onlyIcon]: onlyIcon,
            [styles[color]]: color,
            [styles.smallSize]: size === 'small',
          })}
          onClick={onClick}
          data-cy={dataCy}
          style={{
            width: maxWidth && '100%',
          }}
          onKeyDown={onKeyDown}
          {...rest}
          {...elementParams}
        >
          {children}
          {icon && <Icon iconName={icon} />}
        </Component>
      }
    </div>
  );
}
