import classNames from 'classnames';
import styles from './ProfileModalTabs.module.scss';
import { useSsrState } from '@issr/core';
import { ProfileModalItem } from '../ProfileModalItem/ProfileModalItem';
import { ProfileMembership } from 'interfaces/profile.interface';
import { TextField } from 'shared/components/common/TextField/TextField';
import { useTranslation } from 'react-i18next';

type AdminTabProps = {
  organizations: Array<Partial<ProfileMembership>>;
};
const { REACT_APP_ADMIN_URL } = process.env;

export const AdminTab = ({ organizations }: AdminTabProps) => {
  const { t } = useTranslation('modals');
  const [searchValue, setSearchValue] = useSsrState('');
  const action = (id: number) => {
    const token = localStorage.getItem('token');
    window.dataLayer.push({
      event: 'event',
      eventProps: {
        category: 'user',
        action: 'click',
        label: 'Admin dashboard button',
      },
    });
    window.open(`${REACT_APP_ADMIN_URL}/signin?token=${token}`, 'roy-team-admin');
  };
  const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (ev) => {
    if (ev.code === 'Esc') {
      setSearchValue('');
    }
  };
  const organizationsToRender = organizations.filter((val) => {
    return val.short_name.toLowerCase().includes(searchValue.toLowerCase());
  });

  return (
    <div className={classNames(styles.wrapper)}>
      <TextField
        variant="text"
        value={searchValue}
        setValue={setSearchValue}
        helperText={t('controls.search.helperText')}
        placeholder={t('controls.search.placeholder')}
        onKeyDown={onKeyDown}
        maxLength={256}
      />
      {organizationsToRender.map((el, index) => {
        return (
          <ProfileModalItem
            isOrganization={true}
            id={el.id}
            image={el.avatar}
            name={el.short_name}
            isCollapsible={false}
            action={() => action(el.id)}
            iconName="securitySafe"
            actionTitle={t('icons.admin')}
            key={`admin-${el.id}-${index}`}
          />
        );
      })}
    </div>
  );
};
