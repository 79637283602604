import { Icon } from 'shared/components/common/Icon/Icon';
import styles from './BadgeView.module.scss';
import { MouseEventHandler } from 'react';
import { IBadge } from 'interfaces/badge.interface';
import { CustomImage } from 'shared/components/common/CustomImage/CustomImage';

type BadgeViewType = {
  badge: IBadge;
  isHideShadow?: boolean;
  onClick?: MouseEventHandler;
};

// default badge visualization

export const BadgeView = ({ badge, isHideShadow, onClick }: BadgeViewType) => {
  let borderColor: string;
  switch (badge.inner_type) {
    case 'trust':
      borderColor = '#5002F7';
      break;
    case 'achievements':
      borderColor = '#FF00D6';
      break;
    case 'expertise':
      borderColor = '#FFBB00';
      break;
    case 'personal':
      borderColor = '#79AA15';
      break;
    default:
      borderColor = '#5002F7';
      break;
  }
  return (
    <div className={styles.wrapper} onClick={onClick}>
      {!isHideShadow && (
        <div className={styles.shadow}>
          <Icon width={20} height={20} rotate={45} iconName={'circleClose'} />
        </div>
      )}
      <div className={styles.badge}>
        <div className={styles.avatar}>
          <div className={styles.border}>
            <CustomImage height={40} width={40} isOrganization src={badge.can[0].avatar} alt={badge.can[0].name} />
          </div>
        </div>
        <p style={{ borderColor }} className={styles.name}>
          {badge.name}
        </p>
        <img className={styles.image} src={badge.icon || '/logo-small.svg'} alt={badge.name} />
      </div>
    </div>
  );
};
