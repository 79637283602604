import { AuthIntengratedServerResponse, ERROR_INTEGRATED_SERVER_CANT_LOGIN, REGOV_CREDENTIAL_TYPE_AUTH } from '@owlmeans/regov-ext-auth';
import { getAuthFromPresentation, getAuthSubject } from '@owlmeans/regov-ext-auth/dist/util';
import { ServerClient, UIExtensionRegistry } from '@owlmeans/regov-lib-react';
import { Credential, Presentation, WalletHandler } from '@owlmeans/regov-ssi-core';
import { IGlobalState } from 'contexts/GlobalContext';
import { IGlobalDataState } from 'contexts/GlobalDataContext';
import * as H from 'history';
import { toast } from 'react-toastify';
import { auth as callAuth, AuthResult } from 'regov/models/auth';
import { setRegovPassword } from 'regov/persistent-wallet';
import { routes } from 'Routes';

export const basicLogin = (params: BasicLoginParams) => {
  const {
    globalState: {
      services: { authService, userService, httpService },
      spinner: { showSpinner, hideSpinner },
      openModal,
      closeModal,
    },
    globalDataState: { auth: globalAuth, setUser },
    history,
    close,
    client,
    extensions,
    credential,
    handler: { wallet },
    isRedirectToAdmin,
  } = params;

  return async () => {
    showSpinner();
    try {
      const authRequest = await client.getVC<Presentation>(`/auth/login/ssi/request/${credential.id}`);

      const ext = extensions.getExtension(REGOV_CREDENTIAL_TYPE_AUTH);
      const factory = ext.extension.getFactory(REGOV_CREDENTIAL_TYPE_AUTH);

      const unsigned = await factory.build(wallet, {
        subjectData: { ...getAuthSubject(getAuthFromPresentation(authRequest)) },
      });
      const auth = await factory.sign(wallet, { unsigned, evidence: credential });

      const authResponse = await factory.respond(wallet, {
        request: authRequest,
        credential: auth,
        identity: credential,
      });
      const oldToken = httpService.token;

      const authProvided = (await client.sendVC<Presentation, AuthIntengratedServerResponse>(
        {
          uri: '/auth/login/ssi',
          ...(userService.isLoggedIn ? { headers: { authorization: `Bearer ${httpService.token}` } } : {}),
        },
        authResponse
      )) as AuthResult;

      if (
        !authProvided ||
        !authProvided.ok ||
        !(await callAuth({ openModal, closeModal, authService, userService, auth: globalAuth, history, doNotClose: true })(authProvided, {
          error: (e) => {
            throw e;
          },
        }))
      ) {
        console.log('LOGOUT');
        setRegovPassword(undefined);
        await params.handler.logout();
        params.handler.notify();
        throw ERROR_INTEGRATED_SERVER_CANT_LOGIN;
      }
      const user = await userService.getUser();
      setUser(user);
      if (isRedirectToAdmin) {
        const { REACT_APP_ADMIN_URL } = process.env;
        window.open(`${REACT_APP_ADMIN_URL}/signin?token=${authProvided.token}`, 'roy-team-admin');
        window.close();
      }
      if (close) {
        setImmediate(() => {
          close();
          if (!oldToken) {
            setImmediate(() => {
              if (authService.inviteParams) {
                history.push(routes.INVITATION_SCREEN);
              } else {
                history.push(routes.PROFILE);
              }
            });
          }
        });
      } else {
        setImmediate(() => {
          closeModal();
          if (!oldToken) {
            if (authService.inviteParams) {
              history.push(routes.INVITATION_SCREEN);
            } else {
              history.push(routes.PROFILE);
            }
          }
        });
      }
    } catch (e) {
      console.error(e);
      toast.error(e instanceof Error ? e.message : `${e}`);
    } finally {
      hideSpinner();
    }
  };
};

export type BasicLoginParams = {
  handler: WalletHandler;
  history: H.History;
  client: ServerClient;
  globalState: IGlobalState;
  credential: Credential;
  extensions: UIExtensionRegistry;
  globalDataState: IGlobalDataState;
  close?: () => void;
  isRedirectToAdmin?: boolean;
};
