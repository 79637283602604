import {
  basicNavigator,
  buildServerClient,
  buildUIExtensionRegistry,
  Config,
  extendNavigator,
} from '@owlmeans/regov-lib-react/dist/common';
import { WalletHandler } from '@owlmeans/regov-ssi-core';
import { useHistory } from 'react-router-dom';
import { routes } from 'Routes';
import { authIntegratedExtension, SERVER_INTEGRATION_ALIAS } from '@owlmeans/regov-ext-auth';
import { buildCommUIExtension, CommExtConfig, DEFAULT_SERVER_ALIAS } from '@owlmeans/regov-ext-comm';
import { buildIdentityExtensionUI } from '@owlmeans/regov-ext-identity';
import { groupsUIExtension } from './extensions/ext-groups';
import {
  addCredential,
  addScansContext,
  USE_CLAIM_VIEW,
  USE_CREATE_CLAIM,
  USE_CREATE_OFFER,
  USE_CRED_VIEW,
  USE_PREVIEW_CLAIM,
  USE_VIEW_OFFER,
} from '@owlmeans/regov-ext-custom';
import { customizeExtension, addLocalization } from '@owlmeans/regov-ext-custom/dist/web';
import './warmup';
import { ssiLocalesSchema } from './i18n/config';
import { i18n } from '@owlmeans/regov-lib-react/dist/web/integration';

Object.entries(ssiLocalesSchema.ssi).forEach(([lng, resource]) => {
  i18n.addResourceBundle(lng, 'ssi', resource, true, true);
});

export const ROY_IDENTITY_TYPE = 'Roy:Identity';

export const regovConfig = {
  DID_PREFIX: process.env.REACT_APP_DIDPREFIX || 'royt',
  DID_SCHEMA_PATH: process.env.REACT_APP_DIDSCHEMA_PATH || 'did-schema.json',
  code: process.env.REACT_APP_REGOV_APP_CODE || 'roy.web.app',
  baseSchemaUrl: process.env.REACT_APP_BASE_SCHEMA_URL || '',
  name: process.env.REACT_APP_REGOV_APP_NAME || 'Roy Team',
  enabled: process.env.REACT_APP_REGOV_ENABLED === 'true' ?? false,
  development: false,
};

const commConfig: CommExtConfig = {
  wsConfig: {
    [DEFAULT_SERVER_ALIAS]: {
      server: process.env.REACT_APP_DIDCOMM_SERVER,
      timeout: process.env.REACT_APP_DIDCOMM_TIMEOUT ? parseInt(process.env.REACT_APP_DIDCOMM_TIMEOUT) : 30,
    },
  },
};

export const walletServerClient = buildServerClient({
  baseUrl: process.env.REACT_APP_NODE_WALLET_URL || process.env.REACT_APP_API_URL,
  servers: {
    [SERVER_INTEGRATION_ALIAS]: process.env.REACT_APP_API_URL,
  },
});

export const regovNavigatorBuilder = (handler: WalletHandler, config: Config, history: ReturnType<typeof useHistory>) =>
  extendNavigator(basicNavigator, {
    assertAuth: async () => {
      if (handler.wallet) {
        return true;
      }

      if (!config.development) {
        history.push(routes.HOME);
      }

      return false;
    },

    checkAuth: async () => !!handler.wallet,

    home: async () => {
      setTimeout(() => history.push(routes.PROFILE), 100);
    },

    back: async () => history.goBack(),
  });

const registry = buildUIExtensionRegistry();
const commUIExt = buildCommUIExtension(commConfig);
commUIExt.extension.localization.translations = {
  ...commUIExt.extension.localization.translations,
  ...ssiLocalesSchema.comm,
};
registry.registerSync(commUIExt);
const idUIExt = buildIdentityExtensionUI(
  ROY_IDENTITY_TYPE,
  { appName: regovConfig.name },
  {
    name: 'Roy ID',
    code: 'roy-identity',
    organization: process.env.REACT_APP_REGOV_APP_NAME || 'Roy Team App',
    home: 'https://roy.team',
    schemaBaseUrl: process.env.REACT_APP_BASE_SCHEMA_URL || '',
  }
);
idUIExt.extension.localization.translations = {
  ...idUIExt.extension.localization.translations,
  ...ssiLocalesSchema.config,
};
registry.registerSync(idUIExt);
authIntegratedExtension.extension.localization.translations = {
  ...authIntegratedExtension.extension.localization.translations,
  ...ssiLocalesSchema.auth,
};
registry.registerSync(authIntegratedExtension);

/**
 * Keep hear as an example
groupsUIExtension.extension = addLocalization(
  addCredential(groupsUIExtension.extension, {
    mainType: 'RoySupport',
    defaultLabel: 'Roy Team Support',
    expirationPeriod: 3600 * 24 * 365,
    credentialContext: {
      xs: 'http://www.w3.org/2001/XMLSchema#',
      custom: 'https://roy.team/ssi/vc/support#',
    },
    subjectMeta: {
      contactName: {
        useAt: [USE_CREATE_CLAIM, USE_PREVIEW_CLAIM, USE_ITEM_CLAIM, USE_CLAIM_VIEW],
        defaultLabel: 'Main contact',
        defaultHint: 'Some contact you are known as',
        validation: { required: true },
        term: { '@id': 'custom:contactName', '@type': 'xs:string' },
      },
      contactToCheck: {
        useAt: [USE_CREATE_CLAIM, USE_PREVIEW_CLAIM, USE_ITEM_CLAIM, USE_CLAIM_VIEW, USE_CREATE_OFFER, USE_VIEW_OFFER, USE_CRED_VIEW],
        defaultLabel: 'Someone we know',
        defaultHint: 'Who we can contact to check you',
        validation: { required: true },
        term: { '@id': 'custom:contactToCheck', '@type': 'xs:string' },
      },
      specialMark: {
        useAt: [USE_CREATE_OFFER, USE_VIEW_OFFER, USE_ITEM_CRED, USE_CRED_VIEW, USE_ITEM_OFFER],
        defaultLabel: 'Special mark to return',
        defaultHint: 'Special mark with some intersting info',
        validation: { required: true },
        term: { '@id': 'custom:specialMark', '@type': 'xs:string' },
      },
      scansField: {
        useAt: [USE_CREATE_CLAIM, USE_CLAIM_VIEW, USE_VIEW_OFFER, USE_CRED_VIEW],
        validation: { required: true },
        defaultLabel: 'Scans Field',
        term: addScansContext('custom', 'scansField'),
      },
    },
  }),
  {
    en: {
      roysupport: { offer_view: { meta_title: { default: 'New Roy Support Badge' } } },
    },
  }
);
 */

/**
 * export const USE_CREATE_CLAIM = "claim_create" - Form to create a claim to get an offer
export const USE_PREVIEW_CLAIM = "claim_preview" - Claim preview screen
export const USE_ITEM_CLAIM = "claim_item" - Claim item that is seen in inbox
export const USE_CLAIM_VIEW = "claim_view" - Claim review section in the offer form that is used by an issuer
export const USE_CREATE_OFFER = "offer_create" - Issuer form that is used to offer a claimed credential (only these fields should go in the final credentials)
export const USE_ITEM_OFFER = "offer_item" - Offer item view in the inbox of potential holder (initial claimer)
export const USE_VIEW_OFFER = "offer_view" - Preview offered credential by potential holder
export const USE_ITEM_CRED = "cred_item" - Final VC item in the wallet
export const USE_CRED_VIEW = "cred_view" - Final VC view in the wallet
 */

groupsUIExtension.extension = addLocalization(
  addCredential(groupsUIExtension.extension, {
    mainType: 'RoySupport',
    defaultLabel: 'Доверие от ROY.TEAM',
    expirationPeriod: (3600 * 24 * 365) / 2,
    credentialContext: {
      xs: 'http://www.w3.org/2001/XMLSchema#',
      custom: 'https://roy.team/ssi/vc/support#',
    },
    subjectMeta: {
      secretWord: {
        useAt: [USE_CREATE_CLAIM, USE_PREVIEW_CLAIM, USE_CLAIM_VIEW],
        defaultLabel: 'Кодовое слово',
        defaultHint: 'Эмитент по этому кодовому слову может догадаться, что это вы',
        validation: { required: true },
        term: { '@id': 'custom:contactName', '@type': 'xs:string' },
      },
      /*  specialMark: {
         useAt: [USE_CREATE_OFFER, USE_ITEM_OFFER, USE_VIEW_OFFER],
         defaultLabel: 'Отметка эмитента',
         defaultHint: 'Должна содержать техническое значение заключения: OK или любое другое значение, в случае отказа',
         validation: { required: true },
         term: { '@id': 'custom:specialMark', '@type': 'xs:string' },
       }, */
    },
  }),
  {
    en: {
      roysupport: { offer_view: { meta_title: { default: 'New trust badge from от ROY.TEAM' } } },
    },
    ru: {
      roysupport: { offer_view: { meta_title: { default: 'Новый бэйдж доверия от ROY.TEAM' } } },
    },
    be: {
      roysupport: { offer_view: { meta_title: { default: 'Новы значок даверу ад ROY.TEAM' } } },
    },
    pl: {
      roysupport: { offer_view: { meta_title: { default: 'Nowy odznak zaufania od ROY.TEAM' } } },
    },
    lt: {
      roysupport: { offer_view: { meta_title: { default: 'Naujas ROY.TEAM pasitikėjimo ženklas' } } },
    },
    uk: {
      roysupport: { offer_view: { meta_title: { default: 'Новий значок довіри від ROY.TEAM' } } },
    },
  }
);
groupsUIExtension.extension = addLocalization(
  addCredential(groupsUIExtension.extension, {
    mainType: 'RoyGroupFriendNCODapamoga',
    defaultLabel: 'Валанцёра НКА «Дапамога»',
    expirationPeriod: (3600 * 24 * 365) / 2,
    credentialContext: {
      xs: 'http://www.w3.org/2001/XMLSchema#',
      custom: 'https://roy.team/ssi/vc/nkodapamoga/friend#',
    },
    subjectMeta: {
      name: {
        useAt: [USE_CREATE_CLAIM, USE_PREVIEW_CLAIM, USE_CLAIM_VIEW, USE_CREATE_OFFER, USE_VIEW_OFFER, USE_CRED_VIEW],
        defaultLabel: 'Имя',
        defaultHint: '',
        validation: { required: true },
        term: { '@id': 'custom:name', '@type': 'xs:string' },
      },
      confirmationInfo: {
        useAt: [USE_CREATE_CLAIM, USE_PREVIEW_CLAIM, USE_CLAIM_VIEW],
        defaultLabel: 'Кто подтверждает',
        defaultHint:
          'Подтверждение одного из волонтеров и руководителя НКО «Дапамога» о том, что человек входит в состав волонтерского круга НКО «Дапамога».',
        validation: { required: true },
        term: { '@id': 'custom:confirmationInfo', '@type': 'xs:string' },
      },
      motivation: {
        useAt: [USE_CREATE_CLAIM, USE_PREVIEW_CLAIM, USE_CLAIM_VIEW],
        defaultLabel: 'Повод для вступления',
        defaultHint: 'Описание мотивации быть волонтером НКО «Дапамога».',
        validation: { required: true },
        term: { '@id': 'custom:motivation', '@type': 'xs:string' },
      },
      confirmationFile: {
        useAt: [USE_CREATE_CLAIM, USE_PREVIEW_CLAIM, USE_CLAIM_VIEW],
        defaultLabel: 'Прикрепите файлы',
        defaultHint:
          'Опциональное изображение или PDF с изображениями подтверждений от двух волонтеров и руководителя НКО «Дапамога» о том, что человек входит в состав волонтерского круга НКО «Дапамога».',
        validation: { required: false },
        term: addScansContext('custom', 'confirmationFile'),
      },
    },
  }),
  {
    en: {
      roygroupfriendncodapamoga: { offer_view: { meta_title: { default: 'New Dapamoga volunteer badge' } } },
    },
    ru: {
      roygroupfriendncodapamoga: { offer_view: { meta_title: { default: 'Новый бэйдж волонтёра НКО «Дапамога»' } } },
    },
    be: {
      roygroupfriendncodapamoga: { offer_view: { meta_title: { default: 'Новы бэйдж валанцёра НКА «Дапамога»' } } },
    },
    pl: {
      roygroupfriendncodapamoga: { offer_view: { meta_title: { default: 'Nowy odznak wolontariusza NGO „Dapamoga”' } } },
    },
    lt: {
      roygroupfriendncodapamoga: { offer_view: { meta_title: { default: 'Naujas NGO „Dapamoga” savanorio ženklas' } } },
    },
    uk: {
      roygroupfriendncodapamoga: { offer_view: { meta_title: { default: 'Новий значок волонтера ГО «Дапамога»' } } },
    },
  }
);
groupsUIExtension.extension = addLocalization(
  addCredential(groupsUIExtension.extension, {
    mainType: 'RoyGroupExpertiseMilitaryMedic',
    defaultLabel: 'Военный медик',
    expirationPeriod: (3600 * 24 * 365) / 2,
    credentialContext: {
      xs: 'http://www.w3.org/2001/XMLSchema#',
      custom: 'https://roy.team/ssi/vc/militarymedic/expertise#',
    },
    subjectMeta: {
      confirmationFile: {
        useAt: [USE_CREATE_CLAIM, USE_PREVIEW_CLAIM, USE_CLAIM_VIEW],
        defaultLabel: 'Прикрепите файлы',
        defaultHint: 'Фотография военного билета',
        validation: { required: true },
        term: addScansContext('custom', 'confirmationFile'),
      },
    },
  }),
  {
    en: {
      roygroupexpertisemilitarymedic: { offer_view: { meta_title: { default: 'New badge «Military Medic»' } } },
    },
    ru: {
      roygroupexpertisemilitarymedic: { offer_view: { meta_title: { default: 'Новый бэйдж «Военный медик»' } } },
    },
    be: {
      roygroupexpertisemilitarymedic: { offer_view: { meta_title: { default: 'Новы значак «Ваенны медык»' } } },
    },
    pl: {
      roygroupexpertisemilitarymedic: { offer_view: { meta_title: { default: 'Nowy odznak «Medyk wojskowy»' } } },
    },
    lt: {
      roygroupexpertisemilitarymedic: { offer_view: { meta_title: { default: 'Naujas ženklas «Kareivinis medicinos specialistas»' } } },
    },
    uk: {
      roygroupexpertisemilitarymedic: { offer_view: { meta_title: { default: 'Новий значок «Військовий медик»' } } },
    },
  }
);
const customizedGroupExt = customizeExtension(groupsUIExtension);

// This line can be uncomment to get actual source of enlgish generated localization:
// console.log(JSON.stringify(groupsUIExtension.extension.localization.translations.en, undefined, 2))

customizedGroupExt.extension.localization.translations = {
  ...groupsUIExtension.extension.localization.translations,
  ...ssiLocalesSchema.group,
};

registry.registerSync(customizedGroupExt);

export const regovExtensionRegistry = registry;
