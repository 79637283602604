import { useContext, useState, useEffect } from 'react';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { GlobalContext } from 'contexts/GlobalContext';
import { Button } from 'shared/components/common/Button/Button';
import { CustomImage } from 'common/CustomImage/CustomImage';
import { useTranslation } from 'react-i18next';
import styles from './BannerWithButton.module.scss';

interface IBannerWithButton {
  banners: {
    buttonTitle: string;
    link: string;
    imageDesktop: string;
    imageMobile: string;
  }[];
}

const BannerWithButton = ({ banners }: IBannerWithButton) => {
  const {
    screen: { innerWidth },
  } = useContext(GlobalContext);
  const [image, setImage] = useState<string | null>(null);
  const [, setCurrentImageIndex] = useState(0);
  const { t } = useTranslation('roy-global');
  const isMobile = innerWidth < 768;

  useEffect(() => {
    if (!banners || banners.length === 0) return;

    const index = banners.length - 1; // if you want to start from the last
    setImage(isMobile ? banners[index].imageMobile : banners[index].imageDesktop);
    setCurrentImageIndex(index);
  }, [banners, isMobile]);

  useEffect(() => {
    if (banners.length < 1) return;

    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => {
        const nextIndex = prevIndex === 0 ? banners.length - 1 : prevIndex - 1;
        setImage(isMobile ? banners[nextIndex].imageMobile : banners[nextIndex].imageDesktop);
        return nextIndex;
      });
    }, 10000);

    return () => clearInterval(intervalId);
  }, [banners, isMobile]);

  if (!banners.length) {
    return null;
  }

  return (
    <div className={classNames(styles.container, { [styles.mobile]: isMobile })}>
      {banners.map((banner) => {
        return (
          <CSSTransition
            classNames={{
              appear: styles.appear,
              appearActive: styles.appearActive,
              appearDone: styles.appearDone,
              enter: styles.enter,
              enterActive: styles.enterActive,
              enterDone: styles.enterDone,
              exit: styles.exit,
              exitActive: styles.exitActive,
              exitDone: styles.exitDone,
            }}
            key={banner.imageDesktop || (!isMobile ? banner.imageDesktop : banner.imageMobile)}
            timeout={1000}
            in={isMobile ? banner.imageMobile === image : banner.imageDesktop === image}
            appear={true}
          >
            <div className={styles.imageBlock}>
              <CustomImage
                src={isMobile ? banner.imageMobile || banner.imageDesktop : banner.imageDesktop}
                alt={t('images.orgImageDefault')}
                background="white"
                errorImage="noImage"
                height={'100%'}
                rounded={false}
                width="100%"
                className={styles.bannerStyle}
              />
              <Button className={styles.button} to={banner.link} target="_blank" color="light" size="small">
                {banner.buttonTitle}
              </Button>
            </div>
          </CSSTransition>
        );
      })}
    </div>
  );
};

export default BannerWithButton;
