import classNames from 'classnames';
import { Suggestions } from 'components/Suggestions/Suggestions/Suggestions';
import { GlobalContext } from 'contexts/GlobalContext';
import { IOrganisationShort as IOrganizationShort } from 'interfaces/organisation.interface';
import { ISuggestion, SuggestionVotesVariant } from 'interfaces/suggestion.interface';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { routes } from 'Routes';
import { Tab } from 'shared/components/common/Tab/Tab';
import formatServerError from 'shared/utils/formatServerError';
import styles from './ProfileSuggestions.module.scss';
import { useTranslation } from 'react-i18next';

type ProfileSuggestionsProps = {
  organizationId?: number;
  isMobile: boolean;
  setSuggestionEdit: (suggestionEdit: ISuggestion | null) => void;
  setSuggestionBlockStatus: (suggestion: boolean) => void;
  userMembership?: IOrganizationShort[];
};

export const ProfileSuggestions = ({
  isMobile,
  setSuggestionBlockStatus,
  setSuggestionEdit,
  organizationId,
  userMembership,
}: ProfileSuggestionsProps) => {
  const { t } = useTranslation('profile');
  const {
    services: { suggestionsService },
    spinner: { showSpinner, hideSpinner },
  } = useContext(GlobalContext);
  const [activeTab, setActiveTab] = useState<number>(0);
  const history = useHistory();
  const [suggestions, setSuggestions] = useState<ISuggestion[]>([]);

  useEffect(() => {
    (async () => {
      if (organizationId && userMembership?.find((org) => org.id === organizationId)) {
        try {
          showSpinner();
          const suggestions = await suggestionsService.getSuggestions(organizationId);
          setSuggestions(suggestions);

          if (history.location.pathname.includes(`${routes.union.getLink(organizationId)}/suggestion`)) {
            history.replace(history.location.pathname);
          }
        } catch (e) {
          setSuggestions([]);
        } finally {
          hideSpinner();
        }
      } else {
        setSuggestions([]);
      }
    })();
  }, [organizationId, userMembership]);

  const deleteSuggestion = async (suggestion: ISuggestion) => {
    try {
      showSpinner();
      await suggestionsService.deleteSuggestion(suggestion.organization_id, suggestion.id);
      const filterSuggestions = suggestions.filter((s) => s.id !== suggestion.id);
      setSuggestions([...filterSuggestions]);
      toast.success(t('organization.suggestions.deleted'));
    } catch (e) {
      toast.error(formatServerError(e));
    } finally {
      hideSpinner();
    }
  };

  const onVoteStatusChange = async (suggestion: ISuggestion) => {
    showSpinner();
    const updatedSuggestion =
      !!suggestion.my_reaction && suggestion.my_reaction === SuggestionVotesVariant.thumbs_up
        ? await suggestionsService.unvote(suggestion.id)
        : await suggestionsService.vote(suggestion.id);
    const index = suggestions.findIndex((s) => s.id === updatedSuggestion.id);
    if (index > -1) {
      Object.assign(suggestions[index], updatedSuggestion);
      setSuggestions([...suggestions]);
    }
    hideSpinner();
  };

  const types: Array<'all' | 'my' | 'archive'> = ['all', 'my', 'archive'];

  return (
    <div className={styles.wrapper}>
      <div className={classNames(styles.title, { [styles.mobile]: isMobile })}>
        <h4 className={classNames({ [styles.titleTextDesc]: !isMobile })}>{t('organization.suggestions.title')}</h4>

        <Tab
          className={styles.tab}
          setOutsidePanel={setActiveTab}
          tabList={[t('organization.suggestions.tabs.0'), t('organization.suggestions.tabs.1'), t('organization.suggestions.tabs.2')]}
        />
      </div>
      {types.map((el, index) => {
        return (
          activeTab === index && (
            <Suggestions
              type={el}
              suggestionsList={suggestions}
              setSuggestionBlockStatus={setSuggestionBlockStatus}
              setSuggestionEdit={setSuggestionEdit}
              voteStatusChanged={onVoteStatusChange}
              deleteSuggestion={deleteSuggestion}
              key={`Suggestions-${el}-${index}`}
              isMobile={isMobile}
            />
          )
        );
      })}
    </div>
  );
};
