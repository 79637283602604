import { Slider } from 'common/Slider/Slider';
import { SuggestionDescription } from 'components/Suggestions/SuggestionDescription/SuggestionDescription';
import SuggestionVotes from 'components/SuggestionVotes/SuggestionVotes';
import { GlobalContext } from 'contexts/GlobalContext';
import { ISuggestion } from 'interfaces/suggestion.interface';
import { useContext } from 'react';
import generalStyles from '../NewsDetails/NewsDetails.module.scss';
import styles from './Suggestion.module.scss';
import { useTranslation } from 'react-i18next';

type SuggestionContentProps = {
  suggestion: ISuggestion;
};
export const SuggestionContent = ({ suggestion }: SuggestionContentProps) => {
  const { t } = useTranslation('polls');
  const { openModal } = useContext(GlobalContext);
  return (
    <div className={generalStyles.contentWrapper}>
      <div>
        {suggestion.description ? <SuggestionDescription suggestion={suggestion} fullDesription styles={styles} /> : <p>Описания нет</p>}
      </div>

      {!!suggestion.images?.length && (
        <Slider
          slidesOnPage={1}
          controlsVerticalOffset={0}
          controlsHorizontalOffset={0}
          children={suggestion.images.map((image) => (
            <div className={styles.imageWrapper}>
              <img
                src={image.url}
                alt={suggestion.title}
                onClick={(e) =>
                  openModal({
                    params: {
                      mainContainer: <img src={image.url} alt={suggestion.title} />,
                    },
                  })
                }
              />
            </div>
          ))}
        />
      )}
      <div className={styles.votesWrapper}>
        <h3>{t('voting')}</h3>
        <SuggestionVotes
          userReaction={suggestion.my_reaction}
          suggestionId={suggestion.id}
          votes={suggestion.reactions}
          disable={suggestion.is_closed}
        />
      </div>
    </div>
  );
};
