import { Tab } from 'shared/components/common/Tab/Tab';
import { AdminTab } from '../tabs/AdminTab';
import { OrganizationsTab } from '../tabs/OrganizationsTab';
import { SubscribesTab } from '../tabs/SubscribesTab';
import { PeopleTabs } from '../tabs/PeopleTabs';
import styles from './Modal.module.scss';
import { IProfile, ProfileMembership } from 'interfaces/profile.interface';
import { useTranslation } from 'react-i18next';

export enum TabListEnvModal {
  admin = 'admin',
  member = 'member',
  subscriptions = 'subscriptions',
  subscribers = 'subscribers',
}

type EnvironmentModalProps = {
  selectDefault?: TabListEnvModal;
  membership: {
    member: Array<Partial<ProfileMembership>>;
    admin: Array<Partial<ProfileMembership>>;
  };
  subscribes: {
    organizations: Array<Partial<ProfileMembership>>;
    people: Array<Partial<IProfile>>;
  };
};

export const EnvironmentModal = ({ selectDefault = TabListEnvModal.member, membership, subscribes }: EnvironmentModalProps) => {
  const { t } = useTranslation('modals');
  const { admin, member } = membership;
  const { organizations, people } = subscribes;

  const tabList = [
    !!admin?.length && t(`environment.tabs.${TabListEnvModal.admin}`),
    !!member?.length && t(`environment.tabs.${TabListEnvModal.member}`),
    (!!organizations?.length || !!people?.length) && t(`environment.tabs.${TabListEnvModal.subscriptions}`),
    !!subscribes.people?.length && t(`environment.tabs.${TabListEnvModal.subscribers}`),
  ];

  const tabPanels = [
    !!admin?.length && <AdminTab organizations={admin} />,
    !!member?.length && <OrganizationsTab organizations={member} />,
    (!!organizations?.length || !!people?.length) && <SubscribesTab organizations={organizations} people={people} />,
    !!subscribes.people?.length && <PeopleTabs people={[...subscribes.people]} />,
  ];

  let defaultSelectValue: number;

  const filteredTabList = tabList.filter((i) => !!i);
  const indexOfDefault = filteredTabList.findIndex((i) => i === selectDefault);

  if (indexOfDefault !== -1) {
    defaultSelectValue = indexOfDefault;
  } else {
    defaultSelectValue = 0;
  }

  return (
    <div className={styles.wrapper}>
      <h4>{t('environment.title')}</h4>
      <Tab selectDefault={defaultSelectValue} tabPanels={tabPanels.filter((i) => !!i)} tabList={filteredTabList} />
    </div>
  );
};
