// import cn from 'classnames';
import { Icon } from 'shared/components/common/Icon/Icon';
// import styles from './SwitchButton.module.scss';

interface SwitchButtonProps {
  open: boolean;
  onClick?: () => void;
  className?: string;
}

const SwitchButton = ({ open, onClick, className }: SwitchButtonProps) => {
  return (
    <div className={className} onClick={onClick}>
      <Icon rotate={open ? -90 : 90} iconName="arrowBorderLeft" />
    </div>
  );
};

export default SwitchButton;
