import { Slider } from 'common/Slider/Slider';
import { GlobalContext } from 'contexts/GlobalContext';
import { useContext } from 'react';
import { PollFront } from 'shared/interfaces/polls';
import pollStyles from './Poll.module.scss';
import PollQuestions from './PollQuestions/PollQuestions';
import { useTranslation } from 'react-i18next';

type PollContentProps = {
  poll: PollFront;
  closed: boolean;
};

export const PollContent = ({ poll, closed }: PollContentProps) => {
  const { t } = useTranslation('polls');
  const { openModal } = useContext(GlobalContext);
  return (
    <>
      {!!poll.images?.length && (
        <Slider
          slidesOnPage={1}
          controlsVerticalOffset={0}
          controlsHorizontalOffset={0}
          children={poll.images.map((image) => (
            <div className={pollStyles.imageWrapper}>
              <img
                src={image?.url}
                alt={poll.name}
                onClick={(e) =>
                  openModal({
                    params: {
                      mainContainer: <img src={image.url} alt={poll.name} />,
                    },
                  })
                }
              />
            </div>
          ))}
        />
      )}
      {!closed ? (
        !!poll.organization.id && !!poll.id && <PollQuestions organizationId={poll.organization.id} pollId={poll.id} />
      ) : (
        <div className={pollStyles.wrapperCloseTitle}>
          <div className={pollStyles.questionCloseTitleWrapper}>
            <h2>{t('finished')}</h2>
          </div>
        </div>
      )}
    </>
  );
};
