import { routes } from 'Routes';
import { CreationEvent } from 'interfaces/events.interface';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addGeneralImage } from 'services/general';
import { createMaterial, publishMaterial } from 'services/knowledge-base';
import formatServerError from 'shared/utils/formatServerError';

export const useMaterialsCreation = () => {
  const { t } = useTranslation('editor');
  const history = useHistory();

  const saveHandler = async (orgId: string, data: Partial<CreationEvent>) => {
    try {
      const created = await createMaterial(orgId, data);
      return created;
    } catch (error) {
      toast.error(formatServerError(error));
    }
  };

  const onPublish = async (orgId: string, data: Partial<CreationEvent>) => {
    const created = await saveHandler(orgId, data);
    await publishMaterial(orgId, created.id);
    toast(t('toasts.success.materialCreated'));
    history.push(routes.materialDetails.getLink(orgId, created.id));
  };
  const onSave = async (orgId: string, data: Partial<CreationEvent>) => {
    await saveHandler(orgId, data);
    toast('toasts.success.materialAddedToDraft');
    /* history.push(routes.newsDetails.getLink(orgId, created.id)); */
  };

  const initialValues: CreationEvent & { isAgree: boolean } = {
    title: '',
    content: '',
    political: false,
    sensitive: false,
    tags: [],
    preview: undefined,
    visible: 0,
    isAgree: false,
  };
  return {
    uploadImage: addGeneralImage,
    onPublish,
    onSave,
    initialValues,
  };
};
