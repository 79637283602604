import { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { GlobalDataContext } from '../../contexts/GlobalDataContext';
import styles from './Profile.module.scss';
import { routes } from 'Routes';
import UserProfileLayout from 'pages/Profile/UserProfileLayout/UserProfileLayout';
import FeedLayout from './FeedLayout/FeedLayout';
import { HideProfileButton } from './HideProfileButton/HideProfileButton';
import { FirstSession } from 'components/Modals/ProfileModals/FirstSession/FirstSession';
import { ModalWindow } from 'shared/components/ModalWindow/ModalWindow';

export function Profile() {
  const { user } = useContext(GlobalDataContext);
  const history = useHistory();
  const { id } = useParams<{ id: string | undefined }>();
  const [isOpenUserInfo, setIsOpenUserInfo] = useState<boolean>(true);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  useEffect(() => {
    if (!id && user?.id) {
      history.push(routes.profile.getLink(user.id));
      return;
    }
    if (localStorage.getItem('redirect') && !!id && !!user?.id) {
      const redirect = localStorage.getItem('redirect');
      localStorage.removeItem('redirect');
      history.push(redirect);
    }
  }, [id, user]);

  useEffect(() => {
    if (!!localStorage?.getItem('firstSession')) {
      setIsOpenModal(true);
      localStorage?.removeItem('firstSession');
    }
  }, []);

  const changeUserInfo = () => {
    setIsOpenUserInfo(!isOpenUserInfo);
  };

  return (
    <div className={styles.profileWrapper}>
      {isOpenModal && (
        <ModalWindow isOpen={isOpenModal} setIsOpen={setIsOpenModal} key={'firstSession-modal'}>
          <FirstSession closeModal={() => setIsOpenModal(false)} key={'firstSession-modal-content'} />
        </ModalWindow>
      )}
      {!isOpenUserInfo && <HideProfileButton isOpenUserInfo={isOpenUserInfo} onclick={changeUserInfo} />}
      <UserProfileLayout profileId={id} isOpenUserInfo={isOpenUserInfo} setIsOpenUserInfo={changeUserInfo} />
      <FeedLayout profileId={id} isOpenUserInfo={isOpenUserInfo} setIsOpenUserInfo={setIsOpenUserInfo} key={`FeedLayout-${id}`} />
    </div>
  );
}
