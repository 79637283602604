import { ContentEditorSkeleton } from 'components/ContentEditor/ContentEditorSkeleton';
import PublicationEventSettings from './PublicationSettings/PublicationSettings';
import { useNewsCreation } from './hooks/newsCreation';

export const NewsCreation = () => {
  const { onPublish, onSave, uploadImage, initialValues } = useNewsCreation();

  return (
    <ContentEditorSkeleton
      ContentSettings={PublicationEventSettings}
      uploadImage={uploadImage}
      onPublish={onPublish}
      onSave={onSave}
      initialValues={initialValues}
    />
  );
};
