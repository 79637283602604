import styles from './Events.module.scss';
import SEO from 'shared/utils/Seo';
import EventsGroupContainer, { EventsDataTypes } from './EventsGroupContainer/EventsGroupContainer';
import BannerWithButton from 'common/BannerWithButton/BannerWithButton';
import { bannersEventsMock } from 'common/BannerWithButton/bannersMock';
import { useTranslation } from 'react-i18next';

const Events = () => {
  const { t } = useTranslation('content');

  return (
    <>
      <SEO image={'/logo-small.svg'} title={'Events'} description={'Events'} />

      <div className={styles.eventsWrapper}>
        <BannerWithButton banners={[{ ...bannersEventsMock[0], buttonTitle: t('try') }]} />
        <EventsGroupContainer type={EventsDataTypes.allEvents} key={'allEvents'} />
      </div>
    </>
  );
};

export default Events;
