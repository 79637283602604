import { useEffect } from 'react';
import ChatMessageAdd from '../ChatMessageAdd';
import ChatMessages from '../ChatMessages/ChatMessages';
import { useDispatch, useSelector } from '../../../../redux-toolkit';
import { getMessages, resetActiveThread } from 'shared/slices/chat';
import styles from './ChatThread.module.scss';
import ArrowButton, { ArrowDirection } from 'shared/components/common/ArrowButton/ArrowButton';
import { Spinner } from 'shared/components/common/Spinner/Spinner';
import { useTranslation } from 'react-i18next';

const ChatThread = () => {
  const { t } = useTranslation('shared');
  const { activeThreadId, threads, isSelectingMessagesMode, isThreadsLoaded, isLoading } = useSelector(
    (state) => state.chat
  );
  const [thread] = (threads || []).filter(({ id }) => Number(activeThreadId) === Number(id));
  const dispatch = useDispatch();
  const loadMessages = (shouldLoad) => {
    if (activeThreadId && shouldLoad) {
      dispatch(getMessages(activeThreadId));
    }
  };
  useEffect(() => {
    const interval = setInterval(() => loadMessages(!isSelectingMessagesMode), 1000 * 600);
    return () => {
      clearInterval(interval);
    };
  }, [activeThreadId, isSelectingMessagesMode]);

  return (
    <div className={styles.wrapper}>
      {isLoading && (
        <div className={styles.wrapperSpinner}>
          <Spinner size={30} />
        </div>
      )}

      <div className={styles.threadHeader}>
        <ArrowButton
          arrowDirection={ArrowDirection.back}
          onClick={() => {
            dispatch(resetActiveThread());
          }}
        />
        {thread && <p>{thread.name}</p>}
      </div>
      {!activeThreadId && isThreadsLoaded && !isLoading && (
        <div className={styles.ThreadMessage}>
          <h4>{threads.length > 0 ? t('chat.selectChat') : t('chat.noChats')}</h4>
        </div>
      )}
      <div className={styles.threadContent}>{activeThreadId && <ChatMessages />}</div>
      {activeThreadId && <ChatMessageAdd />}
    </div>
  );
};

export default ChatThread;
