import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          fontFamily: 'inherit',
          borderRadius: 16,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontFamily: 'inherit',
          borderRadius: 16,
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            fontFamily: 'inherit',
            borderColor: '#6339fb',
          },
        },
        notchedOutline: {
          fontFamily: 'inherit',
          fontSize: 16,
          padding: '0 10px',
          '& legend': {
            width: 'fit-content',
            '& span': {
              fontFamily: 'inherit',
              fontSize: 12,
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        shrink: {
          fontFamily: 'inherit',
          fontSize: 16,
          '&.Mui-focused': {
            fontFamily: 'inherit',
            color: '#6339fb',
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontFamily: 'inherit',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        contained: {
          fontFamily: 'inherit',
          fontSize: 14,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
  },
});
