import styles from './DatePicker.module.scss';
import { useTranslation } from 'react-i18next';
import { formatDistanceWithLocale, formatWithLocale } from 'shared/utils/dateHelper';
import { Icon } from 'shared/components/common/Icon/Icon';
import classNames from 'classnames';
import { DatePickerModal } from './DatePickerModal/DatePickerModal';

type Props = {
  date_start: Date;
  date_end?: Date;
  setDateStart: (val: Date) => void | Promise<void>;
  setDateEnd: (val: Date) => void | Promise<void>;
  openModal: (content: JSX.Element) => void;
  closeModal: () => void;
};

export const PublicationSettingDatePicker = (props: Props) => {
  const { t, i18n } = useTranslation('editor');

  const eventDate = formatWithLocale(new Date(props.date_start), i18n.language, 'dd.MM.yy - HH:mm');

  const eventDateDistance = props.date_end ? formatDistanceWithLocale(props.date_start, props.date_end, i18n.language) : undefined;

  const openModalHandler = () => {
    props.openModal(<DatePickerModal {...props} />);
  };
  return (
    <div className={styles.wrapper}>
      <div className={classNames(styles.time, styles.pointer)} onClick={openModalHandler}>
        <Icon iconName="calendar" />
        <h4>{eventDate}</h4>
      </div>
      <div className={styles.time}>
        <p>{`${t('duration')}: ${eventDateDistance}`}</p>
      </div>
    </div>
  );
};
