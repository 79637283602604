import { useContext } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Button } from 'shared/components/common/Button/Button';
import { Radio } from 'shared/components/common/Radio/Radio';
import { GlobalContext } from 'contexts/GlobalContext';
import { TextArea } from 'shared/components/common/TextArea/TextArea';
import formatServerError from 'shared/utils/formatServerError';
import { useTranslation } from 'react-i18next';

const NewsErrorModal = ({ newsId, organisationId }: { newsId: number; organisationId: number }) => {
  const { t } = useTranslation('news');
  const {
    closeModal,
    services: { organisationsService },
  } = useContext(GlobalContext);
  const newsErrors = [
    {
      id: 1,
      label: t('errors.grammar'),
    },
    {
      id: 2,
      label: t('errors.info'),
    },
    {
      id: 3,
      label: t('errors.sensitive'),
    },
    {
      id: 4,
      label: t('errors.commercial'),
    },
  ];

  const validationSchema = Yup.object().shape({
    error: Yup.string().trim().required(t('errors.required')),
    textError: Yup.string().trim().required(t('errors.required')),
  });

  return (
    <>
      <Formik
        initialValues={{ error: 1, textError: '' }}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          try {
            const { error, textError } = values;
            await organisationsService.errorNews(newsId, error, textError);
            toast(t('toast.sendError'));
            closeModal();
          } catch (e) {
            toast.error(formatServerError(e));
          }
        }}
      >
        {({ errors, touched, isSubmitting, dirty, isValid, values, handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <h3>{t('errors.chooseError')}</h3>
            <Radio
              options={newsErrors}
              autoFocus
              error={errors?.error && touched?.error && errors.error}
              name="error"
              value={values['error']}
              valueChange={(value, name) => setFieldValue(name, value)}
            />
            <TextArea
              name="textError"
              label={t('actions.TextArea.label')}
              valueChange={(value, name) => {
                setFieldValue(name, value);
              }}
              error={errors?.textError && touched?.textError && errors.textError}
            />

            <Button type="submit" disabled={isSubmitting || !isValid || !dirty}>
              {t('actions.send')}
            </Button>
          </form>
        )}
      </Formik>
    </>
  );
};

export default NewsErrorModal;
