import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import styles from './LanguageSelect.module.scss';
import { Icon } from 'shared/components/common/Icon/Icon';
import { languages } from 'shared/constants/languages';

export const LanguageSelect = () => {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLangCode, setSelectedLangCode] = useState(i18n.language);
  const selectedLang = languages.find((lang) => lang.code === selectedLangCode);

  useEffect(() => {
    if (!selectedLang) {
      handleLanguageChange('en');
    }
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleLanguageChange = (langCode: string) => {
    i18n.changeLanguage(langCode);
    setSelectedLangCode(langCode);
    setIsOpen(false);
  };

  return (
    <div className={styles.languageSelect}>
      {!isOpen && (
        <div className={styles.selected} onClick={toggleDropdown}>
          <Icon iconName={'translate'} />
          <span>{selectedLang?.name}</span>
        </div>
      )}
      {isOpen && (
        <div className={styles.options}>
          <div className={styles.languages}>
            {languages.map(({ code, name }) => (
              <div
                key={code}
                className={`${styles.option} ${code === selectedLangCode ? styles.active : ''}`}
                onClick={() => handleLanguageChange(code)}
              >
                {name}
              </div>
            ))}
          </div>
          <div className={styles.crossIcon} onClick={toggleDropdown}>
            &#10005;
          </div>
        </div>
      )}
    </div>
  );
};
