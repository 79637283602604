import Map from 'react-map-gl';
import type { MapEvent, ViewStateChangeEvent } from 'react-map-gl';
import type { ViewState } from 'react-map-gl/src/types';

type Props = {
  children: Array<JSX.Element> | JSX.Element;
  onZoomEnd?: (e: ViewStateChangeEvent) => void;
  onDragEnd?: (e: ViewStateChangeEvent) => void;
  onZoom?: (e: ViewStateChangeEvent) => void;
  onLoad?: (e: MapEvent) => void;
  onClick?: (e: mapboxgl.MapLayerMouseEvent) => void;
  onMouseMove?: (e: mapboxgl.MapLayerMouseEvent) => void;
  initialViewState?: Partial<ViewState> & {
    bounds?: mapboxgl.LngLatBoundsLike;
    fitBoundsOptions?: mapboxgl.FitBoundsOptions;
  };
};

export const CustomMap = ({ children, onZoomEnd, onDragEnd, onZoom, onLoad, onClick, onMouseMove, initialViewState }: Props) => {
  const { REACT_APP_MAPBOX_TOKEN } = process.env;
  const MAX_ZOOM = 19;
  const MAPBOX_STYLE = 'mapbox://styles/staski/clevhv0t200m901lkoh1xrwnt';
  return (
    <Map
      attributionControl={false}
      initialViewState={initialViewState}
      mapStyle={MAPBOX_STYLE}
      mapboxAccessToken={REACT_APP_MAPBOX_TOKEN}
      onZoomEnd={onZoomEnd}
      onDragEnd={onDragEnd}
      onZoom={onZoom}
      onLoad={onLoad}
      onClick={onClick}
      onMouseMove={onMouseMove}
      maxZoom={MAX_ZOOM}
    >
      {children}
    </Map>
  );
};
