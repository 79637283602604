import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { BaseEmoji, Picker } from 'emoji-mart';
import ReactQuill from 'react-quill';
import styles from './CustomToolbar.module.scss';
import { ReactComponent as EmojiIcon } from 'shared/icons/emoji.svg';
import classNames from 'classnames';
import emojiRu from 'shared/i18next/ru/emoji.json';
import emojiLt from 'shared/i18next/lt/emoji.json';
import emojiBe from 'shared/i18next/be/emoji.json';
import emojiUk from 'shared/i18next/uk/emoji.json';
import emojiEn from 'shared/i18next/en/emoji.json';
import emojiPl from 'shared/i18next/pl/emoji.json';
import { useTranslation } from 'react-i18next';

const emojiTranslations = {
  en: emojiEn,
  ru: emojiRu,
  uk: emojiUk,
  be: emojiBe,
  lt: emojiLt,
  pl: emojiPl,
};

type CustomToolbarProps = {
  quillRef: MutableRefObject<ReactQuill>;
  helperText?: string;
  customButton?: JSX.Element;
  className?: string;
};

export const CustomToolbar = ({ quillRef, helperText, customButton, className }: CustomToolbarProps) => {
  const { i18n } = useTranslation();
  const [showEmoji, setShowEmoji] = useState(false);
  const emojiRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (emojiRef.current && !emojiRef.current.contains(event.target as Node)) {
        setShowEmoji(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [showEmoji]);

  const onPickerSelect: (emoji: BaseEmoji) => void = (e) => {
    const quill = quillRef.current.getEditor();
    quill.focus();
    const cursorPosition = quill.getSelection().index;
    quill.insertText(cursorPosition, e.native);
    setShowEmoji(false);
  };

  return (
    <div id="toolbar" className={classNames(styles.wrapper, className)}>
      {helperText && <div className={styles.helper}>{helperText}</div>}
      <button className="ql-bold"></button>
      <button className="ql-italic"></button>
      <button className="ql-underline"></button>
      <button className="ql-link"></button>
      <button onClick={() => setShowEmoji(!showEmoji)} className={styles.fileIcon}>
        <EmojiIcon />
      </button>
      {customButton}
      {showEmoji && (
        <div ref={emojiRef} className={styles.emojiWrapper}>
          <Picker
            onSelect={onPickerSelect}
            i18n={emojiTranslations[i18n.language] || emojiEn}
            showPreview={false}
            showSkinTones={false}
          />
        </div>
      )}
    </div>
  );
};
