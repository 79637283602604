import { FunctionComponent, MouseEventHandler, useContext } from 'react';
import { GlobalContext } from 'contexts/GlobalContext';
import { WalletHandler, REGISTRY_SECTION_OWN, normalizeValue } from '@owlmeans/regov-ssi-core';
import { regovConfig, regovExtensionRegistry, regovNavigatorBuilder, walletServerClient } from 'regov/config';
import styles from './CustomBadge.module.scss';
import { ClaimCreateParams } from '@owlmeans/regov-ext-custom/dist/web/component/claim/create';
import {
  ClaimNavigator,
  ClaimNavigatorParams,
  EXTENSION_ITEM_PURPOSE_CLAIM,
  EXTENSION_ITEM_PURPOSE_ROUTE,
  NavigatorContextProvider,
  useNavigator,
} from '@owlmeans/regov-lib-react';
import { matchPath, useHistory } from 'react-router-dom';
import { useRegov } from 'regov/context';
import { toast } from 'react-toastify';
import { IBadge } from 'interfaces/badge.interface';
import { BadgeInfo } from '../BadgeInfo/BadgeInfo';
import { BadgeView } from 'components/BadgeView/BadgeView';
import { CustomDescription } from '@owlmeans/regov-ext-custom';
import { Button } from 'shared/components/common/Button/Button';
import { openSSILoginBuilder } from '../Dialog/login.helper';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { i18n } from '@owlmeans/regov-lib-react/dist/web/integration';
import { isSSR } from 'utils/isSSR';
import { RegovProviderWrapper } from 'regov/persistent-wallet';
import { Suspensed } from 'components/Suspense/Suspense';

type CustomBadgeType = {
  badge: IBadge;
  isHideShadow?: boolean;
  handler?: WalletHandler;
};

export const CustomBadge = ({ badge, handler, isHideShadow = false }: CustomBadgeType) => {
  const { t } = useTranslation('ssi');
  const { handler: _localHandler } = useRegov();
  handler = handler ?? _localHandler;
  const { openModal, closeModal } = useContext(GlobalContext);
  const history = useHistory();
  const navigatorBuilder = (handler: WalletHandler) => regovNavigatorBuilder(handler, regovConfig, history);

  const claimViewNavigator = useNavigator({
    home: async () => {
      toast(t('toast.badgeReq'));
      closeModal();
    },
  });
  const issuers = badge.can.flatMap((shortOrg) => shortOrg.issuers);
  const navigator = useNavigator<ClaimNavigator>({
    success: async (params: ClaimNavigatorParams & { issuer?: string }) => {
      const renderers = regovExtensionRegistry.produceComponent(EXTENSION_ITEM_PURPOSE_ROUTE, params.descr.mainType);
      const Renderer = renderers.find((renderer) => matchPath(params.path, { path: `/${renderer.params.path}` })).com as FunctionComponent<{
        id: string;
        lockIssuer?: boolean;
        issuer?: string;
      }>;
      if (Renderer) {
        openModal({
          params: {
            mainContainer: (
              <RegovProviderWrapper
                handler={handler}
                config={regovConfig}
                navigatorBuilder={navigatorBuilder}
                serverClient={walletServerClient}
                source="claim.popup"
              >
                <I18nextProvider i18n={i18n}>
                  <NavigatorContextProvider navigator={claimViewNavigator}>
                    <Renderer id={params.id} issuer={params.issuer} lockIssuer />
                  </NavigatorContextProvider>
                </I18nextProvider>
              </RegovProviderWrapper>
            ),
          },
        });
      }
    },
  });
  const asquired = handler.wallet
    ?.getCredRegistry()
    .registry.credentials[REGISTRY_SECTION_OWN].find((inst) => inst.credential.type.includes(badge.type));

  const descr = regovExtensionRegistry.registry.extensions
    .flatMap((extension) => extension.schema.credentials[badge.type])
    .find((descr) => !!descr) as CustomDescription;

  const requested = handler.wallet
    ?.getClaimRegistry()
    .registry.credentials[REGISTRY_SECTION_OWN].find((inst) => inst.credential.type.includes(descr.claimType));

  const openGetBadge: MouseEventHandler = (ev) => {
    ev.stopPropagation();
    ev.preventDefault();
    const renderers = regovExtensionRegistry.produceComponent(EXTENSION_ITEM_PURPOSE_CLAIM, badge.type);
    const Renderer = renderers[0].com as FunctionComponent<ClaimCreateParams>;
    openModal({
      params: {
        mainContainer: (
          <BadgeInfo descr={descr} badge={badge} wallet={handler.wallet} requested={!!requested}>
            <>
              {!isSSR() && !asquired && !!handler.wallet && (
                <Suspensed fallback={<div>Loading...</div>}>
                  <RegovProviderWrapper
                    handler={handler}
                    config={regovConfig}
                    navigatorBuilder={navigatorBuilder}
                    serverClient={walletServerClient}
                    source="claim.popup"
                  >
                    <NavigatorContextProvider navigator={navigator}>
                      <Renderer issuer={normalizeValue(issuers)[0]} />
                    </NavigatorContextProvider>
                  </RegovProviderWrapper>
                </Suspensed>
              )}
              {!handler.wallet && (
                <div className={styles.actions}>
                  <img className={styles.image} src="/icons/OwlMeans.svg" alt="OwlMeans" />
                  <Button
                    onClick={() => openSSILoginBuilder({ openModal, closeModal, passedHandler: handler, history })()}
                    size={'small'}
                    className={styles.button}
                    maxWidth
                  >
                    {t('buttons.enterWallet')}
                  </Button>
                </div>
              )}
            </>
          </BadgeInfo>
        ),
      },
    });
  };

  return <BadgeView badge={badge} isHideShadow={isHideShadow || !!asquired} onClick={openGetBadge} />;
};
