export interface IComments {
  answers?: number;
  comment: string;
  is_changed: boolean;
  parent_id?: number;
  deleted_at?: Date;
  id: number;
  published_at: Date;
  user: { id: number; public_family: string; public_name: string; public_avatar: string; username: string };
  answersComments?: IComments[];
  my_reaction: ICommentReactions | null;
  reactions: any;
}

export enum ICommentReactions {
  thumbs_up = 'thumbs_up',
  thumbs_down = 'thumbs_down',
  deleteReaction = -1,
}
