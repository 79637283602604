import styles from './ProfilePayments.module.scss';
import { useContext } from 'react';
import { GlobalContext } from 'contexts/GlobalContext';
import { PaymentCreated } from 'shared/interfaces/finance';
import PaymentModal from 'common/PaymentModal/PaymentModal';
import { StepsProgressBar } from 'common/StepsProgressBar/StepsProgressBar';
import { Button } from 'shared/components/common/Button/Button';
import { currencyLabel } from 'components/Finance/Finance';
import { MainCard } from 'components/Crads/NewsCards/MainCard/MainCard';
import { mockImages } from 'shared/constants/MockImages/MockImages';
import { useTranslation } from 'react-i18next';

type ProfilePaymentsProps = {
  payment: PaymentCreated;
  isMobile?: boolean;
};

export const ProfilePayments = ({ payment, isMobile }: ProfilePaymentsProps) => {
  const { t } = useTranslation('profile');
  const { openModal } = useContext(GlobalContext);
  const openPaymentModal = (id: number) => {
    if (!payment.auto_payments?.length && payment.manual_payments?.length === 1) {
      window.open(payment.manual_payments[0].payment_link);
      return;
    }

    openModal({
      params: {
        mainContainer: <PaymentModal payment={payment} />,
      },
    });
  };
  const isPromotion = payment.type === 1;
  const content = (
    <div className={styles.content}>
      {isPromotion && <StepsProgressBar stepNumber={payment.collected} stepsCount={payment.ammount} />}
      <div className={styles.payButtonWrapper}>
        <Button className={styles.button} maxWidth color="light" size="small" onClick={() => openPaymentModal(payment.id)}>
          {t('organization.finance.payment.help')}
        </Button>
        {isPromotion && (
          <div className={styles.paidCount}>
            <span>{t('organization.finance.payment.paid')}</span>
            <div className={styles.count}>
              {payment.collected}
              {currencyLabel[payment.currency]}
            </div>
          </div>
        )}
      </div>
      <span>{payment.description}</span>
      {isPromotion && <span>{`${t('organization.finance.payment.validUntil')} ${payment.date_end}`}</span>}
    </div>
  );
  return (
    <MainCard
      maxHeight={{ content: 140, title: 30 }}
      content={content}
      image={payment.image || mockImages.payment}
      title={payment.title}
      publishedAt={payment.created_at}
      withFooter={false}
      isMobile={isMobile}
      eventExtra={{ date_start: payment.created_at, date_end: payment.date_end, price: payment.currency }}
    />
  );
};
