import React from 'react';
import styles from './Chip.module.scss';
import classNames from 'classnames';
interface ChipProps {
  title?: string;
  color?: string;
  className?: string;
  count?: number;
}

const Chip = ({ title, color, className }: ChipProps): JSX.Element => {
  return (
    <div style={{ backgroundColor: `${color}` }} className={classNames(styles.chip, { [className]: className })}>
      {title}
    </div>
  );
};

export default Chip;
