import classNames from 'classnames';
import styles from './ScrollingTextWrapper.module.scss';
import { MouseEventHandler, useRef, useState } from 'react';

type Props = {
  className?: string;
  children: JSX.Element;
  speed?: number;
};

export const ScrollingTextWrapper = ({ className, children, speed = 50 }: Props) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [duration, setDuration] = useState(0);

  const move: MouseEventHandler<HTMLDivElement> = () => {
    const scrollRefCurrent = scrollRef.current;
    const childrenWidth = (scrollRefCurrent?.offsetWidth || 0) - (containerRef.current?.offsetWidth || 0);
    const animationDuration = childrenWidth / speed;
    setDuration(animationDuration);
  };

  const stop: MouseEventHandler<HTMLDivElement> = () => {
    setDuration(0);
  };

  return (
    <div className={classNames(styles.wrapper, className)} onMouseEnter={move} onMouseLeave={stop} ref={containerRef}>
      <div
        style={
          duration > 0
            ? {
                transform: `translateX(${
                  (containerRef.current?.offsetWidth || 0) - (scrollRef.current?.offsetWidth || 0)
                }px)`,
                transition: `all ${duration}s linear .5s`,
              }
            : { transform: 'none' }
        }
        className={styles.scrollable}
        ref={scrollRef}
      >
        {children}
      </div>
    </div>
  );
};
