import { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Input } from 'shared/components/common/Input/Input';
import styles from './Checklist.module.scss';
// import buttonStyles from 'shared/components/common/Button/Button.module.scss';
import formatServerError from 'shared/utils/formatServerError';
import { Button } from 'shared/components/common/Button/Button';
import { useTranslation } from 'react-i18next';

interface Props {
  isExpanded: boolean;
  addCheckItem: (name: string) => void;
  setIsExpanded: (value: boolean) => void;
}
const KanbanCheckItemAdd = (props: Props) => {
  const { t } = useTranslation('shared');
  const { addCheckItem, setIsExpanded, isExpanded } = props;
  const [name, setName] = useState<string>('');

  // const handleCancel = (): void => {
  //   setIsExpanded(false);
  //   setName('');
  // };

  const handleChange = (value): void => {
    setName(value);
  };

  const handleSave = async (): Promise<void> => {
    try {
      if (!name) {
        return;
      }
      addCheckItem(name);
      setIsExpanded(false);
      setName('');
    } catch (err) {
      toast.error(formatServerError(err));
      console.error(err);
    }
  };

  return (
    <>
      {isExpanded ? (
        <div className={styles.wrapper}>
          <Input
            valueChange={handleChange}
            placeholder={t('kanban.checklist.placeholder')}
            value={name}
            className={styles.inputWrapper}
          />
          <Button onClick={handleSave}>{t('actions.add')}</Button>
        </div>
      ) : null}
    </>
  );
};

KanbanCheckItemAdd.propTypes = {
  addCheckItem: PropTypes.func.isRequired,
};

export default KanbanCheckItemAdd;
