import { useContext, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { REGISTRATION_PUBLIC_NAME_SS_KEY } from 'constans/storage';
import { routes } from 'Routes';
import { Link, useLocation } from 'react-router-dom';
import styles from './StepCredentials.module.scss';
import { GlobalContext } from 'contexts/GlobalContext';
import { IUser } from 'interfaces/user.interface';
import { Checkbox } from 'shared/components/common/Checkbox/Checkbox';
import { Button } from 'shared/components/common/Button/Button';
import { PASSWORD_PATTERN } from 'shared/components/common/Validator/validators';
import { TextField } from 'shared/components/common/TextField/TextField';
import { useTranslation } from 'react-i18next';

type WithEmailProps = {
  goNext?: () => void;
  goBack?: () => void;
};

export const WithEmail = ({ goNext }: WithEmailProps) => {
  const {
    services: { authService },
    spinner: { showSpinner, hideSpinner },
  } = useContext(GlobalContext);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const { t } = useTranslation('auth');
  const [asyncErrors, setAsyncErrors] = useState<{ [key: string]: string }>({});

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .trim()
      .email(t('registration.errors.invalidEmail'))
      .max(100, t('registration.errors.max100'))
      .required(t('registration.errors.required')),
    password: Yup.string()
      .max(50, t('registration.errors.max50'))
      .trim()
      .matches(PASSWORD_PATTERN, t('registration.errors.notValidPassword'))
      .required(t('registration.errors.required')),
    _confirm_password: Yup.string()
      .trim()
      .oneOf([Yup.ref('password'), null], t('registration.errors.confirmPassword'))
      .required(t('registration.errors.required')),
    _accept: Yup.boolean(),
    mailing: Yup.boolean(),
  });

  const { errors, touched, values, handleSubmit, setFieldValue, isValid, validateForm, setFieldTouched } = useFormik({
    initialValues: authService.registrationUserData, // not needed
    validationSchema,
    validate: () => {
      return asyncErrors;
    },
    onSubmit: async (values) => {
      showSpinner();
      const redirect = searchParams.get('redirect') ?? '';

      const { email, password, mailing } = values;
      const { invite_code, invite_id } = authService.registrationUserData;

      const newUser: IUser & { _accept: boolean; mailing: boolean } = {
        email,
        password,
        mailing,
        invite_code,
        invite_id,
      } as IUser & { _accept: boolean; mailing: boolean }; // fix types

      const result = await authService.register(newUser).finally(() => hideSpinner());

      localStorage?.setItem('redirect', redirect);

      const { public_family, public_name } = result;

      if (public_family && public_name) {
        sessionStorage.setItem(REGISTRATION_PUBLIC_NAME_SS_KEY, `${public_family} ${public_name}`);
      }
      goNext();

      hideSpinner();
    },
  });

  authService.registrationUserData = values;

  return (
    <div className={styles.StepCredentials}>
      <div className={styles.container}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            validateForm().then(() => handleSubmit());
          }}
        >
          <TextField
            setValue={(value) => {
              delete asyncErrors.email;
              validateForm();
              setFieldValue('email', value, true);
              setAsyncErrors({ ...asyncErrors });
            }}
            value={values.email}
            onBlur={() => setFieldTouched('email')}
            helperText={t('registration.inputs.email')}
            placeholder={t('registration.inputs.emailPlaceholder')}
            variant="text"
            maxLength={50}
            error={errors?.email && touched?.email && (errors.email as string)}
          />
          <TextField
            variant="password"
            setValue={(value) => {
              setFieldValue('password', value, true);
            }}
            value={values.password}
            onBlur={() => setFieldTouched('password')}
            helperText={t('registration.inputs.password')}
            maxLength={50}
            placeholder={t('registration.inputs.passwordPlaceholder')}
            error={errors?.password && touched?.password && (errors.password as string)}
          />
          <TextField
            variant="password"
            setValue={(value) => {
              setFieldValue('_confirm_password', value, true);
            }}
            value={values._confirm_password}
            onBlur={() => setFieldTouched('_confirm_password')}
            helperText={t('registration.inputs.password')}
            maxLength={50}
            placeholder={t('registration.inputs.confirmPasswordPlaceholder')}
            error={errors?._confirm_password && touched?._confirm_password && (errors._confirm_password as string)}
          />
          <Checkbox
            value={values._accept}
            valueChange={(value) => setFieldValue('_accept', value)}
            name="_accept"
            type="small"
            className={styles.RegistrationCheckbox}
            label={
              <div>
                {`${t('registration.checkboxes.accept')} `}
                <Link to={routes.RULES_SERVICE} target="_blank">
                  {t('registration.checkboxes.termsOfService')}
                </Link>
                {`${t('registration.checkboxes.and')} `}
                <Link to={routes.PRIVACY_POLICY} target="_blank">
                  {t('registration.checkboxes.privacyPolicy')}
                </Link>
                {' *'}
              </div>
            }
            onTouch={() => setFieldTouched('_accept')}
            error={errors?._accept && touched?._accept && errors._accept}
          />
          <Checkbox
            value={values.mailing}
            valueChange={(value) => setFieldValue('mailing', value)}
            name="mailing"
            type="small"
            className={styles.RegistrationCheckbox}
            label={<div>{t('registration.checkboxes.newsletter')}</div>}
            onTouch={() => setFieldTouched('mailing')}
            error={errors?.mailing && touched?.mailing && errors.mailing}
          />
          <div className={`${styles.actions} form-group form-actions`}>
            <Button className={styles.button} size="small" type="submit" disabled={!values._accept || !isValid}>
              {t('registration.actions.next')}
            </Button>
            <p className={styles.link_btn}>
              <Link to={`${routes.LOGIN}?${searchParams.toString()}`}>{t('registration.actions.account')}</Link>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};
