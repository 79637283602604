import { UserShort } from './user';
import { OrganizationShort } from './organization';
export enum QuestionsType {
  oneAnswer = 'oneAnswer',
  multiplyAnswers = 'multiplyAnswers',
  textAnswer = 'textAnswer',
  scale = 'scale',
  percent = 'percent',
}

export enum PollsTypes {
  creating = 'creating',
  active = 'active',
  closed = 'closed',
}

export type PollType = {
  id: number;
  name: string;
  description?: string;
  type?: number;
  images?: any[];
  date_start: Date;
  date_end: Date;
  is_active?: boolean;
  published?: boolean;
};

export enum QuestionsTypesBack {
  oneAnswer = 0,
  multiplyAnswers = 1,
  textAnswer = 2,
  scale = 3,
}

export const questionsTypesBackToFront = ['oneAnswer', 'multiplyAnswers', 'textAnswer', 'scale'];

export type PollQuestionType = {
  id?: number;
  question: string;
  settings?: {
    answers?: string[];
    right_answer?: number | number[];
    min_value?: string;
    min_name?: string;
    max_value?: string;
    max_name?: string;
  };
  type?: QuestionsTypesBack;
  image?: { url: string; uuid: string };
  index?: number;
};

export type MultiplyAnswersType = {
  id?: number;
  name: string;
};
export type OneAnswerType = {
  id?: number;
  name: string;
};

export type PollFront = {
  answers_count: number;
  id: number;
  images?: { uuid: string; url: string }[];
  is_closed: boolean;
  description?: string;
  name: string;
  published_at: Date;
  questions_count: number;
  date_end?: Date;
  date_start?: Date;
  organization_id: number;
  organization: OrganizationShort;
  user: UserShort;
};
