import classNames from 'classnames';
import styles from './ProfileModalTabs.module.scss';
import { useHistory } from 'react-router-dom';
import { GlobalContext } from 'contexts/GlobalContext';
import { routes } from 'Routes';
import { useContext } from 'react';
import { useSsrState } from '@issr/core';
import { ProfileModalItem } from '../ProfileModalItem/ProfileModalItem';
import { UserShort } from 'shared/interfaces/user';
import { TextField } from 'shared/components/common/TextField/TextField';
import { useTranslation } from 'react-i18next';

type FriendsTabProps = {
  people: Array<Partial<UserShort>>;
};

export const PeopleTabs = ({ people }: FriendsTabProps) => {
  const { t } = useTranslation('modals');
  const [searchValue, setSearchValue] = useSsrState('');
  const history = useHistory();
  const { closeModal } = useContext(GlobalContext);
  const action = (id: number) => {
    history.push(`${routes.chat}?id=${id}&type=organization`);
    closeModal();
  };
  const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (ev) => {
    if (ev.code === 'Esc') {
      setSearchValue('');
    }
  };
  const peopleToRender = people.filter((val) => {
    return val.public_name.toLowerCase().includes(searchValue.toLowerCase());
  });

  return (
    <div className={classNames(styles.wrapper)}>
      <TextField
        variant="text"
        value={searchValue}
        setValue={setSearchValue}
        helperText={t('controls.search.helperText')}
        placeholder={t('controls.search.placeholder')}
        onKeyDown={onKeyDown}
        maxLength={256}
      />
      {peopleToRender.map((el, index) => {
        return (
          <ProfileModalItem
            isOrganization={false}
            id={el.id}
            image={el.public_avatar}
            name={el.public_name}
            action={() => {
              action(el.id);
            }}
            /* isCollapsible */
            actionTitle={t('icons.chat')}
            iconName="chat"
            key={`organization-${el.id}-${index}`}
          />
        );
      })}
    </div>
  );
};
