import { Dayjs } from 'dayjs';
import styles from './CustomDateTimePicker.module.scss';
import { StaticDateTimePicker } from '@mui/x-date-pickers/StaticDateTimePicker';
import { DateTimeValidationError, PickerChangeHandlerContext } from '@mui/x-date-pickers';

export type CustomDatePickerProps = {
  title: string;
  disablePast?: boolean;
  ampmInClock?: boolean;
  ampm?: boolean;
  reduceAnimations?: boolean;
  disableIgnoringDatePartForTimeValidation?: boolean;
  value?: Dayjs;
  minutesStep?: number;
  minDateTime?: Dayjs;
  onChange?: (value: Dayjs | null, context: PickerChangeHandlerContext<DateTimeValidationError>) => void;
};

export const CustomDateTimePicker = ({
  title,
  onChange,
  value,
  ampm = false,
  ampmInClock = false,
  disableIgnoringDatePartForTimeValidation,
  disablePast,
  reduceAnimations,
  minutesStep = 5,
  minDateTime,
}: CustomDatePickerProps) => {
  return (
    <div className={styles.wrapper}>
      <StaticDateTimePicker
        localeText={{ toolbarTitle: title }}
        minutesStep={minutesStep}
        disablePast={disablePast}
        ampmInClock={ampmInClock}
        ampm={ampm}
        reduceAnimations={reduceAnimations}
        disableIgnoringDatePartForTimeValidation={disableIgnoringDatePartForTimeValidation}
        value={value}
        onChange={onChange}
        minDateTime={minDateTime}
      />
    </div>
  );
};
