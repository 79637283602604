import { CustomImage } from 'shared/components/common/CustomImage/CustomImage';
import styles from './EditAvatar.module.scss';
import { toast } from 'react-toastify';
import { Icon } from 'shared/components/common/Icon/Icon';
import Cropper from 'react-cropper';
import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from 'contexts/GlobalContext';
import { useTranslation } from 'react-i18next';

type EditAvatarProps = {
  src: string;
  width?: number | string;
  height?: number | string;
  isEdit: boolean;
  IsEditImage: boolean;
  setCropper: (val: Cropper) => void;
  setAvatar: (val: string) => void;
  setIsEditImage: (val: boolean) => void;
};

export const EditAvatar = ({
  src,
  height = '167px',
  width = '167px',
  isEdit: isEditMode,
  setAvatar,
  setCropper,
  setIsEditImage,
  IsEditImage,
}: EditAvatarProps) => {
  const { t } = useTranslation('roy-global');
  const [inputCounter, setInputCounter] = useState(0);
  const {
    spinner: { showSpinner, hideSpinner },
  } = useContext(GlobalContext);
  useEffect(() => {
    setInputCounter(inputCounter);
  }, [isEditMode]);
  const onFileChange = async (files: FileList) => {
    showSpinner();
    setInputCounter(inputCounter + 1);
    const fileReader = new FileReader();
    const fileTypes = ['jpg', 'jpeg', 'png', 'webp', 'apng', 'bmp'];

    if (!files?.length) {
      return;
    }

    if (!files[0].type.includes('image') || !fileTypes.some((type) => files[0].type.includes(type))) {
      toast(t('errors.files.format'));
      hideSpinner();
      return;
    }

    fileReader.onloadend = () => {
      setAvatar(fileReader.result as string);
    };
    fileReader.onerror = () => {
      toast(t('errors.files.download'));
    };
    fileReader.onabort = () => {
      toast(t('errors.files.download'));
    };
    hideSpinner();
    fileReader.readAsDataURL(files[0]);
  };

  return (
    <div
      className={styles.wrapper}
      onClick={() => {
        isEditMode && !IsEditImage && setIsEditImage(true);
      }}
    >
      {isEditMode && (
        <label htmlFor={'avatar'}>
          <Icon color="white" iconName="addSquare" />
        </label>
      )}
      {isEditMode ? (
        <Cropper
          key={`avatar-cropper-${inputCounter}`}
          className={styles.cropper}
          src={src}
          cropBoxResizable={false}
          toggleDragModeOnDblclick={false}
          cropBoxMovable={false}
          minCropBoxHeight={167}
          minCropBoxWidth={167}
          dragMode={'move'}
          zoom={() => {
            !IsEditImage && setIsEditImage(true);
          }}
          draggable
          onInitialized={(instance) => {
            setCropper(instance);
          }}
        />
      ) : (
        <CustomImage
          key={`avatar-image-${inputCounter}`}
          className={styles.image}
          src={src}
          alt="user avatar"
          width={width}
          height={height}
        />
      )}
      <input
        key={`input-avatar-${inputCounter}`}
        style={{ display: 'none' }}
        type="file"
        id={'avatar'}
        name={'avatar'}
        accept="image/png, image/gif, image/jpeg, image/jpg"
        onChange={(ev) => {
          setIsEditImage(true);
          onFileChange(ev.target.files);
        }}
      />
    </div>
  );
};
