import { toast } from 'react-toastify';
import styles from './EditableContent.module.scss';
import 'react-quill/dist/quill.bubble.css';
import sanitizeHtml from 'sanitize-html';
import {
  allowedAttributesSchema,
  allowedTagsSynitizer,
  allowedIframeHostnamesSchema,
} from 'shared/constants/allowedTagsSynitizer';
import { FocusEventHandler, KeyboardEventHandler } from 'react';
import classNames from 'classnames';
import { TextField } from '../TextField/TextField';
import { FormError } from '../Validator/FormError/FormError';
import { CustomReactQuill } from '../CustomReactQuill/CustomReactQuill';

type EditTableProps = {
  variant: 'h4' | 'ReactQuill' | 'p';
  value: string;
  isEdit: boolean;
  placeholder: string;
  onChange: (value: string) => void;
  onAccept: KeyboardEventHandler<HTMLInputElement>;
  onCancel: KeyboardEventHandler<HTMLInputElement>;
  isEnterDefault?: boolean;
  className?: string;
  error?: string;
  helperText?: string;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  maxLength?: number;
};

export const EditableContent = ({
  isEdit,
  onChange,
  value,
  variant,
  placeholder,
  onAccept,
  onCancel,
  isEnterDefault = true,
  className,
  error,
  helperText,
  onBlur,
  maxLength,
}: EditTableProps) => {
  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = (ev) => {
    if (ev.code === 'Escape') {
      onCancel(ev);
    }
    if (ev.code === 'Enter' && !isEnterDefault) {
      onAccept(ev);
    }
  };

  switch (isEdit) {
    case true:
      switch (variant) {
        case 'ReactQuill':
          return (
            <div>
              <div className={classNames(styles.wrapper, className)}>
                <CustomReactQuill
                  onChange={onChange}
                  onKeyDown={onKeyDown}
                  value={value}
                  placeholder={placeholder}
                  onBlur={onBlur}
                  helperText={helperText}
                />
              </div>
              <span className={classNames(styles.errorText, { [styles.error]: !!error?.length })}>
                <FormError>{!!error ? error : 'no-error'}</FormError>
              </span>
            </div>
          );
        default:
          return (
            <TextField
              variant="text"
              setValue={onChange}
              error={error}
              helperText={helperText}
              placeholder={placeholder}
              onKeyDown={onKeyDown}
              value={value}
              maxLength={maxLength}
              onBlur={onBlur}
            />
          );
      }
    case false:
      switch (variant) {
        case 'h4':
          return (
            <div className={styles.editableContent}>
              <h4>{value}</h4>
            </div>
          );
        case 'ReactQuill':
          return (
            <div className={classNames(styles.editableContent, className)}>
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(value, {
                    allowedTags: allowedTagsSynitizer,
                    allowedAttributes: allowedAttributesSchema,
                    allowedIframeHostnames: allowedIframeHostnamesSchema,
                  }),
                }}
              />
            </div>
          );
        default:
          return (
            <div className={classNames(styles.editableContent, className)}>
              <p>{value}</p>
            </div>
          );
      }
    default:
      toast('incorrect "isEdit"');
      break;
  }
};
