import { mockImages } from 'shared/constants/MockImages/MockImages';
import { BigCard } from './BigCard/BigCard';
import { MainCard } from './MainCard/MainCard';
import { RowCard } from './RowCard/RowCard';
import { SmallCard } from './SmallCard/SmallCard';

type NewsCardProps = {
  variant: 'main' | 'big' | 'small' | 'row';
  image: string;
  title: string;
  content: string | JSX.Element;
  link?: string;
  organization?: { logo: string; name: string; id: number };
  author?: { logo: string; name: string; id: number };
  publishedAt?: Date;
  commentsCount?: number;
  withFooter: boolean;
  isMobile?: boolean;
  eventExtra?: {
    price?: string;
    place?: string;
    date_start: Date;
    date_end: Date;
  };
};

export const NewsCard = (props: NewsCardProps) => {
  const cardProps: Omit<NewsCardProps, 'variant'> = { ...props, image: props.image || mockImages.news };
  switch (props.variant) {
    case 'big':
      return <BigCard {...cardProps} />;
    case 'row':
      return <RowCard {...cardProps} />;
    case 'small':
      return <SmallCard {...cardProps} />;
    default:
      return <MainCard {...cardProps} />;
  }
};
