import { IOrganisationWithPoint } from 'interfaces/organisation.interface';
import { activitiesLayersTemplateConfig, activityCircleLayerNamesArray, communityClusterLayer, slotsLayer } from './layers';
import { intersectionBy, unionBy, map, find, reduce, cloneDeep, differenceBy } from 'lodash';
import mapboxgl, { MapboxGeoJSONFeature } from 'mapbox-gl';
import CommunityPoint from './assets/community-marker.png';
import ActiveCommunity from './assets/active-community-marker.png';
import ActiveSlotMarker from './assets/active-slot.png';
import SlotMarker from './assets/slot.png';

export const translate = (position, radius, frequency) => {
  return [radius * Math.cos(position * ((2 * Math.PI) / frequency)), radius * Math.sin(position * ((2 * Math.PI) / frequency))];
};

const createOrganisationFeature = ({ id, coords, properties }): MapboxGeoJSONFeature => {
  return {
    type: 'Feature',
    layer: undefined,
    sourceLayer: undefined,
    source: 'organisationsSource',
    id,
    geometry: {
      type: 'Point',
      coordinates: coords,
    },
    state: {},
    properties: {
      organizationId: id,
      pointType: 'organization',
      coordinates: `${coords[0]}~${coords[1]}`,
      ...properties,
    },
  };
};

export const createLineFeature = ({ id, coordinates, properties, state, source }: FeatureProps): MapboxGeoJSONFeature => {
  return {
    id,
    type: 'Feature',
    source: source,
    geometry: {
      type: 'LineString',
      coordinates,
    },
    properties: {
      coordinates,
      ...properties,
    },
    state: { ...state },
    layer: undefined,
    sourceLayer: undefined,
  };
};

export const convertOrganisationToGeoJsonFeature = (organization: IOrganisationWithPoint): MapboxGeoJSONFeature => {
  const countsFields = reduce(
    activitiesLayersTemplateConfig,
    (result, { countFieldName }) => ({
      ...result,
      [countFieldName]: organization[countFieldName] || 0,
    }),
    {}
  );
  return createOrganisationFeature({
    id: organization.id,
    coords: [organization.point.lng, organization.point.lat],
    properties: {
      name: organization.name,
      featureType: organization.type_id,
      ...countsFields,
    },
  });
};

export const flyTo = (map, center?: unknown, zoom?: number, duration?: number) => {
  map?.flyTo({ center, duration: duration || 1300, essential: true });
};

export const fitBounds = (map, coordinates) => {
  if (!coordinates?.[0]) return;
  var bounds = new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]);
  coordinates.forEach(function (coordinate) {
    bounds.extend(coordinate);
  });
  map?.fitBounds(bounds, { padding: 50 });
};

export const focus = (fucusedFeatures, geojsonmap) => {
  const length = fucusedFeatures.length;
  if (!geojsonmap?.isMoving()) {
    switch (true) {
      case length === 1:
        flyTo(geojsonmap, fucusedFeatures[0].geometry.coordinates);
        break;
      case length > 1:
        fitBounds(geojsonmap, map(fucusedFeatures, 'geometry.coordinates'));
        break;
      default:
    }
  }
};

export const zoomCluster = (cluster, source, map) => {
  const clusterId = cluster?.properties.cluster_id;
  clusterId &&
    //@ts-ignore
    map?.getSource(source).getClusterExpansionZoom(clusterId, (err, zoom) => {
      if (err) return;

      map?.easeTo({
        //@ts-ignore
        center: cluster.geometry.coordinates,
        zoom: zoom,
      });
    });
};

export const convertOrganisationsToFeatures = (organizations: IOrganisationWithPoint[]): MapboxGeoJSONFeature[] =>
  organizations?.map((organisation) => convertOrganisationToGeoJsonFeature(organisation));

export const createSelectedFeatureWithFriendlyFeatures = (targetOrganisation, friendsOrgs) => {
  const friendlyOrganisationsWithCoords = friendsOrgs.filter((item) => item?.point);
  const point = targetOrganisation?.properties?.coordinates?.split('~') || [targetOrganisation.point.lng, targetOrganisation.point.lat];
  const targetFeature: IOrganisationWithPoint = { ...targetOrganisation, point: { lng: point[0], lat: point[1] } };
  const friendlyFeatures = convertOrganisationsToFeatures(friendlyOrganisationsWithCoords);
  return { targetFeature, friendlyFeatures };
};

interface FeatureProps {
  id: string;
  coordinates: [[number, number], [number, number]];
  properties?: { [key: string]: any };
  state?: { [key: string]: any };
  source: string;
}

export const createLineFeaturesBetweenCoordAndOtherCoords = (
  point: [number, number],
  otherCoords: [number, number][],
  source: string
): MapboxGeoJSONFeature[] => {
  return otherCoords
    ? otherCoords.map((coord, index) =>
        createLineFeature({
          id: index.toString(),
          coordinates: [point, coord],
          source,
        })
      )
    : [];
};

export const intersectionByWithCluster = async (features: mapboxgl.MapboxGeoJSONFeature[], cluster, comparator, map) => {
  const getResultOfIntersection = () => {
    return new Promise((resolve, reject) => {
      map?.getSource(features?.[0].source).getClusterLeaves(cluster.id, Infinity, 0, (err, leaves) => {
        if (err) throw err;
        const intersectedFeatures = intersectionBy(features, leaves, `${comparator}`);
        const isSomeFeaturesBelongs = !!intersectedFeatures?.length;

        resolve([isSomeFeaturesBelongs ? cluster : null, intersectedFeatures]);
      });
    });
  };
  return await getResultOfIntersection();
};

export const intersectionByWithClusters = async (
  features: MapboxGeoJSONFeature[],
  clusters: MapboxGeoJSONFeature[],
  comparator: string,
  map
) => {
  let allIntersectedFeatures = [];
  const intersectedWithFeaturesClusters = await Promise.all(
    clusters?.map(async (cluster) => {
      const [clusterThatContainsSomeFeatures, intersectedFeaturesInOneCluster]: any = await intersectionByWithCluster(
        features,
        cluster,
        comparator,
        map
      );

      allIntersectedFeatures = unionBy(allIntersectedFeatures, intersectedFeaturesInOneCluster, `${comparator}`);
      return clusterThatContainsSomeFeatures;
    })
  );

  return [intersectedWithFeaturesClusters.filter((item) => item), allIntersectedFeatures];
};

export const createSlotsAround = async (cluster, featuresSource, slotsSource, map) => {
  const clusterId = cluster?.properties.cluster_id;
  const clusterCoords = cluster?.geometry.coordinates;
  const getResult = () => {
    return new Promise((resolve) => {
      clusterId &&
        map?.getSource(featuresSource).getClusterLeaves(clusterId, Infinity, 0, function (err, l) {
          if (err) throw err;
          const leaves = l ?? [];
          const slots = [];
          for (let i = 0; i < leaves.length; i++) {
            const slot = cloneDeep(leaves[i]);
            slot.geometry.coordinates = [clusterCoords?.[0], clusterCoords?.[1]];
            slot.source = featuresSource;
            slot.properties.translate =
              (i < 6 && translate(i, 26, 6)) ||
              (i >= 6 && i < 12 && translate(i + 0.5, 46, 6)) ||
              (i >= 12 && i < 18 && translate(i, 54, 6));

            slot.properties.isSlot = true;
            slots.push(slot);
          }
          resolve(slots);
        });
    });
  };
  return await getResult();
};

export const setSelectedFeatureOnMap = (selectedFeature, newlySelectedOrganisationFeature: MapboxGeoJSONFeature, map) => {
  const prevSelectedFeature = selectedFeature;
  prevSelectedFeature && map?.setFeatureState(prevSelectedFeature, { selected: false });

  if (newlySelectedOrganisationFeature) {
    map?.setFeatureState(newlySelectedOrganisationFeature, { selected: true });
  }
};

//create lines between selected organisation and its friends with clusters
export const createConnectionLines = async (selectedFeature, otherFeatures, map) => {
  if (!selectedFeature || !otherFeatures?.length) return [];

  const communityClusterRenderedFeatures = map?.queryRenderedFeatures({ layers: [communityClusterLayer.id] });
  const [clusterRenderedFeatures, otherRenderedFeaturesIntersectedWIthClusters] = await intersectionByWithClusters(
    otherFeatures,
    communityClusterRenderedFeatures,
    'id',
    map
  );
  const otherRenderedFeaturesNotClustered = differenceBy(otherFeatures, otherRenderedFeaturesIntersectedWIthClusters, 'id');
  const otherRenderedFeaturesNotClusteredCoords = otherRenderedFeaturesNotClustered?.map(
    (feature: any): [number, number] => feature.geometry.coordinates
  );
  const clusterRenderedFeaturesCoords = clusterRenderedFeatures?.map((feature: any): [number, number] => feature.geometry.coordinates);
  const selectedFeatureCoords = selectedFeature?.geometry.coordinates;
  const lines = createLineFeaturesBetweenCoordAndOtherCoords(
    selectedFeatureCoords,
    [...otherRenderedFeaturesNotClusteredCoords, ...clusterRenderedFeaturesCoords],
    'linesSource'
  );
  return lines;
};

export const getActivityFeaturesAndActivityNameFromCoords = (coords, map) => {
  const { features: activityFeatures, layerName } = activityCircleLayerNamesArray?.reduce(
    (acc, layerName) => {
      const activityFeatures = map?.queryRenderedFeatures(coords, { layers: [layerName] });
      return activityFeatures?.length ? { features: activityFeatures, layerName: layerName } : acc;
    },
    { features: null, layerName: '' }
  );
  const activityName = find(activitiesLayersTemplateConfig, ({ circleId }) => circleId === layerName)?.activityName;
  return { activityFeatures, activityName };
};

export const getSlotFeatureFromCoords = (coords, map) => {
  const clickedSlotFeature = map?.queryRenderedFeatures(coords, { layers: [slotsLayer.id] })?.[0];
  const renderedSlotsFeatures = map?.queryRenderedFeatures(undefined, { layers: [slotsLayer.id] });
  if (clickedSlotFeature) {
    renderedSlotsFeatures?.forEach((slotFeature) => {
      map?.setFeatureState(slotFeature, { selected: false });
    });
    map?.setFeatureState(clickedSlotFeature, { selected: true });
  }
  return clickedSlotFeature;
};

export const onLoadHandler = (setMap) => (e: any) => {
  setMap(e.target);
  const map = e.target;
  map?.loadImage(CommunityPoint, (error, image) => {
    error && console.warn(error);
    map?.addImage('community-pic', image, { pixelRatio: 4.5 });
  });
  map?.loadImage(ActiveCommunity, (error, image) => {
    error && console.warn(error);
    map?.addImage('active-community-pic', image, { pixelRatio: 4.8 });
  });
  map?.loadImage(SlotMarker, (error, image) => {
    error && console.warn(error);
    map?.addImage('slot-pic', image, { pixelRatio: 4.8 });
  });
  map?.loadImage(ActiveSlotMarker, (error, image) => {
    error && console.warn(error);
    map?.addImage('active-slot-pic', image, { pixelRatio: 4.8 });
  });
};
