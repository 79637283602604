import { Formik } from 'formik';
import * as Yup from 'yup';
import { GlobalContext } from 'contexts/GlobalContext';
import { useContext, useState } from 'react';
import { GlobalDataContext } from 'contexts/GlobalDataContext';
import { toast } from 'react-toastify';
import formatServerError from 'shared/utils/formatServerError';
import { routes } from 'Routes';
import { useHistory, useLocation } from 'react-router';
import { Button } from 'shared/components/common/Button/Button';
import { TextField } from 'shared/components/common/TextField/TextField';
import { useTranslation } from 'react-i18next';
import { TwoFAProps } from '../Login';

type TwoFAComponentProps = {
  twoFaStep: TwoFAProps;
  setTwoFaStep: React.Dispatch<React.SetStateAction<TwoFAProps>>;
};

export const TwoFA = ({ twoFaStep }: TwoFAComponentProps) => {
  const { t } = useTranslation('settings');
  const {
    services: { authService },
    spinner: { showSpinner, hideSpinner },
  } = useContext(GlobalContext);

  const validationSchema = Yup.object().shape({
    code: Yup.string().required(t('errors.required')),
  });

  const { auth } = useContext(GlobalDataContext);
  const [asyncErrors, setAsyncErrors] = useState<{ errors?: string[] }>();
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  async function onSubmit(values) {
    showSpinner();
    const { code } = values;

    const result = await authService
      .login({
        email: twoFaStep.login,
        password: twoFaStep.password,
        twoFA: twoFaStep.isNeeded
          ? {
              method: 'totp',
              password: code,
            }
          : undefined,
      })
      .finally(() => {
        hideSpinner();
      });

    if (result.token) {
      try {
        await auth.authenticate();
        // Check inviteParams to open invitation page after first login
        if (!!localStorage.getItem('firstSession')) {
          window.dataLayer.push({
            event: 'event',
            eventProps: {
              category: 'account',
              action: 'login_first_time',
            },
          });
        }
        if (authService.inviteParams) {
          history.push(routes.INVITATION_SCREEN);
        } else {
          history.push(searchParams.get('redirect') ?? routes.PROFILE);
        }
      } catch (error) {
        // think what to do, maybe toast not needed
        toast(formatServerError(error));
        hideSpinner();
      }
    }
    hideSpinner();
  }
  return (
    <>
      <Formik
        initialValues={{ code: '' }}
        validationSchema={validationSchema}
        validate={() => {
          return asyncErrors;
        }}
        onSubmit={onSubmit}
      >
        {({ errors, touched, values, handleSubmit, setFieldValue, setFieldTouched }) => (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <TextField
              setValue={(value) => {
                setAsyncErrors({});
                setFieldValue('code', value);
              }}
              value={values.code}
              onBlur={() => setFieldTouched('code')}
              helperText={t('password.input.code.helperText')}
              placeholder={t('password.input.code.placeholder')}
              variant="number"
              maxLength={8}
              error={errors?.code && touched?.code && (errors.code as string)}
            />
            <Button type="submit">{t('password.input.code.submit')}</Button>
          </form>
        )}
      </Formik>
    </>
  );
};
