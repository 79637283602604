import styles from './FirstSession.module.scss';
import { GlobalDataContext } from 'contexts/GlobalDataContext';
import { useContext, useState } from 'react';
import { Checkbox } from 'shared/components/common/Checkbox/Checkbox';
import { Icon } from 'shared/components/common/Icon/Icon';
import { userService } from 'services';
import { toast } from 'react-toastify';
import formatServerError from 'utils/formatServerError';
import { GlobalContext } from 'contexts/GlobalContext';
import { Button } from 'shared/components/common/Button/Button';
import { useTranslation } from 'react-i18next';

type FirstSessionProps = {
  closeModal: () => void;
};

export const FirstSession = ({ closeModal }: FirstSessionProps) => {
  const { t } = useTranslation('modals');
  const { user, setUser } = useContext(GlobalDataContext);
  const {
    spinner: { showSpinner, hideSpinner },
  } = useContext(GlobalContext);

  const [isPoliticalLoading, setIsPoliticalLoading] = useState<boolean>(user?.restrict?.political || false);
  const [isSensitiveLoading, setIsSensitiveLoading] = useState<boolean>(user?.restrict?.sensitive || false);

  const onUpdate = async () => {
    try {
      showSpinner();
      const userToUpdate = { restrict: { sensitive: isSensitiveLoading, political: isPoliticalLoading } };
      if (!!user.restrict.political !== isPoliticalLoading) {
        toast(t('toast.political'));
      }
      if (!!user.restrict.sensitive !== isSensitiveLoading) {
        toast(t('toast.sensitive'));
      }
      await userService.updateUser(userToUpdate);
      setUser({ ...user, ...userToUpdate });
    } catch (e) {
      toast.error(formatServerError(e));
    } finally {
      hideSpinner();
      closeModal();
    }
  };
  return (
    <div className={styles.wrapper}>
      <h4>{t('firstSession.title')}</h4>
      <div className={styles.info}>
        <Icon className={styles.green} iconName="infoCircle" />
        <div className={styles.section}>
          <p className={styles.green}>{t('firstSession.info')}</p>
          <Checkbox
            className={styles.checkBox}
            value={isPoliticalLoading}
            valueChange={() => setIsPoliticalLoading(!isPoliticalLoading)}
            type="small"
            label={t('controls.political')}
          />
          <Checkbox
            className={styles.checkBox}
            value={isSensitiveLoading}
            valueChange={() => setIsSensitiveLoading(!isSensitiveLoading)}
            type="small"
            label={t('controls.sensitive')}
          />
        </div>
      </div>

      <Button className={styles.button} color="light" size="small" type="button" onClick={onUpdate}>
        {t('buttons.confirm')}
      </Button>
    </div>
  );
};
