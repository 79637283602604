import classNames from 'classnames';
import { FocusEventHandler, RefObject, useEffect, useRef } from 'react';
import type ReactQuill from 'react-quill';
import styles from './ContentQuillEditor.module.scss';
import { CustomEmojiPicker } from 'shared/components/common/CustomEmojiPicker/CustomEmojiPicker';
import { useQuillEditor } from './hooks/quillEditor';
import { SideToolbar } from './SideToolbar/SideToolbar';

type Props = {
  initialValue: string;
  setValue: (val: string) => void;
  placeholder?: string;
  className?: string;
  quillClassName?: string;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  theme?: 'snow' | 'bubble';
  focused?: boolean;
  scrollingRef?: RefObject<HTMLDivElement>;
  uploadImage: (image: string | ArrayBuffer | Array<string | ArrayBuffer>) => Promise<{ url: string }[]>;
  showSpinner: () => void;
  hideSpinner: () => void;
};

let ReactQuillCustom: typeof ReactQuill;
export const ContentQuillEditor = ({
  placeholder,
  initialValue,
  className,
  onBlur,
  quillClassName,
  theme = 'bubble',
  setValue,
  focused = false,
  scrollingRef,
  hideSpinner,
  showSpinner,
  uploadImage,
}: Props) => {
  const reactQuillRef = useRef<ReactQuill>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const {
    addEmojiHandler,
    onDrop,
    onChange,
    sideBarTop,
    value,
    onChangeSelection,
    showSideToolbar,
    onKeyDown,
    setShowEmoji,
    showEmoji,
    onFocus,
  } = useQuillEditor({
    reactQuillRef,
    value: initialValue,
    setValue,
    wrapperRef,
    isActive: focused,
    scrollingRef,
    hideSpinner,
    showSpinner,
    uploadImage,
  });

  useEffect(() => {
    // not working with SSR
    // https://github.com/zenoamaro/react-quill/issues/389
    ReactQuillCustom = require('react-quill');
    require('./hooks/helpers/block');
  }, []);

  const MODULES = {
    toolbar: [['bold', 'italic', 'link', { header: [2, 3, false] }, 'blockquote', { list: 'ordered' }, { list: 'bullet' }]],
  };
  const FORMATS = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video',
    'embedVideo',
  ];

  return (
    <div className={classNames(styles.wrapper, className)} ref={wrapperRef}>
      {showSideToolbar && <SideToolbar top={sideBarTop} onAddImage={onDrop} setShowEmoji={setShowEmoji} />}
      <CustomEmojiPicker
        showEmoji={showEmoji}
        onSelect={addEmojiHandler}
        setShowEmoji={setShowEmoji}
        position={{
          top: sideBarTop > 435 ? sideBarTop - 12 - 435 : sideBarTop + 24,
        }}
      />
      {ReactQuillCustom && (
        <ReactQuillCustom
          theme={theme}
          className={classNames(styles.quill, quillClassName)}
          onFocus={onFocus}
          onChange={onChange}
          onChangeSelection={onChangeSelection}
          onKeyDown={onKeyDown}
          value={value}
          placeholder={placeholder}
          modules={MODULES}
          formats={FORMATS}
          preserveWhitespace
          onBlur={() => onBlur}
          ref={reactQuillRef}
        />
      )}
    </div>
  );
};
