import React, { FunctionComponent } from 'react';
import { RegovWidgetProps } from '../types';
import { Suspensed } from 'components/Suspense/Suspense';

const SelfLogin: FunctionComponent<RegovWidgetProps> = React.lazy(() => import('../Login/SelfLogin'));

export const openSSILoginBuilder = (props: RegovWidgetProps) => () => {
  //todo i18
  props.openModal({
    params: {
      mainContainer: (
        <Suspensed fallback={<div>Loading...</div>}>
          <SelfLogin {...props} />
        </Suspensed>
      ),
    },
  });
};
