import styles from './CustomImage.module.scss';
import { Icon } from 'shared/components/common/Icon/Icon';

type IImageProps = {
  src?: string | ArrayBuffer;
  alt: string;
  rounded?: boolean;
  width?: number | string;
  height?: number | string;
  background?: 'white' | 'gray';
  className?: any;
  isOrganization?: boolean;
};

export function CustomImage({
  src = '',
  alt,
  width = 160,
  height = 160,
  rounded = true,
  className,
  isOrganization = false,
}: IImageProps) {
  return (
    <div className={`${styles.CustomImage} ${className}`} style={{ width, height }}>
      {src ? (
        <img className={styles.rounded} src={src as string} alt={alt} />
      ) : (
        <Icon iconName={isOrganization ? 'unions' : 'user'} />
      )}
    </div>
  );
}
