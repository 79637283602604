import { Link } from 'react-router-dom';
import { routes } from 'Routes';
import { Icon } from 'shared/components/common/Icon/Icon';
import { Button } from 'shared/components/common/Button/Button';
import styles from './CreateUnionSuccess.module.scss';
import { useTranslation } from 'react-i18next';

export function CreateUnionSuccess() {
  const { t } = useTranslation('createUnion');
  const { REACT_APP_ADMIN_URL } = process.env;
  const token = localStorage.getItem('token');
  return (
    <>
      <h1>{t('success.title')}</h1>
      <div className={styles.CreateUnionSuccessInfo}>
        <Icon iconName="logoSmall" />
        <p data-cy="description-created-union-success" className={styles.CreateUnionSuccessText}>
          {t('success.about')}
        </p>
      </div>

      <div className={styles.CreateUnionSuccessButtons}>
        <Link to={routes.PROFILE} data-cy="go-back-the-page">
          {t('success.return')}
        </Link>
        <Button to={`${REACT_APP_ADMIN_URL}/signin?token=${token}`} icon="securitySafe" data-cy="admin-btn">
          {t('success.admin')}
        </Button>
      </div>
    </>
  );
}
