import { CustomDateTimePicker } from './CustomDateTimePicker/CustomDateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import styles from './DatePickerModal.module.scss';
import { useTranslation } from 'react-i18next';
import { Button } from 'shared/components/common/Button/Button';
import { useState } from 'react';

type Props = {
  date_start: Date;
  date_end?: Date;
  setDateStart: (val: Date) => void | Promise<void>;
  setDateEnd: (val: Date) => void | Promise<void>;
  closeModal: () => void;
};

export const DatePickerModal = ({ date_start, setDateEnd, setDateStart, date_end, closeModal }: Props) => {
  const { t } = useTranslation('editor');
  const [start, setStart] = useState(dayjs(date_start));
  const [end, setEnd] = useState(dayjs(date_end));

  const acceptHandler = () => {
    const dateStart = start.toDate();
    const dateEnd = end.toDate();
    dateStart && setDateStart(dateStart);
    dateEnd && setDateEnd(dateEnd);
    closeModal();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className={styles.wrapper}>
        <CustomDateTimePicker
          title={t('dateStart')}
          minutesStep={5}
          disablePast
          ampmInClock={false}
          ampm={false}
          value={start}
          onChange={setStart}
        />
        <CustomDateTimePicker
          title={t('dateEnd')}
          minutesStep={5}
          minDateTime={start}
          ampmInClock={false}
          ampm={false}
          value={end}
          onChange={setEnd}
        />
      </div>
      <Button size="small" className={styles.button} onClick={acceptHandler}>
        {t('save')}
      </Button>
    </LocalizationProvider>
  );
};
