import { SVGProps, Ref, forwardRef, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="M19.1506 2.21868C18.2119 1.92214 17.2135 1.76001 16.1767 1.76001C13.1516 1.76001 10.4444 3.12748 8.63965 5.27574L13.2903 9.18828L19.1506 2.21868Z"
      fill="#1A73E8"
    />
    <path
      d="M8.63816 5.27573C7.2003 6.98666 6.33203 9.19466 6.33203 11.6032C6.33203 13.4549 6.7011 14.9547 7.30696 16.2987L13.2888 9.18826L8.63816 5.27573Z"
      fill="#EA4335"
    />
    <path
      d="M16.1776 7.83999C18.2576 7.83999 19.9429 9.52533 19.9429 11.6053C19.9429 12.5312 19.608 13.3803 19.0533 14.0352C19.0533 14.0352 22.0272 10.4981 24.9136 7.06773C23.7211 4.77439 21.6539 3.01013 19.1536 2.21866L13.2891 9.18826C13.9803 8.36266 15.0171 7.83999 16.1776 7.83999Z"
      fill="#4285F4"
    />
    <path
      d="M16.1761 15.3686C14.0961 15.3686 12.4107 13.6832 12.4107 11.6032C12.4107 10.6838 12.7414 9.84109 13.2875 9.18616L7.30566 16.2987C8.32753 18.5664 10.0278 20.3862 11.7793 22.6795L19.0497 14.0352C18.3606 14.8502 17.3281 15.3686 16.1761 15.3686Z"
      fill="#FBBC04"
    />
    <path
      d="M18.9076 25.039C22.1908 19.904 26.0201 17.5723 26.0201 11.6032C26.0201 9.96695 25.619 8.42455 24.9129 7.06775L11.7822 22.6795C12.339 23.4091 12.9001 24.1856 13.4484 25.0411C15.4452 28.1259 14.8905 29.9755 16.179 29.9755C17.4633 29.9755 16.9108 28.1238 18.9076 25.039Z"
      fill="#34A853"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
const Memo = memo(ForwardRef);
export { Memo as GoogleMapsIcon };
