import { ContentEditorSkeleton } from 'components/ContentEditor/ContentEditorSkeleton';
import PublicationMaterialsLinkCreationSettings from './PublicationMaterialsLinkCreationSettings/PublicationMaterialsLinkCreation';
import { useMaterialsLinkCreation } from './hooks/useMaterialsLinkCreation';

export const MaterialsLinkCreation = () => {
  const { onPublish, onSave, uploadImage, initialValues } = useMaterialsLinkCreation();

  return (
    <ContentEditorSkeleton
      ContentSettings={PublicationMaterialsLinkCreationSettings}
      onlySettings
      uploadImage={uploadImage}
      onPublish={onPublish}
      onSave={onSave}
      initialValues={initialValues}
    />
  );
};
