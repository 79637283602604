import { routes } from 'Routes';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { IContentPreview } from 'shared/interfaces/feed';
import { GlobalContext } from 'contexts/GlobalContext';
import InfiniteScrollList from 'components/InfiniteScrollList/InfiniteScrollList';
import ActivityCard from 'components/ActivityCard/ActivityCard';
import { CONTENT_TYPE } from 'interfaces/streamContent';

import styles from './ActivityGroup.module.scss';
import { OrganizationShort } from 'shared/interfaces/organization';
import { CustomImage } from 'shared/components/common/CustomImage/CustomImage';
import { Link } from 'react-router-dom';

interface Props {
  loadData: () => void;
  hasMoreData?: boolean;
  activities: Omit<IContentPreview, 'images'>[];
  title?: string;
  type: 'news' | 'events' | 'material';
  translateType?: string;
  organization?: OrganizationShort;
}

const ActivityGroup = ({ hasMoreData, loadData, activities, title, type, translateType, organization }: Props) => {
  const { t } = useTranslation(translateType || type);
  const {
    screen: { innerWidth },
  } = useContext(GlobalContext);

  const getUrl = (type: CONTENT_TYPE, orgId: number, id: number): string => {
    switch (type) {
      case 'material':
        return routes.materialDetails.getLink(orgId, id);
      case 'news':
        return routes.newsDetails.getLink(orgId, id);
      case 'quiz':
        return routes.poll.getLink(orgId, id);
      case 'task':
        return routes.task.getLink(orgId, id);
      case 'event':
        return routes.eventsDetails.getLink(orgId, id);
    }
  };

  const numberOfColumns = innerWidth > 1256 ? 3 : innerWidth > 756 ? 2 : 1;

  const getWidthColumn = (colIndex) => {
    if (numberOfColumns === 3) {
      return colIndex % 2 ? 'calc(44% - 20px)' : 'calc(28% - 10px)';
    }
    if (numberOfColumns === 2) {
      return 'calc(50% - 10px)';
    }

    return '100%';
  };

  return (
    <div className={styles.container}>
      <div className={styles.titleWrapper}>
        {organization && (
          <>
            <Link to={routes.union.getLink(organization.id)} className={styles.organizationLink}>
              <CustomImage className={styles.organization} width={45} height={45} alt={organization.name} src={organization.avatar} />
            </Link>
            <h2>{organization.short_name || organization.name}</h2>
          </>
        )}
      </div>
      {title && <h2 className={styles.title}>{title}</h2>}
      <div className={styles.content}>
        {
          <InfiniteScrollList
            dataLength={activities?.length}
            next={() => loadData()}
            hasMore={hasMoreData}
            listContent={
              <div className={styles.columns}>
                {[...Array(numberOfColumns)].map((_, colIndex) => {
                  return (
                    <div
                      style={{
                        width: getWidthColumn(colIndex),
                      }}
                      key={colIndex}
                      className={styles.column}
                    >
                      {activities
                        .filter((_, activityIndex) => activityIndex % numberOfColumns === colIndex)
                        .map((activityPreview) => (
                          <ActivityCard
                            key={activityPreview.id}
                            activityInfo={{
                              id: activityPreview.id,
                              title: activityPreview.title,
                              description: activityPreview.excerpt,
                              publishedData: activityPreview.published_at,
                              commentsCount: activityPreview.comments_count || 0,
                            }}
                            eventsAdditionalInfo={
                              activityPreview.date_start
                                ? {
                                    date_end: activityPreview.date_end,
                                    date_start: activityPreview.date_start,
                                    place: activityPreview.place,
                                    price: activityPreview.price,
                                    point: activityPreview.point,
                                    needRoyPass: activityPreview.needRoyPass,
                                  }
                                : undefined
                            }
                            image={activityPreview.image || activityPreview.preview}
                            externalLink={!!activityPreview.link}
                            organization={{
                              id: activityPreview.organization.id,
                              logo: activityPreview.organization.avatar,
                              name: activityPreview.organization.name,
                            }}
                            link={activityPreview.link || getUrl(activityPreview.type, activityPreview.organization.id, activityPreview.id)}
                          />
                        ))}
                    </div>
                  );
                })}
              </div>
            }
            // endMessage={t('actions.InfiniteScrollList.endMessage')}
            dontFindData={t('actions.InfiniteScrollList.dontFindData')}
          />
        }
      </div>
    </div>
  );
};

export default ActivityGroup;
