import classNames from 'classnames';
import { FocusEventHandler, KeyboardEventHandler, useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';
import { FormError } from '../Validator/FormError/FormError';
import styles from './CustomReactQuill.module.scss';
import { CustomToolbar } from './CustomToolbar/CustomToolbar';
import { useTranslation } from 'react-i18next';

type CustomReactQuillProps = {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  className?: string;
  quillClassName?: string;
  tollBarClassName?: string;
  error?: string;
  helperText?: string;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  customButton?: JSX.Element;
  theme?: 'snow' | 'bubble';
};
let ReactQuillCustom: typeof ReactQuill;
export const CustomReactQuill = ({
  onChange,
  placeholder,
  value,
  className,
  error,
  helperText,
  onBlur,
  onKeyDown,
  customButton,
  quillClassName,
  tollBarClassName,
  theme = 'snow',
}: CustomReactQuillProps) => {
  const { i18n } = useTranslation();
  const reactQuillRef = useRef<ReactQuill>(null);
  useEffect(() => {
    // not working with SSR
    // https://github.com/zenoamaro/react-quill/issues/389
    ReactQuillCustom = require('react-quill');
    console.log(value);
  }, []);
  const modules = {
    toolbar: {
      container: '#toolbar',
    },
  };
  return (
    <>
      {ReactQuillCustom && (
        <>
          <div className={classNames(styles.wrapper, className)}>
            <CustomToolbar
              className={tollBarClassName}
              customButton={customButton}
              helperText={helperText}
              quillRef={reactQuillRef}
            />
            <ReactQuillCustom
              theme={theme}
              className={classNames(styles.quill, quillClassName)}
              onChange={onChange}
              onKeyDown={onKeyDown}
              value={value}
              placeholder={placeholder}
              modules={modules}
              onBlur={() => onBlur}
              ref={reactQuillRef}
              key={i18n.language}
            />
          </div>
          {error && (
            <span className={classNames(styles.errorText, { [styles.error]: !!error?.length })}>
              <FormError>{!!error ? error : 'no-error'}</FormError>
            </span>
          )}
        </>
      )}
    </>
  );
};
