import { ICommentReactions } from 'shared/interfaces/comments';
import { api } from './helper';

type GeneralProps = {
  essenceName: string;
  essenceId: string;
  isAdminApp?: boolean;
};

type PropsGetComments = GeneralProps & {
  limit?: number;
  parent_id?: number;
  after_id?: number;
};

type PropsCreateComment = GeneralProps & {
  parent_id?: number;
  comment: string;
};

type PropsUpdateComment = GeneralProps & {
  commentId: number;
  comment: string;
};

type PropsDeleteComment = GeneralProps & {
  commentId: number;
};

type PropsAddReactionComment = GeneralProps & {
  commentId: number;
  reaction: ICommentReactions;
};

const generalPath = (isAdminApp, essenceName, essenceId) => {
  return `${!!isAdminApp ? '/admin_org' : ''}/${essenceName}/${essenceId}/comments`;
};

export async function getComments({
  essenceName,
  essenceId,
  isAdminApp,
  limit = 10,
  parent_id,
  after_id,
}: PropsGetComments) {
  return api().get(`${generalPath(isAdminApp, essenceName, essenceId)}`, {
    params: { limit, parent_id, after_id },
  });
}

export async function createComment({ essenceName, essenceId, isAdminApp, parent_id, comment }: PropsCreateComment) {
  return api().post(`${generalPath(isAdminApp, essenceName, essenceId)}`, {
    comment,
    parent_id,
  });
}

export async function updateComment({ essenceName, essenceId, isAdminApp, comment, commentId }: PropsUpdateComment) {
  return api().put(`${generalPath(isAdminApp, essenceName, essenceId)}/${commentId}`, {
    comment,
  });
}

export async function deleteComment({ essenceName, essenceId, isAdminApp, commentId }: PropsDeleteComment) {
  return api().delete(`${generalPath(isAdminApp, essenceName, essenceId)}/${commentId}`);
}

export async function updateReactionComment({
  essenceName,
  essenceId,
  isAdminApp,
  commentId,
  reaction,
}: PropsAddReactionComment) {
  return api().post(`${generalPath(isAdminApp, essenceName, essenceId)}/${commentId}/reaction`, {
    reaction: reaction,
  });
}
