import { useTranslation } from 'react-i18next';
import styles from './MotivationalQuote.module.scss';

export const MotivationalQuote = () => {
  const { t } = useTranslation('home');
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.text_container}>
          <p>{t('motivationalQuote.description')}</p>
        </div>
        <img src={'/logo-small.svg'} alt={'Roy Team'} width={240} height={240} />
      </div>
    </div>
  );
};
