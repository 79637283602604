import { ru, be, enGB, lt, uk, pl } from 'date-fns/locale';
import { formatDistanceToNow, format, formatDistance } from 'date-fns';

export const fnsLocales: Record<string, Locale> = {
  ru,
  be,
  en: enGB,
  lt,
  uk,
  pl,
};

export const formatDistanceToNowWithLocale = (
  date: number | Date,
  language: string = 'en',
  options?: {
    includeSeconds?: boolean | undefined;
    addSuffix?: boolean | undefined;
  }
) => {
  const locale = fnsLocales[language];
  return formatDistanceToNow(date, { ...options, locale });
};
export const formatDistanceWithLocale = (
  date: number | Date,
  baseDate: number | Date,
  language: string = 'en',
  options?: {
    includeSeconds?: boolean | undefined;
    addSuffix?: boolean | undefined;
  }
) => {
  const locale = fnsLocales[language];
  return formatDistance(date, baseDate, { ...options, locale });
};

export const formatWithLocale = (
  date: number | Date,
  language: string = 'en',
  dateFormat: string = 'dd/MM HH:mm',
  options?: {
    locale?: Locale | undefined;
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | undefined;
    firstWeekContainsDate?: number | undefined;
    useAdditionalWeekYearTokens?: boolean | undefined;
    useAdditionalDayOfYearTokens?: boolean | undefined;
  }
) => {
  const locale = fnsLocales[language];
  return format(date, dateFormat, { ...options, locale });
};

export const formatDateForGoogle = (date: Date): string | null => {
  const newDate = new Date(date).toISOString();
  if (newDate) {
    return newDate.replaceAll(':', '').replaceAll('-', '').replaceAll('.', '');
  } else {
    return null;
  }
};
