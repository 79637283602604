import { OrganizationShort } from 'shared/interfaces/organization';
import { UserId } from './user';

type visibleType = 0 | 1 | 2 | 3;
//0 - видно всем, 1 - видно пользователям сервиса, 2 - видно участникам организации, 3 - видно только в админке; по умолчанию 0

export const enum KbaseMaterialTypes {
  text = 'text',
  link = 'link',
}

export type KbaseSectionLight = {
  id: number;
  name: string;
  description?: string;
  cover?: string;
};

export type KbaseSection = KbaseSectionLight & {
  materials_count: number;
  created_at: Date;
  updated_at: Date;
  organization_id: number;
};

type KbaseMaterial = {
  id: number;
  title: string;
  content?: string;
  image?: string;
  excerpt?: string;
  tags?: string[];
  type?: KbaseMaterialTypes;
  comments_count?: number;
};

export type KbaseMaterialAdmin = KbaseMaterial & {
  visible: visibleType | number;
  published?: boolean;
  created_at?: Date;
  updated_at?: Date;
  index?: number;
  section_id?: number;
  published_at?: string;
};

export type KbaseMaterialFront = KbaseMaterial & {
  comments: boolean;
  organization: OrganizationShort;
  section: KbaseSection;
  link?: string;
  published_at: Date;
  user: UserId;
  excerpt: string;
};
