import { IProfile } from 'interfaces/profile.interface';
import { toast } from 'react-toastify';
import formatServerError from 'shared/utils/formatServerError';
import { Api, HttpService } from './http.service';

export class ProfileService {
  get isLoggedIn(): boolean {
    return !!this.httpService.token;
  }

  private readonly userSettingsKey = 'userSettings';

  constructor(private httpService: HttpService) {}
  async getProfile(userId?: string) {
    try {
      const { data } = await this.httpService.axios.get<{ data: IProfile }>(`${Api.PROFILE}/${userId ? userId : ''}`);
      return data.data;
    } catch (e) {
      toast.error(formatServerError(e));
    }
  }

  async subs(userId: string) {
    try {
      const { data } = await this.httpService.axios.post<{ data: IProfile }>(`${Api.PROFILE}/subscribe?id=${userId}`);
      return data.data;
    } catch (e) {
      toast.error(formatServerError(e));
    }
  }
  async unsubs(userId: string) {
    try {
      const { data } = await this.httpService.axios.post<{ data: IProfile }>(`${Api.PROFILE}/unsubscribe?id=${userId}`);
      return data.data;
    } catch (e) {
      toast.error(formatServerError(e));
    }
  }
}
