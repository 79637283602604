import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from 'contexts/GlobalContext';
import { ISuggestion } from 'interfaces/suggestion.interface';
import { toast } from 'react-toastify';
import formatServerError from 'shared/utils/formatServerError';
import { useParams } from 'react-router-dom';
import { routes } from 'Routes';
import ContentLayout from 'containers/ContentLayout/ContentLayout';
import { RoutingContainer } from 'common/Routing/RoutingContainer/RoutingContainer';
import { SuggestionContent } from './SuggestionContent';
import SEO from 'shared/utils/Seo';

export function Suggestion() {
  const {
    services: { suggestionsService },
  } = useContext(GlobalContext);

  const { organizationId, suggestionId } = useParams<{ organizationId: string; suggestionId: string }>();
  const [suggestion, setSuggestion] = useState<ISuggestion>();

  useEffect(() => {
    (async () => {
      try {
        const suggestion = await suggestionsService.getSuggestion(+organizationId, +suggestionId);
        setSuggestion(suggestion);
      } catch (e) {
        toast.error(formatServerError(e));
      }
    })();
  }, [organizationId, suggestionId]);

  if (!suggestion || !suggestion.created_at) {
    return null;
  }

  return (
    <>
      <SEO image={suggestion.images[0]?.url || '/logo-small.svg'} title={suggestion.title} description={suggestion.description} />
      <RoutingContainer title={suggestion.organization.short_name || suggestion.organization.name} showCloseButton>
        <ContentLayout
          logo={{
            alt: suggestion.organization.short_name,
            src: suggestion.organization.avatar,
            href: routes.union.getLink(suggestion.organization.id),
          }}
          title={suggestion.title}
          contentId={suggestion.id}
          orgId={suggestion.organization.id}
          contentType={'suggestion'}
          user={suggestion.user}
          published_at={suggestion.created_at}
          commentsCount={suggestion.comments_count}
          uniqueContent={<SuggestionContent suggestion={suggestion} />}
        />
      </RoutingContainer>
    </>
  );
}
