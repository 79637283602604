/* eslint-disable no-useless-escape */
import { Socials as SocialsEnum } from 'shared/interfaces/organization';

export const formatDomainType: (src: string) => SocialsEnum | null = (src) => {
  if (src?.length >= 256) {
    return null;
  }
  const regexMap: Record<SocialsEnum, RegExp> = {
    telegram: /^https?:\/\/(t(elegram)?\.me|telegram\.org)(\/[\w-.]+(\/[\w-.]+)*)?\/?$/,
    facebook: /^https?:\/\/(?:www\.)?facebook\.com(\/[\w-.]+(\/[\w-.]+)*)?\/?$/,
    youtube: /^https?:\/\/(?:www\.)?youtube\.com(\/[\w-.]+(\/[\w-.]+)*)?\/?$/,
    instagram: /^https?:\/\/(?:www\.)?instagram\.com(\/[\w-.]+(\/[\w-.]+)*)?\/?$/,
    whatsapp: /^https?:\/\/(?:www\.)?wa\.me(\/[\w-.]+(\/[\w-.]+)*)?\/?$/,
    tiktok: /^https?:\/\/(?:www\.)?tiktok\.com(\/[\w-.]+(\/[\w-.]+)*)?\/?$/,
    wechat: /^https?:\/\/(?:www\.)?weixin\.qq\.com(\/[\w-.]+(\/[\w-.]+)*)?\/?$/,
    snapchat: /^https?:\/\/(?:www\.)?snapchat\.com(\/[\w-.]+(\/[\w-.]+)*)?\/?$/,
    twitter: /^https?:\/\/(?:www\.)?twitter\.com(\/[\w-.]+(\/[\w-.]+)*)?\/?$/,
    linkedin: /^https?:\/\/(?:www\.)?linkedin\.com(\/[\w-.]+(\/[\w-.]+)*)?\/?$/,
    pinterest: /^https?:\/\/(?:www\.)?pinterest\.com(\/[\w-.]+(\/[\w-.]+)*)?\/?$/,
    ok: /^https?:\/\/(?:www\.)?ok\.ru(\/[\w-.]+(\/[\w-.]+)*)?\/?$/,
    vk: /^https?:\/\/(?:www\.)?vk\.com(\/[\w-.]+(\/[\w-.]+)*)?\/?$/,
    gitHub: /^(https?:\/\/)?(www\.)?github\.com(\/[\w-.]+(\/[\w-.]+)*)?\/?$/,
    gitLab: /^https?:\/\/(?:www\.)?gitlab\.com(\/[\w-.]+(\/[\w-.]+)*)?\/?$/,
    other: /^https?:\/\/[\w\-]+(\.[\w\-]+)+[/#?]?.*$/,
  };
  for (const [socialNetwork, regex] of Object.entries(regexMap)) {
    if (regex.test(src)) {
      return SocialsEnum[socialNetwork];
    }
  }
  return null;
};
