import { Link, useLocation } from 'react-router-dom';
import { routes } from 'Routes';
import sanitizeHtml from 'sanitize-html';
import { CustomImage } from 'shared/components/common/CustomImage/CustomImage';
import { allowedAttributesSchema, allowedIframeHostnamesSchema, allowedTagsSynitizer } from 'shared/constants/allowedTagsSynitizer';
import { StreamCardFooter } from 'components/Crads/StreamCardFooter/StreamCardFooter';
import mockImage from 'public/images/about-us.jpg';
import styles from './ActivityCard.module.scss';
import EventsInfo from './EventsInfo/EventsInfo';
import { ItemCardProps } from './props';
import { useGetActivityCardIcons } from './hooks/useGetActivityCardIcons';

const ActivityCard = ({ link, organization, image, externalLink, activityInfo, eventsAdditionalInfo }: ItemCardProps): JSX.Element => {
  const location = useLocation();
  // @ts-ignore
  let ItemCardWrapper: string | Link = externalLink ? 'a' : link ? Link : 'div';
  const { description, title, publishedData, commentsCount } = activityInfo;
  const icons = useGetActivityCardIcons({
    publishedData,
    commentsCount,
    needRoyPass: eventsAdditionalInfo?.needRoyPass,
    link,
    id: activityInfo.id,
  });

  const onImageError = (e) => {
    e.target.src = mockImage;
  };

  return (
    <ItemCardWrapper
      className={styles.card}
      to={{
        pathname: link,
        state: {
          from: location.pathname,
        },
      }}
      href={link}
      target={externalLink && '_blank'}
      rel="noreferrer"
    >
      <div className={styles.imageWrapper}>
        <img src={image || mockImage} onError={onImageError} alt="main" className={styles.image} />
        <Link to={routes.union.getLink(organization.id)} className={styles.organizationLink}>
          <CustomImage className={styles.organization} width={45} height={45} alt={organization.name} src={organization.logo} />
        </Link>
      </div>
      {eventsAdditionalInfo && <EventsInfo eventsAdditionalInfo={eventsAdditionalInfo} title={title} />}
      <div className={styles.innerContentWrapper}>
        <h4 className={styles.title}>{title}</h4>
        {description && description !== '...' && (
          <span className={styles.description}>
            {typeof description === 'string' ? (
              <div
                className={styles.description}
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(description, {
                    allowedTags: allowedTagsSynitizer,
                    allowedAttributes: allowedAttributesSchema,
                    allowedIframeHostnames: allowedIframeHostnamesSchema,
                  }),
                }}
              />
            ) : (
              description
            )}
          </span>
        )}
        <StreamCardFooter icons={icons} />
      </div>
    </ItemCardWrapper>
  );
};

export default ActivityCard;
