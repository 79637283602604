import { useTranslation } from 'react-i18next';
import styles from './Mission.module.scss';

export const Mission = () => {
  const { t } = useTranslation('home');
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <h2 className={styles.title}>{t('mission.title')}</h2>
        <div className={styles.text}>
          <p>{t('mission.description')}</p>
        </div>
        <img src={'/images/communities.jpg'} alt={'communities map'} width={1800} height={1000} className={styles.image} />
      </div>
    </div>
  );
};
