import { Autocomplete, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styles from './PlaceEditor.module.scss';
import { EventOnMap } from './EventOnMap/EventOnMap';
import useGeocoder from './hooks/useGeocoder';

type PointType = { lat: number; lng: number };

type Props = {
  place: string;
  setPlace: (val: string) => void;
  setPoint: (val: PointType) => void;
  point: PointType;
};

export const PlaceEditor = ({ place, setPlace, point, setPoint }: Props) => {
  const { suggestions, onChangePoint, onChangeValue, setSuggestion, setValue, suggestion, value } = useGeocoder({
    token: process.env.REACT_APP_MAPBOX_TOKEN,
    value: place,
    setValue: setPlace,
    point,
    setPoint,
  });

  const { t } = useTranslation('editor');
  return (
    <div className={styles.wrapper}>
      <Autocomplete
        getOptionLabel={(option) => option.place_name}
        options={suggestions.map((s) => ({ ...s })) || []}
        value={suggestion}
        inputValue={value}
        onInputChange={(ev, val) => {
          onChangeValue(val);
        }}
        onChange={(e, selectedValue) => {
          if (selectedValue) {
            setSuggestion(selectedValue);
          } else {
            setPoint({ lat: 0, lng: 0 });
            setValue('');
          }
        }}
        renderInput={(params): JSX.Element => (
          <TextField fullWidth label={t('address')} placeholder={t('writeAddress')} variant="outlined" {...params} />
        )}
      />
      <EventOnMap
        onChange={(val) => {
          onChangePoint(val);
        }}
        point={point}
      />
    </div>
  );
};
