import { TextField } from 'shared/components/common/TextField/TextField';
import styles from './WithEmail.module.scss';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { GlobalContext } from 'contexts/GlobalContext';
import { useContext, useState } from 'react';
import { GlobalDataContext } from 'contexts/GlobalDataContext';
import { toast } from 'react-toastify';
import formatServerError from 'shared/utils/formatServerError';
import { routes } from 'Routes';
import { useHistory, useLocation } from 'react-router';
import { Button } from 'shared/components/common/Button/Button';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TwoFAProps } from '../Login';

type WithEmailProps = {
  twoFaStep: TwoFAProps;
  setTwoFaStep: React.Dispatch<React.SetStateAction<TwoFAProps>>;
};

export const WithEmail = ({ setTwoFaStep, twoFaStep }: WithEmailProps) => {
  const {
    services: { authService },
    spinner: { showSpinner, hideSpinner },
  } = useContext(GlobalContext);

  const { t } = useTranslation('auth');

  const { auth } = useContext(GlobalDataContext);
  const [asyncErrors, setAsyncErrors] = useState<{ errors?: string[] }>();
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const validationSchema = Yup.object().shape({
    login: Yup.string().max(50, t('login.errors.max50')).trim().email(t('login.errors.invalidEmail')).required(t('login.errors.required')),
    password: Yup.string().min(8, t('login.errors.min8')).max(50, t('login.errors.max50')).trim().required(t('login.errors.required')),
  });

  async function onSubmit(values) {
    showSpinner();
    const { login, password, code } = values;

    const result = await authService
      .login({
        email: login,
        password,
        twoFA: twoFaStep.isNeeded
          ? {
              method: 'totp',
              password: code,
            }
          : undefined,
      })
      .finally(() => {
        hideSpinner();
      });

    if (result.need_2fa) {
      setTwoFaStep({
        login,
        password,
        isNeeded: true,
      });
    } else if (result.token) {
      try {
        await auth.authenticate();
        // Check inviteParams to open invitation page after first login
        if (!!localStorage.getItem('firstSession')) {
          window?.dataLayer.push({
            event: 'event',
            eventProps: {
              category: 'account',
              action: 'login_first_time',
            },
          });
        }
        if (authService.inviteParams) {
          history.push(routes.INVITATION_SCREEN);
        } else {
          history.push(searchParams.get('redirect') ?? routes.PROFILE);
        }
      } catch (error) {
        // think what to do, maybe toast not needed
        toast(formatServerError(error));
      }
    }
    hideSpinner();
  }
  return (
    <>
      <Formik
        initialValues={{ login: '', password: '' }}
        validationSchema={validationSchema}
        validate={() => {
          return asyncErrors;
        }}
        onSubmit={onSubmit}
      >
        {({ errors, touched, values, handleSubmit, setFieldValue, setFieldTouched }) => (
          <form className={styles.form} onSubmit={handleSubmit}>
            <TextField
              setValue={(value) => {
                setAsyncErrors({});
                setFieldValue('login', value);
              }}
              value={values.login}
              onBlur={() => setFieldTouched('login')}
              helperText={t('login.inputs.email')}
              placeholder={t('login.inputs.emailPlaceholder')}
              variant="text"
              maxLength={50}
              error={errors?.login && touched?.login && (errors.login as string)}
            />

            <div className={styles.form_group}>
              <TextField
                variant="password"
                setValue={(value) => {
                  setAsyncErrors({});
                  setFieldValue('password', value);
                }}
                value={values.password}
                onBlur={() => setFieldTouched('password')}
                helperText={t('login.inputs.password')}
                maxLength={50}
                placeholder={t('login.inputs.passwordPlaceholder')}
                error={errors?.password && touched?.password && (errors.password as string)}
              />
              <span className={styles.danger}>{asyncErrors?.errors?.join()}</span>
            </div>

            <div className={styles.enter_buttons_wrapper}>
              <Button size="small" type="submit" className={styles.button_login}>
                {t('login.actions.signIn')}
              </Button>
              <Link type="button" to={`${routes.REGISTRATION}?${searchParams.toString()}`}>
                <p className={styles.register}>{t('login.actions.signUp')}</p>
              </Link>
              <Link type="button" to={`${routes.FORGET_PASSWORD}?${searchParams.toString()}`}>
                <p className={styles.forgot}>{t('login.actions.forgotPassword')}</p>
              </Link>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};
