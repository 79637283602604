import { EditUserAvatar } from '../EditUserProfile/EditUserAvatar/EditUserAvatar';
import { TextArea } from '../../shared/components/common/TextArea/TextArea';
import { Contents, IContent } from '../../common/Contents/Contents';
import { Helmet } from 'react-helmet';
import styles from './PrivacyPolicy.module.scss';
import { useTranslation } from 'react-i18next';

const { REACT_APP_EMAIL } = process.env;

export function PrivacyPolicy() {
  const { t } = useTranslation('privacy');
  const tableOfContents: IContent[] = [
    {
      id: 'privacy-policy',
      label: t('general.label'),
    },
    {
      id: 'how-it-works',
      label: t('works.label'),
    },
    {
      id: 'general-access',
      label: t('access.label'),
    },
    {
      id: 'type-of-data',
      label: t('data.label'),
    },
    {
      id: 'grounds-for-using',
      label: t('grounds.label'),
    },
    {
      id: 'data-storing',
      label: t('storing.label'),
    },
    {
      id: 'data-protecting',
      label: t('protecting.label'),
    },
    {
      id: 'data-deleting',
      label: t('deleting.label'),
    },
    {
      id: 'private-data',
      label: t('private.label'),
    },
    {
      id: 'security-breach',
      label: t('security.label'),
    },
    {
      id: 'order-of-interaction',
      label: t('interaction.label'),
    },
  ];
  return (
    <div className={styles.PrivacyPolicy}>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>

      <aside>
        <Contents contents={tableOfContents} />
      </aside>
      <main className="p-left p-right">
        <h2 id="privacy-policy">{t('title').toLowerCase()}</h2>
        <br />
        <h3>{t('general.title')}</h3>
        <br />

        <ul className={styles.privacyPolicy}>
          <li>{`${t('general.description.private')} ${t('general.description.save')}`}</li>
          <li>{t('general.description.collect')}</li>
          <li>{t('general.description.tells')}</li>
          <li>
            {`
            ${t('general.description.change')} 
            ${t('general.description.report')} 
            ${t('general.description.accept')} 
            ${t('general.description.disagree')}
            `}
          </li>
        </ul>
        <br />
        <br />
        <h2 id="how-it-works">{t('works.title')}</h2>

        <p>{t('works.description.collect')}</p>
        <br />
        <br />

        <h2 id="general-access">{t('access.title')}</h2>

        <p>
          {`
          ${t('access.description.encrypted')} 
          ${t('access.description.key')} 
          ${t('access.description.never')} 
          ${t('access.description.admin')} 
          ${t('access.description.communication')} 
          ${t('access.description.cantSee')} 
          ${t('access.description.clarification')} 
          `}
        </p>

        <br />
        <h3>{t('access.exceptions.title')}</h3>
        <ul>
          <li>{t('access.exceptions.list.photo')}</li>
          <li>{t('access.exceptions.list.about')}</li>
          <li>{t('access.exceptions.list.registration')}</li>
        </ul>

        <br />
        <div className={`${styles.card} ${styles.about}`}>
          <span>{t('access.exceptions.list.manager')}</span>
        </div>
        <br />
        <ul>
          <li>{t('access.exceptions.list.registration')}</li>
          <li>{t('access.exceptions.list.can')}</li>
        </ul>

        <br />
        <div className={styles.card}>
          <EditUserAvatar disabled={true} />

          <br />

          <div className={styles.about}>
            <br />
            <h3>{t('access.exceptions.list.label')}</h3>
            <TextArea
              name="about"
              label={t('access.exceptions.list.label')}
              placeholder={t('access.exceptions.list.manager')}
              value=""
              valueChange={() => {}}
              bgColor="white"
              disabled={true}
            />
          </div>
        </div>

        <br />
        <p>{t('access.exceptions.list.attention')}</p>

        <br />
        <h3>{t('access.exceptions.list.technically')}</h3>

        <ul>
          <li>{t('access.exceptions.list.message')}</li>
          <li>{t('access.exceptions.list.mark')}</li>
        </ul>
        <br />
        <br />

        <h2 id="type-of-data">{t('data.title')}</h2>

        <h3>{t('data.description.personal.title')}</h3>
        <p>{t('data.description.personal.description')}</p>
        <br />
        <div className={`${styles.card} ${styles.cardLarge}`}>
          <ul>
            <li>{t('data.description.personal.list.name')}</li>
            <li>{t('data.description.personal.list.date')}</li>
            <li>{t('data.description.personal.list.sex')}</li>
            <li>{t('data.description.personal.list.country')}</li>
            <li>{t('data.description.personal.list.phone')}</li>
            <li>{t('data.description.personal.list.address')}</li>
            <li>{t('data.description.personal.list.login')}</li>
            <li>{t('data.description.personal.list.passport')}</li>
          </ul>
        </div>
        <br />
        <br />

        <h3>{t('data.description.professional.title')}</h3>
        <p>{t('data.description.professional.description')}</p>
        <br />
        <div className={`${styles.card} ${styles.cardLarge}`}>
          <ul>
            <li>{t('data.description.professional.list.organization')}</li>
            <li>{t('data.description.professional.list.position')}</li>
            <li>{t('data.description.professional.list.other')}</li>
            <li>{t('data.description.professional.list.employment')}</li>
          </ul>
        </div>
        <p>{t('data.description.professional.list.import')}</p>

        <br />
        <br />

        <h3>{t('data.description.auto.title')}</h3>
        <p>{t('data.description.auto.description')}</p>

        <br />
        <br />

        <h3>{t('data.description.device.title')}</h3>

        <div className={`${styles.card} ${styles.cardLarge}`}>
          <ul>
            <li>{t('data.description.device.list.model')}</li>
            <li>{t('data.description.device.list.system')}</li>
            <li>{t('data.description.device.list.id')}</li>
            <li>{t('data.description.device.list.mobile')}</li>
            <li>{t('data.description.device.list.data')}</li>
          </ul>
        </div>
        <br />
        <br />

        <h3>{t('data.description.location.title')}</h3>

        <div className={`${styles.card} ${styles.cardLarge}`}>
          <ul>
            <li>{t('data.description.location.list.ip')}</li>
            <li>{t('data.description.location.list.timeZone')}</li>
            <li>{t('data.description.location.list.mobile')}</li>
          </ul>
        </div>
        <br />
        <br />
        <h3>{t('data.description.platform.title')}</h3>

        <div className={`${styles.card} ${styles.cardLarge}`}>
          <ul>
            <li>{t('data.description.platform.list.frequency')}</li>
            <li>{t('data.description.platform.list.sections')}</li>
            <li>{t('data.description.platform.list.specific')}</li>
            <li>{t('data.description.platform.list.functions')}</li>
          </ul>
        </div>

        <p>
          {t('data.description.platform.list.cookie')}
          <span className="highlight">{` ${t('data.description.platform.list.cookiePrivacy')}`}</span>
        </p>
        <br />
        <br />

        <h3>{t('data.description.outside.title')}</h3>
        <p>{t('data.description.outside.description')}</p>
        <br />
        <br />

        <h2 id="grounds-for-using">{t('grounds.title')}</h2>
        <br />
        <h3>{t('grounds.description.title')}</h3>

        <ul>
          <li>
            <strong>{t('grounds.description.list.agree.label')}</strong>
            {` ${t('grounds.description.list.agree.text')}`}
          </li>
          <li>
            <strong>{t('grounds.description.list.relations.label')}</strong>
            {` ${t('grounds.description.list.relations.text')}`}
          </li>
        </ul>
        <br />
        <br />

        <h2 id="data-storing">{t('storing.title')}</h2>
        <h3>{t('storing.description.title')}</h3>
        <ul>
          <li>{`${t('storing.description.list.outside')}`}</li>
          <li>{`${t('storing.description.list.encrypted')}`}</li>
        </ul>
        <br />
        <br />

        <h2 id="data-protecting">{t('protecting.title')}</h2>
        <h3>{t('protecting.description.title')}</h3>
        <ul>
          <li>{`${t('protecting.description.list.token')}`}</li>
          <li>{`${t('protecting.description.list.encrypted')}`}</li>
          <li>{`${t('protecting.description.list.scanning')}`}</li>
          <li>{`${t('protecting.description.list.protect')}`}</li>
          <li>{`${t('protecting.description.list.other')}`}</li>
        </ul>

        <p>{`${t('protecting.description.list.password')}`}</p>
        <br />
        <br />

        <h2 id="data-deleting">{t('deleting.title')}</h2>

        <p>{t('deleting.description')}</p>
        <br />

        {/* <div className={styles.deletingProfile}>
          <div className={`${styles.icon} with-radius bg-grey-1`}>
            <Icon iconName="pencil" />
          </div>
          <Button>удалить профиль</Button>
        </div> */}
        <br />
        <br />

        <h2 id="private-data">{t('private.title')}</h2>

        <h3>{t('private.description.sections.title')}</h3>

        <div className={`${styles.card} ${styles.cardLarge}`}>
          <ul>
            <li>{t('private.description.sections.list.suggestions')}</li>
            <li>{t('private.description.sections.list.tasks')}</li>
            <li>{t('private.description.sections.list.news')}</li>
            <li>{t('private.description.sections.list.events')}</li>
            <li>{t('private.description.sections.list.blog')}</li>
          </ul>
        </div>
        <br />
        <br />

        <h3>{t('private.description.information.title')}</h3>

        <div className={`${styles.card} ${styles.cardLarge}`}>
          <ul>
            <li>{t('private.description.information.list.news')}</li>
            <li>{t('private.description.information.list.blog')}</li>
            <li>{t('private.description.information.list.users')}</li>
          </ul>
        </div>

        <p>{t('private.description.information.list.information')}</p>
        <br />
        <br />

        <h3>{t('private.description.community.title')}</h3>

        <div className={`${styles.card} ${styles.cardLarge}`}>
          <ul>
            <li>{t('private.description.community.list.suggestions')}</li>
            <li>{t('private.description.community.list.tasks')}</li>
            <li>{t('private.description.community.list.events')}</li>
            <li>{t('private.description.community.list.finance')}</li>
          </ul>
        </div>
        <br />
        <br />

        <h3>{t('private.description.personal.title')}</h3>
        <div className={`${styles.card} ${styles.cardLarge}`}>
          <ul>
            <li>{t('private.description.personal.list.tasks')}</li>
            <li>{t('private.description.personal.list.finance')}</li>
            <li>{t('private.description.personal.list.surety')}</li>
            <li>{t('private.description.personal.list.data')}</li>
          </ul>
        </div>
        <br />
        <br />

        <h2 id="security-breach">{t('security.title')}</h2>

        <p>
          {`${t('security.description')} `}
          <span className="highlight"> {REACT_APP_EMAIL}</span>.
        </p>
        <br />
        <br />

        <h2 id="order-of-interaction">{t('interaction.title')}</h2>

        <p>{t('interaction.text')}</p>
        <br />
        <h3>{t('interaction.description.variants.title')}</h3>

        <p>
          {`${t('interaction.description.variants.text')} `}
          <span className="highlight"> {REACT_APP_EMAIL}</span>.
        </p>
        <br />
        <br />

        <h3>{t('interaction.description.contact.title')}</h3>

        <p>
          {`${t('interaction.description.contact.text')} `}
          <span className="highlight"> {REACT_APP_EMAIL}</span>.
        </p>
      </main>
    </div>
  );
}
