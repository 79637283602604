import React from 'react';
import cn from 'classnames';

import styles from './SuggestionDesktop.module.scss';
import { ISuggestion } from '../../../interfaces/suggestion.interface';
import { CustomImage } from '../../../common/CustomImage/CustomImage';
import { SuggestionDescription } from '../SuggestionDescription/SuggestionDescription';
import { Button } from 'shared/components/common/Button/Button';
import { routes } from 'Routes';
import SuggestionDoughnut from '../SuggestionDoughnut/SuggestionDoughnut';
import { useTranslation } from 'react-i18next';

type ISuggestionDesktopProps = {
  suggestion: ISuggestion;
  setSuggestionBlockStatus: (status: boolean) => void;
  setSuggestionEdit?: (suggestion: ISuggestion) => void;
  deleteSuggestion: (suggestionId: ISuggestion) => void;
  setHoveredSuggestion: (suggestionId: ISuggestion) => void;
};
export function SuggestionDesktop({
  suggestion,
  setSuggestionBlockStatus,
  setSuggestionEdit,
  deleteSuggestion,
  setHoveredSuggestion,
}: ISuggestionDesktopProps) {
  const { t } = useTranslation('suggestion');
  return (
    <div className={styles.SuggestionDesktop}>
      <div className={cn(styles.suggestionTextWrapper, 'custom-scroll custom-scroll-black')}>
        <div className={styles.titleAvatarWrapper}>
          <div className={styles.avatar}>
            <CustomImage rounded={true} src={suggestion?.user?.public_avatar} height={60} width={60} alt="User Avatar" />
          </div>
          <div>
            <h4 className={styles.title}>{suggestion?.title}</h4>
            <p>
              {t('discussionsCounter')}
              <span className={styles.quantity}>{suggestion?.comments_count}</span>
            </p>
          </div>
        </div>
        <div className={styles.wrapperDesctiptionDoug}>
          <SuggestionDescription suggestion={suggestion} styles={styles} />
          <div className={styles.doughnut}>
            {!!suggestion?.reactions && <SuggestionDoughnut votes={suggestion?.reactions} withoutLabel />}
            <div className={styles.footer}>
              <Button size="small" color="primary" maxWidth to={routes.suggestion.getLink(suggestion?.organization_id, suggestion?.id)}>
                {t('buttons.readMore')}
              </Button>
              {suggestion?.owner && (
                <div className={styles.changeButton}>
                  <button
                    onClick={() => {
                      setSuggestionBlockStatus(true);
                      setSuggestionEdit(suggestion);
                    }}
                  >
                    {t('buttons.change')}
                  </button>
                  <button
                    onClick={() => {
                      const isDelete = window.confirm(`${t('deleteMsg')} "${suggestion.title}"?`);
                      if (isDelete) {
                        deleteSuggestion(suggestion);
                      }
                      setHoveredSuggestion(null);
                    }}
                  >
                    {t('buttons.delete')}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
