import { useContext, useEffect } from 'react';
import { GlobalDataContext } from 'contexts/GlobalDataContext';
import { Button } from 'shared/components/common/Button/Button';
import styles from './HelpOffers.module.scss';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

interface IHelpOffersProps {
  unionId: number;
  openHelpOffersModal: () => void;
  isMobile?: boolean;
}

export const helpOffersBlockId = 'help-offers';

const HelpOffers = ({ openHelpOffersModal, unionId, isMobile }: IHelpOffersProps): JSX.Element => {
  const { helpOffers, user } = useContext(GlobalDataContext);
  const { t } = useTranslation('modals');
  useEffect(() => {
    helpOffers.getHelpOffers(unionId);
  }, [unionId]);

  // @ts-ignore
  const activeHelpOffers = (user.membership.find((org) => org.id === unionId)?.help_offers || []) as [];

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.mobileTopWrapper]: isMobile,
      })}
    >
      <div
        data-cy="ready-to-do"
        className={classNames(styles.helpOffers, {
          [styles.mobileWrapper]: isMobile,
        })}
        id={helpOffersBlockId}
      >
        <ul className={styles.helpOffersInfo}>
          {!!activeHelpOffers.length &&
            activeHelpOffers.map((id) => {
              const offer = helpOffers?.data.find((i) => i.id === id)?.text;
              if (offer) {
                return <li key={id}>{offer}</li>;
              }
              return null;
            })}
        </ul>
        <div className={styles.wrapperButtonHelpOffers}>
          <h3>{t('helpOffers.title')}</h3>
          <Button data-cy="choose-help-btn" maxWidth onClick={openHelpOffersModal} size={isMobile ? 'small' : 'default'}>
            {t('helpOffers.choose')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default HelpOffers;
