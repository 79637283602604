import styles from './Card.module.scss';

export const Card = ({ step, text }: { text: string; step: number }) => (
  <div className={styles.wrapper}>
    <div className={styles.step}>{step}</div>
    <div className={styles.text}>
      <h2>{text}</h2>
    </div>
  </div>
);
