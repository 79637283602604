import { MembershipSubject } from '@owlmeans/regov-ext-groups';
import {
  buildWalletWrapper,
  EncryptedStore,
  WalletWrapper,
  cryptoHelper,
  Credential,
  WalletHandler,
  REGISTRY_TYPE_IDENTITIES,
  REGISTRY_SECTION_PEER,
} from '@owlmeans/regov-ssi-core';
import { regovConfig, regovExtensionRegistry, walletServerClient } from 'regov/config';

export const buildRegovWallet = async (password: string, store: EncryptedStore): Promise<WalletWrapper> => {
  return buildWalletWrapper({ crypto: cryptoHelper, extensions: regovExtensionRegistry?.registry }, password, store, {
    prefix: regovConfig.DID_PREFIX,
    defaultSchema: regovConfig.baseSchemaUrl,
    didSchemaPath: regovConfig.DID_SCHEMA_PATH,
  });
};

export const populateTrustedCredentials = async (handler: WalletHandler) => {
  try {
    const credentialList = (await walletServerClient.getVCs<Credential<MembershipSubject>[]>({
      uri: '/organizations/ssi/list',
      serverAlias: 'integration',
    })) as unknown as { credentials: Credential<MembershipSubject>[] };

    await Promise.all(
      credentialList.credentials.map(async (credential) => {
        if (!handler.wallet.getRegistry(REGISTRY_TYPE_IDENTITIES).getCredential(credential.id, REGISTRY_SECTION_PEER)) {
          await handler.wallet.getRegistry(REGISTRY_TYPE_IDENTITIES).addCredential(credential, REGISTRY_SECTION_PEER);
        }
      })
    );
    handler.notify();
  } catch (e) {
    console.error(e);
  }
};
