import { useContext, useEffect, useState } from 'react';
import { getNotification } from 'services/notifications';
import { LaborUnionInfo } from 'common/LaborUnioniInfo/LaborUnionInfo';
import { formatDistanceToNow } from 'date-fns';
import { GlobalContext } from 'contexts/GlobalContext';
import logoSystem from '../../../public/systemAvatar.png';
import { ru, be, enGB, lt, uk } from 'date-fns/locale';
import styles from './NotificationModal.module.scss';
import { GlobalDataContext } from 'contexts/GlobalDataContext';
import { toast } from 'react-toastify';
import formatServerError from 'utils/formatServerError';
import { Notification, NotificationStatusEnum } from 'interfaces/notification.interface';
import { cloneDeep } from 'lodash';
import { getUrl } from 'modules/notifications';
import { Button } from 'shared/components/common/Button/Button';
import sanitizeHtml from 'sanitize-html';
import { allowedAttributesSchema, allowedTagsSynitizer, allowedIframeHostnamesSchema } from 'shared/constants/allowedTagsSynitizer';
import { useTranslation } from 'react-i18next';

function NotificationModal({ id }) {
  const { i18n, t } = useTranslation('roy-global');
  const locales = {
    ru,
    be,
    enGB,
    lt,
    uk,
  };
  const [notification, setNotification] = useState<Notification>(null);

  const { notifications, setNotifications } = useContext(GlobalDataContext);

  const { closeModal } = useContext(GlobalContext);
  useEffect(() => {
    (async () => {
      try {
        const notification = await getNotification(id);

        setNotification(notification.data);

        const newNotifications = cloneDeep(notifications);

        newNotifications.find((notification) => notification.id === id).status = NotificationStatusEnum.read;

        setNotifications(newNotifications);
      } catch (e) {
        toast.error(formatServerError(e));
      }
    })();
  }, []);

  if (!notification) {
    return null;
  }

  const { from, title, message, created_at, data, type } = notification;

  const urlNotification = getUrl(type, data);

  return (
    <div className={styles.wrapper}>
      <LaborUnionInfo
        // @ts-ignore
        organisation={{
          name: from.name !== 'system' ? from.name : 'Roy.team',
          id: from.name !== 'system' ? 22 : data.organization_id || data.from_id || 0,
          avatar: from.logo || logoSystem,
        }}
        onClick={closeModal}
      />
      <h3 className={styles.title}>{title}</h3>
      <p
        dangerouslySetInnerHTML={{
          __html: sanitizeHtml(message, {
            allowedTags: allowedTagsSynitizer,
            allowedAttributes: allowedAttributesSchema,
            allowedIframeHostnames: allowedIframeHostnamesSchema,
          }),
        }}
      ></p>
      {!!urlNotification && (
        <Button to={urlNotification} maxWidth onClick={() => closeModal()} className={styles.button}>
          {t('buttons.forward')}
        </Button>
      )}
      <time className={styles.date}>
        {formatDistanceToNow(new Date(created_at), {
          locale: locales[i18n.language],
          addSuffix: false,
        })}
      </time>
    </div>
  );
}

export default NotificationModal;
