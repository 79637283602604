import { normalizeValue, Presentation, REGISTRY_SECTION_OWN, WalletHandler } from '@owlmeans/regov-ssi-core';
import { IOrganisation } from 'interfaces/organisation.interface';
import { regovExtensionRegistry, walletServerClient } from 'regov/config';

export const buildMembershipHelper = (organization: IOrganisation, handler: WalletHandler): RegovMembershipHelper => {
  return {
    isntAuthentiated: () => !handler.wallet,

    isAuthorizationRequired: () => organization?.badge_needs && organization.badge_needs.length > 0,

    areRequirementsSatisfied: async () =>
      !organization.badge_needs ||
      organization.badge_needs.length === 0 ||
      (handler.wallet &&
        (
          await Promise.all(
            organization.badge_needs.map(async ({ type }) => {
              const creds = await handler.wallet.getCredRegistry().lookupCredentials(type, REGISTRY_SECTION_OWN);
              return !!creds.find((inst) => inst.credential.type.includes(type));
            })
          )
        ).every((result) => result)),

    challengeRequirements: async ({ token }) => {
      if (!organization.badge_needs || !organization.badge_needs.length) {
        return true;
      }
      try {
        const challengeRequest: Presentation = await walletServerClient.getVC({
          uri: 'regov/challenge/create',
          headers: { authorization: `Bearer ${token}` },
          params: {
            types: Object.fromEntries(organization.badge_needs.map(({ type, can }) => [type, can.flatMap((shortOrg) => shortOrg.issuers)])),
            holder: handler.wallet.getIdentity().credential.id,
          },
        });

        const responseCredentials = (
          await Promise.all(
            normalizeValue(challengeRequest.verifiableCredential).map(async (cred) => {
              const type = cred.type.find((type) => organization.badge_needs.find(({ type: _type }) => type === _type));
              return (await handler.wallet.getCredRegistry().lookupCredentials(type, REGISTRY_SECTION_OWN))
                .map((cred) => cred.credential)
                .find((cred) => cred);
            })
          )
        ).filter((cred) => cred);

        if (responseCredentials.length !== organization.badge_needs.length) {
          return false;
        }
        const type = responseCredentials
          .find((cred) => cred)
          .type.find((type) => organization.badge_needs.find(({ type: _type }) => _type === type));

        const factory = regovExtensionRegistry.getExtension(type);

        const response = factory.extension.getFactory(type).respond(handler.wallet, {
          request: challengeRequest,
          credential: responseCredentials,
          identity: handler.wallet.getIdentityCredential(),
        });

        return response;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
  };
};

export type RegovMembershipHelper = {
  isntAuthentiated: () => boolean;

  isAuthorizationRequired: () => boolean;

  areRequirementsSatisfied: () => Promise<boolean>;

  challengeRequirements: (options: { token: string }) => Promise<boolean | Presentation>;
};
