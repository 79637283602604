import React from 'react';

export const toastMessageWithTitle =
  (title = '', message: string) =>
  () =>
    (
      <div>
        {title && <p style={{ margin: 0, fontWeight: 'bold' }}>{title}</p>}
        {message}
      </div>
    );
