import { GlobalContext } from 'contexts/GlobalContext';
import { useContext } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { SelfRegistrationWidget } from 'regov/components/Registration/SelfRegistrationWidget';
import styles from './StepCredentials.module.scss';
import { routes } from 'Routes';
import { useTranslation } from 'react-i18next';

type WithoutEmailProps = {
  goBack?: () => void;
};

export const WithoutEmail = ({ goBack }: WithoutEmailProps) => {
  const { t } = useTranslation('auth');
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const {
    services: { authService },
    spinner: { showSpinner, hideSpinner },
    openModal,
    closeModal,
  } = useContext(GlobalContext);
  return (
    <div className={styles.container}>
      <SelfRegistrationWidget
        openModal={openModal}
        closeModal={closeModal}
        history={history}
        showSpinner={showSpinner}
        hideSpinner={hideSpinner}
        authService={authService}
        goBack={goBack}
      />
      <Link className={styles.link_btn} to={`${routes.LOGIN}?${searchParams.toString()}`}>
        <Link to={`${routes.LOGIN}?${searchParams.toString()}`}>{t('registration.actions.account')}</Link>
      </Link>
    </div>
  );
};
