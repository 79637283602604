import { useState } from 'react';

const useToggle = (state?: boolean): any[] => {
  const [isToggled, setIsToggled] = useState(state ?? false);

  const toggle = () => {
    setIsToggled((prev) => !prev);
  };
  return [isToggled, toggle];
};

export default useToggle;
