import { CustomMap } from 'shared/components/common/CustomMap/CustomMap';
import styles from './MapModal.module.scss';
import { useTranslation } from 'react-i18next';
import { NavigationControl, GeolocateControl, Marker } from 'react-map-gl';
import { Icon } from 'shared/components/common/Icon/Icon';
import { GoogleMapsIcon } from './GoogleMapsIcon';

type Props = {
  lat: number;
  lng: number;
  title: string;
};
const INITIAL_ZOOM = 5;
export const MapModal = ({ lat, lng, title }: Props) => {
  const { t } = useTranslation('content');
  const href = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
  return (
    <div className={styles.wrapper}>
      <h4>{title}</h4>
      <div className={styles.map}>
        <CustomMap
          initialViewState={{
            latitude: lat,
            longitude: lng,
            zoom: INITIAL_ZOOM,
          }}
          onLoad={(e) => {
            e.target.zoomTo(15);
          }}
        >
          <NavigationControl position="top-right" />
          <GeolocateControl />
          <Marker longitude={lng} latitude={lat}>
            <Icon width={32} height={32} color="white" iconName="location" />
          </Marker>
        </CustomMap>
      </div>
      <a href={href} className={styles.google} target="_blank" rel="noreferrer">
        <GoogleMapsIcon />
        <p className={styles.text}>{t('googleMaps')}</p>
      </a>
    </div>
  );
};
