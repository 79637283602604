import { GlobalDataContext } from 'contexts/GlobalDataContext';
import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { userService } from 'services';
import { Checkbox } from 'shared/components/common/Checkbox/Checkbox';
import formatServerError from 'shared/utils/formatServerError';
import styles from './FeedFiltration.module.scss';
import { useTranslation } from 'react-i18next';

const FeedFiltration = () => {
  const { user, setUser } = useContext(GlobalDataContext);
  const { t } = useTranslation('modals');
  const [isPoliticalLoading, setIsPoliticalLoading] = useState<boolean>(false);
  const [isSensitiveLoading, setIsSensitiveLoading] = useState<boolean>(false);

  const changePolitical = async () => {
    try {
      setIsPoliticalLoading(true);
      const userToUpdate = { restrict: { sensitive: user.restrict.sensitive, political: !user.restrict.political } };
      await userService.updateUser(userToUpdate);
      setUser({ ...user, ...userToUpdate });
      toast(t('toast.political'));
    } catch (e) {
      toast.error(formatServerError(e));
    } finally {
      setIsPoliticalLoading(false);
    }
  };

  const changeSensetive = async () => {
    try {
      setIsSensitiveLoading(true);
      const userToUpdate = { restrict: { sensitive: !user.restrict.sensitive, political: user.restrict.political } };
      await userService.updateUser(userToUpdate);
      setUser({ ...user, ...userToUpdate });
      toast(t('toast.sensitive'));
    } catch (e) {
      toast.error(formatServerError(e));
    } finally {
      setIsSensitiveLoading(false);
    }
  };

  return (
    <div className={styles.wrapper}>
      <h4>{t('firstSession.title')}</h4>
      <Checkbox
        disabled={isPoliticalLoading}
        value={user.restrict.political}
        valueChange={changePolitical}
        type="small"
        label={t('controls.political')}
      />
      <Checkbox
        disabled={isSensitiveLoading}
        value={user.restrict.sensitive}
        valueChange={changeSensetive}
        type="small"
        label={t('controls.sensitive')}
      />
    </div>
  );
};

export default FeedFiltration;
