import { useEffect, useState } from 'react';
import styles from './UserLanguageSelect.module.scss';
import { Icon } from 'shared/components/common/Icon/Icon';
import { useTranslation } from 'react-i18next';
import { languages } from 'shared/constants/languages';

export const UserLanguageSelect = () => {
  const { i18n } = useTranslation();
  const [selectedLangCode, setSelectedLangCode] = useState(i18n.language);
  const selectedLang = languages.find((lang) => lang.code === selectedLangCode);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!selectedLang) {
      handleLanguageChange('en');
    }
  }, []);

  const handleLanguageChange = (langCode: string) => {
    i18n.changeLanguage(langCode);
    setSelectedLangCode(langCode);
  };

  const handleSelectLanguage = (e, langCode: string) => {
    e.stopPropagation();
    handleLanguageChange(langCode);
    setIsOpen(false);
  };

  const handleOpenMenu = (e) => {
    e.stopPropagation();
    setIsOpen((prev) => !prev);
  };

  return (
    <div className={styles.selectWrapper}>
      <div className={styles.selectButton} onClick={(e) => handleOpenMenu(e)}>
        <Icon iconName="translate" className={styles.icon} width={20} height={20} />
        <span className={`${styles.title} ${isOpen ? styles.active : ''}`}> {selectedLang?.name} </span>
        <Icon rotate={isOpen ? 180 : 0} iconName="arrowBottom" color={isOpen ? 'primary' : 'black'} />
      </div>
      {isOpen && (
        <div className={styles.selectList}>
          {languages.map(
            (language, idx) =>
              selectedLangCode !== language.code && (
                <div key={idx} onClick={(e) => handleSelectLanguage(e, language.code)} className={styles.selectItem}>
                  {language.name}
                </div>
              )
          )}
        </div>
      )}
    </div>
  );
};
