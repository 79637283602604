import { NewsInterface } from '../../../shared/interfaces/news';
import { useContext, useEffect, useMemo } from 'react';

import { getNews, getNewsFilters, getOrganisationNews } from 'services/news';

import NewsGroup, { NewsGroupDirection } from '../NewsGroup/NewsGroup';
import { useSsrEffect, useSsrState } from '@issr/core';
import { GlobalContext } from 'contexts/GlobalContext';

import { toast } from 'react-toastify';
import formatServerError from 'shared/utils/formatServerError';
import { useTranslation } from 'react-i18next';
import ActivityGroup from 'components/ActivityGroup/ActivityGroup';

export enum NewsDataTypes {
  day = 'day',
  popular = 'popular',
  allNews = 'allNews',
  organisationNews = 'organisation-news',
  bestNews = 'bestNews',
  tagsNews = 'tagsNews',
  organisationNewsPage = 'organisationNewsPage',
}

const config = {
  [NewsDataTypes.popular]: {
    method: getNews,
    sort: { sortBy: 'popular' } as getNewsFilters,
    title: 'news.popular',
  },
  [NewsDataTypes.bestNews]: {
    method: getNews,
    sort: { featured: 1 } as getNewsFilters,
    title: 'news.bestNews',
  },
  [NewsDataTypes.allNews]: {
    method: getNews,
    sort: {} as getNewsFilters,
    title: 'news.allNews',
  },
  [NewsDataTypes.tagsNews]: {
    method: getNews,
    sort: {} as getNewsFilters,
    title: '',
  },
  [NewsDataTypes.organisationNews]: {
    method: getOrganisationNews,
    title: 'news.default',
  },
  [NewsDataTypes.organisationNewsPage]: {
    method: getOrganisationNews,
    title: 'news.default',
  },
  org: {
    method: getOrganisationNews,
    title: 'news.default',
  },
};

interface Props {
  type?: NewsDataTypes;
  organisationId?: number;
  tags?: string[];
  direction?: NewsGroupDirection;
  isNewDesign?: boolean;
}

const NewsGroupContainer = ({ type, organisationId, tags, direction, isNewDesign }: Props) => {
  const { t } = useTranslation('roy-global');
  const {
    isMounted,
    spinner: { showSpinner, hideSpinner },
    screen,
  } = useContext(GlobalContext);

  const [news, setNews] = useSsrState<NewsInterface[]>([]);
  const [metaPageData, setMetaPageData] = useSsrState<{ last_page: number; current_page: number }>(null);

  let newsGroupDirection = useMemo(() => {
    return screen.innerWidth <= 680
      ? !!tags?.length || type === NewsDataTypes.allNews || type === NewsDataTypes.organisationNewsPage
        ? NewsGroupDirection.list
        : NewsGroupDirection.slider
      : direction;
  }, [type, organisationId, tags, direction]);

  let isListDirection = useMemo(() => {
    return (
      newsGroupDirection === NewsGroupDirection.list ||
      !!tags?.length ||
      type === NewsDataTypes.allNews ||
      type === NewsDataTypes.organisationNewsPage
    );
  }, [type, organisationId, tags, direction]);

  useEffect(() => {
    setNews([]);
    setMetaPageData(null);
  }, []);

  const loadNews = async (limit?: number) => {
    let requestedNews = [];

    if (type) {
      const { method, sort } = config[type];

      const obj = {
        ...sort,
        ...{
          organisationId,
          tags,
          limit: isListDirection ? 12 : limit,
          page: isListDirection && metaPageData?.current_page ? metaPageData.current_page + 1 : 1,
        },
      };

      !!limit && showSpinner();
      const { data, meta } = await method(obj);

      requestedNews = data;

      if (isListDirection) setMetaPageData({ current_page: meta.current_page, last_page: meta.last_page });

      hideSpinner();
    }

    setNews(!isMounted ? [] : isListDirection ? [...news, ...requestedNews] : requestedNews);
  };

  useSsrEffect(async () => {
    setNews([]);
    setMetaPageData(null);
    await loadNews(12);
    return () => {
      setNews([]);
      setMetaPageData(null);
    };
  }, type);

  useEffect(() => {
    if (isMounted) {
      (async () => {
        try {
          await loadNews(12);
        } catch (e) {
          toast.error(formatServerError(e));
        }
      })();
    }
    return () => {
      setNews([]);
      setMetaPageData(null);
    };
  }, [type, organisationId, isMounted, isListDirection, tags]);

  if (!news?.length) {
    return null;
  }

  if (isNewDesign) {
    return (
      <ActivityGroup
        loadData={loadNews}
        hasMoreData={metaPageData?.current_page < metaPageData?.last_page}
        activities={news.map((eachNews) => {
          return { ...eachNews, type: 'news', user: eachNews.user_id };
        })}
        type="news"
      />
    );
  }

  return (
    <NewsGroup
      loadData={loadNews}
      hasMoreData={metaPageData?.current_page < metaPageData?.last_page}
      direction={newsGroupDirection}
      news={news}
      title={!!tags?.length ? `${t('news.subject')}: ${tags?.join(',')}` : type ? t(config[type].title) : t('news.default')}
    />
  );
};

export default NewsGroupContainer;
