import classNames from 'classnames';
import styles from './CustomSlider.module.scss';

type CustomSliderProps = {
  slides: Array<JSX.Element>;
  slidesOnPage: number;
  activeSlide: number;
  className?: string;
};

export const CustomSlider = ({ slides, slidesOnPage, activeSlide, className }: CustomSliderProps) => {
  return (
    <div className={classNames(styles.wrapper, className)}>
      {slides.map((el, index) => {
        return (
          <div
            style={{
              width: `calc((100% / ${slidesOnPage}) - ${slidesOnPage === 1 ? '0px' : '4px'} * ${slidesOnPage} - ${
                slidesOnPage === 1 ? '0px' : '2px'
              })`,
              transform: `translate(calc((100% + 20px) * ${-activeSlide}), 0)`,
            }}
            className={styles.slide}
            key={`custom-slider-slide${index}`}
          >
            {el}
          </div>
        );
      })}
    </div>
  );
};
