import React, { useEffect, useRef, useState } from 'react';
import './Select.scss';
import { FormError } from 'shared/components/common/Validator/FormError/FormError';
import { Icon } from 'shared/components/common/Icon/Icon';
import { isArray } from 'lodash';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

type SelectedValue = {
  id: number;
  name: string;
};

export type ISelectProps = {
  name?: string;
  label?: string;
  description?: string;
  value?: string | number | string[] | number[];
  options: any[];
  onSelect: (event: any, name?: string) => void;
  idKey?: string;
  labelKey?: string;
  error?: string;
  onTouch?: () => void;
  multiselect?: boolean;
  required?: boolean;
  dataCy?: string;
  openStatusChanged?: (isOpen: boolean) => void;
  hasEmptyValue?: boolean;
  className?: string;
  alwaysOpen?: boolean;
};

export function Select({
  dataCy,
  name,
  multiselect = false,
  label,
  options,
  value = multiselect ? [] : null,
  onSelect,
  description,
  idKey = 'id',
  labelKey = 'label',
  error,
  onTouch,
  required = false,
  openStatusChanged,
  hasEmptyValue = true,
  className = '',
  alwaysOpen = false,
}: ISelectProps) {
  const { t } = useTranslation('shared');
  const [isOpen, setOpenStatus] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<SelectedValue[]>(
    multiselect
      ? options?.filter((option) => (value as string[])?.includes(option[idKey])) || []
      : options?.find((option) => option[idKey] === value) || null
  );

  useEffect(() => {
    if (multiselect) {
      if (
        (options && value && (!selectedValue || selectedValue.length) !== (value as string[]).length) ||
        (selectedValue as object[]).every((item) => (value as string[]).includes(item[idKey]))
      ) {
        setSelectedValue(options?.filter((option) => (value as string[])?.includes(option[idKey])) || []);
      }
    } else {
      if (options && value && (!selectedValue || selectedValue[idKey]) !== value) {
        setSelectedValue(options?.find((option) => option[idKey] === value) || null);
      }
    }
  }, [options, value]);

  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      onTouch && onTouch();
      setOpenStatus(false);
      openStatusChanged && openStatusChanged(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', handleClickOutside, true);
      return () => {
        document.removeEventListener('click', handleClickOutside, true);
      };
    }
  }, [isOpen]);

  const select = (option) => {
    if (multiselect) {
      let newValue;
      const index = selectedValue?.findIndex((item) => item[idKey] === option[idKey]);
      if (index > -1) {
        selectedValue.splice(index, 1);
        newValue = [...selectedValue];
      } else {
        newValue = [...selectedValue, option];
      }
      onSelect(
        newValue.map((item) => item[idKey]),
        name
      );
      setSelectedValue(newValue);
    } else {
      onSelect(option[idKey], name);
      setSelectedValue(option);
      setOpenStatus(false);
    }
  };

  const onToggle = () => {
    if (isOpen) {
      onTouch && onTouch();
    }
    setOpenStatus(!isOpen);
    openStatusChanged && openStatusChanged(!isOpen);
  };

  const selectedValueLabel = !selectedValue
    ? ''
    : isArray(selectedValue)
    ? selectedValue.map((el) => el.name).join(', ')
    : selectedValue[labelKey];

  return (
    <div className={`form-group ${error ? 'error' : ''} ${className}`} ref={ref}>
      <div
        className={`${isOpen || alwaysOpen ? 'opened' : ''} ${
          (multiselect ? selectedValue?.length : selectedValue) || isOpen || alwaysOpen ? 'form-group__select' : ''
        }`}
        data-cy={dataCy}
      >
        {label && (
          <label htmlFor={name} onClick={onToggle}>
            {label + (label && required ? ' *' : '')}
          </label>
        )}
        {!alwaysOpen && (
          <div className="select" onClick={onToggle}>
            <span>{selectedValueLabel}</span>
          </div>
        )}

        <div
          className={cn('select__options', {
            multiselect: !!multiselect,
            alwaysOpenedMultiSelect: !!alwaysOpen,
          })}
        >
          <div className="select__scroll custom-scroll custom-scroll-black">
            {!multiselect && hasEmptyValue && (
              <button
                className="select__option"
                onClick={() =>
                  select({
                    [idKey]: '',
                  })
                }
              >
                {t('actions.notSelected')}
              </button>
            )}
            {options?.map((option) => {
              const selected = multiselect ? selectedValue.map((item) => item[idKey]).includes(option[idKey]) : false;
              return (
                <div
                  className={`select__option ${selected ? 'selected' : ''}`}
                  onClick={select.bind(this, option)}
                  key={option[idKey]}
                  data-cy={option.name}
                >
                  {multiselect && (
                    <div className="icon">
                      {selected && <Icon iconName="check" width={13} height={13} color="black" />}
                    </div>
                  )}
                  <div style={{ width: '100%' }}>{option[labelKey]}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {description && (
        <div className="description">
          {error ? (
            <FormError>{error}</FormError>
          ) : (
            !(multiselect ? selectedValue.length : selectedValue) && description
          )}
        </div>
      )}
    </div>
  );
}
