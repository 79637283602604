import { IContentPreview } from 'shared/interfaces/feed';
import { StreamEventCard } from './StreamEventCard/StreamEventCard';
import { StreamNewsCard } from './StreamNewsCard/StreamNewsCard';
import { StreamPostCard } from './StreamPostCard/StreamPostCard';

type StreamCardsAssignmentProps = {
  data: IContentPreview;
  updateFeed?: () => void;
};

export const StreamCardsAssignment = ({ data, updateFeed }: StreamCardsAssignmentProps) => {
  switch (data.type) {
    case 'event':
      return (
        <StreamEventCard
          watches={0}
          title={data.title}
          tags={data.tags}
          published_at={data.published_at}
          organization={data.organization}
          id={data.id}
          excerpt={data.excerpt}
          commentsCount={data.comments_count || 0}
          author={data.user}
          key={`${data.type}-${data.id}`}
          type={data.type}
          images={data.images}
          date={{ dateStart: data.extra.date_start, dateEnd: data.extra.date_end }}
          place={data.place}
          price={data.extra.price}
        />
      );
    case 'ADVERTISEMENT':
    case 'DONATE':
    case 'SECTIONS':
    case 'VACANCY':
    case 'material':
    case 'quiz':
    case 'task':
    case 'news':
      return (
        <StreamNewsCard
          watches={0}
          title={data.title}
          tags={data.tags}
          published_at={data.published_at}
          organization={data.organization}
          id={data.id}
          excerpt={data.excerpt}
          commentsCount={data.comments_count || 0}
          author={data.user}
          key={`${data.type}-${data.id}`}
          type={data.type}
          images={data.images}
        />
      );
    case 'post':
      return (
        <StreamPostCard
          watches={0}
          tags={data.tags}
          published_at={data.published_at}
          id={data.id}
          excerpt={data.excerpt}
          commentsCount={data.comments_count || 0}
          author={data.user}
          key={`${data.type}-${data.id}`}
          type={data.type}
          images={data.images}
          updateFeed={updateFeed}
        />
      );
    case 'suggestion':
      return (
        <StreamNewsCard
          watches={0}
          title={data.title}
          tags={data.tags}
          published_at={data.published_at}
          organization={data.organization}
          id={data.id}
          excerpt={data.excerpt}
          commentsCount={data.comments_count || 0}
          author={data.user}
          key={`${data.type}-${data.id}`}
          type={data.type}
          images={data.images}
        />
      );
    default:
      return (
        <StreamPostCard
          watches={0}
          tags={data.tags}
          published_at={data.published_at}
          id={data.id}
          excerpt={data.excerpt}
          commentsCount={data.comments_count || 0}
          author={data.user}
          key={`${data.type}-${data.id}`}
          type={data.type}
          images={data.images}
          updateFeed={updateFeed}
        />
      );
  }
};
