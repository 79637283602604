import styles from './SideToolbar.module.scss';
import { GalleryAddIcon } from './icons/GalleryAddIcon';
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone';
import { Dispatch, SetStateAction } from 'react';
import { EmojiIcon } from './icons/EmojiIcon';

type Props = {
  top: number;
  onAddImage: <T extends File>(acceptedFiles: T[], fileRejections: FileRejection[], event: DropEvent) => void;
  setShowEmoji: Dispatch<SetStateAction<boolean>>;
};

export function SideToolbar({ top, onAddImage, setShowEmoji }: Props) {
  const acceptedFileTypes = {
    'image/png': ['.png'],
    'image/jpg': ['.jpg'],
    'image/jpeg': ['.jpeg'],
  };

  const { getInputProps, open } = useDropzone({
    onDrop: onAddImage,
    noClick: true,
    maxFiles: 1,
    accept: acceptedFileTypes,
  });

  return (
    <div className={styles['side-toolbar']} style={{ top: top - 2 }}>
      <input {...getInputProps()} />
      <button className={styles['side-toolbar-btn']} onClick={open}>
        <GalleryAddIcon />
      </button>
      <button className={styles['side-toolbar-btn']} onClick={() => setShowEmoji((val) => !val)}>
        <EmojiIcon />
      </button>
    </div>
  );
}
