import Chip from './components/Chip/Chip';
import Dropdown from './components/Dropdown/Dropdown';
import styles from './Legend.module.scss';
import { useTranslation } from 'react-i18next';

interface LegendProps {
  options: { title: string; color: string }[];
}
const Legend = ({ options }: LegendProps) => {
  const { t } = useTranslation('content');
  return (
    <div className={styles.legend}>
      <Dropdown direction="down" title={<p className={styles.dropdownTitle}>{t('activity')}</p>}>
        <div className={styles.dropdownList}>
          {options.map(({ title, color }, index) => (
            <Chip key={index} title={title} color={color} />
          ))}
        </div>
      </Dropdown>
    </div>
  );
};

export default Legend;
