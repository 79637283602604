import classNames from 'classnames';
import React, { ReactElement, ReactNode, SyntheticEvent } from 'react';
import MainDropdownButton from './components/MainDropdownButton/MainDropdownButton';
import styles from './Dropdown.module.scss';
import useToggle from './hooks/useToggle';
import { ReactComponent as ArrowDown } from 'shared/public/icons/arrow-bottom.svg';

interface DropdownProps {
  icon?: ReactNode;
  title?: ReactNode;
  direction?: 'up' | 'down';
  children?: ReactNode;
  className?: string;
  onClick?: (e: SyntheticEvent) => void;
}

const Dropdown = ({ className, children, direction, title, icon, onClick }: DropdownProps): ReactElement => {
  const [isOpened, toggleIsOpened] = useToggle();

  const handleMailButtonClick = (e: SyntheticEvent): void => {
    onClick?.(e);
    toggleIsOpened();
  };
  return (
    <div className={classNames(styles.dropdown, className)}>
      <MainDropdownButton
        className={styles.mainDropdownButton}
        title={title}
        icon={icon || <ArrowDown width={16} />}
        state={isOpened ? 'active' : undefined}
        onClick={handleMailButtonClick}
      >
        {isOpened ? children : title}
      </MainDropdownButton>
    </div>
  );
};

export default Dropdown;
