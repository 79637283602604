import React from 'react';
import { Helmet } from 'react-helmet';

interface ISeo {
  title: string;
  description?: string;
  creator?: string;
  type?: string;
  image: string;
}

export default function SEO({ title, description, creator, type = 'website', image }: ISeo) {
  return (
    <Helmet>
      <meta name="robots" content="index, follow" />
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      {description && <meta name="description" content={description} />}
      <meta property="og:description" content={description} />
      {image && <meta property="og:image" content={image} />}
      {/* End Facebook tags */}
      {/* Twitter tags */}
      {creator && <meta name="twitter:creator" content={creator} />}
      <meta name="twitter:card" content={type} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {/* End Twitter tags */}
      <title>{title}</title>
    </Helmet>
  );
}
