import { Slider } from 'common/Slider/Slider';
import { KbaseMaterialFront, KbaseSection } from 'shared/interfaces/kbase';
import { isSSR } from 'utils/isSSR';
import { KbaseDataTypes, KbaseGroupDirection, KbaseVariety } from '../KbaseGroupContainer/KbaseGroupContainer';
import SectionCard from '../SectionCard/SectionCard';
import styles from './KbaseGroup.module.scss';
import InfiniteScrollList from 'components/InfiniteScrollList/InfiniteScrollList';
import { useContext } from 'react';
import { GlobalContext } from 'contexts/GlobalContext';
import { CardsAssemble } from 'components/Crads/CardsAssemble/CardsAssemble';
import { useTranslation } from 'react-i18next';

interface PropsKbaseGroup {
  hasMoreData?: boolean;
  loadData?: () => void;
  materials?: KbaseMaterialFront[];
  sections?: KbaseSection[];
  title?: string;
  type?: KbaseDataTypes;
  direction?: KbaseGroupDirection;
  variety: KbaseVariety;
}

const KbaseGroup = ({
  loadData,
  hasMoreData,
  materials,
  variety,
  sections,
  title,
  type,
  direction = KbaseGroupDirection.slider,
}: PropsKbaseGroup) => {
  const { t } = useTranslation('kbase');
  const {
    services: { deviceService },
    screen,
  } = useContext(GlobalContext);
  const isMobile = deviceService.isMobile || screen.innerWidth <= 1024;
  const isMaterials = variety === KbaseVariety.material && !!materials.length;
  const noDataMessage = isMaterials ? t('articles') : t('sections');
  const formatArr = (arr: KbaseMaterialFront[]) => {
    const result: KbaseMaterialFront[][] = [];
    const size = 10;
    arr.forEach((el, index) => {
      if (!result[Math.floor(index / size)]) {
        result[Math.floor(index / size)] = [];
      }
      result[Math.floor(index / size)].push(el);
    });
    return result;
  };
  const kbaseGroupData = isMaterials ? materials : sections;
  const kbaseGroupContentChildren = isMaterials
    ? formatArr(materials).map((el, index) => {
        return <CardsAssemble key={`newsGroup-${index}`} cards={el} isMobile={isMobile} />;
      })
    : sections.map((section) => {
        return <SectionCard key={`section-${section.id}`} section={section} />;
      });

  const kbaseGroupContent = {
    [KbaseGroupDirection.slider]: (
      <Slider
        controlsVerticalOffset={0}
        controlsHorizontalOffset={0}
        slidesOnPage={(() => {
          if (isSSR()) {
            return 2;
          }
          const clientWidth = screen.innerWidth;

          return clientWidth > 1720 ? 3 : clientWidth > 1430 ? 2 : 1;
        })()}
        children={kbaseGroupContentChildren}
      />
    ),
    [KbaseGroupDirection.grid]: (
      <InfiniteScrollList
        dataLength={kbaseGroupData?.length}
        next={() => loadData()}
        hasMore={hasMoreData}
        listContent={<div className={styles.wrapperGrid}>{kbaseGroupContentChildren}</div>}
        endMessage={`${noDataMessage} ${t('noMore')}`}
        dontFindData={`${noDataMessage} ${t('notFound')}`}
      />
    ),
    [KbaseGroupDirection.list]: (
      <InfiniteScrollList
        dataLength={kbaseGroupData?.length}
        next={() => loadData()}
        hasMore={hasMoreData}
        listContent={<div className={styles.list}>{kbaseGroupContentChildren}</div>}
        endMessage={`${noDataMessage} ${t('noMore')}`}
        dontFindData={`${noDataMessage} ${t('notFound')}`}
      />
    ),
  };

  return (
    <div className={styles.wrapper}>
      {title && <h2>{title}</h2>}
      {kbaseGroupContent[direction]}
    </div>
  );
};

export default KbaseGroup;
