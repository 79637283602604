export type IIcon = {
  [iconName in keyof typeof Icons]: {
    width: number;
    height: number;
    icon: any;
  };
};

export enum Icons {
  menu = 'menu',
  bell = 'bell',
  bellBing = 'bell-bing',
  chatting = 'chatting',
  fingerprint = 'fingerprint',
  pencil = 'pencil',
  group = 'group',
  logout = 'logout',
  out = 'out',
  like = 'like',
  close = 'close',
  facebook = 'facebook',
  instagram = 'instagram',
  ok = 'ok',
  telegram = 'telegram',
  vk = 'vk',
  email = 'email',
  profile = 'profile',
  user = 'user',
  unions = 'unions',
  union = 'union',
  chat = 'chat',
  news = 'news',
  favoriteChart = 'favoriteChart',
  securitySafe = 'securitySafe',
  briefcase = 'briefcase',
  notificationBell = 'notificationBell',
  notificationChat = 'notificationChat',
  notificationFavoriteChart = 'notificationFavoriteChart',
  setting = 'setting',
  lampOn = 'lampOn',
  noteAdd = 'noteAdd',
  tickSquare = 'tickSquare',
  teacher = 'teacher',
  presentionChart = 'presentionChart',
  taskSquare = 'taskSquare',
  security = 'security',
  alliance = 'alliance',
  laborUnion = 'laborUnion',
  logo = 'logo',
  logoSmall = 'logoSmall',
  logoText = 'logoText',
  link = 'link',
  arrowRight = 'arrowRight',
  arrowLeft = 'arrowLeft',
  arrowBorderLeft = 'arrowBorderLeft',
  arrowBottom = 'arrowBottom',
  document = 'document',
  check = 'check',
  key = 'key',
  settings = 'settings',
  exclamation = 'exclamation',
  noImage = 'noImage',
  bulb = 'bulb',
  iconSwipeTraining = 'iconSwipeTraining',
  warning = 'warning',
  burgerWhite = 'burgerWhite',
  checked = 'checked',
  star = 'star',
  message = 'message',
  share = 'share',
  burger = 'burger',
  userAdd = 'iconUserAdd',
  edit = 'edit',
  bomb = 'bomb',
  entryChat = 'entryChat',
  paypal = 'paypal',
  patreon = 'patreon',
  stripe = 'stripe',
  other = 'other',
  light = 'light',
  wallet = 'wallet',
  verification = 'verification',
  contacts = 'contacts',
  searchNews = 'searchNews',
  finance = 'finance',
  dislike = 'dislike',
  circleClose = 'circleClose',
  polls = 'polls',
  infoCircle = 'infoCircle',
  clock = 'clock',
  comments = 'comments',
  eye = 'eye',
  eyeClose = 'eye-close',
  hashtag = 'hashtag',
  newUser = 'newUser',
  location = 'location',
  calendar = 'calendar',
  googleCalendar = 'google-calendar',
  properties = 'properties',
  trash = 'trash',
  ssiWallet = 'ssiWallet',
  ssiWalletInbox = 'ssiWalletInbox',
  translate = 'translate',
  addSquare = 'addSquare',
  autopost = 'autopost',
  add = 'add',
}
