import { CropperModal } from 'components/Modals/CropperModal/CropperModal';
import { GlobalContext } from 'contexts/GlobalContext';
import { useContext, useEffect } from 'react';
import styles from './CroppedImage.module.scss';

type CroppedImageProps = {
  image: string;
  setImage: (src: string) => void;
  deleteImage: () => void;
  getDefaultImage: () => string;
};

export const CroppedImage = ({ image, deleteImage, setImage, getDefaultImage }: CroppedImageProps) => {
  useEffect(() => {
    openCropper(image as string, true);
  }, []);
  const { openModal, closeModal } = useContext(GlobalContext);

  const openCropper = (src: string, isCancelDisabled: boolean = false) => {
    openModal({
      params: {
        mainContainer: (
          <CropperModal
            onAccept={(src) => saveChanges(src)}
            onDelete={deleteImage}
            onCancel={
              !isCancelDisabled
                ? () => {
                    closeModal();
                    const img = getDefaultImage();
                    openCropper(img);
                  }
                : undefined
            }
            src={src}
            aspectRatio={16 / 9}
          />
        ),
      },
    });
    return;
  };
  const saveChanges = (src: string) => {
    closeModal();
    setImage(src);
  };

  return (
    <div
      onClick={() => openCropper(image)}
      className={styles.imageWrapper}
      /* key={`new-post-image-${index}`} */
    >
      <img src={image} alt={`new-post`} className={styles.image} />
    </div>
  );
};
