import cn from 'classnames';
import InfiniteScrollList from 'components/InfiniteScrollList/InfiniteScrollList';
import { GlobalContext } from 'contexts/GlobalContext';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getOrgFeed } from 'services/feed';
import { IContentPreview } from 'shared/interfaces/feed';
import formatServerError from 'utils/formatServerError';
import styles from './FeedLayout.module.scss';
import NoPost from './NoPost/NoPost';
import { StreamCardsAssignment } from 'components/Crads/StreamCards/StreamCardsAssignment';
import { useTranslation } from 'react-i18next';

interface IFeedLayout {
  isOpenOrgInfo: boolean;
  isOpenStream: boolean;
  organizationId: number;
}

export const FeedLayout = ({ isOpenOrgInfo, organizationId, isOpenStream }: IFeedLayout) => {
  const { t } = useTranslation('profile');
  const [content, setContent] = useState<IContentPreview[]>([]);
  const [isMore, setIsMore] = useState<boolean>(true);

  const {
    isMounted,
    spinner: { showSpinner, hideSpinner },
  } = useContext(GlobalContext);

  async function getContent(time?: Date, isNew?: boolean) {
    try {
      const response = await getOrgFeed({ beforeTime: time, organizationId: organizationId });
      if (response?.length !== 10) {
        setIsMore(false);
      } else {
        setIsMore(true);
      }
      if (isNew) {
        setContent(response);
      } else {
        setContent([...content, ...response]);
      }
    } catch (e) {
      toast.error(formatServerError(e));
    }
  }

  useEffect(() => {
    if (isMounted) {
      (async () => {
        try {
          showSpinner();
          await getContent(new Date(), true);
        } finally {
          hideSpinner();
        }
      })();
    }
  }, [isMounted, organizationId]);

  return (
    <div
      className={cn(styles.wrapper, {
        [styles.fullWidthWrapper]: isOpenStream && !isOpenOrgInfo,
        [styles.hide]: !isOpenStream,
      })}
      id="feed-org-wrapper"
    >
      {content?.length ? (
        <InfiniteScrollList
          dataLength={content.length}
          next={() => getContent(content[content.length - 1].published_at)}
          scrollableTarget="feed-org-wrapper"
          hasMore={isMore}
          listContent={
            <div className={styles.content}>
              {content.map((el, index) => (
                <StreamCardsAssignment key={`stream-card-${el.type}-${index}`} data={el} />
              ))}
            </div>
          }
          dontFindData={t('feed.noPost.title')}
        />
      ) : (
        <NoPost />
      )}
    </div>
  );
};
