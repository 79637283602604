import { GlobalContext } from 'contexts/GlobalContext';
import { GlobalDataContext } from 'contexts/GlobalDataContext';
import { IUser } from 'interfaces/user.interface';
import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { routes } from 'Routes';
import { Icon } from 'shared/components/common/Icon/Icon';
import { Icons } from 'shared/components/common/Icon/Icon.interface';
import styles from './UserButton.module.scss';
import { useTranslation } from 'react-i18next';
import { UserLanguageSelect } from './UserLanguageSelect/UserLanguageSelect';

interface IMenu {
  title: string;
  icon?: keyof typeof Icons;
  link?: string;
  action?: () => void;
  disabled?: boolean;
}

const { REACT_APP_ADMIN_URL } = process.env;

const UserButton = ({ user }: { user?: IUser }) => {
  const { t } = useTranslation('roy-global');
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);

  const { setUser } = useContext(GlobalDataContext);
  const {
    services: { authService },
    spinner: { showSpinner, hideSpinner },
  } = useContext(GlobalContext);

  const history = useHistory();

  const logoutAction = async () => {
    try {
      showSpinner();
      await authService.logout();
      history.push(routes.LOGIN);
      setUser(null);
      window.location.reload();
    } catch (e) {
    } finally {
      hideSpinner();
    }
  };

  const noUserMenu: IMenu[] = [
    {
      title: 'languages',
    },
    {
      title: t('buttons.login'),
      link: routes.LOGIN,
    },
    {
      title: t('buttons.registration'),
      link: routes.REGISTRATION,
    },
  ];

  const userMenu: IMenu[] = [
    {
      title: t('pages.admin'),
      icon: 'securitySafe',
      disabled: user?.administer.length === 0,
      action: () => {
        if (user.administer.length === 0) {
          return;
        }
        const token = localStorage?.getItem('token');
        window.dataLayer.push({
          event: 'event',
          eventProps: {
            category: 'user',
            action: 'click',
            label: 'Admin dashboard button',
          },
        });
        window.open(`${REACT_APP_ADMIN_URL}/signin?token=${token}`, 'roy-team-admin');
      },
    },
    {
      title: 'languages',
    },
    {
      title: t('pages.security'),
      icon: 'security',
      link: routes.SECURITY,
    },
    {
      title: t('pages.settings'),
      icon: 'setting',
      link: routes.settings,
    },
    {
      title: t('buttons.logout'),
      icon: 'out',
      action: logoutAction,
    },
  ];
  return (
    <button className={styles.userButton} onClick={() => setIsOpenMenu((prev) => !prev)}>
      <Icon iconName="newUser" />
      {isOpenMenu &&
        (!!user ? (
          <div className={styles.menu}>
            {userMenu.map((item, idx) => {
              if (item.disabled) {
                return null;
              }
              if (item.title === 'languages') {
                return <UserLanguageSelect />;
              }
              return (
                <button
                  key={idx}
                  onClick={() => {
                    if (item.link) {
                      history.push(item.link);
                    } else {
                      item.action();
                    }
                  }}
                  className={styles.item}
                >
                  <Icon iconName={item.icon} className={styles.icon} width={20} height={20} />
                  <span className={styles.title}>{item.title}</span>
                </button>
              );
            })}
          </div>
        ) : (
          <div className={styles.menu}>
            {noUserMenu.map((item, idx) => {
              if (item.title === 'languages') {
                return <UserLanguageSelect />;
              }
              return (
                <button
                  key={idx}
                  onClick={() => {
                    if (item.link) {
                      history.push(item.link);
                    } else {
                      item.action();
                    }
                  }}
                  className={styles.item}
                >
                  {item.icon && <Icon iconName={item.icon} className={styles.icon} width={20} height={20} />}
                  <span className={styles.title}>{item.title}</span>
                </button>
              );
            })}
          </div>
        ))}
    </button>
  );
};

export default UserButton;
