import { Dispatch, SetStateAction, useRef, useState } from 'react';
import debounce from 'debounce';

const DEBOUNCE_TIME = 400;

type PointType = { lat: number; lng: number };

type Props = {
  token: string;
  point: PointType;
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
  setPoint: Dispatch<SetStateAction<PointType>>;
};

type SuggestionsType = {
  id: string;
  type: string;
  place_type: Array<string>;
  relevance: number;
  properties: {
    mapbox_id: string;
    wikidata: string;
    short_code: string;
  };
  text: string;
  place_name: string;
  bbox: Array<string>;
  center: [number, number];
  geometry: {
    type: string;
    coordinates: [number, number];
  };
  context: [
    {
      id: string;
      mapbox_id: string;
      wikidata: string;
      short_code: string;
      text: string;
    }
  ];
};

const useGeocoder = ({ setValue, token, value, point, setPoint }: Props) => {
  const [suggestions, setSuggestions] = useState<Array<SuggestionsType>>([]);
  const [suggestion, setSuggestion] = useState<SuggestionsType>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleChangeValue = async (val: string) => {
    try {
      setIsLoading(true);
      setValue(val || '');
      const endpoint = `https://api.mapbox.com/geocoding/v5/mapbox.places/${val || ''}.json?access_token=${token}&autocomplete=true`;
      const response = await fetch(endpoint);
      const results = (await response.json()) as { features: Array<SuggestionsType> };
      if (results && results.features) {
        setSuggestions(results.features);
      }
    } catch (error) {
      console.log('Error fetching data, ', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangePoint = async (val: PointType) => {
    try {
      setIsLoading(true);
      if (val?.lat && val?.lng) {
        const endpoint = `https://api.mapbox.com/geocoding/v5/mapbox.places/${val.lng || ''},${
          val.lat || ''
        }.json?access_token=${token}&autocomplete=true`;
        const response = await fetch(endpoint);
        const results = (await response.json()) as { features: Array<SuggestionsType> };
        setSuggestions(results?.features);
        if (results && results.features.length) {
          setSuggestion(results.features[0]);
          setPoint(val);
          setValue(results.features[0].place_name);
        }
      }
    } catch (error) {
      console.log('Error fetching data, ', error);
    } finally {
      setIsLoading(false);
    }
  };

  const debouncedHandleChangeValue = useRef(
    debounce((value: string) => {
      handleChangeValue(value);
    }, DEBOUNCE_TIME)
  ).current;

  const debouncedHandleChangePoint = useRef(
    debounce((value: PointType) => {
      handleChangePoint(value);
    }, DEBOUNCE_TIME)
  ).current;

  const setSuggestionHandle = (val: SuggestionsType) => {
    setSuggestion(val);
    setPoint({ lat: val.geometry.coordinates[1], lng: val.geometry.coordinates[0] });
  };

  return {
    value,
    onChangeValue: debouncedHandleChangeValue,
    onChangePoint: debouncedHandleChangePoint,
    setValue,
    suggestions,
    setSuggestions,
    isLoading,
    suggestion,
    setSuggestion: setSuggestionHandle,
  };
};

export default useGeocoder;
