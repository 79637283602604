import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import styles from './LanguagesMenu.module.scss';
import { languages } from 'shared/constants/languages';

type LanguagesMenuProps = {
  toggleOpenMenu: (isOpen: boolean) => void;
};

export const LanguagesMenu = ({ toggleOpenMenu }: LanguagesMenuProps) => {
  const { i18n } = useTranslation();
  const [selectedLangCode, setSelectedLangCode] = useState(i18n.language);
  const selectedLang = languages.find((lang) => lang.code === selectedLangCode);

  useEffect(() => {
    if (!selectedLang) {
      handleLanguageChange('en');
    }
  }, []);

  const handleLanguageChange = (langCode: string) => {
    i18n.changeLanguage(langCode);
    setSelectedLangCode(langCode);
  };

  const handleSelectLanguage = (langCode: string) => {
    handleLanguageChange(langCode);
    toggleOpenMenu(false);
  };

  return (
    <div className={styles.overlay}>
      <div className={styles.selectList}>
        {languages.map(({ code, name }) => (
          <div
            key={code}
            onClick={() => handleSelectLanguage(code)}
            className={`${styles.selectItem} ${code === selectedLangCode ? styles.active : ''}`}
          >
            {name}
          </div>
        ))}
      </div>
    </div>
  );
};
