import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Button } from 'shared/components/common/Button/Button';
import { Input } from 'shared/components/common/Input/Input';
import { PASSWORD_PATTERN } from 'shared/components/common/Validator/validators';
import styles from './EditUserPassword.module.scss';
import { useContext, useState } from 'react';
import { GlobalContext } from 'contexts/GlobalContext';
import { GlobalDataContext } from 'contexts/GlobalDataContext';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

interface IPassword {
  password?: string;
  confirmPassword?: string;
}

const EditUserPassword = () => {
  const { t } = useTranslation('settings');
  const INITIAL_VALUES = {
    password: '',
    confirmPassword: '',
  };
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, `${t('errors.min')} 8 ${t('errors.symbols')}`)
      .max(50, `${t('errors.max')} 50 ${t('errors.symbols')}`)
      .matches(PASSWORD_PATTERN, t('errors.notValidPassword')),
    _confirm_password: Yup.string().oneOf([Yup.ref('password'), null], t('errors.confirm')),
  });

  const [passwordValues, setPasswordValues] = useState<IPassword>(INITIAL_VALUES);

  const {
    services: { userService },
    spinner: { showSpinner, hideSpinner },
  } = useContext(GlobalContext);
  const { setUser, user } = useContext(GlobalDataContext);

  const onUpdatePassword = async (password: string): Promise<boolean> => {
    const result = await userService.updatePassword(password);
    setPasswordValues(INITIAL_VALUES);

    return result.ok;
  };

  const { errors, touched, values, handleSubmit, setFieldValue, validateForm, setFieldTouched } = useFormik({
    initialValues: passwordValues as IPassword,
    validationSchema: validationSchema,
    validate: () => {
      const errors: { [key: string]: string } = {};

      return errors;
    },
    onSubmit: async (values, actions) => {
      showSpinner();

      if (values.password && values.confirmPassword) {
        const result = await onUpdatePassword(values.password);
        if (result) {
          actions.setFieldValue('password', '');
          actions.setFieldValue('confirmPassword', '');
        }
      }
      toast(t('toast.updatePassword'));
      const user = await userService.getUser();
      setUser(user);
      hideSpinner();
    },
  });
  if (user.email.substring(user.email.length - 4).toLowerCase() === '@ssi') {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <h3>{t('password.title')}</h3>

      <Input
        name="password"
        type="password"
        valueChange={(value) => {
          setFieldValue('password', value.trim(), true);
        }}
        value={values.password}
        label={t('password.input.password.label')}
        autoComplete="new-password"
        description={t('password.input.password.description')}
        onTouch={setFieldTouched.bind(null, 'password')}
        error={
          (errors?.password && touched?.password && errors.password) ||
          (values.confirmPassword.length >= values.password.length && values.confirmPassword !== values.password && t('errors.confirm')) ||
          (values.confirmPassword.length > 0 && values.password.length < 8 && `${t('errors.min')} 8 ${t('errors.symbols')}`)
        }
      />

      <Input
        name="confirmPassword"
        type="password"
        valueChange={(value) => {
          setFieldValue('confirmPassword', value.trim(), true);
          if (!touched.confirmPassword) {
            setFieldTouched('confirmPassword');
          }
        }}
        value={values.confirmPassword}
        label={t('password.input.confirm.label')}
        description={t('password.input.confirm.description')}
        onTouch={() => setFieldTouched('confirmPassword')}
        error={
          (errors?.confirmPassword && touched?.confirmPassword && errors.confirmPassword) ||
          (values.confirmPassword.length >= values.password.length && values.confirmPassword !== values.password && t('errors.confirm'))
        }
      />

      <Button onClick={() => validateForm().then(() => handleSubmit())} type="submit" maxWidth>
        {t('password.input.save')}
      </Button>
    </div>
  );
};

export default EditUserPassword;
