import { CustomImage } from 'shared/components/common/CustomImage/CustomImage';
import styles from './SelectedUnion.module.scss';

type Props = {
  short_name: string;
  avatar?: string;
};

export const SelectedUnion = ({ short_name, avatar }: Props) => {
  return (
    <div className={styles.wrapper}>
      <CustomImage isOrganization rounded width={28} height={28} src={avatar} alt={short_name} />
      <p>{short_name}</p>
    </div>
  );
};
