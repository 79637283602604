import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { routes } from 'Routes';
import { Icon } from 'shared/components/common/Icon/Icon';
import styles from './ProfileModalItem.module.scss';
import { useSsrEffect, useSsrState } from '@issr/core';
import { Icons } from 'shared/components/common/Icon/Icon.interface';
import { GlobalContext } from 'contexts/GlobalContext';
import { useContext, useState } from 'react';
import { CustomImage } from 'shared/components/common/CustomImage/CustomImage';
import { useTranslation } from 'react-i18next';

type OrganizationItemProps = {
  image: string;
  id: number;
  isOrganization: boolean;
  name: string;
  action?: React.MouseEventHandler<HTMLDivElement>;
  actionTitle?: string;
  iconName?: keyof typeof Icons;
  isCollapsible?: boolean;
};

export const ProfileModalItem = ({
  id,
  image,
  isOrganization,
  name,
  action,
  iconName,
  isCollapsible = false,
  actionTitle,
}: OrganizationItemProps) => {
  const { t } = useTranslation('content');
  const { closeModal } = useContext(GlobalContext);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [controlsValues, setControlsValues] = useSsrState<Array<{ title: string; isActive: boolean }>>([]);

  const mockData: Array<{ title: string; isActive: boolean }> = [
    { isActive: false, title: t('buttons.selectAll') },
    { isActive: false, title: t('match.news') },
    { isActive: false, title: t('match.material') },
    { isActive: false, title: t('match.event') },
    { isActive: false, title: t('match.vacancy') },
    { isActive: false, title: t('match.donate') },
    { isActive: false, title: t('match.task') },
    { isActive: false, title: t('match.suggestion') },
    { isActive: false, title: t('match.quiz') },
  ];
  useSsrEffect(() => {
    // todo: get data from server
    setControlsValues(JSON.parse(JSON.stringify(mockData)));
  });
  const onChange = (index: number, value: boolean) => {
    const values = [...controlsValues];
    if (index === 0) {
      values.forEach((el) => (el.isActive = value));
    }
    values[index].isActive = value;
    setControlsValues(values);
  };

  return (
    <div className={classNames({ [styles.hide]: isCollapsed }, { [styles.show]: !isCollapsed }, styles.cardContainer)}>
      <div className={styles.element}>
        <Link onClick={closeModal} className={styles.content} to={isOrganization ? routes.union.getLink(id) : routes.profile.getLink(id)}>
          <CustomImage isOrganization={isOrganization} className={styles.image_border} width={56} height={56} alt={name} src={image} />
          <h5>{name}</h5>
        </Link>
        <div className={styles.content}>
          {iconName && action && (
            <div title={actionTitle} className={classNames(styles.button, styles.nonpermanent)} onClick={action}>
              {!!actionTitle && <p className={styles.text}>{actionTitle}</p>}
              <Icon color="black" height={30} width={30} iconName={iconName} />
            </div>
          )}
          {isCollapsible && (
            <div
              className={classNames(styles.button, styles.nonpermanent)}
              onClick={(ev) => {
                ev.stopPropagation();
                ev.preventDefault();
                setIsCollapsed(!isCollapsed);
              }}
            >
              <Icon color="black" height={20} width={20} rotate={isCollapsed ? 0 : 180} iconName="arrowBottom" />
            </div>
          )}
        </div>
      </div>
      {isCollapsible && !isCollapsed && (
        <div className={styles.controls}>
          {controlsValues.map((el, index) => {
            return (
              <div className={styles.control}>
                <input
                  className={styles.checkbox}
                  onChange={() => {
                    onChange(index, !el.isActive);
                  }}
                  checked={el.isActive}
                  type={'checkbox'}
                />
                <p>{el.title}</p>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
