import { useContext, useEffect } from 'react';
import { Task, TaskInfo } from './Task';
import { GlobalContext } from 'contexts/GlobalContext';
import { toast } from 'react-toastify';
import formatServerError from 'utils/formatServerError';
import { useParams } from 'react-router-dom';
import { getOrgTask } from 'shared/services/tasks.service';
import { useSsrEffect, useSsrState } from '@issr/core';
import styles from '../NewsDetails/NewsDetails.module.scss';
import { RoutingContainer } from 'common/Routing/RoutingContainer/RoutingContainer';
import ContentLayout from 'containers/ContentLayout/ContentLayout';
import { routes } from 'Routes';
import { useTranslation } from 'react-i18next';

export function TaskContainer() {
  const { t } = useTranslation('polls');
  const {
    spinner: { showSpinner, hideSpinner },
  } = useContext(GlobalContext);
  const { orgId, taskId } = useParams<{ orgId: string; taskId: string }>();

  const [serverError, setServerError] = useSsrState(null);

  const [task, setTask] = useSsrState<TaskInfo>(null);

  const fetchTask = async () => {
    const {
      data: { data },
    } = await getOrgTask(orgId, taskId);
    setTask(data);
  };

  useSsrEffect(async () => {
    try {
      await fetchTask();
    } catch (error) {
      setServerError(formatServerError(error));
    }
  });

  useEffect(() => {
    (async () => {
      try {
        showSpinner();
        await fetchTask();
      } catch (error) {
        toast.error(formatServerError(error));
      } finally {
        hideSpinner();
      }
    })();
  }, []);

  return (
    <RoutingContainer
      showCloseButton
      // logo={{ src: task?.organization?.avatar, alt: task?.organization?.short_name }}
      title={task?.organization?.short_name || task?.organization?.name}
      // hideFooter
      // showScrollToTopButton
      className={styles.main}
    >
      {!!serverError && <p className="danger">{serverError}</p>}
      {task && (
        <ContentLayout
          title={task.title}
          contentId={task.id}
          orgId={task.organization.id}
          contentType={'task'}
          content={task.description || t('noDescription')}
          user={{
            id: task.user.id,
            public_avatar: task.user.public_avatar,
            public_family: task.user.public_family,
            public_name: task.user.public_name,
          }}
          published_at={task.created_at}
          logo={{
            alt: task.organization.short_name,
            src: task.organization.avatar,
            href: routes.union.getLink(task.organization.id),
          }}
          uniqueContent={<Task task={task} />}
        />
      )}
    </RoutingContainer>
  );
}
