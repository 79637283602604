import { useCallback, useEffect, useRef, useState } from 'react';
import styles from './Editor.module.scss';
import { toast } from 'react-toastify';
import { get, last } from 'lodash';
import { useParams } from 'react-router';
// @ts-ignore
import { uploadImage } from 'services/api/news';
import { uploadImage as uploadImageEvents } from 'services/api/events';
// TODO: lib styles move somewhere
import 'react-quill/dist/quill.bubble.css';
import { SideToolbar } from './SideToolbar/SideToolbar';
import { removeLinkPlaceholderToLine, isEmptyLineByIndex, insertLink, insertImage, deletePasteImage } from './helpers';
import formatServerError from 'shared/utils/formatServerError';
// @ts-ignore
import { uploadImageMaterial } from 'services/api/kbase';
import cn from 'classnames';
import { Spinner } from '../common/Spinner/Spinner';
import { allowedSizeFiles, toLargeFileSize } from 'shared/constants/allowedFileSize';
import { useTranslation } from 'react-i18next';
import { addGeneralImage } from 'services/api/genereal';

type EditorProps = {
  title: string;
  content: string;
  onChange: (type: 'title' | 'content', title: string) => void;
  isMaterial?: boolean;
  isAdmin?: boolean;
  isEvents?: boolean;
};

let ReactQuill;
let block;

export default function Editor({ title, content, onChange, isMaterial, isEvents, isAdmin = false }: EditorProps) {
  const { t, i18n } = useTranslation('shared');
  const [sideBarTop, setSideBarTop] = useState(0);
  const [showSideToolbar, setShowSideToolbar] = useState(false);
  const [showSideBarPlaceholder, setShowSideBarPlaceholder] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(0);
  const [updateRef, setUpdateRef] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const contentRef = useRef(null);
  const titleRef = useRef(null);
  const { organizationId } = useParams<{ organizationId?: string; newsId?: string }>();

  useEffect(() => {
    setIsLoading(true);
    // not working with SSR
    // https://github.com/zenoamaro/react-quill/issues/389
    ReactQuill = require('react-quill');

    setForceUpdate(Math.random());
    setTimeout(() => {
      setUpdateRef(Math.random());
      setIsLoading(false);
    }, 1000);
    if (!block) {
      require('./block');
    }
  }, []);

  useEffect(() => {
    document.querySelector('.ql-tooltip-editor input')?.setAttribute('data-link', 'http://...');
  }, [forceUpdate]);

  const addLinkPlaceholderToLine = (editor, index: number) => {
    //todo: i18n
    const [block] = editor.getLine(index);
    if (block) {
      block.domNode.dataset.placeholder = t('editor.reactQuill.addLink');
    }
  };

  const handleAddImage = useCallback(
    (e, isPaste?: boolean) => {
      if (!isPaste ? e.target.files[0].size > allowedSizeFiles : e.size > allowedSizeFiles) {
        toast.error(toLargeFileSize);
        return;
      }
      setIsLoading(true);

      setShowSideToolbar(false);
      const fileReader = new FileReader();
      const { editor } = contentRef?.current;
      fileReader.readAsDataURL(!isPaste ? e.target.files[0] : e);
      fileReader.onload = async () => {
        try {
          contentRef?.current?.focus();
          if (!organizationId) {
            const { url } = (await addGeneralImage(fileReader.result))[0];
            insertImage(editor, url);
          } else {
            if (isMaterial) {
              const {
                data: { url },
              } = await uploadImageMaterial(organizationId, fileReader.result);
              insertImage(editor, url);
            } else if (isEvents) {
              const {
                data: { url },
              } = await uploadImageEvents(organizationId, fileReader.result);
              insertImage(editor, url);
            } else {
              const {
                data: { url },
              } = await uploadImage(organizationId, fileReader.result);
              insertImage(editor, url);
            }
          }

          setShowSideToolbar(false);
        } catch (error) {
          toast.error(formatServerError(error));
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      };
    },
    [isMaterial, organizationId, isEvents]
  );

  const handlePasteContent = useCallback(
    (e) => {
      const item = e.clipboardData.items[0];
      const { editor } = contentRef?.current;
      if (item.type.indexOf('image') === 0 || item.type === 'text/html') {
        contentRef?.current?.focus();
        setIsLoading(true);
        var image = item.getAsFile();
        deletePasteImage(editor);
        setTimeout(() => {
          if (!!image) {
            handleAddImage(image, true);
          } else {
            toast.error(t('toast.cantPaste'));
            setIsLoading(false);
          }
        }, 310);
      }
    },
    [handleAddImage]
  );

  useEffect(() => {
    if (!!contentRef.current && !!contentRef.current?.editingArea) {
      const contentElement = contentRef.current.editingArea;

      contentElement?.addEventListener('paste', handlePasteContent);
      return () => {
        contentElement?.removeEventListener('paste', handlePasteContent);
      };
    }
  }, [updateRef, handlePasteContent]);

  useEffect(() => {
    if (!!titleRef?.current) {
      titleRef.current.style.height = 'auto';
      titleRef.current.style.height = titleRef?.current.scrollHeight + 'px';
    }
  }, [updateRef]);

  const handleShowSideToolbar = (unprivilegedEditor, range) => {
    setShowSideBarPlaceholder(false);
    if (range) {
      const { editor } = contentRef.current;
      if (isEmptyLineByIndex(editor, range.index)) {
        const { top } = unprivilegedEditor.getBounds(range.index);
        setSideBarTop(top);
        setShowSideToolbar(true);
      } else {
        setShowSideToolbar(false);
      }
    }
  };

  const handleChangeContent = (content, _delta, _source, unprivilegedEditor) => {
    onChange('content', content);

    const range = unprivilegedEditor.getSelection();
    handleShowSideToolbar(unprivilegedEditor, range);

    if (showSideBarPlaceholder) {
      setShowSideBarPlaceholder(false);
    }
  };

  const handleChangeSelection = (range, _source, unprivilegedEditor) => {
    handleShowSideToolbar(unprivilegedEditor, range);
  };

  const handleAddLink = () => {
    setShowSideToolbar(false);
    const { editor } = contentRef?.current;
    editor.root.classList.remove('ql-blank');

    const position = editor.getSelection().index;
    addLinkPlaceholderToLine(editor, position);
    contentRef?.current?.focus();

    editor.once('text-change', (delta) => {
      removeLinkPlaceholderToLine(editor, position);

      const value = get(last(delta.ops), 'insert', '');
      editor.history.undo();
      insertLink(editor, value, position);
    });

    editor.once('selection-change', () => {
      removeLinkPlaceholderToLine(editor, position);
    });
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      const { index } = contentRef.current.getEditorSelection();
      const [block] = contentRef.current.editor.getLine(index);

      block.domNode.scrollIntoView({ block: 'center' });
    }
  };

  const modules = {
    toolbar: [['bold', 'italic', 'link', { header: 1 }, { header: 2 }, 'blockquote']],
  };
  if (!ReactQuill) {
    return <div></div>;
  }
  return (
    <div className={styles['content-editor']}>
      <div
        className={cn(styles.wrapperTitle, {
          [styles.adminWrapperTitle]: !!isAdmin,
        })}
      >
        <textarea
          className={styles['title-input']}
          ref={titleRef}
          placeholder={t('editor.textarea.placeholder')}
          value={title}
          onChange={(e) => {
            onChange('title', e.target.value);
            e.target.style.height = 'auto';
            e.target.style.height = e.target.scrollHeight + 'px';
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();

              contentRef?.current?.focus();
            }
          }}
        />
      </div>
      {!!isLoading && (
        <div
          className={cn(styles.wrapperSpinner, {
            [styles.frontWrapperSpinner]: !isAdmin,
          })}
        >
          <Spinner size={100} borderWidth="8px" />
        </div>
      )}

      <div className={styles['editor-container']}>
        {showSideToolbar && <SideToolbar top={sideBarTop} onAddImage={handleAddImage} onAddLink={handleAddLink} />}
        <ReactQuill
          className={styles['editor']}
          ref={contentRef}
          theme="bubble"
          placeholder={t('editor.reactQuill.placeholder')}
          value={content}
          onChangeSelection={handleChangeSelection}
          onChange={handleChangeContent}
          onBlur={() => setShowSideToolbar(false)}
          onKeyDown={handleKeyDown}
          modules={modules}
          key={i18n.language}
        />
      </div>
    </div>
  );
}
