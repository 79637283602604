import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from 'contexts/GlobalContext';
import TwoFactorAuthenticationModal from './TwoFactorAuthenticationModal/TwoFactorAuthenticationModal';
import { toast } from 'react-toastify';
import styles from './TwoFactorAuthentication.module.scss';
import Toggle from 'common/Toggle/Toggle';
import { useTranslation } from 'react-i18next';

function TwoFactorAuthentication() {
  const { t } = useTranslation('settings');
  const {
    openModal,
    services: { authService },
  } = useContext(GlobalContext);

  const [twoFaEnabled, setTwoFaEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const { enabled } = await authService.status2fa();
      setTwoFaEnabled(enabled);
      setIsLoading(false);
    })();
  }, []);

  if (isLoading) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <h3>{t('2fa')}</h3>

      <Toggle
        choice={[t('notifications.on'), t('notifications.installed')]}
        checked={twoFaEnabled}
        valueChange={async (value) => {
          if (value) {
            openModal({
              params: {
                mainContainer: <TwoFactorAuthenticationModal setTwoFaEnabled={setTwoFaEnabled} />,
              },
            });
            return;
          }

          await authService.unregister2fa();

          setTwoFaEnabled(false);
          toast(t('toast.2faStopped'));
        }}
      />
    </div>
  );
}

export default TwoFactorAuthentication;
