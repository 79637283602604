import classNames from 'classnames';
import React, { ReactNode, SyntheticEvent } from 'react';
import styles from './MainDropdownButton.module.scss';

interface MainDropdownButtonProps {
  className?: string;
  icon?: ReactNode;
  direction?: 'reversed';
  children?: ReactNode;
  title?: string | ReactNode;
  state?: 'active';
  onClick?: (e: SyntheticEvent) => void;
}

const MainDropdownButton = ({ icon, children, title, direction, state, className, ...other }: MainDropdownButtonProps): JSX.Element => {
  return (
    <button className={classNames(styles.mainDropdownButton, className, { [styles[direction]]: direction })} {...other}>
      <div className={styles.content}>{children || title}</div>
      {icon && <div className={classNames(styles.icon, { [styles[state]]: state })}>{icon}</div>}
    </button>
  );
};

export default MainDropdownButton;
