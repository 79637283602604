import { IUser } from 'interfaces/user.interface';
import { CustomImage } from 'shared/components/common/CustomImage/CustomImage';
import { allowedAttributesSchema, allowedTagsSynitizer, allowedIframeHostnamesSchema } from 'shared/constants/allowedTagsSynitizer';
import styles from './UserCard.module.scss';
import sanitizeHtml from 'sanitize-html';
import { Link, useHistory } from 'react-router-dom';
import { routes } from 'Routes';
import { FooterItem, StreamCardFooter } from 'components/Crads/StreamCardFooter/StreamCardFooter';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

type UserCardProps = {
  user: IUser;
  isMobile?: boolean;
};

export const UserCard = ({ user, isMobile }: UserCardProps) => {
  const { t } = useTranslation('profile');
  const history = useHistory();
  const footerIcons: FooterItem[] = [
    // { iconName: 'star', content: '26' },
    // { iconName: 'userAdd', onClick: () => {} },
    {
      iconName: 'chat',
      onClick: () => {
        history.push(`${routes.chat}?id=${user.id}&type=user`);
      },
    },
    {
      iconName: 'link',
      onClick: (e) => {
        e.preventDefault();
        e.stopPropagation();
        navigator.clipboard.writeText(`${process.env.REACT_APP_BASE_URL}${routes.profile.getLink(user.id)}`);
        toast(t('toast.copyLink'));
      },
    },
  ];
  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.mobileWrapper]: isMobile,
      })}
    >
      <Link className={styles.link} to={routes.profile.getLink(user.id)}>
        <CustomImage className={styles.image} height={168} width={168} alt={user.public_name} src={user.public_avatar} />
      </Link>
      <Link className={styles.link} to={routes.profile.getLink(user.id)}>
        <h4>{user.public_name}</h4>
      </Link>
      <p>
        {`${t('organization.members.role.title')} ${
          user.position_name || t(`organization.members.role.roles.${user.position_id || 'member'}`)
        }`}
      </p>
      {!!user.about && (
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(user.about, {
              allowedTags: allowedTagsSynitizer,
              allowedAttributes: allowedAttributesSchema,
              allowedIframeHostnames: allowedIframeHostnamesSchema,
            }),
          }}
        />
      )}
      <StreamCardFooter icons={footerIcons} className={styles.footerWrapper} />
    </div>
  );
};
