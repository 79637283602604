import { FooterItem } from 'components/Crads/StreamCardFooter/StreamCardFooter';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { formatDistanceToNow } from 'date-fns';
import { ru, be, enGB, lt, uk } from 'date-fns/locale';
import styles from '../ActivityCard.module.scss';
import { IUseGetActivityCardIcons } from '../props';
import { Button } from 'shared/components/common/Button/Button';
import { useContext } from 'react';
import { GlobalDataContext } from 'contexts/GlobalDataContext';
import { useHistory } from 'react-router-dom';
import { routes } from 'Routes';

const locales = {
  ru,
  be,
  enGB,
  lt,
  uk,
};

export const useGetActivityCardIcons = ({ publishedData, commentsCount, link, needRoyPass, id }: IUseGetActivityCardIcons) => {
  const { user } = useContext(GlobalDataContext);
  const { t, i18n } = useTranslation(['profile', 'kabse', 'regov-wallet-main']);
  const history = useHistory();

  const icons: FooterItem[] = [
    {
      iconName: 'clock',
      content: formatDistanceToNow(new Date(publishedData), {
        locale: locales[i18n.language],
        addSuffix: false,
      }),
    },
    { iconName: 'comments', content: `${commentsCount || 0}` },
    needRoyPass && {
      type: 'button',
      isBottomSide: true,
      button: (
        <Button
          size="small"
          color="light"
          className={styles.newButtonStyle}
          maxWidth
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (!user) {
              toast(t('regov-wallet-main:error.authenticated'));
              history.push({
                pathname: routes.LOGIN,
                search: `?redirect=${window.location.pathname + window.location.search}`,
              });
              return;
            }

            const link = `${process.env.REACT_APP_ROY_PASS_URL}events/${id}?rid=${user?.id}`;
            window.open(link, '_blank', 'noopener,noreferrer');
          }}
        >
          {t('kbase:join')}
        </Button>
      ),
    },
    link && {
      iconName: 'link',
      isRightSide: !needRoyPass,
      isBottomSide: !!needRoyPass,
      iconStyles: styles.newIconStyles,

      onClick: (e) => {
        e.preventDefault();
        e.stopPropagation();
        navigator.clipboard.writeText(`${process.env.REACT_APP_BASE_URL}${link}`);
        toast(t('profile:toast.copyLink'));
      },
    },
  ];

  return icons;
};
