import styles from './Security.module.scss';
import { Icon } from 'shared/components/common/Icon/Icon';
import { Contents, IContent } from 'common/Contents/Contents';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

// const radioOptions = [
//   { id: 1, label: '15 суток' },
//   { id: 2, label: '24 часа' },
//   { id: 3, label: '1 час' }
// ];

export function Security() {
  const { t } = useTranslation('security');
  const tableOfContents: IContent[] = [
    {
      id: 'platform-security',
      label: t('table.security.title'),
    },
    {
      id: 'encryption',
      label: t('table.encryption.title'),
    },
    /* {
      id: 'surety',
      label: t('table.surety.title'),
    }, */
  ];
  return (
    <div className={styles.Security}>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>

      <aside>
        <Contents contents={tableOfContents} />
      </aside>

      <main className="p-left p-right">
        <h2 id="platform-security">{t('title')}</h2>
        <br />
        <div className={styles.card}>
          <div className={styles.icon}>
            <Icon iconName="fingerprint" />
          </div>
          <div>
            <ul className="text-uppercase no-style large">
              <li>{`1. ${t('table.security.description.list.trustContacts')}`}</li>
              <li>{`2. ${t('table.security.description.list.encryption')}`}</li>
              <li>{`3. ${t('table.security.description.list.anonymisation')}`}</li>
              <li>{`4. ${t('table.security.description.list.access')}`}</li>
            </ul>
          </div>
        </div>

        <p>{t('table.security.description.information.title')}</p>

        <ul>
          <li>{t('table.security.description.information.servers')}</li>
          <li>{t('table.security.description.information.access')}</li>
        </ul>

        <p>{t('table.security.description.intercept.title')}</p>
        <ul>
          <li>{t('table.security.description.intercept.encryption')}</li>
        </ul>

        <p>{t('table.security.description.dataBase.title')}</p>
        <ul>
          <li>{t('table.security.description.dataBase.encryption')}</li>
        </ul>

        <br />

        <p>{t('table.security.description.trusted.title')}</p>

        <p>{t('table.security.description.bad.title')}</p>
        <ul>
          <li>{t('table.security.description.bad.safe')}</li>
          <li>{t('table.security.description.bad.inkognio')}</li>
        </ul>

        <p>{t('table.security.description.captured.title')}</p>
        <ul>
          <li>{t('table.security.description.captured.page')}</li>
        </ul>

        <br />
        <br />
        <br />
        <h2 id="encryption">{t('table.encryption.title')}</h2>
        <br />

        <div className={styles.card}>
          <div className={styles.icon}>
            <Icon iconName="fingerprint" />
          </div>
          <div>
            <ul className="text-uppercase no-style large">
              <li>{`1. ${t('table.encryption.description.list.traffic')}`}</li>
              <li>{`2. ${t('table.encryption.description.list.personal')}`}</li>
            </ul>
          </div>
        </div>

        <p>{t('table.encryption.description.personal')}.</p>
        <br />
        <h3>{`${t('table.encryption.description.exceptions.title')}:`}</h3>
        <ul>
          <li>{t('table.encryption.description.exceptions.about')}</li>
          <li>{t('table.encryption.description.exceptions.form')}</li>
        </ul>
        <br />

        <p>{t('table.encryption.description.keys.title')}</p>

        <p>{t('table.encryption.description.email.title')}</p>

        <br />
        {/* <h2 id="surety">{t('table.surety.title')}</h2> */}
        {/* <Link to={routes.GUARANTEE}>{t('table.surety.title')}</Link> */}
      </main>
    </div>
  );
}
