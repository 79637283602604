import classNames from 'classnames';
import { Icon } from 'shared/components/common/Icon/Icon';
import styles from './UserContacts.module.scss';
import { Link, useHistory } from 'react-router-dom';
import { CustomImage } from 'shared/components/common/CustomImage/CustomImage';
import { routes } from 'Routes';
import { useContext } from 'react';
import { GlobalContext } from 'contexts/GlobalContext';
import { EnvironmentModal } from 'components/Modals/ProfileModals/modals/EnvironmentModal';
import { IProfile, ProfileMembership } from 'interfaces/profile.interface';
import { GlobalDataContext } from 'contexts/GlobalDataContext';
import { useTranslation } from 'react-i18next';

type UserContactsProps = {
  className?: string;
  profile: IProfile;
};

export const UserContacts = ({ profile, className }: UserContactsProps) => {
  const history = useHistory();
  const { t } = useTranslation('profile');
  const { openModal } = useContext(GlobalContext);
  const { user } = useContext(GlobalDataContext);
  const isAuthor = profile.username === String(user?.id);

  const { membership } = profile;

  const sortedMembership: {
    member: Partial<ProfileMembership>[];
    admin: Partial<ProfileMembership>[];
  } = {
    member: [],
    admin: isAuthor ? user?.administer : [],
  };

  const sortedSubscribes = {
    organizations: [],
    people: [],
  };
  membership.forEach((el) => {
    switch (el.role_id) {
      case 1:
        if (isAuthor) {
          break;
        }
        sortedMembership.admin.push(el);
        break;
      case 2:
        sortedMembership.member.push(el);
        break;
      case 3:
        sortedSubscribes.organizations.push(el);
        break;
      default:
        sortedMembership.member.push(el);
        break;
    }
  });
  const isHasInfo =
    !!sortedMembership.member.length ||
    !!sortedSubscribes.organizations.length ||
    !!sortedMembership.member.length ||
    sortedMembership.admin.length;

  const modal = () => {
    isHasInfo
      ? openModal({
          params: {
            mainContainer: <EnvironmentModal membership={sortedMembership} subscribes={sortedSubscribes} />,
          },
        })
      : history.push(routes.UNIONS);
  };
  const organizations = [...membership];
  return (
    <div className={classNames(styles.wrapper, className)}>
      <div className={styles.container}>
        <div className={styles.control} onClick={modal}>
          <h5 className={styles.title}>
            {t('environment.title')} <Icon width={24} height={24} iconName="arrowRight" />
          </h5>
        </div>
        <div className={styles.content}>
          {organizations.slice(0, 6).map((el, index) => {
            return (
              <Link to={routes.union.getLink(el.id)} key={`contact-${el.name}-${index}`}>
                <CustomImage isOrganization className={styles.image_border} width={56} height={56} alt={el.name} src={el.avatar} />
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};
