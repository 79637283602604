import classNames from 'classnames';
import { CustomSlider } from 'components/CustomSlider/CustomSlider';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getOrganisationNews } from 'services/news';
import { Icon } from 'shared/components/common/Icon/Icon';
import { NewsInterface } from 'shared/interfaces/news';
import formatServerError from 'shared/utils/formatServerError';
import styles from './ProfileNews.module.scss';
import { routes } from 'Routes';
import { MainCard } from 'components/Crads/NewsCards/MainCard/MainCard';
import { mockImages } from 'shared/constants/MockImages/MockImages';
import { useTranslation } from 'react-i18next';

type CustomMembersProps = {
  organizationId?: number;
  slidesOnPage: number;
  isMobile: boolean;
};

export const ProfileNews = ({ organizationId, slidesOnPage = 1, isMobile }: CustomMembersProps) => {
  const LIMIT = 20;
  const { t } = useTranslation('profile');
  const [activeSlide, setActiveSlide] = useState<number>(0);
  const [news, setNews] = useState<NewsInterface[]>([]);
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);

  const moveSliderRightHandler = () => {
    if (activeSlide === news.length - LIMIT && news.length !== total) {
      loadMembers();
    }
    setActiveSlide((val) => val + 1);
  };
  const moveSliderLeftHandler = () => setActiveSlide((val) => val - 1);

  const loadMembers = async (isNew = false) => {
    try {
      const {
        data,
        meta: { total },
      } = await getOrganisationNews({
        organisationId: organizationId,
        limit: LIMIT,
        page,
      });
      setNews(isNew ? data : [...news, ...data]);
      setTotal(total);
      setPage((val) => val + 1);
    } catch (e) {
      toast(formatServerError(e));
    }
  };
  useEffect(() => {
    setActiveSlide(0);
    setPage(1);
    setNews([]);
    loadMembers(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId]);

  const slides = news.map((el, index) => {
    return (
      <MainCard
        maxHeight={{ content: 95, title: 56 }}
        content={el.excerpt}
        image={el.image || mockImages.news}
        title={el.title}
        author={{ id: el.user_id.id, logo: el.user_id.public_avatar, name: el.user_id.public_name }}
        commentsCount={el.comments_count || 0}
        organization={{ id: el.organization.id, logo: el.organization.avatar, name: el.organization.name }}
        publishedAt={el.published_at}
        withFooter={true}
        link={routes.newsDetails.getLink(el.organization.id, el.id)}
        key={`news-card-${index}`}
        isMobile={isMobile}
      />
    );
  });
  return (
    <div className={classNames(styles.wrapper, { [styles.mobileWrapper]: isMobile })}>
      <div className={classNames(styles.title, { [styles.mobile]: isMobile })}>
        <h4 className={classNames({ [styles.titleTextDesc]: !isMobile })}>{`${t('organization.news.title')}`}</h4>
        <div className={styles.controls}>
          <button className={styles.button} disabled={activeSlide === 0} onClick={moveSliderLeftHandler} title={t('buttons.slider.prev')}>
            <Icon width={24} height={24} iconName={'arrowLeft'} color="black" />
          </button>
          <button
            className={styles.button}
            onClick={moveSliderRightHandler}
            disabled={activeSlide >= slides.length - 1}
            title={t('buttons.slider.next')}
          >
            <Icon width={24} height={24} iconName={'arrowRight'} color="black" />
          </button>
        </div>
      </div>
      <CustomSlider activeSlide={activeSlide} slides={slides} slidesOnPage={slidesOnPage} />
    </div>
  );
};
