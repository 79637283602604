import { routes } from 'Routes';
import styles from '../ContentLayout.module.scss';
import { useTranslation } from 'react-i18next';

const InviteBanner = () => {
  const { t } = useTranslation('auth');
  if (typeof window === 'undefined') {
    return null;
  }
  return (
    <div className={styles.inviteWrapper}>
      <div className={styles.invite}>
        <img src="/logo.svg" alt="ROY TEAM" />
        <div className={styles.inviteButtons}>
          Хочешь больше контента от сообществ?
          <div className={styles.inviteButtonsText}>
            <a href={`${process.env.REACT_APP_BASE_URL}${routes.LOGIN}?redirect=${window?.location.pathname + window?.location.search}`}>
              {t('login.actions.signIn')}
            </a>
            {t('invitation.actions.or')}
            <a
              href={`${process.env.REACT_APP_BASE_URL}${routes.REGISTRATION}?redirect=${
                window?.location.pathname + window?.location.search
              }`}
            >
              {t('login.actions.signUp')}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InviteBanner;
