import styles from './EditBanner.module.scss';
import { toast } from 'react-toastify';
import { Icon } from 'shared/components/common/Icon/Icon';
import Cropper from 'react-cropper';
import classNames from 'classnames';
import bannerImageMock from './join.jpg';
import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from 'contexts/GlobalContext';
import { useTranslation } from 'react-i18next';

type EditBannerProps = {
  src: string;
  width?: number | string;
  height?: number | string;
  isEdit: boolean;
  setCropper: (val: Cropper) => void;
  setImage: (val: string) => void;
  className?: string;
  setIsEditImage: (val: boolean) => void;
  IsEditImage: boolean;
};

export const EditBanner = ({
  src,
  height = '200px',
  width,
  isEdit: isEditMode,
  setImage,
  setCropper,
  className,
  setIsEditImage,
  IsEditImage,
}: EditBannerProps) => {
  const { t } = useTranslation('roy-global');
  const [inputCounter, setInputCounter] = useState(0);
  const {
    spinner: { showSpinner, hideSpinner },
  } = useContext(GlobalContext);
  useEffect(() => {
    setInputCounter(inputCounter);
  }, [isEditMode]);
  const onFileChange = async (files: FileList) => {
    showSpinner();
    setInputCounter(inputCounter + 1);
    const fileReader = new FileReader();
    const fileTypes = ['jpg', 'jpeg', 'png', 'webp', 'apng', 'bmp'];

    if (!files?.length) {
      hideSpinner();
      return;
    }

    if (!files[0].type.includes('image') || !fileTypes.some((type) => files[0].type.includes(type))) {
      toast(t('errors.files.format'));
      hideSpinner();
      return;
    }

    fileReader.onloadend = () => {
      setImage(fileReader.result as string);
    };
    fileReader.onerror = () => {
      toast(t('errors.files.download'));
    };
    fileReader.onabort = () => {
      toast(t('errors.files.download'));
    };
    hideSpinner();
    fileReader.readAsDataURL(files[0]);
  };
  return (
    <div
      className={classNames(styles.wrapper, className)}
      style={{ width: `${width}px` }}
      onClick={() => {
        isEditMode && setIsEditImage(true);
      }}
    >
      {isEditMode && (
        <label htmlFor={'user-banner'}>
          <Icon color="white" iconName="addSquare" />
        </label>
      )}
      {isEditMode ? (
        <Cropper
          key={`banner-cropper-${inputCounter}`}
          className={styles.cropper}
          src={src === '' ? bannerImageMock : src}
          cropBoxResizable={false}
          toggleDragModeOnDblclick={false}
          cropBoxMovable={false}
          autoCropArea={100}
          dragMode={'move'}
          width={width}
          height={height}
          zoom={() => {
            !IsEditImage && setIsEditImage(true);
          }}
          draggable
          onInitialized={(instance) => {
            setCropper(instance);
          }}
        />
      ) : (
        <img
          key={`profile-banner-${inputCounter}`}
          className={styles.image}
          src={src === '' ? bannerImageMock : src}
          alt="user banner"
          width={width}
          height={height}
        />
      )}
      <input
        key={`input-banner-${inputCounter}`}
        style={{ display: 'none' }}
        type="file"
        id={'user-banner'}
        name={'avatar'}
        accept="image/png, image/gif, image/jpeg, image/jpg"
        onChange={(ev) => {
          setIsEditImage(true);
          onFileChange(ev.target.files);
        }}
      />
    </div>
  );
};
