import { useTranslation } from 'react-i18next';
import styles from './BusinessGoals.module.scss';
import { Plan, PlanType } from './Plan/Plan';

export const BusinessGoals = () => {
  const { t } = useTranslation('home');
  const plans: PlanType[] = [
    {
      plan: 800,
      year: 2022,
      text: t('businessGoals.plan.2022'),
    },
    {
      plan: 10000,
      year: 2023,
      text: t('businessGoals.plan.2023'),
    },
    {
      plan: 100000,
      year: 2024,
      text: t('businessGoals.plan.2024'),
    },
    {
      plan: 1000000,
      year: 2025,
      text: t('businessGoals.plan.2025'),
    },
  ];
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.block_header}>
          <h5 className={styles.title}>{t('businessGoals.title')}</h5>
          <div className={styles.list}>
            <ul>
              <li>{t('businessGoals.list.0')}</li>
              <li>{t('businessGoals.list.1')}</li>
            </ul>
          </div>
        </div>
        <div className={styles.plans_list}>
          {plans.map((el) => (
            <Plan plan={el.plan} text={el.text} year={el.year} key={el.year} />
          ))}
        </div>
      </div>
    </div>
  );
};
