import { useContext, useLayoutEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { routes } from 'Routes';
import { GlobalContext } from '../../contexts/GlobalContext';
import { GlobalDataContext } from '../../contexts/GlobalDataContext';

export function VerifyGoogleLogin() {
  const {
    services: { authService, userService },
    spinner: { showSpinner, hideSpinner },
  } = useContext(GlobalContext);
  const history = useHistory();
  const { auth } = useContext(GlobalDataContext);
  const { search } = useLocation();
  function useQuery() {
    return useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();

  const code = query.get('code');

  const verifyGoogleLogin = async () => {
    showSpinner();
    const result = await authService.googleLogin({ code: code });
    hideSpinner();

    if (!!result?.token) {
      const urlSearchParams = new URLSearchParams(search);
      auth.authenticate();
      // Check inviteParams to open invitation page after first login
      if (!!localStorage.getItem('firstSession')) {
        window?.dataLayer.push({
          event: 'event',
          eventProps: {
            category: 'account',
            action: 'login_first_time',
          },
        });
      }
      if (authService.inviteParams) {
        history.push(routes.INVITATION_SCREEN);
      } else {
        history.push(urlSearchParams.get('redirect') ?? routes.PROFILE);
      }
    } else {
      history.push(routes.LOGIN);
    }
  };

  useLayoutEffect(() => {
    (async () => {
      try {
        if (userService.isLoggedIn) {
          await authService.logout();
        }
      } catch (error) {
        console.error(error);
      }
      if (!!code) {
        verifyGoogleLogin();
      }
    })();
  }, [code]);

  return <div />;
}
