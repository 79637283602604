import classNames from 'classnames';
import { Icon } from 'shared/components/common/Icon/Icon';
import styles from './Achievements.module.scss';
import { GlobalContext } from 'contexts/GlobalContext';
import { useContext, useEffect } from 'react';
import { BadgeList } from 'regov/components/BadgeList/BadgeList';
import { IBadge } from 'interfaces/badge.interface';
import { CustomBadge } from 'regov/components/CustomBadge/CustomBadge';
import { useRegov } from 'regov/context';
import { getRegovPassword, isRegovPasswordSet } from 'regov';
import { buildRegovWallet } from 'regov/components/Wallet/wallet.util';
import { useTranslation } from 'react-i18next';

type AchievementsProps = {
  badges: Array<IBadge>;
  className?: string;
};

export const Achievements = ({ badges, className }: AchievementsProps) => {
  const { t } = useTranslation('profile');
  const { openModal } = useContext(GlobalContext);
  const { handler } = useRegov();

  const modal = () => {
    openModal({
      params: {
        mainContainer: <BadgeList handler={handler} badges={badges} />,
      },
    });
  };

  useEffect(() => {
    if (!handler.wallet && handler.stores['default'] && isRegovPasswordSet()) {
      void (async () => {
        handler.wallet = await buildRegovWallet(getRegovPassword(), handler.stores['default']);
      })();
    }
  }, [handler.wallet, getRegovPassword()]);
  if (!badges.length) {
    return null;
  }
  return (
    <div className={classNames(styles.wrapper, className)}>
      <div className={styles.container}>
        <div className={styles.control} onClick={modal}>
          <h5 className={styles.title}>
            {`${t('badges.title')} ${badges.length}`}
            <Icon width={24} height={24} iconName="arrowRight" />
          </h5>
        </div>
        <div className={styles.content}>
          {badges.slice(0, 4).map((el, index) => {
            return <CustomBadge handler={handler} isHideShadow badge={el} key={`badge-trust-${index}`} />;
          })}
        </div>
      </div>
    </div>
  );
};
