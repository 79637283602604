import { ContentEditorSkeleton } from 'components/ContentEditor/ContentEditorSkeleton';
import PublicationMaterialsSettings from './PublicationMaterialsSettings/PublicationMaterialsSettings';
import { useMaterialsCreation } from './hooks/useMaterialsCreation';

export const MaterialsCreation = () => {
  const { onPublish, onSave, uploadImage, initialValues } = useMaterialsCreation();

  return (
    <ContentEditorSkeleton
      ContentSettings={PublicationMaterialsSettings}
      uploadImage={uploadImage}
      onPublish={onPublish}
      onSave={onSave}
      initialValues={initialValues}
    />
  );
};
