import { routes } from 'Routes';
import { CreationNews } from 'interfaces/news.interface';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addGeneralImage } from 'services/general';
import { createNews, publishNews } from 'services/news';
import formatServerError from 'shared/utils/formatServerError';

export const useNewsCreation = () => {
  const { t } = useTranslation('editor');
  const history = useHistory();

  const onPublish = async (orgId: string, data: Partial<CreationNews>) => {
    const created = await saveHandler(orgId, data);
    await publishNews(orgId, created.id);
    history.push(routes.newsDetails.getLink(orgId, created.id));
  };
  const onSave = async (orgId: string, data: Partial<CreationNews>) => {
    await saveHandler(orgId, data);
    /* history.push(routes.newsDetails.getLink(orgId, created.id)); */
  };

  const saveHandler = async (orgId: string, data: Partial<CreationNews>) => {
    try {
      const created = await createNews(orgId, data);
      toast(t('toasts.success.newsCreated'));
      return created;
    } catch (error) {
      toast.error(formatServerError(error));
    }
  };

  const initialValues: CreationNews & { isAgree: boolean } = {
    title: '',
    content: '',
    political: false,
    sensitive: false,
    tags: [],
    preview: undefined,
    visible: 0,
    isAgree: false,
  };
  return {
    uploadImage: addGeneralImage,
    onPublish,
    onSave,
    initialValues,
  };
};
