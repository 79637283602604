import { useTranslation } from 'react-i18next';
import styles from './Together.module.scss';

export const Together = () => {
  const { t } = useTranslation('home');
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.title}>{t('together.description')}</div>
        <div>
          <img src={'/images/grid.svg'} alt={'grid'} width={512} height={451} />
        </div>
      </div>
    </div>
  );
};
