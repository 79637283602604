import classNames from 'classnames';
import { Icon } from 'shared/components/common/Icon/Icon';
import { Icons } from 'shared/components/common/Icon/Icon.interface';
import styles from './StreamCardFooter.module.scss';

export type FooterItem = {
  type?: 'icon' | 'button';
  iconName?: keyof typeof Icons;
  button?: JSX.Element;
  content?: string | JSX.Element;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  isRightSide?: boolean;
  isBottomSide?: boolean;
  iconStyles?: string;
  rotate?: number;
};

type FooterElementProps = {
  icons: FooterItem[];
  className?: string;
};

const FooterElement = ({ item }: { item: FooterItem }) => {
  const { type, iconName, button, content, onClick, iconStyles, rotate } = item;

  if (type === 'button' && button) {
    return button;
  }

  return (
    <div className={classNames(styles.wrapper, { [styles.active]: !!onClick })} onClick={onClick}>
      {(type === 'icon' || !type) && iconName ? (
        <div className={classNames(styles.wrapper, { [styles.active]: !!onClick })} onClick={onClick} key={iconName}>
          <Icon color="disabled" iconName={iconName} className={iconStyles} rotate={rotate} />
          {content && <div className={styles.content}>{content}</div>}
        </div>
      ) : null}
    </div>
  );
};

export const StreamCardFooter = ({ icons, className }: FooterElementProps): JSX.Element => {
  const iconsLeftSide = icons.filter((icon) => !!icon && !icon.isRightSide && !icon.isBottomSide);
  const iconsRightSide = icons.filter((icon) => !!icon && !!icon.isRightSide);
  const iconsBottomSide = icons.filter((icon) => !!icon && !!icon.isBottomSide);

  return (
    <div className={styles.wrapperFooter}>
      <div className={classNames(styles.footer, className)}>
        <div className={styles.leftSide}>
          {iconsLeftSide.map((item, index) => (
            <FooterElement item={item} key={index} />
          ))}
        </div>
        <div className={styles.rightSide}>
          {iconsRightSide.map((item, index) => (
            <FooterElement item={item} key={index} />
          ))}
        </div>
      </div>
      {iconsBottomSide.length > 0 && (
        <div className={styles.bottomSide}>
          {iconsBottomSide.map((item, index) => (
            <FooterElement item={item} key={index} />
          ))}
        </div>
      )}
    </div>
  );
};
