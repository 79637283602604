import { Button } from 'shared/components/common/Button/Button';
import EnterOrganization from '../../EnterOrganization';
import { routes } from 'Routes';
import styles from './Default.module.scss';
import { useContext } from 'react';
import { GlobalDataContext } from 'contexts/GlobalDataContext';
import { useTranslation } from 'react-i18next';

export enum Types {
  success,
  confirmation,
}

interface Props {
  type?: Types;
  id: number;
}

function Default({ type, id }: Props) {
  const { t } = useTranslation('roy-global');
  const { organisations } = useContext(GlobalDataContext);
  // const [subscribeToNotifications, setSubscribeToNotifications] = useState(true);

  let title = t('organization.enterOrganization.succesful.title');
  let text = t('organization.enterOrganization.succesful.text');

  if (type === Types.confirmation) {
    title = t('organization.enterOrganization.awaitAdmin.title');
    text = t('organization.enterOrganization.awaitAdmin.text');
  }

  const union = organisations.find((org) => org.id === id);
  // rename variable
  const closedUnion = !union || (union.public_status === 1 && type === Types.confirmation);

  return (
    <EnterOrganization.Container title={title} className={styles.wrapper}>
      <div className={styles.wrapperContent}>
        <p>{text}</p>
        <img src={require('./images/people-together.png').default} alt={t('enterOrganization.together')} />
      </div>

      <Button className={styles.button} to={!closedUnion ? routes.union.getLink(id) : routes.UNIONS}>
        {!closedUnion ? t('organization.enterOrganization.goToOrg') : t('organization.enterOrganization.allOrgs')}
      </Button>
      {/* <Checkbox
        className={styles.checkbox}
        label="Я согласен принимать уведомления от данного обьединения"
        valueChange={setSubscribeToNotifications}
        value={subscribeToNotifications}
      /> */}
    </EnterOrganization.Container>
  );
}

Default.Types = Types;

export default Default;
