import classNames from 'classnames';
import { Icon } from 'shared/components/common/Icon/Icon';
import styles from './HideProfileButton.module.scss';
import { useTranslation } from 'react-i18next';

type CloseButtonProps = {
  setIsOpenInfo: (val: boolean) => void;
  setIsOpenStream: (val: boolean) => void;
  isOpenInfo: boolean;
  isOpenStream: boolean;
};

export const HideProfileButton = ({ isOpenInfo, isOpenStream, setIsOpenInfo, setIsOpenStream }: CloseButtonProps) => {
  const { t } = useTranslation('profile');
  const clickLeft = () => {
    if (isOpenInfo && isOpenStream) {
      setIsOpenInfo(false);
    } else {
      setIsOpenStream(true);
    }
  };
  const clickRight = () => {
    if (isOpenInfo && isOpenStream) {
      setIsOpenStream(false);
    } else {
      setIsOpenInfo(true);
    }
  };
  return (
    <div className={styles.wrapper}>
      {isOpenInfo && (
        <button
          className={classNames(styles.button)}
          onClick={clickLeft}
          title={isOpenStream ? t('feed.buttons.hideProfile') : t('feed.buttons.showFeed')}
        >
          <Icon iconName={'arrowBorderLeft'} color="black" />
        </button>
      )}
      {isOpenStream && (
        <button
          className={classNames(styles.button, { [styles.hide]: isOpenStream && isOpenInfo })}
          onClick={clickRight}
          title={isOpenInfo ? t('feed.buttons.hideFeed') : t('feed.buttons.showProfile')}
        >
          <Icon rotate={180} iconName={'arrowBorderLeft'} color="black" />
        </button>
      )}
    </div>
  );
};
