import classNames from 'classnames';
import { CONTENT_TYPE } from 'interfaces/streamContent';
import { Link } from 'react-router-dom';
import { Icon } from 'shared/components/common/Icon/Icon';
import { Icons } from 'shared/components/common/Icon/Icon.interface';
import styles from './CreateButtons.module.scss';
import { useContext } from 'react';
import { GlobalContext } from 'contexts/GlobalContext';
import { useTranslation } from 'react-i18next';

type CreateButtonsProps = {
  type: CONTENT_TYPE | 'article' | 'linkMaterial';
  to: string;
  isActive: boolean;
  iconName?: keyof typeof Icons;
};

export const CreateButtons = ({ isActive, to, type, iconName }: CreateButtonsProps) => {
  const { t } = useTranslation('content');
  const { closeModal } = useContext(GlobalContext);
  return (
    <Link
      title={`${t(`single.${type.toLocaleLowerCase()}`).toLocaleLowerCase()}`}
      onClick={isActive && closeModal}
      to={isActive ? to : '#'}
      className={classNames(styles.wrapper, { [styles.active]: isActive })}
    >
      <Icon iconName={iconName} width={20} height={20} />
      <p>{t(`single.${type.toLocaleLowerCase()}`)}</p>
    </Link>
  );
};
