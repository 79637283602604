import { Checkbox } from 'shared/components/common/Checkbox/Checkbox';
import styles from './Agreements.module.scss';
import { useTranslation } from 'react-i18next';

type Props = {
  values: {
    political: boolean;
    sensitive: boolean;
    isAgree: boolean;
  };
  setValue: (field: 'political' | 'sensitive' | 'isAgree', val: boolean) => void;
};

export const Agreements = ({ setValue, values }: Props) => {
  const { t } = useTranslation('editor');
  return (
    <div className={styles.wrapper}>
      <Checkbox
        className={styles.checkBox}
        value={values.political}
        valueChange={() => setValue('political', !values.political)}
        type="small"
        label={t('politicalContent')}
      />
      <Checkbox
        className={styles.checkBox}
        value={values.sensitive}
        valueChange={() => setValue('sensitive', !values.sensitive)}
        type="small"
        label={t('sensitiveContent')}
      />
      <Checkbox
        className={styles.checkBox}
        value={values.isAgree}
        valueChange={() => setValue('isAgree', !values.isAgree)}
        type="small"
        label={`${t('agree')}*`}
      />
    </div>
  );
};
