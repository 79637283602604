import { useDispatch as useReduxDispatch, useSelector as useReduxSelector } from 'react-redux';
import type { TypedUseSelectorHook } from 'react-redux';
import type { ThunkAction } from 'redux-thunk';
import type { Action } from '@reduxjs/toolkit';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';

export const store = () =>
  configureStore({
    reducer: rootReducer,
    devTools: true,
  });

export type RootState = ReturnType<typeof rootReducer>;

export type AppStore = ReturnType<typeof store>;

export type AppDispatch = AppStore['dispatch'];

export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export const useDispatch = () => useReduxDispatch<AppDispatch>();
