import classNames from 'classnames';
import { CustomSlider } from 'components/CustomSlider/CustomSlider';
import { useState } from 'react';
import { Icon } from 'shared/components/common/Icon/Icon';
import styles from './ImagesSlider.module.scss';
import { useTranslation } from 'react-i18next';

type ImagesSliderProps = {
  images: Array<string>;
  id: string;
  type: string;
};

export const ImagesSlider = ({ images, id, type }: ImagesSliderProps) => {
  const { t } = useTranslation('profile');
  const [activeSlide, setActiveSlide] = useState<number>(0);

  const slides = images.map((el, index) => {
    return (
      <div key={`${type}-${id}-slide-${index}`} className={styles.image}>
        <img src={el} alt={`${id}-img-${index}`} width={'100%'} height={'auto'} />
      </div>
    );
  });

  return (
    <div className={styles.wrapper}>
      <button
        className={classNames(styles.button, styles.previous)}
        disabled={activeSlide === 0}
        onClick={() => setActiveSlide(activeSlide - 1)}
        title={t('buttons.slider.prev')}
      >
        <Icon width={24} height={24} iconName={'arrowLeft'} color="black" />
      </button>
      <CustomSlider activeSlide={activeSlide} slides={slides} slidesOnPage={1} />
      <button
        className={classNames(styles.button, styles.next)}
        onClick={() => setActiveSlide(activeSlide + 1)}
        disabled={activeSlide >= slides.length - 1}
        title={t('buttons.slider.next')}
      >
        <Icon width={24} height={24} iconName={'arrowRight'} color="black" />
      </button>
    </div>
  );
};
