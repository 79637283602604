import { SimpleRoutingContainer } from 'common/Routing/SimpleRoutingContainer/SimpleRoutingContainer';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import formatServerError from 'utils/formatServerError';
import { Button } from '../../shared/components/common/Button/Button';
import { Icon } from '../../shared/components/common/Icon/Icon';
import { GlobalContext } from '../../contexts/GlobalContext';
import { GlobalDataContext } from '../../contexts/GlobalDataContext';
import styles from './InviteColleague.module.scss';
import { routes } from 'Routes';
import { Footer } from '../Footer/Footer';
import { useTranslation } from 'react-i18next';

export function InviteColleague() {
  // ToDo to delete
  const { t } = useTranslation('roy-global');
  const {
    services: { inviteLinksService },
    spinner: { showSpinner, hideSpinner },
  } = useContext(GlobalContext);
  const { selectedOrganisation } = useContext(GlobalDataContext);

  const [linkWasGenerated, setLinkWasGeneratedStatus] = useState<boolean>(false);
  const [linkWasCopied, setLinkWasCopiedStatus] = useState<boolean>(false);
  const [inviteUrl, setInviteUrl] = useState<string>();
  const [isLimitExceeded] = useState<boolean>(false);

  // const isMember = selectedOrganisation && !!user.membership.find((o) => o.id === selectedOrganisation.id);

  const showCopyLinkButton = !!navigator?.clipboard?.writeText;

  const onGenerateLink = async () => {
    try {
      showSpinner();
      const result = await inviteLinksService.createInviteLink(selectedOrganisation?.id);
      // if (result.invites === 0) {
      //   setIsLimitExceededStatus(true);
      //   hideSpinner();
      //   return;
      // }

      const { id, code } = result;

      const link = inviteLinksService.generateInviteLink(id, code);
      setInviteUrl(link);

      if (!linkWasGenerated) {
        setLinkWasGeneratedStatus(true);
      }
    } catch (error) {
      toast.error(formatServerError(error));
    } finally {
      hideSpinner();
    }
  };

  const onCopyLink = async (link) => {
    await navigator?.clipboard?.writeText(t('invite.indeed') + link);
    setLinkWasCopiedStatus(true);
  };

  return (
    <SimpleRoutingContainer
      showCloseButton
      title={t('invite.title')}
      logo={{
        src: selectedOrganisation?.avatar,
        alt: selectedOrganisation?.name,
      }}
      hideFooter
      className={styles.Main}
    >
      <div className={styles.InviteColleague}>
        {selectedOrganisation && (
          <p className={styles.inviteOrganisation}>
            {`${t('invite.invite')} `}
            <Link to={routes.union.getLink(selectedOrganisation.id)}>{selectedOrganisation?.name}</Link>
          </p>
        )}

        <div className={styles.content}>
          {isLimitExceeded && (
            <p>
              {t('invite.two')}
              <br />
              {t('invite.limit')}
            </p>
          )}

          {!isLimitExceeded &&
            (linkWasGenerated ? (
              <div className={styles.linkCopied}>
                <br />
                <div className={styles.info}>
                  <h3>{t('invite.invitation')}</h3>
                </div>
                <br />
                <div className={styles.link}>
                  <span className="highlight" data-cy="invite-url">
                    {inviteUrl}
                  </span>
                </div>
                <br />
                <div>{linkWasCopied && <span>{t('invite.copy')}</span>}</div>
              </div>
            ) : (
              <>
                <div className={`${styles.card} p`}>
                  <div>
                    <div className={styles.icon}>
                      <Icon iconName="bell" width={31} height={34} />
                    </div>
                    <div className={styles.info}>
                      <h3>{t('invite.security')}</h3>
                    </div>
                  </div>
                  <div>
                    <div className={styles.icon}>
                      <Icon iconName="group" width={53} height={38} />
                    </div>
                    <div className={styles.info}>
                      <h3>{t('invite.platform')}</h3>
                    </div>
                  </div>
                </div>
              </>
            ))}
        </div>
        <div className={styles.actions}>
          <Footer>
            {linkWasGenerated ? (
              <>
                {showCopyLinkButton && (
                  <Button
                    color={!linkWasCopied ? 'primary' : 'light'}
                    icon="group"
                    onClick={onCopyLink.bind(null, inviteUrl)}
                    dataCy="copy-link"
                  >
                    {t('invite.copyLink')}
                  </Button>
                )}
              </>
            ) : (
              <>
                <Button dataCy="generate-link" icon="group" onClick={onGenerateLink} disabled={isLimitExceeded}>
                  {t('invite.generateLink')}
                </Button>
              </>
            )}
          </Footer>
        </div>
      </div>
    </SimpleRoutingContainer>
  );
}
