import styles from './StreamEventCard.module.scss';
import { Author, CONTENT_TYPE } from 'interfaces/streamContent';
import { CustomImage } from 'shared/components/common/CustomImage/CustomImage';
import { StreamCardFooter, FooterItem } from '../../StreamCardFooter/StreamCardFooter';
import { useEffect, useRef, useState } from 'react';
import Tags from 'common/Tags/Tags';
import { Link, useLocation } from 'react-router-dom';
import { routes } from 'Routes';
import { toast } from 'react-toastify';
import { Icon } from 'shared/components/common/Icon/Icon';
import { formatDistanceToNow, format } from 'date-fns';
import { ru, be, enGB, lt, uk } from 'date-fns/locale';
import { EnumContentType } from 'containers/ContentLayout/ContentLayoutTypes';
import { OrganizationShort } from 'shared/interfaces/organization';
import { ImagesSlider } from '../../ImagesSlider/ImagesSlider';
import { allowedAttributesSchema, allowedTagsSynitizer, allowedIframeHostnamesSchema } from 'shared/constants/allowedTagsSynitizer';
import sanitizeHtml from 'sanitize-html';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { formatDateForGoogle } from 'utils/formatDateForGoogle';
// import { Button } from 'shared/components/common/Button/Button';

type StreamEventCardProps = {
  type: CONTENT_TYPE;
  id: number;
  title: string;
  images?: Array<string>;
  author: Author;
  excerpt: string;
  commentsCount: number;
  tags: Array<string>;
  watches?: number;
  published_at: Date;
  organization: OrganizationShort;
  date?: { dateStart: Date; dateEnd?: Date };
  place?: string;
  price?: string;
};

export const StreamEventCard = ({
  author,
  commentsCount,
  excerpt,
  id,
  organization,
  published_at,
  tags,
  title,
  watches,
  date,
  images,
  type,
  place,
  price,
}: StreamEventCardProps): JSX.Element => {
  const location = useLocation();
  const { t, i18n } = useTranslation('profile');
  const locales = {
    ru,
    be,
    enGB,
    lt,
    uk,
  };
  const [showHashtag, setShowHashtag] = useState(true);
  const [showFullText, setShowFullText] = useState(false);
  const temporaryImages = images?.length ? images : [];
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current.scrollHeight > containerRef.current.offsetHeight) {
    } else {
      setShowFullText(true);
    }
  }, []);
  const eventDate = `${format(new Date(date.dateStart || Date.now()), "dd MMMM yyyy ':' HH:mm", {
    locale: ru,
  })}${
    date.dateEnd
      ? ` - ${format(new Date(date.dateEnd || Date.now()), "dd MMMM yyyy ':' HH:mm", {
          locale: ru,
        })}`
      : ''
  }`;
  const icons: FooterItem[] = [
    {
      iconName: 'clock',
      content: formatDistanceToNow(new Date(published_at), {
        locale: locales[i18n.language],
        addSuffix: false,
      }),
    },
    // {iconName: 'like', content: `${likes}`},
    // { iconName: 'eye', content: `${watches}` },
    { iconName: 'comments', content: `${commentsCount}` },
    {
      iconName: 'hashtag',
      onClick: () => setShowHashtag(!showHashtag),
      content: <Icon rotate={showHashtag ? 180 : 0} iconName="arrowBottom" />,
    },
    {
      iconName: 'link',
      onClick: (e) => {
        e.preventDefault();
        e.stopPropagation();
        navigator.clipboard.writeText(`${process.env.REACT_APP_BASE_URL}${getUrl()}`);
        toast(t('toast.copyLink'));
      },
    },
  ];

  const getUrl = (): string => {
    switch (type) {
      case 'material':
        return routes.materialDetails.getLink(organization.id, id);
      case 'news':
        return routes.newsDetails.getLink(organization.id, id);
      case 'quiz':
        return routes.poll.getLink(organization.id, id);
      case 'task':
        return routes.task.getLink(organization.id, id);
      case 'event':
        return routes.eventsDetails.getLink(organization.id, id);
    }
  };

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <Link
            to={{
              pathname: routes.union.getLink(organization.id),
              state: {
                from: location.pathname,
              },
            }}
            className={styles.imageLink}
          >
            <CustomImage isOrganization src={organization.avatar} alt={organization.name} width={55} height={55} className={styles.image} />
          </Link>
          <div className={styles.title}>
            <Link
              to={{
                pathname: getUrl(),
                state: {
                  from: location.pathname,
                },
              }}
            >
              <h4>{title}</h4>
            </Link>
            <Link to={routes.profile.getLink(author.id)}>
              <span className={styles.author_name}>@{author.public_name ?? author.public_family ?? t('anonym')}</span>
            </Link>
          </div>
        </div>
        {!!temporaryImages.length && <ImagesSlider key={`${type}-${id}-slider`} type={type} images={temporaryImages} id={String(id)} />}
        <div className={styles.eventExtra}>
          <div className={styles.info}>
            {!!date && (
              <a
                href={`http://www.google.com/calendar/event?action=TEMPLATE&text=${title}&dates=${formatDateForGoogle(date.dateStart)}${
                  date.dateEnd ? `/${formatDateForGoogle(date.dateEnd)}` : ''
                }&details=<span>${t('social.helperText')}: </span><a href='${
                  window?.location
                }' target='_blank'>${title}</a><br />${title}&location=${place || ''}&trp=false&sprop=&sprop=name:`}
                target="_blank"
                rel="nofollow noreferrer"
              >
                <h4>
                  <Icon color="black" height={24} width={24} iconName={'calendar'} />
                  {eventDate}
                </h4>
              </a>
            )}
            {!!place && (
              <a href={`https://www.google.com/maps/search/${place}'`} target="_blank" rel="noreferrer">
                <h4>
                  <Icon color="black" height={24} width={24} iconName={'location'} />
                  {place}
                </h4>
              </a>
            )}
            {!!price && price !== '0' && (
              <h4>
                <Icon color="black" height={24} width={24} iconName={'wallet'} />
                {price}
              </h4>
            )}
          </div>
          {/* <Button color="light" size="small" className={styles.submitButton}>
            Присоединиться
          </Button> */}
        </div>
        <div className={classNames(styles.content, { [styles.visible]: showFullText })} ref={containerRef}>
          <Link
            to={{
              pathname: getUrl(),
              state: {
                from: location.pathname,
              },
            }}
          >
            <div
              className={styles.description}
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(excerpt, {
                  allowedTags: allowedTagsSynitizer,
                  allowedAttributes: allowedAttributesSchema,
                  allowedIframeHostnames: allowedIframeHostnamesSchema,
                }),
              }}
            />
          </Link>
        </div>
        <StreamCardFooter icons={icons} />
        {showHashtag && <Tags tags={[type, ...tags]} contentTypes={EnumContentType[type]} />}
      </div>
    </>
  );
};
