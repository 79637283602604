import classNames from 'classnames';
import { NewsCard } from 'components/Crads/NewsCards';
import { routes } from 'Routes';
import { KbaseMaterialFront } from 'shared/interfaces/kbase';
import { NewsInterface } from 'shared/interfaces/news';
import styles from './CardsAssemble.module.scss';

type MainGroupCardsProps = {
  items: Array<NewsInterface | KbaseMaterialFront>;
  isReverse: boolean;
  isMobile: boolean;
};

export const MainGroupCards = ({ isReverse, items, isMobile }: MainGroupCardsProps) => {
  const small = items.slice(1, 4);

  const extractAuthor = (el) => {
    const author = {
      id: el.user_id ? el.user_id.id : el.user.id,
      logo: el.user_id ? el.user_id.public_avatar : el.user.public_avatar,
      name: el.user_id ? el.user_id.public_name : el.user.public_name,
    };
    return author;
  };
  const getLink = (el) => {
    const link = el.user_id
      ? routes.newsDetails.getLink(el.organization.id, el.id)
      : routes.materialDetails.getLink(el.organization.id, el.id);
    return link;
  };

  return (
    <div className={classNames(styles.main, { [styles.reverse]: isReverse })}>
      <NewsCard
        variant={isMobile ? 'main' : 'big'}
        content={items[0].excerpt}
        image={items[0].image}
        title={items[0].title}
        author={extractAuthor(items[0])}
        commentsCount={items[0].comments_count || 0}
        organization={{ id: items[0].organization.id, logo: items[0].organization.avatar, name: items[0].organization.name }}
        publishedAt={items[0].published_at}
        withFooter={true}
        link={getLink(items[0])}
        isMobile={isMobile}
      />
      <div className={styles.container}>
        {small.map((el: NewsInterface | KbaseMaterialFront, index: number) => {
          return (
            <NewsCard
              variant={isMobile ? 'main' : 'small'}
              content={el.excerpt}
              image={el.image}
              title={el.title}
              author={extractAuthor(el)}
              commentsCount={el.comments_count || 0}
              organization={{ id: el.organization.id, logo: el.organization.avatar, name: el.organization.name }}
              publishedAt={el.published_at}
              withFooter={true}
              link={getLink(el)}
              key={`news-main-small-card-${index}`}
              isMobile={isMobile}
            />
          );
        })}
      </div>
    </div>
  );
};
