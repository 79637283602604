import { MouseEventHandler, forwardRef, useEffect, useState } from 'react';
import KanbanCardModal from '../KanbanCardModal/KanbanCardModal';
import styles from './KanbanCard.module.scss';
import { CardInfo } from 'shared/services/tasks.service';
import { toast } from 'react-toastify';
import { useLocation, useHistory } from 'react-router';
import { Icon } from 'shared/components/common/Icon/Icon';
import { CustomImage } from 'shared/components/common/CustomImage/CustomImage';
import KanbanCardModalActions from '../KanbanCardModalActions/KanbanCardModalActions';
import { routes } from 'Routes';
import { Button } from 'shared/components/common/Button/Button';
import { assignMeTask } from 'shared/services/tasks.service';
import formatServerError from 'shared/utils/formatServerError';
import { OrganizationShort } from 'shared/interfaces/organization';
import { UserShort } from 'shared/interfaces/user';
import classNames from 'classnames';
import { deleteTask } from 'shared/services/tasks.service';
import { useTranslation } from 'react-i18next';

interface Props {
  card: CardInfo;
  dragging: boolean;
  fetchTasks: () => void;
  openModal: (component: object, modalProps: object) => void;
  closeModal: () => void;
  getOrganizationUsers: (value: { organizationId?: string }) => Promise<{ data: OrganizationShort[] }>;
  isAdminApp?: boolean;
  orgId?: string;
  userInOrganization: boolean;
  user: UserShort;
  isSuggestion?: boolean;
}

const KanbanCard = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { t } = useTranslation('shared');
  const {
    card,
    getOrganizationUsers,
    fetchTasks,
    openModal,
    closeModal,
    user,
    isAdminApp,
    orgId,
    userInOrganization,
    isSuggestion = false,
    ...other
  } = props;
  const location = useLocation();
  const { search } = location;
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const goToTaskScreen = (): void => {
    // @ts-ignore
    history.push(routes?.task.getLink(orgId, card.id));
  };
  const handleDelete: MouseEventHandler<HTMLDivElement> = async (ev) => {
    ev.stopPropagation();
    ev.preventDefault();
    try {
      await deleteTask(orgId, card.id);
      toast(t('toast.deleteTask'));
      fetchTasks();
    } catch (e) {
      toast.error(formatServerError(e));
    }
  };
  const handleClose = (): void => {
    fetchTasks();
    closeModal();
  };
  const handleOpen = (): void => {
    if (!card.column_id && !!card.suggestion_id) {
      window.open(`${process.env.REACT_APP_CLIENT_APP_URL}/union/${orgId}/suggestion/${card.suggestion_id}`, '_blank');
    } else if (isAdminApp) {
      openModal(
        <KanbanCardModal
          orgId={orgId}
          openModal={openModal}
          card={card}
          getOrganizationUsers={getOrganizationUsers}
          closeModal={closeModal}
          isAdminApp={isAdminApp}
          user={user}
        />,
        { isTask: true, actions: <KanbanCardModalActions card={card} onClose={handleClose} orgId={orgId} /> }
      );
    } else {
      goToTaskScreen();
    }
  };

  useEffect(() => {
    if (search && search.split('=')[1] === String(card.id)) {
      handleOpen();
    }
  }, []);

  const cardUsers = () => {
    const maxLength = 4;
    const result = card.users.slice(0, maxLength).map((user) => {
      return <CustomImage key={user.id} src={user.public_avatar} alt="user logo" width={24} height={24} />;
    });

    if (card.users.length > maxLength) {
      result.push(<span className={styles.UsersLogo}>+{card.users.length - maxLength}</span>);
    }

    return result.reverse();
  };

  return (
    <>
      <div ref={ref} className={styles.KanbanCard} {...other}>
        <div onClick={handleOpen} className={styles.Card}>
          {card.image && (
            <div className={styles.CardMedia}>
              <img src={card.image} alt="cardlogo" />
            </div>
          )}
          <div className={styles.CardContent}>
            <div className={styles.CardTitle}>
              <span>{card.title}</span>
              {!!card?.suggestion_id && (
                <div title={t('kanban.card.task')}>
                  <Icon iconName="bulb" className={styles.wrapperIconSuggestion} />
                </div>
              )}
            </div>
            <div className={`${styles.FlexCenter} ${styles.CardContentContainer}`}>
              {userInOrganization &&
                !card.users.find((u) => u.id === user.id) &&
                card.can_self_assign &&
                !!card?.visibility && (
                  <Button
                    disabled={isLoading}
                    size="small"
                    className={styles.asignBtn}
                    onClick={async (e) => {
                      e.stopPropagation();
                      try {
                        setIsLoading(true);
                        await assignMeTask(orgId, card.id);

                        window.dataLayer.push({
                          event: 'event',
                          eventProps: {
                            category: 'task',
                            action: 'assign_to_me',
                          },
                        });

                        toast(t('toast.getTask'));
                        goToTaskScreen();
                      } catch (error) {
                        toast.error(formatServerError(error));
                        console.dir(error);
                      }
                      setIsLoading(false);
                    }}
                  >
                    {t('actions.getTask')}
                  </Button>
                )}
              <div className={`${styles.controls}`}>
                <div className={`${styles.FlexCenter}`}>
                  <div
                    title={t('kanban.card.copy')}
                    className={styles.IconWrapper}
                    onClick={(ev) => {
                      ev.stopPropagation();
                      if (isAdminApp) {
                        navigator.clipboard.writeText(`${window.location}?cardId=${card.id}`);
                      } else {
                        navigator.clipboard.writeText(`${window.location}/tasks/${card.id}`);
                      }

                      window.dataLayer.push({
                        event: 'event',
                        eventProps: {
                          category: 'task',
                          action: 'share',
                          label: 'kanban',
                        },
                      });

                      toast(t('toast.copyLink'));
                    }}
                  >
                    <Icon iconName="share" />
                  </div>
                  {card.is_urgent && (
                    <div
                      title={t('kanban.card.urgent')}
                      className={classNames(styles.lightIconWrapper, { [styles.coloredlightIcon]: !isAdminApp })}
                    >
                      <Icon iconName="light" />
                    </div>
                  )}
                </div>
                {isSuggestion && (
                  <div onClick={handleDelete} className={styles.IconWrapper} title={t('kanban.card.delete')}>
                    <Icon iconName="trash" />
                  </div>
                )}
                {card.users.length > 0 && <div className={styles.UsersContainer}>{cardUsers()}</div>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default KanbanCard;
