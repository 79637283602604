import { useContext, useEffect } from 'react';

import { useSsrEffect, useSsrState } from '@issr/core';
import { GlobalContext } from 'contexts/GlobalContext';

import { toast } from 'react-toastify';
import formatServerError from 'shared/utils/formatServerError';
import ActivityGroup from 'components/ActivityGroup/ActivityGroup';
import { IGetOrganizationEvents, getEvents, getOrganizationEvents } from 'services/events';
import { IContentPreview } from 'shared/interfaces/feed';

export enum EventsDataTypes {
  day = 'day',
  popular = 'popular',
  allEvents = 'allEvents',
  organisationNews = 'organisation-news',
  bestNews = 'bestNews',
  tagsNews = 'tagsNews',
  organisationNewsPage = 'organisationNewsPage',
}

const config = {
  [EventsDataTypes.popular]: {
    method: getEvents,
    sort: { sortBy: 'popular' } as IGetOrganizationEvents,
    title: 'events.popular',
  },
  [EventsDataTypes.bestNews]: {
    method: getEvents,
    sort: { featured: 1 } as IGetOrganizationEvents,
    title: 'events.bestNews',
  },
  [EventsDataTypes.allEvents]: {
    method: getEvents,
    sort: { sortBy: 'published_at' } as IGetOrganizationEvents,
    title: 'events.allNews',
  },
  [EventsDataTypes.tagsNews]: {
    method: getEvents,
    sort: {} as IGetOrganizationEvents,
    title: '',
  },
  [EventsDataTypes.organisationNews]: {
    method: getOrganizationEvents,
    title: 'events.default',
  },
  [EventsDataTypes.organisationNewsPage]: {
    method: getOrganizationEvents,
    title: 'events.default',
  },
  org: {
    method: getOrganizationEvents,
    title: 'events.default',
  },
};

interface Props {
  type?: EventsDataTypes;
  organisationId?: number;
  tags?: string[];
}

const EventsGroupContainer = ({ type, organisationId, tags }: Props) => {
  const {
    isMounted,
    spinner: { showSpinner, hideSpinner },
  } = useContext(GlobalContext);

  const [events, setEvents] = useSsrState<IContentPreview[]>([]);
  const [metaPageData, setMetaPageData] = useSsrState<{ last_page: number; current_page: number }>(null);

  useEffect(() => {
    setEvents([]);
    setMetaPageData(null);
  }, []);

  const loadEvents = async (limit?: number) => {
    let requestedEvents = [];

    if (type) {
      const { method, sort } = config[type];

      const obj = {
        ...sort,
        ...{
          organisationId,
          tags,
          limit: 12,
          page: metaPageData?.current_page ? metaPageData.current_page + 1 : 1,
        },
      };

      !!limit && showSpinner();
      const { data, meta } = await method(obj);

      requestedEvents = data;

      setMetaPageData({ current_page: meta.current_page, last_page: meta.last_page });

      hideSpinner();
    }

    setEvents(!isMounted ? [] : [...events, ...requestedEvents] ? requestedEvents : []);
  };

  useSsrEffect(async () => {
    setEvents([]);
    setMetaPageData(null);
    await loadEvents(12);
    return () => {
      setEvents([]);
      setMetaPageData(null);
    };
  }, type);

  useEffect(() => {
    if (isMounted) {
      (async () => {
        try {
          await loadEvents(12);
        } catch (e) {
          toast.error(formatServerError(e));
        }
      })();
    }
    return () => {
      setEvents([]);
      setMetaPageData(null);
    };
  }, [type, organisationId, isMounted, tags]);

  if (!events?.length) {
    return null;
  }

  return (
    <ActivityGroup
      loadData={loadEvents}
      hasMoreData={metaPageData?.current_page < metaPageData?.last_page}
      activities={events.map((event) => {
        return { ...event, type: 'event' };
      })}
      type="events"
    />
  );
};

export default EventsGroupContainer;
